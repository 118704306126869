import React, { useState, useEffect } from 'react';
import Media from 'react-media';
import { useSelector, useDispatch } from 'react-redux';
import { toastr } from 'react-redux-toastr';
import { useRouteMatch } from 'react-router-dom';

import {
  FormControlLabel,
  Checkbox,
  Slider,
  Input,
  CircularProgress,
} from '@material-ui/core';
import {
  CheckBoxOutlineBlank,
  CheckBox,
  IndeterminateCheckBox,
} from '@material-ui/icons';

import errorIcon from '../../../../../../../../../assets/imgs/error-icon.png';
import { CustomButton } from '../../../../../../../../../components/CustomButton';
import { SearchField } from '../../../../../../../../../components/SearchField';
import { IndicatorAlert } from '../../../../../../../../../models/AssetInfo';
import { Creators } from '../../../../../../../../../store/actionCreators';

import { supportedDevices } from '../../../../../../../../../styles/supportedDevices';
import { Container, SliderValueLabel } from './styles';
import { getCompanyEmails, useAuthStore } from '../../../../../../../../../zustand/AuthStore';

const formColumns: [keyof IndicatorAlert, string][] = [
  ['kpiName', 'Indicadores'],
  ['alertValue', 'Valor mínimo'],
  ['returnValue', 'Valor de retorno'],
  ['enable', 'Ativo/Inativo'],
];

interface MatchParams {
  ativoPath: string;
}

interface AlertFormState {
  id: null | number;
  kpiUuid: string;
  alertValue: number;
  returnValue: number;
  enable: boolean;
}

export interface EditAlertModalProps {
  /**
   * On cancel button click callback
   */
  handleCancel(): void;
}

/**
 * Asset alerts editing modal
 */
export const EditAlertModal: React.FC<EditAlertModalProps> = ({
  handleCancel,
}) => {
  const {
    alerts: { isLoading, error, data },
  } = useSelector((state) => state.assetData);
  // const {
  //   user: { companyEmails },
  // } = useSelector((state) => state.auth);

  const companyEmails = useAuthStore((state) => state.user.companyEmails);

  const dispatch = useDispatch();

  const match = useRouteMatch<MatchParams>();

  const [isAllSelected, setIsAllSelected] = useState<'none' | 'all' | 'some'>(
    'none'
  );
  const [formState, setFormState] = useState<{ [key: string]: AlertFormState }>(
    {}
  );
  const [selectedEmails, setSelectedEmails] = useState<string[]>([]);

  // Request company emails
  useEffect(() => {
    async function initial(){
      await getCompanyEmails()

    }
    initial()
    // dispatch(Creators.getCompanyEmailsRequest());
  }, [
    // dispatch
  ]);

  // Request initial alert data
  useEffect(() => {
    dispatch(Creators.getAssetAlertRequest(match.params.ativoPath));
  }, [dispatch, match.params.ativoPath]);

  // Sets initial value for forms
  useEffect(() => {
    if (data && !Object.keys(formState).length) {
      let newFormState: typeof formState = {};
      data.kpiAlertList.forEach((indicator) => {
        newFormState = {
          ...newFormState,
          [indicator.kpiName]: {
            id: indicator.id,
            kpiUuid: indicator.kpiUuid,
            enable: indicator.enable,
            alertValue: Number(
              Math.floor(indicator.alertValue * 100).toFixed(2)
            ),
            returnValue: Number(
              Math.floor(indicator.returnValue * 100).toFixed(2)
            ),
          },
        };
      });
      setFormState(newFormState);
    }
  }, [data, formState]);

  // Sets initial emails
  useEffect(() => {
    if (data && data?.emails.length) setSelectedEmails(data?.emails);
  }, [data]);

  // Set initial all checkbox state
  useEffect(() => {
    if (Object.keys(formState).length) {
      let hasDisabled = false;
      let hasEnabled = false;

      Object.keys(formState).forEach((key) => {
        if (formState[key].enable) hasEnabled = true;
        else hasDisabled = true;
      });

      if (hasEnabled && hasDisabled) setIsAllSelected('some');
      else if (hasDisabled) setIsAllSelected('none');
      else setIsAllSelected('all');
    }
  }, [formState]);

  function handleAllCheckboxChange() {
    const newState = isAllSelected === 'all';
    setIsAllSelected(newState ? 'none' : 'all');
    if (Object.keys(formState).length)
      setFormState((state) =>
        Object.fromEntries(
          Object.entries(state).map(([key, value]) => [
            key,
            {
              ...value,
              enable: !newState,
            },
          ])
        )
      );
  }

  function handleEmailSearchChange(_: React.ChangeEvent<{}>, value: string[]) {
    setSelectedEmails(value);
  }

  function handleConfirm() {
    if (
      Object.keys(formState).find(
        (key) =>
          formState[key].enable &&
          formState[key].alertValue > formState[key].returnValue
      )
    )
      toastr.error(
        'Alerta não permitido',
        'Os valores de alerta devem ser menores que os valores de retorno.'
      );
    else {
      dispatch(
        Creators.updateAssetAlertRequest({
          assetUuid: match.params.ativoPath,
          emails: selectedEmails,
          kpiAlertList: Object.keys(formState).map((key) => ({
            ...formState[key],
            kpiName: key,
            alertValue: formState[key].alertValue / 100,
            returnValue:formState[key].returnValue / 100,
          })),
        })
      );
      handleCancel();
    }
  }

  function handleFormChange(
    indicator: string,
    key: keyof IndicatorAlert,
    value: string | number | boolean
  ) {
    setFormState({
      ...formState,
      [indicator]: {
        ...formState[indicator],
        [key]: value,
      },
    });
  }

  function onSliderBlur(indicator: string, key: 'alertValue' | 'returnValue') {
    if (formState[indicator] && formState[indicator][key] < 0)
      handleFormChange(indicator, key, 0);
    else if (formState[indicator] && formState[indicator][key] > 100)
      handleFormChange(indicator, key, 100);
  }

  function buildSlider(
    key: 'alertValue' | 'returnValue',
    indicator: IndicatorAlert
  ) {
    return (
      <div className="slider-container">
        <Slider
          data-testid={`${indicator.kpiName}-${key}Slider`}
          defaultValue={30}
          onChange={(_, value) =>
            handleFormChange(indicator.kpiName, key, Number(value))
          }
          value={Number(
            formState[indicator.kpiName] ? formState[indicator.kpiName][key] : 0
          )}
          valueLabelDisplay="auto"
          valueLabelFormat={(value) => (
            <SliderValueLabel>{`${value}%`}</SliderValueLabel>
          )}
          disabled={
            formState[indicator.kpiName]
              ? !formState[indicator.kpiName].enable
              : false
          }
        />
        <Input
          data-testid={`${indicator.kpiName}-${key}Input`}
          margin="dense"
          onChange={(event) =>
            handleFormChange(indicator.kpiName, key, Number(event.target.value))
          }
          value={Number(
            formState[indicator.kpiName] ? formState[indicator.kpiName][key] : 0
          )}
          onBlur={() => onSliderBlur(indicator.kpiName, key)}
          inputProps={{
            step: 10,
            min: 0,
            max: 100,
            type: 'number',
            'aria-labelledby': 'input-slider',
          }}
          endAdornment={<>%</>}
          disabled={
            formState[indicator.kpiName]
              ? !formState[indicator.kpiName].enable
              : false
          }
        />
      </div>
    );
  }

  function buildFormInput(
    key: keyof IndicatorAlert,
    indicator: IndicatorAlert
  ) {
    switch (key) {
      case 'enable':
        return (
          <div>
            <Checkbox
              checked={
                formState[indicator.kpiName]
                  ? formState[indicator.kpiName][key]
                  : false
              }
              onChange={(_, checked) =>
                handleFormChange(indicator.kpiName, key, checked)
              }
              color="primary"
              icon={<CheckBoxOutlineBlank className="checkbox-icon" />}
              checkedIcon={<CheckBox className="checkbox-icon" />}
            />
          </div>
        );
      case 'alertValue':
        return buildSlider(key, indicator);
      case 'returnValue':
        return buildSlider(key, indicator);
      default:
        return (
          <p
            data-testid={`${indicator.kpiName}Container`}
            className="indicator-name"
          >
            {indicator[key]==="Health"?"Saúde":indicator[key]}
          </p>
        );
    }
  }

  function buildErrorAndLoader() {
    return error ? (
      <>
        <div className="error-container" data-testid="error">
          <img src={errorIcon} alt="Ícone de erro" />
          <h3>Ocorreu um erro! Recarregue a página para tentar novamente.</h3>
        </div>
      </>
    ) : (
      <CircularProgress size={50} data-testid="loader" />
    );
  }

  return (
    <Media query={supportedDevices.tablet}>
      {(isTablet) => (
        <Container
          data-testid="modalContainer"
          className={isLoading ? 'loading' : ''}
        >
          {isLoading || error ? (
            buildErrorAndLoader()
          ) : (
            <>
              <div className="title-container">
                <h3>Alertas do Ativo</h3>
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={isAllSelected === 'all'}
                      indeterminate={isAllSelected === 'some'}
                      onChange={handleAllCheckboxChange}
                      data-testid="enableAllCheck"
                      color="primary"
                      icon={
                        <CheckBoxOutlineBlank
                          className="checkbox-icon"
                          data-testid="emptyCheckboxIcon"
                        />
                      }
                      checkedIcon={
                        <CheckBox
                          className="checkbox-icon"
                          data-testid="checkedCheckboxIcon"
                        />
                      }
                      indeterminateIcon={
                        <IndeterminateCheckBox
                          className="checkbox-icon"
                          data-testid="indeterminateCheckboxIcon"
                        />
                      }
                    />
                  }
                  label="Ativar/Desativar Todos"
                />
              </div>
              <div className="email-select-container">
                <SearchField
                  onChange={handleEmailSearchChange}
                  getOptionLabel={(option) => option}
                  options={companyEmails || []}
                  multiple
                  limitTags={isTablet ? 4 : 1}
                  loading={isLoading || !companyEmails}
                  placeholder="Destinatários..."
                  value={selectedEmails}
                />
              </div>
              <div className="indicators-form">
                {isTablet && (
                  <div className="form-row">
                    {formColumns.map(([key, label]) => (
                      <h6 key={key}>{label}</h6>
                    ))}
                  </div>
                )}
                {data?.kpiAlertList.map((indicator) => (
                  <div key={indicator.kpiName} className="form-row">
                    {formColumns.map(([key, label], index) => (
                      <div className={`key-form ${key}-area`} key={label}>
                        {!isTablet && (index === 1 || index === 2) && (
                          <p className="slider-label">{label}</p>
                        )}
                        {buildFormInput(key, indicator)}
                      </div>
                    ))}
                  </div>
                ))}
              </div>
              <div className="action-buttons">
                <CustomButton data-testid="cancelButton" onClick={handleCancel}>
                  cancelar
                </CustomButton>
                <CustomButton
                  data-testid="confirmButton"
                  onClick={handleConfirm}
                  gradient
                >
                  confirmar
                </CustomButton>
              </div>
            </>
          )}
        </Container>
      )}
    </Media>
  );
};
