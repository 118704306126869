import { toastr } from 'react-redux-toastr';

import qs from 'qs';
import { put, select, call } from 'redux-saga/effects';

import config from '../../config';
import { Creators } from '../actionCreators';

export function* getOs() {
  try {
    const pageRequest = yield select((state) => state.os.pageRequest);
    const activeTab = yield select((state) => state.os.activeTab);
    const searchText = yield select((state) => state.os.searchText);
    pageRequest.status = activeTab;
    pageRequest.searchText = searchText;

    const pageRequestQs = `?${qs.stringify(pageRequest)}`;
    const ordens = yield config.api.getServiceOrder(pageRequestQs);
    yield put(Creators.getOsSuccess(ordens.data));
  } catch (error) {
    yield put(Creators.osFailure('Ocorreu um erro ao buscar as OS'));
  }
}

export function* getOsResume() {
  try {
    const osResume = yield config.api.getServiceOrderResume();
    yield put(Creators.getOsResumeSuccess(osResume.data));
  } catch (error) {
    yield put(Creators.osFailure('Ocorreu um erro ao buscar o resumo de OS'));
  }
}

export function* getOsById(action) {
  try {
    const orden = yield config.api.getServiceOrderById(action.id);
    yield put(Creators.getOsByIdSuccess(orden.data));
  } catch (error) {
    yield put(Creators.osFailure('Ocorreu um erro ao buscar as OS'));
  }
}

export function* saveOs(action) {
  try {
    if (action.os.id === undefined || action.os.id === null)
      yield config.api.postServiceOrder(action.os);
    else {
      const newOs = action.os;
      Object.keys(newOs).forEach((el) => {
        if (el === 'costCenter' || el === 'responsible' || el === 'executor')
          newOs[el] = newOs[el].id ? newOs[el].id : parseInt(newOs[el], 10);
      });
      yield config.api.putServiceOrder(newOs);
    }
    const msg = 'Ordem de serviço salva!';
    toastr.success('Ok 😄', msg);
    yield put(Creators.saveOsSuccess(action.os));
    yield call(getOs);
  } catch (error) {
    yield put(Creators.osFailure(error));
  }
}
