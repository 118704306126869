import { Accordion } from '@material-ui/core';
import styled from 'styled-components';

import { supportedDevices } from '../../../../../../../../styles/supportedDevices';

export const Container = styled.div`
  color: black;
  display: flex;
  flex-direction: column;

  padding: 16px;
  width: 100%;

  &.no-element {
    align-items: center;
    justify-content: center;

    font-size: 20px;
  }

  & .loader,
  .error {
    height: calc(100% - 160px);
  }

  & .loader {
    display: grid;
    place-items: center;
  }

  & .error {
    display: flex;
    flex-flow: column;
    align-items: center;
    justify-content: center;
    text-align: center;

    img {
      width: 150px;
      margin-bottom: 20px;
    }

    h3 {
      font-size: 24px;
      color: black;
    }
  }

  .table {
    .element-header {
      overflow-x: auto;

      margin-bottom: 15px;

      .header-cell {
        background: ${({ theme }) => theme.palette.background.paper};
        border: 2px solid white;
        font-size: 1.4rem;
        font-weight: 700;

        &.green {
          color: green;
        }

        &.red {
          color: red;
        }
      }
    }
  }

  .charts {
    margin-top: 30px;

    & > div {
      width: 100%;
    }

    .pie {
      .pie-container {
        height: 500px;
        width: 100%;
      }
    }
  }

  @media ${supportedDevices.tablet} and (orientation: landscape) {
    padding: 20px 24px;
  }

  .card {
    background: white;
    border-radius: 4px;
    box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.04);

    min-height: 100%;
    padding: 16px;

    .title-container {
      h3 {
        font-size: 20px;
        margin: 10px 0;
      }
    }

    .filters-container {
      margin: 10px 0;

      .search-container {
        width: 100%;
      }
    }

    .loader-container {
      display: grid;
      place-items: center;

      height: 250px;
    }
  }
`;

export const CustomAccordion = styled(Accordion)`
  && {
    border-radius: 4px;
    box-shadow: 0 0 2px 0 rgba(0, 0, 0, 0.4);
    margin: 5px 0;

    ::before {
      height: 0;
    }
  }

`;
