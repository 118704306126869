import withReduxEnhancer from 'addon-redux/enhancer';
import { createStore, applyMiddleware, Store, compose, Reducer } from 'redux';
import { devToolsEnhancer } from 'redux-devtools-extension';
import createSagaMiddleware from 'redux-saga';

import reducers from './reducers';
import sagas from './sagas';

const sagaMiddleware = createSagaMiddleware();

function createMiddlewareEnhancer() {
  const middleware = [];
  middleware.push(sagaMiddleware);
  return applyMiddleware(...middleware);
}

function createEnhancer() {
  const enhancers = [];
  enhancers.push(createMiddlewareEnhancer());
  if (process.env.NODE_ENV !== 'production') {
    enhancers.push(withReduxEnhancer);
    enhancers.push(
      devToolsEnhancer({
        trace: true,
        traceLimit: 25,
      })
    );
  }
  const composedEnhancers = compose<any>(...enhancers);
  return composedEnhancers;
}

export type RootState = ReturnType<typeof reducers>;

export function configureStore(runSagas = true): Store<RootState, any> {
  const store = createStore(reducers as Reducer, createEnhancer());

  if (runSagas) sagaMiddleware.run(sagas);

  return store as Store<RootState, any>;
}
