import styled from 'styled-components';

interface AsideProps {
  isOpen: boolean;
}

export const Aside = styled.aside<AsideProps>`
  display: grid;
  place-items: center;
  color: black;
  position: relative;

  height: 100%;
  padding: 20px 0;
  width: ${({ isOpen }) => (isOpen ? '420px' : 0)};
  padding-left: ${({ isOpen }) => (isOpen ? '24px' : 0)};

  transition: width 0.4s, padding 0.4s;
`;

export const Container = styled.div`
  position: absolute;
  top: 20px;
  bottom: 20px;
  left: 24px;
  right: 0;

  overflow-y: auto;
  overflow-x: hidden;
  background-color: white;
  border-radius: 4px;
  box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.04);
  z-index: 1;

  &.hidden {
    left: 0;
    box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.04);
    z-index: 0;
  }

  ::-webkit-scrollbar {
    width: 3px;
  }
`;
