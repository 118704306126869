import React from 'react';
import { Chart } from 'react-google-charts';
import Media from 'react-media';

import { CircularProgress } from '@material-ui/core';

import { kChartMultiColors } from '../../../utils/constants';

import { supportedDevices } from '../../../styles/supportedDevices';
import { Container, Loader } from './styles';

export interface PieProps {
  /**
   * Values to be displayed
   * @arg string propery name
   * @arg number property value
   */
  values: [string, number][];

  /**
   * Chart subject
   */
  subject: string;
}

/**
 * Pie chart component
 */
export const Pie: React.FC<PieProps> = ({ values, subject }) => {
  return (
    <Media
      queries={{
        tablet: supportedDevices.tablet,
      }}
    >
      {(matches) => (
        <Container data-testid="pieChart">
          <Chart
            width="100%"
            height="100%"
            chartType="PieChart"
            loader={
              <Loader>
                <CircularProgress size={50} />
              </Loader>
            }
            data={[['Data', subject], ...values]}
            options={{
              backgroundColor: 'transparent',
              tooltip: { isHtml: true },
              animation: {
                startup: true,
                easing: 'linear',
                duration: 1000,
              },
              fontName: 'Roboto',
              colors: [
                kChartMultiColors[7],
                kChartMultiColors[4],
                ...kChartMultiColors.slice(0, 4),
                ...kChartMultiColors.slice(5, 7),
              ],
              sliceVisibilityThreshold: 0.00001,
              legend: {
                position: matches.tablet ? 'right' : 'bottom',
              },
            }}
          />
        </Container>
      )}
    </Media>
  );
};
