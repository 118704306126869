import React, { useState } from 'react';
import { useSelector } from 'react-redux';

import { Box, Button, Divider, Typography } from '@material-ui/core';
import { ArrowForward, ArrowBack, LockOpen, Lock } from '@material-ui/icons';
import { HierarchyNode } from 'd3';

import { NodeData } from '../../../../../../../../models/TreeMapNode';
import { AppState } from '../../../../../../../../store/reducers';

import {
  Container,
  DelayedFade,
  BreadcrumbsAndButtonContainer,
} from './styles';
import { NodesEnum } from '../../../../../../../../utils/sunburstNodesTypes';

interface HeaderProps {
  onFilterSelect: (node: HierarchyNode<NodeData>) => void;
  handleLayerBlock(): void;
  selectedIndicator: string;
}

export const Header: React.FC<HeaderProps> = ({
  onFilterSelect,
  handleLayerBlock,
  selectedIndicator,
}) => {
  const treeHealth = useSelector((state: AppState) => state.treeHealth);

  const [areBreadcrumbsShowing, setAreBreadcrumbsShowing] = useState(false);

  function toggleBreadcrumbsVisible() {
    setAreBreadcrumbsShowing(!areBreadcrumbsShowing);
  }

  const breadcrumbs = treeHealth.sequence!.map((node, index, array) => (
    <React.Fragment key={node.data.identifier}>
      <DelayedFade
        in={areBreadcrumbsShowing}
        delay={index * 100}
        unmountOnExit
        mountOnEnter
      >
        <div>
          <Button
            style={{
              height: 'fit-content',
              borderRadius: 4,
              padding: '2px 7px',
            }}
            color="secondary"
            variant="contained"
            className={
              array.length - 1 === index ? 'breadcrumb gradient' : 'breadcrumb'
            }
            onClick={() => onFilterSelect(node)}
          >
            <Box flexDirection="column" width="100%">
              <p
                style={{
                  color: array.length - 1 === index ? '#f2f2f2' : '#595959',
                  fontSize: '0.8em',
                }}
              >
                {NodesEnum[node.data.type.toUpperCase()]}
              </p>
              <Box
                display="flex"
                justifyContent="space-between"
                alignItems="center"
              >
                <Typography noWrap component="span" variant="button">
                  {node.data.name}
                </Typography>
                <ArrowForward />
              </Box>
            </Box>
          </Button>
        </div>
      </DelayedFade>
    </React.Fragment>
  ));

  return (
    <Container>
      <h2>Índice de {selectedIndicator || 'Saúde'}</h2>
      <div className="breadcrumb-container">
        <div className="p-absolute center-vertical w-100 title-actions-container">
          <div>
            <DelayedFade
              in={!areBreadcrumbsShowing}
              unmountOnExit
              mountOnEnter
              delay={300}
            >
              <div>
                <Button
                  color="secondary"
                  variant="contained"
                  className="toggle-breadcrumbs-button forward"
                  onClick={toggleBreadcrumbsVisible}
                >
                  <ArrowForward />
                </Button>
              </div>
            </DelayedFade>
            <DelayedFade
              in={!areBreadcrumbsShowing}
              delay={400}
              unmountOnExit
              mountOnEnter
            >
              <Typography variant="h1" noWrap>
                {treeHealth.selectedNode?.data.name}
              </Typography>
            </DelayedFade>
          </div>
          <DelayedFade
            in={!areBreadcrumbsShowing}
            delay={500}
            unmountOnExit
            mountOnEnter
          >
            <div>
              <Button
                color="secondary"
                variant="contained"
                className="lock-btn"
                onClick={handleLayerBlock}
              >
                {treeHealth.selectedNode.data.blocked ? <LockOpen /> : <Lock />}
              </Button>
            </div>
          </DelayedFade>
        </div>
        <BreadcrumbsAndButtonContainer
          isOpen={areBreadcrumbsShowing}
          className="p-absolute"
        >
          <div className="breadcrumbs">{breadcrumbs}</div>
          <DelayedFade in={areBreadcrumbsShowing}>
            <div>
              <Button
                color="secondary"
                variant="contained"
                className="toggle-breadcrumbs-button backward"
                onClick={toggleBreadcrumbsVisible}
              >
                <ArrowBack />
              </Button>
            </div>
          </DelayedFade>
        </BreadcrumbsAndButtonContainer>
      </div>
    </Container>
  );
};
