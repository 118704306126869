import React from 'react';
import { Switch, Route, RouteComponentProps, Redirect } from 'react-router-dom';

import { Screen404 } from '../404';
import { HealthAndIndicatorView } from './components/views/HealthAndIndicatorView';

export const Router: React.FC<RouteComponentProps> = ({ match }) => (
  <Switch>
    <Route
      path={`${match.path}/saudeeindicadores`}
      component={HealthAndIndicatorView}
    />
    <Redirect
      exact
      from={`${match.path}`}
      to={`${match.path}/saudeeindicadores`}
    />
    <Route path={`${match.path}/404`} component={Screen404} />
    <Redirect from={`${match.path}/*`} to="/404" />
  </Switch>
);
