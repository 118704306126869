import React, { useEffect } from 'react';
import ReduxToastr from 'react-redux-toastr';
import { Redirect } from 'react-router-dom';

import Logo from '../../assets/logo_nexum.png';
import LogoTagna from '../../assets/powered by tagna.png';
import { Router } from './router';

import { GlobalStyle, Container, LogoContainer, FormContainer } from './styles';
import { useAuthStore } from '../../zustand/AuthStore';

export const AuthScreen: React.FC = () => {
  const { isAuth } = useAuthStore();


  if (isAuth) return <Redirect to="/admin/dashboard" />;
  return (
    <>
      <GlobalStyle />
      <Container data-testid="container">
        <div className="inner-container">
          <div className="outside-logo">
            <img src={Logo} alt="Logo Nexum" />
          </div>
          <div className="card" data-testid="card">
            <LogoContainer>
              <img src={Logo} alt="Logo Nexum" data-testid="logo" />
              <img src={LogoTagna} alt="Powered By Tagna" />
            </LogoContainer>
            <FormContainer>
              <Router />
            </FormContainer>
            <ReduxToastr
              timeOut={4000}
              position="top-center"
              preventDuplicates
              progressBar
              closeOnToastrClick
            />
          </div>
          <div className="outside-logo2">
            <img src={LogoTagna} alt="Powered By Tagna" />
          </div>
        </div>
      </Container>
    </>
  );
};

