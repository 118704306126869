import React from 'react';

import { TextField } from '@material-ui/core';

import { Container, Label, ErrorText } from './styles';

interface CustomTextFieldProps {
  placeholder: string;
  label: string;
  error?: string;
  type: string;
  isDisabled?: boolean;
  value: string;
  handleChange: (
    fieldName: string,
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => void;
  name: string;
  cleanError?: (fieldName: string) => void;
}

export const CustomTextField: React.FC<CustomTextFieldProps> = (props) => {
  const {
    name,
    placeholder,
    label,
    error,
    type,
    isDisabled,
    value,
    handleChange,
    cleanError,
  } = props;

  return (
    <Container>
      <Label htmlFor={name}>{label}</Label>
      <TextField
        name={name}
        placeholder={placeholder}
        variant="outlined"
        error={error !== undefined}
        type={type}
        disabled={isDisabled}
        value={value || ''}
        onChange={(e) => handleChange(name, e)}
        onFocus={cleanError ? () => cleanError(name) : undefined}
      />
      <ErrorText>{error || ''}</ErrorText>
    </Container>
  );
};
