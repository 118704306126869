export const deviceSizes = {
  mobileS: 320,
  mobileM: 375,
  mobileL: 425,
  tablet: 768,
  laptop: 1028,
  laptopP: 1250,
  laptopM: 1372,
  laptopL: 1440,
  desktop: 2560,
};

export const supportedDevices = {
  mobileS: `(min-width: ${deviceSizes.mobileS}px)`,
  mobileM: `(min-width: ${deviceSizes.mobileM}px)`,
  mobileL: `(min-width: ${deviceSizes.mobileL}px)`,
  tablet: `(min-width: ${deviceSizes.tablet}px)`,
  laptop: `(min-width: ${deviceSizes.laptop}px)`,
  laptopP: `(min-width: ${deviceSizes.laptopP}px)`,
  laptopM: `(min-width: ${deviceSizes.laptopM}px)`,
  laptopL: `(min-width: ${deviceSizes.laptopL}px)`,
  desktop: `(min-width: ${deviceSizes.desktop}px)`,
};
