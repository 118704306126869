import Keycloak, {
  KeycloakConfig,
  KeycloakInitOptions,
  KeycloakProfile,
} from 'keycloak-js';
import { create } from 'zustand';

import { UserData } from '../models/UserData';

import { CLIENTS, COMPANY_UUID, DOMAIN, ROLES } from '../utils/authTypes';

import {
  AuthFailureAction,
  AuthSuccessAction,
  GetCompanyEmailsSuccessAction,
  GetMeSuccessAction,
  SignInFailureAction,
  SignInRequestAction,
  SignInSuccessAction,
  ChangeDefaultTreeElementAction,
} from '../store/auth/types';
import { auth as firebase, getCurrentUser } from '../api/firebase';
import config from '../config';
import { toastr } from 'react-redux-toastr';

import { Creators } from '../store/actionCreators';
import { Dispatch } from 'react';
import { AxiosRequestConfig } from 'axios';
import {
  APPLICATIONS,
  createUserIfNotExistsBalance,
  createUserIfNotExistsHealth,
} from '../api';

type LoginStep = 'advance' | 'login';

type keycloakReamls = {
  [key: string]: KeycloakConfig;
};

type AuthState = {
  isAuthing: boolean;
  isAuth: boolean;
  isSigningin: boolean;
  user: UserData;
  error: boolean;
  errorMessage: string;
  isSaving: boolean;
  saved: boolean;
  step: LoginStep;
  keycloak?: Keycloak;
};

const keycloakConfigList: keycloakReamls = {
  [CLIENTS.SAMARCO]: {
    realm: 'samarco',
    clientId: 'balance-frontend',
    url: 'https://api.nexum.digital/auth-v2',
  },
  [CLIENTS.ARCELOR_MITTAL]: {
    realm: 'arcelor-mittal',
    clientId: 'balance-frontend',
    url: 'https://api.nexum.digital/auth-v2',
  },
  [CLIENTS.TAGNA]: {
    realm: 'tagna',
    clientId: 'balance-frontend',
    url: 'https://api.nexum.digital/auth-v2',
  },
};

export const keycloakInitOptions: KeycloakInitOptions = {
  onLoad: 'check-sso',
  enableLogging: true,
  scope: 'openid profile email',
  // useNonce: true,
};

const INITIAL_STATE: AuthState = {
  isAuthing: true,
  isAuth: false,
  isSigningin: false,
  user: {} as UserData,
  error: false,
  errorMessage: '',
  isSaving: false,
  saved: false,
  step: 'advance',
  keycloak: localStorage.getItem('client')
    ? new Keycloak(keycloakConfigList[localStorage.getItem('client')])
    : null,
};

interface LoginAction {
  email: SignInRequestAction['email'];
  password: SignInRequestAction['password'];
  keycloak: Keycloak;
}

export const useAuthStore = create<AuthState>((set, get) => {
  return {
    ...INITIAL_STATE,
  };
});

export const advance = async (email: string) => {
  const domain = email.split('@').length === 2 ? email.split('@')[1] : '';
  try {
    var keycloak = null;

    switch (domain) {

      case DOMAIN.SAMARCO_PROJETOS:
      case DOMAIN.SAMARCO:
        keycloak = new Keycloak(keycloakConfigList[CLIENTS.SAMARCO]);
        localStorage.setItem('client', CLIENTS.SAMARCO);
        break;

      case DOMAIN.ARCELOR_MITTAL:
      case DOMAIN.ARCELOR_MITTAL_AM_CONTRATOS:
        keycloak = new Keycloak(keycloakConfigList[CLIENTS.ARCELOR_MITTAL]);
        localStorage.setItem('client', CLIENTS.ARCELOR_MITTAL);
        break;

      case DOMAIN.TAGNA:
        keycloak = new Keycloak(keycloakConfigList[CLIENTS.TAGNA]);
        localStorage.setItem('client', CLIENTS.TAGNA);
        break;

      default:
        localStorage.removeItem('client');
        break;
    }

    signinKeycloak(keycloak);
    advanceRequest(email);
  } catch (error) {
    console.log(error);
    signinFailure({ error: error });
  }
};


export const hasRoleAccess = (roles: ROLES[], keycloak: Keycloak) => {

  if( keycloak && keycloak.realmAccess && keycloak.realmAccess.roles) {

    const realmRoles = keycloak.realmAccess.roles;

    const isAuthorized: boolean = roles.some((role) =>
			realmRoles.includes(role),
		);

    return isAuthorized;
  }
  else {

    return isFirebaseDev();

  }

}


export const isFirebaseDev = () => {

  try{
    const userEmail = useAuthStore.getState().user.email;
    const domain = userEmail.split('@').length === 2 ? userEmail.split('@')[1] : '';
    if(domain === DOMAIN.NEXUM)
      return true;
    return false;
  }
  catch(err){
    console.log(err);
    return false;
  }
}

export const login = async ({ email, password, keycloak }: LoginAction) => {
  try {
    signinRequest();

    if (!!keycloak) {
      const response = await loginWithKeycloak(email, keycloak);
      console.log('loginWithKeycloak', response);
      if (!response) throw new Error('Falha no login com keycloak.');

      //to do
      const profile = await keycloak.loadUserProfile();
      console.log('profile', profile);
      const isCreated = await createUserIfNotExist(
        email,
        profile.id,
        `${profile.firstName} ${profile.lastName}`
      );

      if (!isCreated) {
        destroyKeycloakAuth();
        throw new Error('Falha ao criar usuário no banco de dados.');
      }
    } else {
      const response = await firebase.signInWithEmailAndPassword(
        email,
        password
      );
      if (!response.user) throw new Error('Falha no login com firebase.');
    }

    signinSuccess({ user: { email } });
    getMe();
  } catch (error) {
    console.log(error);
    const msg = 'Verifique os dados inseridos e tente novamente!';
    toastr.error('Erro', msg);
    signinFailure({ error: 'Erro ao realizar login!' });
  }
};

export const implicitFlowLogin = async (keycloak: Keycloak) => {
  try {
    signinRequest();

    const profile = await keycloak.loadUserProfile();
    const isCreated = await createUserIfNotExist(
      profile.email,
      profile.id,
      `${profile.firstName} ${profile.lastName}`
    );

    if (!isCreated) {
      destroyKeycloakAuth();
      throw new Error('Falha ao criar usuário no banco de dados.');
    }

    signinSuccess({
      user: {
        email: profile.email,
        name: `${profile.firstName} ${profile.lastName}`,
        uid: profile.id,
      },
    });
    getMe();
  } catch (error) {
    console.log(error);
    toastr.error('Erro', 'Verifique os dados inseridos e tente novamente!');
    signinFailure({ error: error });
  }
};

export const auth = async () => {
  authRequest();

  const user = await getCurrentUser();

  try {
    if (user?.email) {
      authSuccess({ user: { email: user.email } });
      getMe();
    } else {
      authFailure({ error: 'Falha na autenticação do usuário!' });
    }
  } catch (error) {
    console.log(error);
    toastr.error('Erro', 'Falha na autenticação do usuário!');
    signinFailure({ error: error });
  }
};

export const destroyAuth = async (
  dispatch: Dispatch<any>,
  keycloak: Keycloak
) => {
  if (!!keycloak) {
    keycloak.logout();
    localStorage.removeItem('client');
  } else {
    await firebase.signOut();
  }

  destroyAuthSuccess();

  dispatch(Creators.resetNotifications());
  dispatch(Creators.resetReports());
  // to do: remove dispatch and use zustand
};

export const destroyKeycloakAuth = async () => {
  localStorage.removeItem('client');
  destroyAuthSuccess();
};

export const getMe = async () => {
  try{
    getMeRequest();

    const meResponse = await config.api.getMe();

    const treeResponse = await config.api.getTree();

    getMeSuccess({
      companyName: meResponse.data.companyName,
      startNode: meResponse.data.startNode,
      tree: treeResponse.data,
    });
  }catch(err){
    console.log(err)
    authFailure({ error: 'Ocorreu um erro ao recuperar os dados do usuário. Recarregue a página para tentar novamente.' });
    toastr.error('Erro', 'Ocorreu um erro ao recuperar os dados do usuário. Recarregue a página para tentar novamente.');
  }
};

export const setDefaultTreeElement = async (id: string) => {
  try {
    changeDefaultTreeElement({ id });
    const response = await config.api.changeDefaultTreeElement(id);
  } catch (error) {
    console.log(error);

  }
}

export const getCompanyEmails = async () => {
  try {
    getCompanyEmailsRequest();

    const emails = await config.api.getCompanyEmails();

    getCompanyEmailsSuccess({
      emails: emails.data,
    });
  } catch (err) {
    const msg =
      'Ocorreu um erro ao recuperar os emails da empresa. Recarregue a página para tentar novamente.';
    toastr.error('Erro', msg);
  }
};

const loginWithKeycloak = async (email: string, keycloak: Keycloak) => {
  try {
    const domain = email.toLowerCase().trim().split('@').length === 2 ? email.split('@')[1] : '';

    switch (domain) {
      case DOMAIN.SAMARCO_PROJETOS:
      case DOMAIN.SAMARCO:
        keycloak.login({ idpHint: 'saml', loginHint: email });
        break;

      case DOMAIN.ARCELOR_MITTAL:
      case DOMAIN.ARCELOR_MITTAL_AM_CONTRATOS:
        keycloak.login({ idpHint: 'saml', loginHint: email });
        break;

      case DOMAIN.TAGNA:
        keycloak.login({ idpHint: 'azuread', loginHint: email });
        break;

      default:
        return false;
    }

    return true;
  } catch (error) {
    console.log(error);
    return false;
  }
};

const signinRequest = () => {
  useAuthStore.setState((state) => {
    return {
      ...state,
      isSigningin: true,
      error: false,
      errorMessage: '',
    };
  });
};

const signinKeycloak = (keycloak: Keycloak) => {
  useAuthStore.setState((state) => {
    return {
      ...state,
      isSigningin: false,
      error: false,
      errorMessage: '',
      keycloak,
    };
  });
};

// const signinFirebase = () => {
//   useAuthStore.setState((state) => {
//     return {
//       ...state,
//       isSigningin: false,
//       error: false,
//       errorMessage: '',
//       loginType: "firebase",
//     };
//   });
// };

export const getMeRequest = () => {
  useAuthStore.setState((state) => {
    return {
      ...state,
      user: {
        ...state.user,
        company: '',
        tree: undefined,
      },
    };
  });
};

const changeDefaultTreeElement = ({ id }: ChangeDefaultTreeElementAction) => {
  useAuthStore.setState((state) => {
    return {
      ...state,
      user: {
        ...state.user,
        defaultTreeElementUuid: id
      },
    };
  });
}

export const signinSuccess = ({ user }: SignInSuccessAction) => {
  useAuthStore.setState((state) => {
    return {
      ...state,
      isSigningin: false,
      isAuthing: false,
      isAuth: true,
      user: {
        ...state.user,
        email: user.email,
        name: user.name,
        uid: user.uid,
      },
    };
  });
};

const signinFailure = (action: SignInFailureAction) => {
  useAuthStore.setState((state) => {
    return {
      ...state,
      isSigningin: false,
      error: true,
      errorMessage: action.error,
    };
  });
};

const authRequest = async () => {
  useAuthStore.setState((state) => {
    return {
      ...state,
      isAuthing: true,
      error: false,
      errorMessage: '',
      user: {} as UserData,
    };
  });
};

const authSuccess = (action: AuthSuccessAction) => {
  useAuthStore.setState((state) => {
    return {
      ...state,
      isAuthing: false,
      isAuth: true,
      user: { ...state.user, email: action.user.email },
    };
  });
};

export const authFailure = (action: AuthFailureAction) => {
  useAuthStore.setState((state) => {
    return {
      ...state,
      isAuthing: false,
      error: true,
      isAuth: false,
      errorMessage: action.error,
    };
  });
};

const destroyAuthSuccess = () => {
  useAuthStore.setState((state) => {
    return {
      ...state,
      isAuthing: false,
      isAuth: false,
      user: {} as UserData,
      step: 'advance',
      keycloak: null,
    };
  });
};

const getMeSuccess = (action: GetMeSuccessAction) => {
  useAuthStore.setState((state) => {
    return {
      ...state,
      user: {
        ...state.user,
        defaultTreeElementUuid: action.startNode,
        company: action.companyName,
        tree: action.tree,
      },
    };
  });
};

const getCompanyEmailsRequest = () => {
  useAuthStore.setState((state) => {
    return {
      user: {
        ...state.user,
        companyEmails: undefined,
      },
    };
  });
};

const getCompanyEmailsSuccess = (action: GetCompanyEmailsSuccessAction) => {
  useAuthStore.setState((state) => {
    return {
      ...state,
      user: {
        ...state.user,
        companyEmails: action.emails,
      },
    };
  });
};

const advanceRequest = (email: string) => {
  useAuthStore.setState((state) => {
    return {
      ...state,
      step: 'login',
      error: false,
      errorMessage: '',
      user: { ...state.user, email: email },
    };
  });
};

export const loginRequest = () => {
  useAuthStore.setState((state) => {
    return {
      ...state,
      step: 'advance',
      error: false,
      errorMessage: '',
    };
  });
};

export const userIdRequest = (
  idHealth: number,
  idBalance: number,
  uid: string
) => {
  useAuthStore.setState((state) => {
    return {
      ...state,
      error: false,
      errorMessage: '',
      user: { ...state.user, idHealth, idBalance, uid },
    };
  });
};

export const getTokenFromKeycloak = async (
  config: AxiosRequestConfig,
  credential = ''
) => {
  const keycloak = useAuthStore.getState().keycloak;
  const user = useAuthStore.getState().user;

  if (!keycloak) {
    return null;
  }

  if (keycloak.isTokenExpired(30)) {
    const refreshed = await keycloak.updateToken(10);

    if (refreshed) console.log('Token refreshed.');
    else console.log('Token not refreshed.');
  } else {
    console.log('Token not expired.');
  }

  let profile: KeycloakProfile;
  if (!user.uid || user.uid === '') {
    profile = await keycloak.loadUserProfile();
  }

  if (config.headers) {
    if (keycloak.token) {
      config.headers['Authorization'] = `${
        credential == APPLICATIONS.BALANCE ? 'Bearer ' : ''
      }${keycloak.token}`;
    }

    if (credential === APPLICATIONS.BALANCE) {
      config.headers['User-Uid'] = user.uid ? user.uid : profile?.id || 'err';
    }
  }

  console.log('getTokenFromKeycloak', config);

  return config;
};

async function createUserIfNotExist(
  email: string,
  uid: string,
  name: string
): Promise<boolean> {
  try {
    const domain = email.toLowerCase().trim().split('@').length === 2 ? email.split('@')[1] : '';
    let companyUuid = COMPANY_UUID.SAMARCO;

    if (domain === DOMAIN.SAMARCO_PROJETOS || domain === DOMAIN.SAMARCO) {
      companyUuid = COMPANY_UUID.SAMARCO;
    } else if (domain === DOMAIN.ARCELOR_MITTAL || domain === DOMAIN.ARCELOR_MITTAL_AM_CONTRATOS) {
      companyUuid = COMPANY_UUID.ARCELOR_MITTAL;
    } else if (domain === 'tagna.com.br') {
      companyUuid = COMPANY_UUID.SAMARCO;
    }

    const responseHealth = await createUserIfNotExistsHealth({
      id: 0,
      uid: uid || '',
      email: email || '',
      name: name,
      role: '',
      companyUuid,
    });

    console.log('responseHealth', responseHealth);

    try {
      const responseBalance = await createUserIfNotExistsBalance({
        id: 0,
        uid: uid || '',
        email: email || '',
        name: name,
        role: '',
        // companyUuid
      });

      console.log('responseBalance', responseBalance);

      if (responseHealth.status === 200) {
        if (responseBalance.status === 200) {
          userIdRequest(responseHealth.data.id, responseBalance.data.id, uid);
        }else{
          userIdRequest(responseHealth.data.id, -1, uid);
        }
        return true;
      } else {
        console.log('Erro ao criar usuário no banco de dados.');
        userIdRequest(-1, -1, 'Erro ao criar usuário no banco de dados.');
      }
    } catch (error) {
      console.log('error', error);
      userIdRequest(responseHealth.data.id, -1, uid);
      return true;
    }

    return false;
  } catch (error) {
    console.log('error', error);
    userIdRequest(-1, -1, 'Erro ao criar usuário.');

    return false;
  }
}
