import React, { useEffect } from 'react';
import { Provider } from 'react-redux';

import DateFnsUtils from '@date-io/date-fns';
import { ThemeProvider as MuiThemeProvider } from '@material-ui/core';
import { MuiPickersUtilsProvider } from '@material-ui/pickers';
import { ptBR } from 'date-fns/locale';
import { ThemeProvider } from 'styled-components';

import { Router } from './router';
import { configureStore } from './store';

import { GlobalStyle } from './styles';
import { theme } from './styles/theme';

import 'react-redux-toastr/lib/css/react-redux-toastr.min.css';
import { authFailure, implicitFlowLogin, keycloakInitOptions, auth, useAuthStore } from './zustand/AuthStore';

const store = configureStore();

function App() {
  const { keycloak, step } = useAuthStore()

  const handleAuth = async () => {
    try {
      await auth();
    } catch (error) {
      console.error('Error during authentication:', error);
      authFailure({ error: error });
    }
  };

  useEffect(() => {
    if (step === "login" && !!keycloak) init()
  }, [step]);

  useEffect(() => {
    if (!!keycloak) init()
  }, []);

  useEffect(() => {
    if (!!keycloak) {
      keycloak.onReady = async (authenticated) => {
        console.log("onReady");

        try {
          if (authenticated) {
            await implicitFlowLogin(keycloak)
          } else {
            authFailure({ error: 'Falha na autenticação do usuário!' });
          }
        } catch (err) {
          console.log(err);
          authFailure({ error: err });
        }
      };

      keycloak.onTokenExpired = refreshToken;
      keycloak.onAuthRefreshError = refreshToken;
    } else {
      handleAuth();
    }
  }, [])

  async function init() {
    try {
      const isAuthenticatedResponse = await keycloak.init(keycloakInitOptions);
      console.log("Initialize keycloak.");

      if (isAuthenticatedResponse) {
        console.log('User authenticated.');
      } else {
        console.log('User not authenticated.');
        authFailure({ error: 'User not authenticated.' });
      }
    } catch (error) {
      console.log("Error initialize keycloak.");
      authFailure({ error: error });
    }
  }

  async function refreshToken() {
    try {
      const refresh = await keycloak.updateToken(10);

      if (!refresh) {
        console.log("Update token fail.");
        authFailure({ error: 'Update token fail.' });
        return;
      }
    } catch (error) {
      console.log("Update token error.");
      authFailure({ error: error });
    }
  }

  return (
    <>
      <GlobalStyle />
      <MuiThemeProvider theme={theme}>
        <ThemeProvider theme={theme}>
          <MuiPickersUtilsProvider utils={DateFnsUtils} locale={ptBR}>
            <Provider store={store}>
              <Router />
            </Provider>
          </MuiPickersUtilsProvider>
        </ThemeProvider>
      </MuiThemeProvider>
    </>
  );
}

export default App;
