import styled from 'styled-components';
import { Button } from '@material-ui/core';

import { supportedDevices } from '../../../../../../styles/supportedDevices';

export const Container = styled.section`
  overflow-y: scroll;
  width: 100%;
  height: 100%;
  padding: 8px 16px;

  & .card {
    overflow: hidden;
    display: flex;
    flex-direction: column;
    min-height: 100%;

    &.loader-container {
      justify-content: center;
      align-items: center;
      background-color: #fff;
    }

    &.error-container {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      background-color: #fff;

      img {
        width: 80px;
        margin-bottom: 10px;
      }

      h3 {
        font-size: 14px;
        color: black;
        text-align: center;
      }
    }

    &.main-container {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;

      .main-card {
        width: 100%;
        background: white;
        border-radius: 4px;
        box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.04);
        padding: 16px;
      }

      .center {
        display: flex;
        justify-content: center;
        align-items: center;
      }

      .treemap {
        margin-top: -30px;
      }

      & .toggle-charts-container {
        display: flex;
        height: 40px;
        margin: 10px 0 0;
        width: 100%;
      }

      .mobileIndicatorSelector {
        margin-top: 10px;
      }
    }
  }
`;

export const ChartsContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
  margin: 10px 0;

  @media ${supportedDevices.mobileM} {
    margin: 30px 0;
  }
`;

export const CriticalityOption = styled.div`
  display: flex;
  align-items: center;
  max-height: 30px;
  flex-grow: 1;
`;

export const CriticalityConfirmationButton = styled(Button)`
  && {
    background-color: rgb(198, 198, 198);
    background-image: linear-gradient(to right, rgb(25, 176, 160), rgb(0, 158, 226));
    border-bottom-color: rgb(255, 255, 255);
    width: 100%;
    height: 100%;
    color: white;
    font-size: 12px;
    min-height: 42px;
  }
`;

