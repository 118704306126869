import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import { useLocation, useHistory } from 'react-router-dom';

import { Button, MenuItem, AppBar, Zoom, ButtonBase } from '@material-ui/core';
import { hasRoleAccess, isFirebaseDev } from '../../../zustand/AuthStore';

import logo from '../../../assets/logo.png';
import menuOff from '../../../assets/new_icons/menu_icon_off.svg';
import menuOn from '../../../assets/new_icons/menu_icon_on.svg';

import { SubHeaderProps } from '..';

import { ToggleButton } from '../components/ToggleButton';

import {
  Toolbar,
  LogoContainer,
  ToggleButtonsContainer,
  NavBar,
  MenuContainer,
  CustomMenu,
} from './styles';
import { destroyAuth, useAuthStore } from '../../../zustand/AuthStore';
import { ROLES } from '../../../utils/authTypes';

export const DesktopHeader: React.FC<SubHeaderProps> = (props) => {
  const dispatch = useDispatch();
  const keycloak = useAuthStore((state) => state.keycloak)

  const currentLocation = useLocation();
  const history = useHistory();

  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);

  function toggleMenu(event: React.MouseEvent<HTMLButtonElement>) {
    setAnchorEl(event.currentTarget);
  }

  function handleClose() {
    setAnchorEl(null);
  }

  async function onLogoutClick() {
    handleClose();
    await destroyAuth(dispatch, keycloak)
  }

  function onLinkClick(link: string) {
    if (currentLocation.pathname !== link) history.push(link);
  }

  function onLogoClick() {
    if (currentLocation.pathname === '/admin/dashboard') {
      history.push('/');
      history.goBack();
    } else history.push('/admin/dashboard');
  }

  return (
    <AppBar position="static">
      <Toolbar>
        <LogoContainer>
          <ButtonBase onClick={onLogoClick} disableRipple>
            <img src={logo} alt="logo nexum" />
          </ButtonBase>
        </LogoContainer>
        <ToggleButtonsContainer>
          {props.navOptions.map((item) =>
            item.toggleButtons.map((button) => (
              <Zoom
                in={currentLocation.pathname.startsWith(item.path)}
                unmountOnExit
                mountOnEnter
                key={item.name}
                timeout={500}
              >
                <div>{button}</div>
              </Zoom>
            ))
          )}
        </ToggleButtonsContainer>
        <NavBar>
          <ul>
            {props.navOptions.map((item) => (
              <li key={item.name}>
                <Button
                  onClick={() => onLinkClick(item.path)}
                  className={
                    currentLocation.pathname.startsWith(item.path)
                      ? 'active'
                      : ''
                  }
                >
                  {item.name}
                </Button>
              </li>
            ))}
          </ul>
        </NavBar>
        <MenuContainer>
          <ToggleButton
            aria-controls="header-menu"
            aria-haspopup="true"
            isSelected={Boolean(anchorEl)}
            onToggle={toggleMenu}
            offIcon={menuOff}
            onIcon={menuOn}
            alt="Icone mostrar ou esconder menu de opções"
          />

          <CustomMenu
            id="header-menu"
            anchorEl={anchorEl}
            getContentAnchorEl={null}
            keepMounted
            open={Boolean(anchorEl)}
            onClose={handleClose}
            anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
            transformOrigin={{ vertical: 'top', horizontal: 'right' }}
          >
            <MenuItem onClick={() => onLinkClick('/admin/configuracao')}>
              Configurações
            </MenuItem>
            { hasRoleAccess([ROLES.DEV], keycloak) && (
              <MenuItem onClick={() => onLinkClick('/admin/collectedTags')}>Relatório Tags</MenuItem>
            )}
            <MenuItem onClick={onLogoutClick}>Sair</MenuItem>
          </CustomMenu>
        </MenuContainer>
      </Toolbar>
    </AppBar>
  );
};
