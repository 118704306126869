import { IconButton } from '@material-ui/core';
import styled from 'styled-components';

import { supportedDevices } from '../../styles/supportedDevices';

export interface CustomIconButtonProps {
  active: boolean;
}

export const CustomIconButton = styled(IconButton).withConfig({
  shouldForwardProp: (prop) => !['active'].includes(prop),
})<CustomIconButtonProps>`
  img {
    width: 35px;
    filter: ${({ active }) => (active ? 'opacity(100%)' : 'opacity(50%)')};

    @media ${supportedDevices.mobileL} {
      width: 45px;
    }
  }

  svg {
    font-size: 3rem;
    color: white;
    opacity: ${({ active }) => (active ? '1' : '0.5')};
  }
`;
