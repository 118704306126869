import React, { useState } from 'react';

import { toastr } from 'react-redux-toastr';
import { Collapse, Tooltip, ButtonBase } from '@material-ui/core';
import { Warning, Info } from '@material-ui/icons';
import { TagInfo } from '../../../../../../../models/TagInfo';

import { ToggleButton, TooltipSpan, Container, Visible } from './styles';

interface TagContainerProps {
  tag: TagInfo;
  isSelected?: boolean;
  isDescriptionShown: boolean;
  handleSelectTag(): void;
  getTagColor(): string;
  indicator: string;
}

export const TagContainer: React.FC<TagContainerProps> = ({
  tag,
  isDescriptionShown,
  isSelected = false,
  handleSelectTag,
  getTagColor,
  indicator,
}) => {
  const [isInfoTooltipOpen, setIsInfoTooltipOpen] = useState(false);
  const [isWarningTooltipOpen, setIsWarningTooltipOpen] = useState(false);

  function handleInfoTooltip(isOpen: boolean = !isInfoTooltipOpen) {
    setIsInfoTooltipOpen(isOpen);
  }

  function handleWarningTooltip(isOpen: boolean = !isWarningTooltipOpen) {
    setIsWarningTooltipOpen(isOpen);
  }

  function getIndicatorSymbol(indicator: string) {
    let symbol = "";

    switch (indicator) {
      case "Comunicação":
        symbol = "co";
        break;

      case "Disponibilidade Física":
        symbol = "pa";
        break;

      case "Variação Temporal":
        symbol = "tv";
        break;

      case "Fora dos Limites":
        symbol = "ol";
        break;

      case "Performance":
        symbol = "pf";
        break;

      case "Qualidade":
        symbol = "sq";
        break;

      default:
        symbol = "informativo";
        break;
    }

    return symbol;
  }

  const handleContextMenu = async (event) => {
    event.preventDefault();
    try {
      await navigator.clipboard.writeText(tag.clientTag);
      toastr.success('Copiado para a área de transferência.', tag.clientTag);
    } catch (err) {
      toastr.error('Falha ao copiar o texto: ', err);
    }
  };

  return (
    <Container isSelected={isSelected}>
      <div className="content-container">
        <Tooltip
          enterDelay={500}
          title={<TooltipSpan>{tag.clientTag}</TooltipSpan>}
        >
          <ToggleButton
            onClick={() => handleSelectTag()}
            borderColor={getTagColor()}
            isSelected={isSelected}
            onContextMenu={handleContextMenu}
          >
            {tag.tag}
          </ToggleButton>
        </Tooltip>
        <div className="icons-container">
          <Visible show={getTagColor() !== 'green' && getTagColor() !== 'grey'}>
            <Tooltip
              enterDelay={500}
              open={isWarningTooltipOpen}
              onOpen={() => handleWarningTooltip(true)}
              onClose={() => handleWarningTooltip(false)}
              title={
                <TooltipSpan>{tag.unhealthyMotive || indicator}</TooltipSpan>
              }
            >
              <ButtonBase
                onClick={() => handleWarningTooltip()}
                className="icon-container warning"
              >
                <Warning className="warning-icon" />
              </ButtonBase>
            </Tooltip>
          </Visible>
          <Tooltip
            enterDelay={500}
            onOpen={() => handleInfoTooltip(true)}
            onClose={() => handleInfoTooltip(false)}
            open={isInfoTooltipOpen}
            title={
              <TooltipSpan>
                {tag.indicators.limits[getIndicatorSymbol(indicator)]}
              </TooltipSpan>
            }
          >
            <ButtonBase
              onClick={() => handleInfoTooltip()}
              className="icon-container info"
            >
              <Info className="info-icon" />
            </ButtonBase>
          </Tooltip>
        </div>
      </div>
      <div className="description-container">
        <Collapse in={isDescriptionShown} unmountOnExit mountOnEnter>
          <span className="tag-description">{tag.description}</span>
        </Collapse>
      </div>
    </Container>
  );
};
