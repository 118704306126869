import styled from "styled-components";

export interface SpacerProps {
  height: number;
}

/**
 * Pass the height as an attribute and use as a spacer;
 */
export const Spacer = styled.div`
  height: ${(props: SpacerProps) => props.height}px;
`;
