import { Button } from '@material-ui/core';
import styled from 'styled-components';

import { supportedDevices } from '../../../../../../../../styles/supportedDevices';

export const HeaderContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap;

  height: 80px;
  margin: 15px 0;

  .info-container {
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-wrap: wrap;

    width: 100%;

    h3 {
      font-size: 12px;
      font-weight: 600;
    }

    p {
      font-size: 10px;
    }
  }

  .search-and-details-container {
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-wrap: wrap;

    width: 100%;

    .search-container {
      width: 150px;
    }
  }

  @media ${supportedDevices.mobileM} {
    .search-and-details-container {
      .search-container {
        width: 200px;
      }
    }
  }

  @media ${supportedDevices.tablet} {
    .info-container {
      width: 200px;

      h3 {
        font-size: 16px;
        font-weight: bold;
      }

      p {
        font-size: 12px;
      }
    }

    .search-and-details-container {
      width: 450px;

      .search-container {
        width: 300px;
      }
    }
  }
`;

export const CategoriesContainer = styled.div`
  color: black;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-evenly;
  min-height: 150px;

  .category-column {
    width: 100%;

    .category-header {
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding: 5px 0;

      .title-container {
        display: flex;
        align-items: center;

        h4 {
          font-size: 12px;
          font-weight: 600;
          margin-right: 8px;
        }
      }

      button {
        padding: 4px;

        & svg {
          font-size: 2.5rem;
        }
      }
    }

    & .tags-container {
      max-height: 22vh;
      overflow-y: scroll;
      overflow-x: hidden;

      ::-webkit-scrollbar {
        width: 3px;
      }
    }
  }

  @media ${supportedDevices.tablet} {
    .category-column {
      display: flex;
      flex-direction: column;
      align-items: center;

      width: 50%;
    }
  }

  @media ${supportedDevices.laptopM} {
    .category-column {
      width: 25%;
    }
  }
`;

interface CircleIndicatorProps {
  color: string;
}

export const CircleIndicator = styled.div<CircleIndicatorProps>`
  height: 10px;
  width: 10px;
  background-color: ${({ color }) => color};
  border-radius: 50%;
  display: inline-block;
`;

interface ToggleButtonProps {
  isSelected: boolean;
}

export const ToggleButton = styled(Button).withConfig({
  shouldForwardProp: (prop) => !['isSelected'].includes(prop),
})<ToggleButtonProps>`
  && {
    width: 100px;
    height: 36px;
    justify-content: space-around;
    align-items: center;
    font-size: 1rem;
    padding: 0 8px;
    border: 0.5px solid rgba(0, 0, 0, 0.1);
    color: ${({ theme, isSelected }) =>
      isSelected ? theme.palette.primary.main : 'rgba(0,0,0,0.5)'};
    background-color: ${({ theme, isSelected }) =>
      isSelected ? theme.palette.background.paper : 'rgba(230,232,235,0.5)'};

    &:hover {
      background-color: ${({ theme, isSelected }) =>
        !isSelected ? theme.palette.background.paper : 'rgba(230,232,235,0.5)'};
    }

    @media ${supportedDevices.tablet} {
      width: 140px;
      height: 48px;
    }
  }
`;

interface ArrowContainerProps {
  isOpen: boolean;
}

export const ArrowContainer = styled.div<ArrowContainerProps>`
  transition: transform 0.1s ease;

  transform: ${({ isOpen }) => (isOpen ? 'rotate(180deg)' : 'rotate(0deg)')};
`;
