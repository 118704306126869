import { toastr } from 'react-redux-toastr';

import qs from 'qs';
import { put, call, select } from 'redux-saga/effects';

import config from '../../config';
import { Creators, Types } from '../actionCreators';
import {
  GetNotificationRequestAction,
  SaveNotificationRequestAction,
} from './types';

export function* getNotifications() {
  try {
    const { pageRequest, activeTab, searchText, selectedNodeUuid } = yield select(
      (state) => state.notifications
    );

    pageRequest.status = activeTab;
    pageRequest.searchText = searchText;
    pageRequest.nodeUuid = selectedNodeUuid

    const pageRequestQs = `?${qs.stringify(pageRequest, { indices: false })}`;
    const notifications = yield config.api.getNotifications(pageRequestQs);

    yield put(Creators.getNotificationsSuccess(notifications.data));
  } catch (error) {
    const msg = 'Ocorreu um erro ao buscar as notificações do servidor!';
    toastr.error('Erro', msg);
    yield put(Creators.notificationFailure(error, msg));
  }
}

export function* getNotificationsByActiveId() {
  try {
    const pageRequest = yield select(
      (state) => state.notifications.pageRequest
    );
    const activeTab = yield select((state) => state.notifications.activeTab);
    const uuid = yield select((state) => state.notifications.uuid);
    pageRequest.status = activeTab;
    pageRequest.uuid = uuid;
    const pageRequestQs = `?${qs.stringify(pageRequest, { indices: false })}`;
    const notifications = yield config.api.getNotificationsByActiveId(
      pageRequestQs
    );
    yield put(Creators.getNotificationsSuccess(notifications.data));
  } catch (error) {
    const msg = 'Ocorreu um erro ao buscar as notificações do servidor!';
    toastr.error('Erro', msg);
    yield put(Creators.notificationFailure(error, msg));
  }
}

interface GetNotificationAction {
  type: typeof Types.GET_NOTIFICATIONS_REQUEST;
  id: GetNotificationRequestAction['id'];
}

export function* getNotification(action: GetNotificationAction) {
  try {
    const notification = yield config.api.getNotificationById(action.id);
    yield put(Creators.getNotificationSuccess(notification.data));
  } catch (error) {
    const msg =
      'Ocorreu um erro ao buscar as informações da notificação selecionada!';
    toastr.error('Erro', msg);
    yield put(Creators.notificationFailure(error, msg));
  }
}

interface SaveNotificationAction {
  type: typeof Types.SAVE_NOTIFICATION_REQUEST;
  notification: SaveNotificationRequestAction['notification'];
}

export function* saveNotification(action: SaveNotificationAction) {
  try {
    if (action.notification.isNonApplicable) yield put(Creators.saveNotificationSuccess())
    const newNotification = {...action.notification}
    delete newNotification.isNonApplicable
    yield config.api.putNotification(newNotification);
    yield put(Creators.saveNotificationSuccess());
    if (!action.notification.isNonApplicable) yield call(getNotifications);
  } catch (error) {
    const msg = 'Ocorreu um erro ao atualizar a notificação!';
    toastr.error('Erro', msg);
    yield put(Creators.notificationFailure(error, msg));
  }
}
