import { Backdrop } from '@material-ui/core';
import styled from 'styled-components';

export const Container = styled(Backdrop)`
  && {
    z-index: ${({ theme }) => theme.zIndex.drawer + 1};
    display: flex;
    align-items: flex-end;

    .sheet {
      background: white;
      width: 100%;
      height: fit-content;
      border-radius: 4px;
    }
  }
`;
