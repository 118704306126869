import { createReducer, createActions } from 'reduxsauce';

import {
  TagsTypes,
  TagsActions,
  TagsState,
  GetTagsRequestAction,
  GetTagsRequestFFTAction,
  GetTagsSuccessAction,
  GetTagsFailureAction,
} from './types';

const { Types, Creators } = createActions<TagsTypes, TagsActions>({
  getTagsRequest: ['pageRequest'],
  getTagsRequestFFT: ['pageRequest'],
  getTagsSuccess: ['tags'],
  getTagsFailure: ['error'],
});

const INITIAL_STATE: TagsState = {
  isLoading: false,
  data: [],
  error: '',
  isError: false,
  pageRequest: {
    assetUuid: '',
    from: '-15min',
    identifier: '',
    until: '-1min',
  },
};

const getTagsRequest = (
  state = INITIAL_STATE,
  action: GetTagsRequestAction
) => {
  let pageRequest = { ...state.pageRequest };
  if (action.pageRequest)
    pageRequest = { ...pageRequest, ...action.pageRequest };
  return {
    ...state,
    isLoading: true,
    error: '',
    isError: false,
    pageRequest,
  };
};

const getTagsRequestFFT = (
  state = INITIAL_STATE,
  action: GetTagsRequestFFTAction
) => {
  let pageRequest = { ...state.pageRequest };
  if (action.pageRequest)
    pageRequest = { ...pageRequest, ...action.pageRequest };
  return {
    ...state,
    isLoading: true,
    error: '',
    isError: false,
    pageRequest,
  };
};

const getTagsSuccess = (
  state = INITIAL_STATE,
  action: GetTagsSuccessAction
) => {
  return {
    ...state,
    isLoading: false,
    data: action.tags,
  };
};

const getTagsFailure = (
  state = INITIAL_STATE,
  action: GetTagsFailureAction
) => {
  return {
    ...state,
    isLoading: false,
    error: action.error,
    isError: false,
  };
};

const HANDLERS = {
  [Types.GET_TAGS_REQUEST]: getTagsRequest,
  [Types.GET_TAGS_REQUEST_FFT]: getTagsRequestFFT,
  [Types.GET_TAGS_SUCCESS]: getTagsSuccess,
  [Types.GET_TAGS_FAILURE]: getTagsFailure,
};

const Reducer = createReducer(INITIAL_STATE, HANDLERS);

export { Reducer as TagsReducer, Types as TagsTypes, Creators as TagsCreators };
