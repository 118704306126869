import React from 'react';
import { RouteComponentProps } from 'react-router-dom';

import { Slide } from '@material-ui/core';

import { Router } from './router';

import { Container } from './styles';

export const HealthAndIndicatorView: React.FC<RouteComponentProps> = (
  props
) => {
  return (
    <Slide in direction="left" timeout={500}>
      <Container>
        <Router {...props} />
      </Container>
    </Slide>
  );
};
