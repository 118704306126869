import { put } from 'redux-saga/effects';

import config from '../../config';
import { Creators } from '../actionCreators';

export function* getUsers(action) {
  try {
    const users = yield config.api.getUsers();
    yield put(Creators.getUsersSuccess(users.data));
  } catch (error) {
    yield put(
      Creators.getUsersFailure('Ocorreu um erro ao buscar os registros!')
    );
  }
}
