import React, { useState } from 'react';

import { IconButton, Button } from '@material-ui/core';
import { DatePicker } from '@material-ui/pickers';
import { MaterialUiPickersDate } from '@material-ui/pickers/typings/date';
import {
  startOfWeek,
  isValid,
  format,
  endOfWeek,
  isWithinInterval,
  isSameDay,
  isFuture,
  addYears,
} from 'date-fns';

import { DayContainer, Container } from './styles';

export interface WeekPickerProps {
  /**
   * On cancel press callback
   */
  onCancelPress?: () => void;

  /**
   * On apply press callback
   */
  onApplyPress(startDate: Date, endDate: Date): void;

  /**
   * initial passed date
   */
  initialDate?: Date;
}

/**
 * Peeker for weeks
 */
export const WeekPicker: React.FC<WeekPickerProps> = ({
  onCancelPress = () => {},
  onApplyPress,
  initialDate = new Date(),
}) => {
  const [selectedDate, setSelectedDate] = useState(initialDate);

  function handleWeekChange(newDate: MaterialUiPickersDate) {
    setSelectedDate(newDate!);
  }

  function formatWeekSelectLabel(
    newDate: MaterialUiPickersDate,
    invalidLabel: string
  ) {
    return newDate && isValid(newDate)
      ? `Semana de ${format(startOfWeek(newDate), 'MMM do')}`
      : invalidLabel;
  }

  function renderWrappedWeekDay(
    newDate: MaterialUiPickersDate,
    currentData: MaterialUiPickersDate,
    dayInCurrentMonth: boolean
  ) {
    const start = startOfWeek(currentData!);
    const end = endOfWeek(currentData!);

    const dayIsBetween = isWithinInterval(newDate!, { start, end });
    const isFirstDay = isSameDay(newDate!, start);
    const isLastDay = isSameDay(newDate!, end);
    const isFutureDay = isFuture(newDate!);

    let containerClass;
    if (dayIsBetween) containerClass = 'highlight';
    if (isFirstDay) containerClass = 'first-highlight';
    if (isLastDay) containerClass = 'end-highlight';

    let buttonClass = 'day';
    if (isFutureDay) buttonClass += ' future-day';
    if (!dayInCurrentMonth) buttonClass += ' non-current-month-day';
    if (!dayInCurrentMonth && dayIsBetween)
      buttonClass += ' highlight-non-current-month-day';

    return (
      <DayContainer className={containerClass}>
        <IconButton className={buttonClass}>
          <span>{format(newDate!, 'd')}</span>
        </IconButton>
      </DayContainer>
    );
  }

  return (
    <Container data-testid="container">
      <DatePicker
        label="Week Picker"
        value={selectedDate}
        onChange={handleWeekChange}
        renderDay={renderWrappedWeekDay}
        labelFunc={formatWeekSelectLabel}
        okLabel="aplicar"
        cancelLabel="cancelar"
        disableFuture
        variant="static"
        minDate={new Date('01/01/2019')}
        maxDate={addYears(new Date(), 5)}
      />
      <div className="picker-actions" data-testid="actionsContainer">
        <Button
          className="action"
          data-testid="cancelButton"
          onClick={onCancelPress}
        >
          cancelar
        </Button>
        <Button
          className="action gradient"
          data-testid="applyButton"
          onClick={() =>
            onApplyPress(startOfWeek(selectedDate), endOfWeek(selectedDate))
          }
        >
          aplicar
        </Button>
      </div>
    </Container>
  );
};
