import { createReducer } from "reduxsauce";

import { Types } from "../actionCreators";

export const INITIAL_STATE = {
  isLoading: false,
  data: [],
  error: "",
  isError: false
};

export const getUsersRequest = (state = INITIAL_STATE, action) => {
  return {
    ...state,
    isLoading: true,
    error: "",
    isError: false
  };
};

export const getUsersSuccess = (state = INITIAL_STATE, action) => {
  return {
    ...state,
    isLoading: false,
    data: action.data
  };
};

export const getUsersFailure = (state = INITIAL_STATE, action) => {
  return {
    ...state,
    isLoading: false,
    data: [],
    error: action.error,
    isError: false
  };
};

export const HANDLERS = {
  [Types.GET_USERS_REQUEST]: getUsersRequest,
  [Types.GET_USERS_SUCCESS]: getUsersSuccess,
  [Types.GET_USERS_FAILURE]: getUsersFailure
};

export default createReducer(INITIAL_STATE, HANDLERS);
