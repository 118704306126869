import { Button } from '@material-ui/core';
import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;

  .back-button {
    padding: 0 12px;
  }

  & .info-container {
    display: flex;
    flex-flow: column;
    align-items: flex-start;
    color: black;

    & .arrow-container {
      display: flex;
      align-items: center;
      margin-left: -12px;

      h1 {
        font-size: 20px;
      }
    }

    & .title-container {
      display: flex;
      align-items: center;

      & > * {
        margin-right: 15px;
      }

      h1 {
        font-size: 32px;
      }
    }

    p {
      font-size: 14px;
    }

    & .breadcrumbs-container {
      display: flex;
      flex-flow: row;
      margin-top: 15px;
      margin-bottom: 10px;
      flex-wrap: wrap;

      & .breadcrumb {
        width: 100px;
        margin-right: 5px;
        margin-bottom: 5px;
        font-weight: 700;
        display: flex;
        justify-content: space-between;
        padding: 6px 16px;
        box-sizing: border-box;
        line-height: 1.75;
        border-radius: 4px;
        text-transform: uppercase;
        background: #e0e0e0;
        box-shadow: 0px 3px 1px -2px rgba(0, 0, 0, 0.2),
          0px 2px 2px 0px rgba(0, 0, 0, 0.14),
          0px 1px 5px 0px rgba(0, 0, 0, 0.12);

        &.gradient {
          background-image: linear-gradient(to right, #19b0a0, #009ee2);
          color: white;
          justify-content: center;
        }
      }
    }

    .description-container {
      display: flex;
      align-items: center;

      & > :first-child {
        margin-right: 3px;
      }
    }
  }
`;

export const TooltipSpan = styled.span`
  font-size: 12px;
`;

export const TitleButton = styled(Button)`
  && {
    background-image: linear-gradient(to right, #19b0a0, #009ee2);
    color: white;
    justify-content: center;
  }
`;
