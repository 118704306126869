import React, { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';

import { CircularProgress, Collapse, Slide } from '@material-ui/core';

import { ResponsiveGraphViewProps } from '..';

import errorIcon from '../../../../../../assets/imgs/error-icon.png';
import { Sunburst } from '../../../../../../components/charts/Sunburst';
import { Treemap } from '../../../../../../components/charts/Treemap';
import { AppState } from '../../../../../../store/reducers';
import { kSunburstColors } from '../../../../../../utils/constants';
import { FilterButtons } from './components/FilterButtons';
import { Header } from './components/Header';
import { HistoryTab } from './components/History';
import { Collapsible } from './components/Collapsible';
import { Container, MainContainer, ChartContainer } from './styles';
import { Creators as NotificationsCreators } from '../../../../../../store/actionCreators';

export const DesktopGraphView: React.FC<ResponsiveGraphViewProps> = ({
  filteredColors,
  selectedHealth,
  visibleGraph,
  onSelect,
  onHover,
  onAuxClick,
  toggleChartVisible,
  onFilterClick,
  getNodeColor,
  onTreemapSelect,
  onTreemapHover,
  handleLayerBlock,
  timeList,
}) => {
  const {
    isLoading,
    error,
    selectedNode,
    hierarchy,
    treeMapData,
    selectedIndicator,
    timePeriod,
  } = useSelector((state: AppState) => state.treeHealth);

  const treeHealth = useSelector((state: AppState) => state.treeHealth);

  const dispatch = useDispatch();

  useEffect(() => {
    if (selectedNode.data != null) {
      const selectedAssetUuid = selectedNode.data.uuid;
      dispatch(NotificationsCreators.setSelectedNodeUuid(selectedAssetUuid));
      dispatch(NotificationsCreators.getNotificationsRequest());
    }
  }, [selectedNode, dispatch]);

  function buildLoaderOrError() {
    if (error)
      return (
        <div className="error-container">
          <img src={errorIcon} alt="Ícone de erro" />
          <h3>Ocorreu um erro! Recarregue a página para tentar novamente.</h3>
        </div>
      );
    return (
      <div className="loader-container">
        <CircularProgress size={50} />
      </div>
    );
  }

  return (
    <Container
      maxHeight={
        1400 + Number(visibleGraph.sunburst && visibleGraph.treemap && 500)
      }
    >
      <Slide in direction="left" timeout={500}>
        <section className="card">
          {isLoading || error || !selectedNode ? (
            buildLoaderOrError()
          ) : (
            <MainContainer>
              <Header
                onFilterSelect={onSelect}
                healthType={selectedHealth}
                isSunburstVisible={visibleGraph.sunburst}
                isTreeMapVisible={visibleGraph.treemap}
                onToggleChart={toggleChartVisible}
                handleLayerBlock={handleLayerBlock}
                timeList={timeList}
              />
              <ChartContainer>
                <div className="filters">
                  <FilterButtons
                    colors={kSunburstColors}
                    onFilterToggle={onFilterClick}
                    filteredColors={filteredColors}
                    indicator={selectedIndicator}
                    timePeriod={timePeriod}
                  />
                </div>
                <div className="charts center">
                  <Collapse
                    in={visibleGraph.sunburst}
                    timeout="auto"
                    unmountOnExit
                    mountOnEnter
                  >
                    <div className="center" onContextMenu={(e) => e.preventDefault()}>
                      <Sunburst
                        data={hierarchy}
                        onSelect={onSelect}
                        onHover={onHover}
                        onAuxClick={onAuxClick}
                        filteredColors={filteredColors}
                        definingNodeColor={getNodeColor}
                        selectedNode={selectedNode}
                        keyId="sunburst"
                        size={550}
                        healthType={selectedHealth}
                      />
                    </div>
                  </Collapse>
                  <Collapse
                    in={visibleGraph.treemap}
                    timeout="auto"
                    unmountOnExit
                    mountOnEnter
                  >
                    <div className="center treemap" onContextMenu={(e) => e.preventDefault()}>
                      <Treemap
                        data={treeMapData}
                        onSelect={onTreemapSelect}
                        onAuxClick={onAuxClick}
                        onHover={onTreemapHover}
                        definingNodeColor={getNodeColor}
                        filteredColors={filteredColors}
                        keyId="treemap"
                        width="600"
                        height="550"
                        healthType={selectedHealth}
                      />
                    </div>
                  </Collapse>
                </div>
              </ChartContainer>
              <Collapsible isHealth={treeHealth.selectedIndicator.name === 'Saúde'} title="Histórico">

                <HistoryTab
                  identifier={selectedNode.data.identifier}
                />
              </Collapsible>

            </MainContainer>
          )}
        </section>

      </Slide>
    </Container>
  );
};
