import { Button } from '@material-ui/core';
import styled, { css } from 'styled-components';

interface CustomButtonProps {
  disabled?: boolean;
  gradient?: boolean;
}

export const CustomButton = styled(Button).withConfig({
  shouldForwardProp: (prop) => !['gradient'].includes(prop),
})<CustomButtonProps>`
  && {
    justify-content: space-around;
    align-items: center;
    border: 0.5px solid
      ${({ disabled }) => (disabled ? '#BDBDBD' : 'rgba(0,0,0,0.1)')};

    ${({ theme, disabled, gradient }) => {
      if (gradient)
        return css`
          color: white;
          background-image: ${() =>
            disabled
              ? 'linear-gradient(to right, rgba(230,232,235,0.3), rgba(230,232,235,0.3))'
              : 'linear-gradient(to right, #19b0a0, #009ee2)'};
        `;

      return css`
        color: ${() => theme.palette.primary.main};
        background-color: ${() =>
          disabled ? 'rgba(230,232,235,0.3)' : 'rgba(230,232,235,0.5)'};
      `;
    }}

    transition: all 0.2s;

    padding: 0 8px;
    width: 100%;
    height: 100%;

    &:hover {
      background-color: ${({ theme }) => theme.palette.background.paper};
    }

    span {
      font-size: 12px;
      font-weight: bold;
    }
  }
`;
