import { all, takeLatest, takeEvery, put } from 'redux-saga/effects';

import { Creators, Types } from '../actionCreators';
import {
  getAssetData,
  getAssetDetails,
  getAssetIndicators,
  getAssetAlert,
  getAssetCalibration,
  patchAssetDetails,
  patchAssetCalibration,
  updateAssetBlock,
  updateAssetIndicatorWeight,
  updateAssetAlert,
  updateAsset
} from '../assetData';
// import { auth, destroyAuth, login, getMe, getCompanyEmails } from '../auth';
import { getAnalysisData, getAssetTags } from '../dataAnalysis';
import { getHistoryIndicatorData } from '../historyIndicator';
import {
  getNotifications,
  saveNotification,
  getNotificationsByActiveId,
} from '../notifications';
import { getCollectedTagsInfo, getReport, getTagRatioInfo } from '../reports';
import { getTags, getTagsFFT } from '../tags';
import {
  getTreeHealth,
  getTreeHealthWithSpecificTimePeriod,
  handleLayerBlock,
  getSelectableIndicators,
} from '../treeHealth';
import { getCostCenters } from './costCenters';
import { getKnowledges, getKnowledgeById, saveKb } from './knowledges';
import { getOs, saveOs, getOsById, getOsResume } from './os';
import { getTagsDatapoints } from './tagsDatapoints';
import { getTreeFilter } from './treeFilter';
import { getUsers } from './users';

export default function* rootSaga() {
  yield all([
    // takeLatest(Types.SIGNIN_REQUEST, login),
    // takeLatest(Types.AUTH_REQUEST, auth),
    // takeLatest(Types.DESTROY_AUTH_REQUEST, destroyAuth),
    // takeLatest(Types.GET_ME_REQUEST, getMe),
    // takeLatest(Types.GET_COMPANY_EMAILS_REQUEST, getCompanyEmails),
    takeLatest(Types.GET_NOTIFICATIONS_REQUEST, getNotifications),
    takeLatest(
      Types.GET_NOTIFICATIONS_BY_ASSET_ID_REQUEST,
      getNotificationsByActiveId
    ),
    takeLatest(Types.SAVE_NOTIFICATION_REQUEST, saveNotification),
    takeLatest(Types.GET_KNOWLEDGES_REQUEST, getKnowledges),
    takeLatest(Types.SAVE_KNOWLEDGE_REQUEST, saveKb),
    takeLatest(Types.GET_KNOWLEDGE_BY_ID_REQUEST, getKnowledgeById),
    takeLatest(Types.GET_ASSET_DATA_REQUEST, getAssetData),
    takeLatest(Types.UPDATE_ASSET_BLOCK, updateAssetBlock),
    takeLatest(Types.GET_ASSET_INDICATORS_REQUEST, getAssetIndicators),
    takeLatest(
      Types.UPDATE_ASSET_INDICATOR_WEIGHT_REQUEST,
      updateAssetIndicatorWeight
    ),
    takeLatest(Types.GET_ASSET_DETAILS_REQUEST, getAssetDetails),
    takeLatest(Types.PATCH_ASSET_DETAILS_REQUEST, patchAssetDetails),
    takeLatest(Types.GET_ASSET_ALERT_REQUEST, getAssetAlert),
    takeLatest(Types.UPDATE_ASSET_ALERT_REQUEST, updateAssetAlert),
    takeLatest(Types.UPDATE_ASSET_REQUEST, updateAsset),
    takeLatest(Types.GET_ASSET_CALIBRATION_REQUEST, getAssetCalibration),
    takeLatest(Types.PATCH_ASSET_CALIBRATION_REQUEST, patchAssetCalibration),
    takeLatest(Types.GET_OS_REQUEST, getOs),
    takeLatest(Types.GET_OS_RESUME_REQUEST, getOsResume),
    takeLatest(Types.SAVE_OS_REQUEST, saveOs),
    takeLatest(Types.GET_OS_BY_ID_REQUEST, getOsById),
    takeLatest(Types.GET_TAGS_REQUEST, getTags),
    takeLatest(Types.GET_TAGS_REQUEST_FFT, getTagsFFT),
    takeLatest(Types.GET_TAGS_DATAPOINTS_REQUEST, getTagsDatapoints),
    takeLatest(Types.GET_TREE_HEALTH_REQUEST, getTreeHealth),
    takeLatest(
      Types.GET_TREE_HEALTH_REQUEST_WITH_TIME_PERIOD,
      getTreeHealthWithSpecificTimePeriod
    ),
    takeLatest(
      Types.GET_SELECTABLE_INDICATORS_REQUEST,
      getSelectableIndicators
    ),
    takeLatest(Types.HANDLE_LAYER_BLOCK_REQUEST, handleLayerBlock),
    takeEvery(Types.GET_TREE_FILTER_REQUEST, getTreeFilter),
    takeLatest(Types.GET_COST_CENTERS_REQUEST, getCostCenters),
    takeLatest(Types.GET_USERS_REQUEST, getUsers),
    takeLatest(Types.GET_REPORTS_REQUEST, getReport),
    takeLatest(Types.GET_COLLECTED_TAGS_INFO_REQUEST, getCollectedTagsInfo),
    takeLatest(Types.GET_TAG_RATIO_INFO_REQUEST, getTagRatioInfo),
    takeLatest(Types.GET_ASSET_TAGS_REQUEST, getAssetTags),
    takeLatest(Types.GET_ANALYSIS_DATA_REQUEST, getAnalysisData),
    // put(Creators.authRequest()),
    // put(Creators.getMeRequest()),
  ]);
}
