import styled from 'styled-components';

import { supportedDevices } from '../../../../../../../../styles/supportedDevices';

export const LoaderContainer = styled.div`
  height: 500px;
  display: grid;
  place-items: center;
`;

export const Container = styled.div`
  min-height: 500px;
  color: black;

  & h3 {
    font-size: 14px;
  }

  .time-selector-container {
    display: flex;
    align-items: center;
    margin-bottom: 15px;

    h3 {
      margin-right: 15px;
    }
  }

  .graph-container {
    display: flex;
    height: 40vh;
    width: 100%;
    margin-bottom: 20px;
    padding: 10px;
    border-color: transparent;
  }

  .logical-container {
    margin-top: -15px;

    .selects-container {
      height: 90px;
      margin-bottom: 20px;
      display: flex;
      justify-content: space-evenly;
      align-items: center;
      flex-wrap: wrap;

      & > div {
        width: 100%;
      }

      @media ${supportedDevices.tablet} {
        margin-bottom: 10px;

        & > div {
          max-width: 400px;
          margin: 5px 0;
        }
      }
    }
  }
`;
