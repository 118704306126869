import { Button } from '@material-ui/core';
import styled from 'styled-components';

import { supportedDevices } from '../styles/supportedDevices';

export const GradientButton = styled(Button)`
  background-image: linear-gradient(to right, #19b0a0, #009ee2);
  font-size: 12px;

  && {
    color: white;
  }

  @media ${supportedDevices.laptop} {
    font-size: 14px;
  }
`;
