import { Button } from '@material-ui/core';
import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  flex-flow: column;
  color: black;
  max-width: 120px;

  p {
    margin-bottom: 5px;
    font-size: 12px;
    font-weight: 400;
  }
`;

interface ToggleIndexButtonProps {
  isSelected: boolean;
  borderColor: string;
}

export const ToggleIndexButton = styled(Button).withConfig({
  shouldForwardProp: (prop) =>
    !['isSelected'].includes(prop) && !['borderColor'].includes(prop),
})<ToggleIndexButtonProps>`
  && {
    width: 100px;
    margin: 3px 0;
    border-left: solid 5px ${(props) => props.borderColor};
    font-weight: 600;
    box-shadow: 0 0 5px 0 rgba(0, 0, 0, 0.1);
    font-size: 1rem;

    background: ${({ isSelected }) => (isSelected ? '#C8C9CC' : '#E6E8EB')};
  }

  &&:hover {
    background: ${({ isSelected }) => (isSelected ? '#B6B7BA' : '#D7D9DB')};
  }
`;
