import React from 'react';

import {
  Paper,
  Grid,
  Typography,
  Button,
  FormControl,
  Select,
  MenuItem,
} from '@material-ui/core';
import { differenceInCalendarMonths } from 'date-fns';

import {
  DateRange,
  Setter,
  NavigationAction,
  I18nArrays,
  TimeRange,
} from '../types';
import Month from './Month';

import { MARKERS } from '..';

import {
  HeaderContainer,
  ButtonsContainer,
  TimeFilterContainer,
} from './styles';

interface MenuProps {
  dateRange: DateRange;
  minDate: Date;
  maxDate: Date;
  firstMonth: Date;
  secondMonth: Date;
  setFirstMonth: Setter<Date>;
  setSecondMonth: Setter<Date>;
  helpers: {
    inHoverRange: (day: Date) => boolean;
  };
  handlers: {
    onDayClick: (day: Date) => void;
    onDayHover: (day: Date) => void;
    onMonthNavigate: (marker: symbol, action: NavigationAction) => void;
  };
  i18n?: I18nArrays;
  onCancelClick(): void;
  onApplyClick(): void;
  showSingleMonth: boolean;
  selectedTime: TimeRange;
  handleTimeClick(time: TimeRange): void;
}

const Menu: React.FunctionComponent<MenuProps> = (props) => {
  const {
    dateRange,
    minDate,
    maxDate,
    firstMonth,
    setFirstMonth,
    secondMonth,
    setSecondMonth,
    helpers,
    handlers,
    i18n,
    onCancelClick,
    onApplyClick,
    showSingleMonth,
    selectedTime,
    handleTimeClick,
  } = props;

  const canNavigateCloser =
    differenceInCalendarMonths(secondMonth, firstMonth) >= 2 || showSingleMonth;

  const commonProps = { dateRange, minDate, maxDate, helpers, handlers };

  function createTimeStrings() {
    const x = 30; //  Minutes interval
    const times = [];
    let tt = 0;

    for (let i = 0; tt < 24 * 60; i++) {
      const hh = Math.floor(tt / 60);
      const mm = tt % 60;
      times[i] = `${`0${hh}`.slice(-2)}:${`0${mm}`.slice(-2)}`;
      tt += x;
    }

    return times;
  }

  function handleSelectItem(
    event: React.ChangeEvent<{
      name?: string;
      value: unknown;
    }>
  ) {
    handleTimeClick({
      ...selectedTime,
      [event.target.name!]: event.target.value,
    });
  }

  return (
    <Paper elevation={5}>
      <Grid container direction="row" wrap="nowrap">
        <Grid>
          <HeaderContainer>
            <Typography variant="h5">Selecione um período</Typography>
          </HeaderContainer>
          <Grid container direction="row" justify="center" wrap="nowrap">
            <Month
              {...commonProps}
              value={firstMonth}
              setValue={setFirstMonth}
              navState={[true, canNavigateCloser]}
              marker={MARKERS.FIRST_MONTH}
              months={i18n ? i18n.months : undefined}
              days={i18n ? i18n.days : undefined}
            />
            {!showSingleMonth && (
              <Month
                {...commonProps}
                value={secondMonth}
                setValue={setSecondMonth}
                navState={[canNavigateCloser, true]}
                marker={MARKERS.SECOND_MONTH}
                months={i18n ? i18n.months : undefined}
                days={i18n ? i18n.days : undefined}
              />
            )}
          </Grid>
          <TimeFilterContainer>
            <div className="time-picker-container">
              <p>Horário inicial</p>
              <FormControl variant="outlined">
                <Select
                  value={selectedTime.startTime}
                  name="startTime"
                  onChange={handleSelectItem}
                  fullWidth
                >
                  {createTimeStrings().map((item) => (
                    <MenuItem key={item} value={item}>
                      {item}
                    </MenuItem>
                  ))}
                  {!createTimeStrings().find(
                    (time) => time === selectedTime.startTime
                  ) && (
                    <MenuItem value={selectedTime.startTime} disabled>
                      {selectedTime.startTime}
                    </MenuItem>
                  )}
                </Select>
              </FormControl>
            </div>
            <div className="time-picker-container">
              <p>Horário final</p>
              <FormControl variant="outlined">
                <Select
                  value={selectedTime.endTime}
                  name="endTime"
                  onChange={handleSelectItem}
                  fullWidth
                >
                  {createTimeStrings().map((item) => (
                    <MenuItem key={item} value={item}>
                      {item}
                    </MenuItem>
                  ))}
                  {!createTimeStrings().find(
                    (time) => time === selectedTime.endTime
                  ) && (
                    <MenuItem value={selectedTime.endTime} disabled>
                      {selectedTime.endTime}
                    </MenuItem>
                  )}
                </Select>
              </FormControl>
            </div>
          </TimeFilterContainer>
          <ButtonsContainer>
            <Button variant="contained" onClick={onCancelClick}>
              Cancelar
            </Button>
            <Button
              variant="contained"
              className="gradient"
              onClick={onApplyClick}
            >
              Aplicar
            </Button>
          </ButtonsContainer>
        </Grid>
      </Grid>
    </Paper>
  );
};

export default Menu;
