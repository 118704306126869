import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  background: white;
  border-radius: 4px;
  box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.04);
  overflow: hidden;

  min-height: 100%;
`;
