import React, { useState } from 'react';
import Media from 'react-media';
import { useSelector, useDispatch } from 'react-redux';

import { Fade, Backdrop } from '@material-ui/core';

import { CustomModal } from '../../../../../../../components/CustomDialog/styles';
import { Creators } from '../../../../../../../store/actionCreators';
import { AppState } from '../../../../../../../store/reducers';
import { GraphViewRouteState } from '../../../GraphView';
import { EditAlertModal } from './components/EditAlertModal';
import { DesktopHeader } from './DesktopHeader';
import { MobileHeader } from './MobileHeader';

import { supportedDevices } from '../../../../../../../styles/supportedDevices';
import { Outline0 } from './styles';
import { EditAssetModal } from './components/EditAssetModal';

interface HeaderProps {
  isLogical: boolean;
  graphViewState: GraphViewRouteState;
}

export interface ResponsiveHeaderParams extends HeaderProps {
  trailStrings: string[];
  handleBlockAsset(): void;
  handleEditAsset(open?: boolean): void;
  toggleAssetAlertsModal(open?: boolean): void;
}

export const Header: React.FC<HeaderProps> = ({
  isLogical,
  graphViewState,
}) => {
  const assetData = useSelector((state: AppState) => state.assetData);
  const dispatch = useDispatch();

  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isModalEditOpen, setIsModalEditOpen] = useState(false);

  const trailStrings = [
    assetData.data.company,
    assetData.data.unit,
    assetData.data.plant,
    assetData.data.area,
    assetData.data.subarea,
    assetData.data.equipment,
    assetData.data.class,
  ];

  function handleBlockAsset() {
    dispatch(
      Creators.updateAssetBlock(assetData.data.uuid, !assetData.data.blocked)
    );
  }

  function toggleAssetAlertsModal(open = false) {
    setIsModalOpen(open);
  }

  function handleEditAsset(open = false){
    setIsModalEditOpen(open);
  }

  const params: ResponsiveHeaderParams = {
    isLogical,
    trailStrings,
    graphViewState,
    handleBlockAsset,
    toggleAssetAlertsModal,
    handleEditAsset,
  };

  return (
    <>
      <Media
        queries={{
          tablet: `${supportedDevices.tablet}`,
        }}
      >
        {(matches) => (
          <>
            {matches.tablet && <DesktopHeader {...params} />}
            {!matches.tablet && <MobileHeader {...params} />}
          </>
        )}
      </Media>
      <CustomModal
        open={isModalOpen}
        onClose={() => toggleAssetAlertsModal()}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
      >
        <Fade in={isModalOpen}>
          <Outline0>
            <EditAlertModal
              handleCancel={() => toggleAssetAlertsModal(false)}
            />
          </Outline0>
        </Fade>
      </CustomModal>
      <CustomModal
        open={isModalEditOpen}
        onClose={() => handleEditAsset()}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
      >
        <Fade in={isModalEditOpen}>
          <Outline0>
            <EditAssetModal
              handleCancel={() => handleEditAsset(false)}
              isLogicalAsset={isLogical}
            />
          </Outline0>
        </Fade>
      </CustomModal>

    </>
  );
};
