import { Backdrop } from '@material-ui/core';
import styled from 'styled-components';

import { kMobileAppBarHeight } from '../../../utils/constants';

export const Toolbar = styled.div`
  min-height: ${kMobileAppBarHeight}px;
  display: grid;
  grid-template-columns: 20% 60% 20%;
  padding: 0 15px;
`;

export const LogoContainer = styled.div`
  -webkit-touch-callout: none;
  -webkit-tap-highlight-color: transparent;
  user-select: none;

  display: flex;
  align-items: center;
  justify-content: center;

  img {
    height: 20px;
  }
`;

export const ToggleButtonsContainer = styled.div`
  display: flex;
  justify-content: space-around;
  align-items: center;

  div {
    -webkit-touch-callout: none;
    -webkit-tap-highlight-color: transparent;
    user-select: none;
  }
`;

export const CustomBackdrop = styled(Backdrop)`
  && {
    background-color: transparent;
  }
`;

export const ModalContainer = styled.div`
  background-color: ${(props) => props.theme.palette.background.default};
  height: calc(100% - ${kMobileAppBarHeight}px);
  width: 100vw;
  position: absolute;
  bottom: 0;
  padding: 15px 0;

  :focus {
    outline: none;
  }

  h1 {
    opacity: 60%;
    padding-left: 16px;
  }

  & .MuiListItemText-root {
    color: white;

    span {
      font-size: 16px;
      font-weight: 600;
    }
  }

  hr {
    background-color: rgba(255, 255, 255, 0.12);
    margin: 8px 16px;
  }
`;
