import React, { useEffect, useState, useRef } from 'react';
import Media from 'react-media';
import { useDispatch, useSelector } from 'react-redux';
import { RouteComponentProps, Switch, Route, Redirect } from 'react-router-dom';

import { CircularProgress, TextField, Slide } from '@material-ui/core';

import { CustomDialog } from '../../components/CustomDialog';
import { SimpleSearchField } from '../../components/SimpleSearchField';
import { NotificationInfo } from '../../models/NotificationInfo';
import { Creators } from '../../store/actionCreators';
import { AppState } from '../../store/reducers';
import { TabItem } from '../../utils/types';
import { Screen404 } from '../404';
import { CustomTabsContainer } from '../Dashboard/components/views/AssetView/styles';
import { Table } from './components/Table';

import { supportedDevices } from '../../styles/supportedDevices';
import { Container, CustomTab } from './styles';

const tabItems: TabItem[] = [
  {
    label: 'Pendentes',
    route: 'pendente',
  },
  {
    label: 'Em Análise',
    route: 'analise',
  },
  {
    label: 'Encerradas',
    route: 'encerrada',
  },
];

export const Notification: React.FC<RouteComponentProps> = ({
  history,
  match,
  location,
}) => {
  const notifications = useSelector((state: AppState) => state.notifications);
  const dispatch = useDispatch();

  const codeTextField = useRef<HTMLInputElement>(null);
  const commentTextField = useRef<HTMLInputElement>(null);
  const searchTextField = useRef<HTMLInputElement>(null);

  const [currentTab, setCurrentTab] = useState(0);
  const [commentModalState, setCommentModalState] = useState<{
    open: boolean;
    notification: NotificationInfo | null;
  }>({
    open: false,
    notification: null,
  });

  const [codeModalState, setCodeModalState] = useState<{
    open: boolean;
    notification: NotificationInfo | null;
  }>({
    open: false,
    notification: null,
  });

  useEffect(() => {
    dispatch(Creators.setSelectedNodeUuid(''));
  }, [dispatch]);

  useEffect(() => {
    dispatch(Creators.setSearchAbility(true));
    dispatch(Creators.searchNotification(''));
    dispatch(Creators.changeNotificationTab('Pendente'));
    dispatch(
      Creators.getNotificationsRequest({
        page: 0,
        lines: 10,
        orderBy: ['criticality', 'lastOccurrence'],
        direction: 'DESC',
      })
    );
  }, [dispatch]);

  useEffect(() => {
    dispatch(Creators.getNotificationsRequest());
  }, [dispatch, notifications.activeTab, notifications.searchText]);

  useEffect(() => {
    tabItems.forEach((item, index) => {
      if (location.pathname.endsWith(item.route)) {
        setCurrentTab(index);
        dispatch(
          Creators.changeNotificationTab(
            (item.route[0].toUpperCase() + item.route.slice(1)) as any
          )
        );
      }
    });
  }, [dispatch, location]);

  function handleTabChange(value: number) {
    const newTab = tabItems[value].route;
    history.replace(`${match.url}/${newTab}`);
  }

  function handleCodeModalOpen(open = false) {
    setCodeModalState({
      ...codeModalState,
      open,
    });
  }

  function handleCodeClick(notification: NotificationInfo) {
    setCodeModalState({
      open: true,
      notification,
    });
  }

  function handleCommentModalOpen(open = false) {
    setCommentModalState({
      ...commentModalState,
      open,
    });
  }

  function handleCommentClick(notification: NotificationInfo) {
    setCommentModalState({
      open: true,
      notification,
    });
  }

  function handleUpdateNotification(notification: NotificationInfo) {
    dispatch(Creators.saveNotificationRequest(notification));
  }

  function handleCommentModalConfirm() {
    handleCommentModalOpen();
    if (
      commentModalState.notification &&
      commentTextField.current?.value !== undefined
    ) {
      commentModalState.notification.observations =
        commentTextField.current?.value;
      handleUpdateNotification({...commentModalState.notification, isNonApplicable: false});
    }
  }

  function handleCodeModalConfirm() {
    handleCodeModalOpen();
    if (
      codeModalState.notification &&
      codeTextField.current?.value !== undefined
    ) {
      codeModalState.notification.code = codeTextField.current?.value;
      handleUpdateNotification({...codeModalState.notification, isNonApplicable: false});
    }
  }

  function handleNotificationStatusChange(
    notification: NotificationInfo,
    newStatus: string
  ) {
    notification.status = newStatus as 'Pendente' | 'Analise' | 'Encerrada';
    handleUpdateNotification({...notification, isNonApplicable: false});
  }

  function handleNotificationNonApplicableChange(
    notification: NotificationInfo,
    newNonApplicable: boolean
  ) {
    notification.nonApplicable = newNonApplicable as boolean;
    handleUpdateNotification({...notification, isNonApplicable: true});
  }

  function handleSearchSubmit() {
    if (searchTextField.current?.value !== undefined)
      dispatch(Creators.searchNotification(searchTextField.current.value));
  }

  return (
    <>
      <Container>
        <Slide in direction="left" timeout={500}>
          <div className="card">
            <div className="title-container">
              <h3>Notificações</h3>
            </div>
            <div className="filters-container">
              <div className="search-container">
                <SimpleSearchField
                  handleSearchSubmit={handleSearchSubmit}
                  searchTextField={searchTextField}
                />
              </div>
            </div>
            <div className="tabs-container">
              <CustomTabsContainer
                value={currentTab}
                onChange={(_, value) => handleTabChange(value)}
                variant="fullWidth"
              >
                {tabItems.map((item) => (
                  <CustomTab key={item.label} label={item.label} />
                ))}
              </CustomTabsContainer>
            </div>
            {notifications.isLoading ? (
              <div className="loader-container">
                <CircularProgress size={50} />
              </div>
            ) : (
              <div className="table-container">
                <Switch>
                  {tabItems.map((item) => (
                    <Route
                      key={item.label}
                      path={`${match.path}/${item.route}`}
                      render={() => (
                        <Table
                          onCodeClick={handleCodeClick}
                          onCommentClick={handleCommentClick}
                          onNotificationStatusChange={
                            handleNotificationStatusChange
                          }
                          onNotificationNonApplicableChange={
                            handleNotificationNonApplicableChange
                          }
                        />
                      )}
                    />
                  ))}
                  <Route path={`${match.path}/404`} component={Screen404} />
                  <Redirect
                    exact
                    from={`${match.path}`}
                    to={`${match.path}/${tabItems[0].route}`}
                  />
                  <Redirect from={`${match.path}/*`} to="/404" />
                </Switch>
              </div>
            )}
          </div>
        </Slide>
      </Container>
      <Media query={supportedDevices.tablet}>
        {(isTablet) => (
          <CustomDialog
            open={commentModalState.open}
            title="Observações"
            width={isTablet ? 500 : 300}
            body={
              <TextField
                inputRef={commentTextField}
                placeholder="Adicione observações"
                multiline
                rows={4}
                rowsMax={8}
                defaultValue={
                  commentModalState.notification?.observations || undefined
                }
                variant="outlined"
                fullWidth
              />
            }
            action={{
              label: 'salvar',
              onClick: handleCommentModalConfirm,
            }}
            onClose={() => handleCommentModalOpen()}
          />
        )}
      </Media>

      <Media query={supportedDevices.tablet}>
        {(isTablet) => (
          <CustomDialog
            open={codeModalState.open}
            title="Código de manutenção"
            width={isTablet ? 500 : 300}
            body={
              <TextField
                inputRef={codeTextField}
                placeholder="Adicione um código de manutenção"
                multiline
                rows={1}
                rowsMax={1}
                defaultValue={
                  codeModalState.notification?.code || undefined
                }
                variant="outlined"
                fullWidth
              />
            }
            action={{
              label: 'salvar',
              onClick: handleCodeModalConfirm,
            }}
            onClose={() => handleCodeModalOpen()}
          />
        )}
      </Media>
    </>
  );
};
