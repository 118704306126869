import { reducer as toastr } from 'react-redux-toastr';

import { combineReducers } from 'redux';

import { AssetDataReducer as assetData, AssetDataState } from '../assetData';
// import { AuthReducer as auth, AuthState } from '../auth';
import {
  DataAnalysisState,
  DataAnalysisReducer as dataAnalysis,
} from '../dataAnalysis';

import {
  HistoryIndicatorReducer as historyIndicator,
  HistoryIndicatorState,
} from '../historyIndicator'; 

import { DrawerReducer as drawer, DrawerState } from '../drawer';
import {
  NotificationsReducer as notifications,
  NotificationsState,
} from '../notifications';
import { ReportsReducer as reports, ReportsState } from '../reports';
import { TagsReducer as tags, TagsState } from '../tags';
import {
  TreeHealthReducer as treeHealth,
  TreeHealthState,
} from '../treeHealth';
import costCenters from './costCenter';
import knowledges from './knowledges';
import os from './os';
import tagsDatapoints from './tagsDatapoints';
import treeFilter from './treeFilter';
import users from './users';

export interface AppState {
  // auth: AuthState;
  treeHealth: TreeHealthState;
  notifications: NotificationsState;
  knowledges: any;
  assetData: AssetDataState;
  os: any;
  tags: TagsState;
  tagsDatapoints: any;
  treeFilter: any;
  costCenters: any;
  users: any;
  toastr: typeof toastr;
  reports: ReportsState;
  drawer: DrawerState;
  dataAnalysis: DataAnalysisState;
  historyIndicator: HistoryIndicatorState;
}

const appReducer = combineReducers({
  // auth,
  notifications,
  knowledges,
  assetData,
  os,
  tags,
  tagsDatapoints,
  treeHealth,
  treeFilter,
  costCenters,
  users,
  toastr,
  reports,
  drawer,
  dataAnalysis,
  historyIndicator
});

const rootReducer = (state: AppState, action: { type: string }) => {
  if (action.type === 'DESTROY_AUTH_REQUEST') state = undefined as any;

  return appReducer(state as any, action);
};

export default rootReducer;
