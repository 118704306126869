import { Button } from '@material-ui/core';
import styled from 'styled-components';

import { supportedDevices } from '../../../../../../../styles/supportedDevices';

interface ContainerProps {
  isSelected: boolean;
}

export const Container = styled.div.withConfig({
  shouldForwardProp: (prop) => !['isSelected'].includes(prop),
})<ContainerProps>`
  overflow-x: hidden;

  .content-container {
    display: flex;
    justify-content: space-between;

    & .icon-container {
      width: 30px;
      align-self: stretch;
      margin: 5px 0;
      display: flex;
      align-items: center;
      justify-content: center;
      border-radius: 4px;

      :last-child {
        margin-right: 5px;
      }

      .info-icon {
        color: #01a0c6;
      }

      .warning-icon {
        color: red;
      }

      & svg {
        font-size: 2rem;
      }
    }
  }

  .icons-container {
    display: flex;
    align-items: center;
    flex-direction: row-reverse;
  }

  .description-container {
    margin-bottom: 10px;
    font-size: 1.3em;

    & span.tag-description {
      font-weight: normal;
      max-width: 100%;
      word-wrap: break-word;
    }
  }

  @media ${supportedDevices.tablet} {
    width: 230px;

    .content-container {
      & .icon-container {
        & svg {
          font-size: 1.5rem;
        }
      }
    }

    .description-container {
      font-size: 1em;

      & span.tag-description {
        max-width: 230px;
      }
    }
  }
`;

interface ToggleButtonProps {
  isSelected: boolean;
  borderColor: string;
}

export const ToggleButton = styled(Button).withConfig({
  shouldForwardProp: (prop) =>
    !['isSelected'].includes(prop) && !['borderColor'].includes(prop),
})<ToggleButtonProps>`
  && {
    width: 100%;
    margin: 5px 0;
    margin-right: 5px;
    border-left: solid 5px ${(props) => props.borderColor};
    font-weight: 600;
    box-shadow: 0 0 5px 0 rgba(0, 0, 0, 0.1);
    font-size: 1.1em;

    background: ${({ isSelected }) => (isSelected ? '#C8C9CC' : '#E6E8EB')};

    @media ${supportedDevices.tablet} {
      font-size: 1em;
    }
  }

  &&:hover {
    background: ${({ isSelected }) => (isSelected ? '#B6B7BA' : '#D7D9DB')};
  }
`;

export const TooltipSpan = styled.span`
  font-size: 12px;
  line-height: 1.5;
  display: flex;
  flex-direction: column;
  white-space: pre-line;
`;

interface Visible {
  show: boolean;
}

export const Visible = styled.div<Visible>`
  opacity: ${({ show }) => Number(show)};
  pointer-events: ${({ show }) => (show ? 'unset' : 'none')};
`;
