import React from 'react';

import { CircularProgress } from '@material-ui/core';

import errorIcon from '../../../../../assets/imgs/error-icon.png';
import { Header, HeaderProps } from '../Header';

import { Container } from './styles';

export interface DataAnalysisPageProps {
  /**
   * Page Header props
   */
  headerProps: HeaderProps;

  /**
   * Page error if any
   */
  error?: string;

  /**
   * Page loading
   */
  isLoading?: boolean;

  /**
   * Selecte element data
   */
  selectElementData?: {
    shouldShow?: boolean;
    shouldShowWithHeader?: boolean;
    message?: string;
  };

  /**
   * Iframe url to be loaded
   */
  url: string;
}

/**
 * Data Analysis Page Template
 */
export const DataAnalysisPage: React.FC<DataAnalysisPageProps> = ({
  headerProps,
  error,
  isLoading = false,
  selectElementData = {
    shouldShow: false,
    shouldShowWithHeader: false,
    message: '',
  },
  url,
}) => {
  function buildErrorAndLoader() {
    if (error)
      return (
        <div className="error" data-testid="error">
          <img src={errorIcon} alt="Ícone de erro" />
          <h3>Ocorreu um erro! Recarregue a página para tentar novamente.</h3>
        </div>
      );
    return (
      <div className="loader" data-testid="loader">
        <CircularProgress size={50} />
      </div>
    );
  }

  if (selectElementData.shouldShow)
    return (
      <Container className="no-element" data-testid="noElementContainer">
        <h2>{selectElementData.message}</h2>
      </Container>
    );

  return (
    <Container data-testid="dataAnalysisPage">
      <Header {...headerProps} />
      {error || isLoading ? (
        buildErrorAndLoader()
      ) : (
        <div className="chart" data-testid="chartContainer">
          {selectElementData.shouldShowWithHeader ? (
            <div
              className="select-element-container"
              data-testid="selectElementContainer"
            >
              <h2>{selectElementData.message}</h2>
            </div>
          ) : (
            <iframe
              data-testid="iframe"
              title={headerProps.title}
              src={url}
              width="100%"
              height="500px"
              frameBorder="0"
            />
          )}
        </div>
      )}
    </Container>
  );
};
