import { createReducer } from "reduxsauce";

import { Types } from "../actionCreators";

export const INITIAL_STATE = {
  associate: [],
  isLoading: false,
  data: [],
  selected: {},
  error: {},
  isError: false,
  errorMsg: "",
  activeTab: "Pendente",
  pageResponse: {
    lastPage: true,
    firstPage: true,
    totalPages: 1,
    totalElements: 0,
    actualPage: 1,
    numberOfElements: 0,
    offset: 0
  },
  pageRequest: {
    page: 0,
    lines: 10,
    orderBy: "created",
    direction: "DESC"
  },
  newKb: false,
  formEnable: false
};

export const getKnowledgesRequest = (state = INITIAL_STATE, action) => {
  let filter = { ...state.pageRequest };
  action.pageRequest &&
    action.pageRequest.forEach(e => (filter = { ...filter, ...e }));
  return {
    ...state,
    isLoading: true,
    error: "",
    errorMsg: "",
    isError: false,
    pageRequest: filter
  };
};

export const getKnowledgesSuccess = (state = INITIAL_STATE, action) => {
  return {
    ...state,
    isLoading: false,
    associate: action.isAssociate ? action.knowledges.content : state.associate,
    data: !action.isAssociate ? action.knowledges.content : state.data,
    pageResponse: {
      totalPages: action.knowledges.totalPages,
      totalElements: action.knowledges.totalElements,
      actualPage: action.knowledges.number,
      lastPage: action.knowledges.last,
      firstPage: action.knowledges.first,
      offset: action.knowledges.pageable.offset
    },
    pageRequest: {
      ...state.pageRequest,
      page: action.knowledges.number
    }
  };
};

export const knowledgesFailure = (state = INITIAL_STATE, action) => {
  return {
    ...state,
    isError: true,
    isLoading: false,
    error: action.error,
    errorMsg: action.errorMsg
  };
};

export const getKnowledgeByIdRequest = (state = INITIAL_STATE, action) => {
  return {
    ...state,
    isLoading: true,
    error: "",
    isError: false
  };
};

export const getKnowledgeByIdSuccess = (state = INITIAL_STATE, action) => {
  return {
    ...state,
    isLoading: false,
    selected: action.knowledge
  };
};

export const saveKnowledgeRequest = (state = INITIAL_STATE, action) => {
  return {
    ...state,
    isLoading: true,
    error: "",
    selected: action.knowledge,
    newKb: false,
    formEnable: false,
    isError: false
  };
};

export const saveKnowledgeSuccess = (state = INITIAL_STATE, action) => {
  return {
    ...state,
    isLoading: false, 
    selected: action.knowledge
  };
};

export const setEnableForm = (state = INITIAL_STATE, action) => {
  return {
    ...state,
    formEnable: !state.formEnable
  };
};

export const resetKnowledgeForm = (state = INITIAL_STATE, action) => {
  return {
    ...INITIAL_STATE,
    associate: state.associate
  };
};

export const HANDLERS = {
  [Types.GET_KNOWLEDGES_REQUEST]: getKnowledgesRequest,
  [Types.GET_KNOWLEDGES_SUCCESS]: getKnowledgesSuccess,

  [Types.GET_KNOWLEDGE_BY_ID_REQUEST]: getKnowledgeByIdRequest,
  [Types.GET_KNOWLEDGE_BY_ID_SUCCESS]: getKnowledgeByIdSuccess,

  [Types.SAVE_KNOWLEDGE_REQUEST]: saveKnowledgeRequest,
  [Types.SAVE_KNOWLEDGE_SUCCESS]: saveKnowledgeSuccess,
  [Types.SET_KNOWLEDGE_FORM]: setEnableForm,
  [Types.RESET_KNOWLEDGE_FORM]: resetKnowledgeForm,

  [Types.KNOWLEDGE_FAILURE]: knowledgesFailure
};

export default createReducer(INITIAL_STATE, HANDLERS);
