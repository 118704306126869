import styled from 'styled-components';

export const DrawerContent = styled.div`
  background: white;
  border-radius: 0 10px 10px 0;

  padding: 20px;
  width: 250px;
  height: 100%;
`;
