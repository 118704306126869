import { createReducer, createActions } from 'reduxsauce';

import { NotificationInfo } from '../../models/NotificationInfo';
import {
  NotificationsTypes,
  NotificationsActions,
  NotificationsState,
  NotificationFailureAction,
  GetNotificationsSuccessAction,
  GetNotificationsRequestAction,
  GetNotificationsByAssetIdRequestAction,
  GetNotificationSuccessAction,
  ChangeNotificationTabAction,
  SearchNotificationAction,
  SetFilterDetailAction,
  SetSearchAbilityAction,
  SetUuidAction,
  SetPageRequestAction,
  SetCurrentNotificationAction,
  SetSelectedNodeUuidAction,
} from './types';

const { Types, Creators } = createActions<
  NotificationsTypes,
  NotificationsActions
>({
  getNotificationsRequest: ['pageRequest'],
  getNotificationsSuccess: ['notifications'],
  getNotificationsByAssetIdRequest: ['pageRequest'],
  getNotificationRequest: ['id'],
  getNotificationSuccess: ['notification'],
  saveNotificationRequest: ['notification'],
  saveNotificationSuccess: null,
  notificationFailure: ['error', 'errorMsg'],
  addNotificationInOs: ['notification'],
  changeNotificationTab: ['id'],
  searchNotification: ['text'],
  setCurrentNotification: ['id'],
  setFilterDetail: ['filterDetail'],
  setSearchAbility: ['active'],
  setUuid: ['uuid'],
  setPageRequest: ['page'],
  resetNotifications: null,
  setSelectedNodeUuid: ['selectedNodeUuid'],
});

const INITIAL_STATE: NotificationsState = {
  isLoading: false,
  data: [],
  selected: {} as NotificationInfo,
  error: null,
  isError: false,
  errorMsg: '',
  activeTab: 'Pendente',
  searchText: '',
  searchAbility: true,
  currentNotification: null,
  filterDetail: false,
  uuid: '',
  pageResponse: {
    lastPage: true,
    firstPage: true,
    totalPages: 1,
    totalElements: 0,
    actualPage: 1,
    numberOfElements: 0,
    offset: 0,
  },
  pageRequest: {
    page: 0,
    lines: 10,
    orderBy: ['criticality', 'lastOccurrence'],
    direction: 'DESC',
  },
  selectedNodeUuid: ''
};

const getNotificationsRequest = (
  state = INITIAL_STATE,
  action: GetNotificationsRequestAction
) => {
  return {
    ...state,
    isLoading: true,
    data: [],
    error: '',
    isError: false,
    pageRequest: {
      ...state.pageRequest,
      ...action.pageRequest,
    },
  };
};

const getNotificationsSuccess = (
  state = INITIAL_STATE,
  action: GetNotificationsSuccessAction
) => {
  return {
    ...state,
    isLoading: false,
    data: action.notifications.content,
    pageResponse: {
      totalPages: action.notifications.totalPages,
      totalElements: action.notifications.totalElements,
      actualPage: action.notifications.number,
      lastPage: action.notifications.last,
      firstPage: action.notifications.first,
      offset: action.notifications.pageable.offset,
    },
    pageRequest: {
      ...state.pageRequest,
      page: action.notifications.number,
    },
  };
};

const getNotificationsByAssetIdRequest = (
  state = INITIAL_STATE,
  action: GetNotificationsByAssetIdRequestAction
) => {
  return {
    ...state,
    isLoading: true,
    data: [],
    error: '',
    isError: false,
    pageRequest: {
      ...state.pageRequest,
      ...action.pageRequest,
    },
  };
};

const getNotificationRequest = (state = INITIAL_STATE) => {
  return {
    ...state,
    isLoading: true,
    error: {},
    isError: false,
  };
};

const getNotificationSuccess = (
  state = INITIAL_STATE,
  action: GetNotificationSuccessAction
) => {
  return {
    ...state,
    isLoading: false,
    selected: action.notification,
  };
};

const saveNotificationRequest = (state = INITIAL_STATE) => {
  return {
    ...state,
    isLoading: true,
    isError: false,
    error: {},
  };
};

const saveNotificationSuccess = (state = INITIAL_STATE) => {
  return {
    ...state,
    isLoading: false,
    isError: false,
    error: {},
  };
};

const notificationFailure = (
  state = INITIAL_STATE,
  action: NotificationFailureAction
) => {
  return {
    ...state,
    isError: true,
    isLoading: false,
    error: action.error,
    errorMsg: action.errorMsg,
  };
};

export const changeNotificationTab = (
  state = INITIAL_STATE,
  action: ChangeNotificationTabAction
) => {
  return {
    ...state,
    activeTab: action.id,
  };
};

const searchNotification = (
  state = INITIAL_STATE,
  action: SearchNotificationAction
) => {
  return {
    ...state,
    searchText: action.text,
  };
};

const setCurrentNotificiation = (
  state = INITIAL_STATE,
  action: SetCurrentNotificationAction
) => {
  return {
    ...state,
    currentNotification: action.id,
  };
};

const setFilterDetail = (
  state = INITIAL_STATE,
  action: SetFilterDetailAction
) => {
  return {
    ...state,
    filterDetail: action.filterDetail,
  };
};

const setSearchAbility = (
  state = INITIAL_STATE,
  action: SetSearchAbilityAction
) => {
  return {
    ...state,
    searchAbility: action.active,
  };
};

const setUuid = (state = INITIAL_STATE, action: SetUuidAction) => {
  return {
    ...state,
    uuid: action.uuid,
  };
};

const setPageRequest = (
  state = INITIAL_STATE,
  action: SetPageRequestAction
) => {
  return {
    ...state,
    pageRequest: {
      ...state.pageRequest,
      page: action.page,
    },
  };
};

const resetNotifications = () => INITIAL_STATE;

const setSelectedNodeUuid = (
  state = INITIAL_STATE,
  action: SetSelectedNodeUuidAction
) => {
  return {
    ...state,
    selectedNodeUuid: action.selectedNodeUuid,
  };
};


export const HANDLERS = {
  [Types.GET_NOTIFICATIONS_REQUEST]: getNotificationsRequest,
  [Types.GET_NOTIFICATIONS_SUCCESS]: getNotificationsSuccess,
  [Types.GET_NOTIFICATIONS_BY_ASSET_ID_REQUEST]: getNotificationsByAssetIdRequest,

  [Types.GET_NOTIFICATION_REQUEST]: getNotificationRequest,
  [Types.GET_NOTIFICATION_SUCCESS]: getNotificationSuccess,

  [Types.SAVE_NOTIFICATION_REQUEST]: saveNotificationRequest,
  [Types.SAVE_NOTIFICATION_SUCCESS]: saveNotificationSuccess,
  [Types.NOTIFICATION_FAILURE]: notificationFailure,

  [Types.CHANGE_NOTIFICATION_TAB]: changeNotificationTab,

  [Types.SEARCH_NOTIFICATION]: searchNotification,

  [Types.SET_CURRENT_NOTIFICATION]: setCurrentNotificiation,
  [Types.SET_FILTER_DETAIL]: setFilterDetail,
  [Types.SET_SEARCH_ABILITY]: setSearchAbility,
  [Types.SET_UUID]: setUuid,
  [Types.SET_SELECTED_NODE_UUID]: setSelectedNodeUuid,
  [Types.SET_PAGE_REQUEST]: setPageRequest,
  [Types.RESET_NOTIFICATIONS]: resetNotifications,
};

const Reducer = createReducer(INITIAL_STATE, HANDLERS);

export {
  Creators as NotificationsCreators,
  Types as NotificationsTypes,
  Reducer as NotificationsReducer,
};
