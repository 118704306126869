import * as React from 'react';

import { IconButton, Typography } from '@material-ui/core';

import { combine } from '../utils';
import { DayContainer } from './styles';

interface DayProps {
  filled?: boolean;
  outlined?: boolean;
  highlighted?: boolean;
  disabled?: boolean;
  startOfRange?: boolean;
  endOfRange?: boolean;
  onClick?: () => void;
  onHover?: () => void;
  value: number | string;
}

const Day: React.FunctionComponent<DayProps> = (props) => {
  return (
    <DayContainer
      className={combine(
        'buttonContainer',
        props.startOfRange && 'leftBorderRadius',
        props.endOfRange && 'rightBorderRadius',
        !props.disabled && props.highlighted && 'highlighted'
      )}
    >
      <IconButton
        className={combine(
          'button',
          !props.disabled && props.outlined && 'outlined',
          !props.disabled && props.filled && 'filled'
        )}
        disabled={props.disabled}
        onClick={props.onClick}
        onMouseOver={props.onHover}
      >
        <Typography
          color={!props.disabled ? 'inherit' : 'textSecondary'}
          className={combine(
            'buttonText',
            !props.disabled && props.filled && 'contrast'
          )}
          variant="body2"
        >
          {props.value}
        </Typography>
      </IconButton>
    </DayContainer>
  );
};

export default Day;
