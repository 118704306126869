import React from 'react';
import { Redirect } from 'react-router-dom';

import { CircularProgress } from '@material-ui/core';

import { Container } from './styles';
import { useAuthStore } from '../../zustand/AuthStore';

export const Splash: React.FC = () => {
  const { isAuth, isAuthing } = useAuthStore();

  if (!isAuthing) return <Redirect to={isAuth ? '/admin' : '/auth'} />;
  return (
    <Container data-testid="progressIndicatorContainer">
      <CircularProgress size={50} />
    </Container>
  );
};
