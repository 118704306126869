import React, { useState } from 'react';
import Media from 'react-media';
import { useDispatch, useSelector } from 'react-redux';

import {
  MenuItem,
  Tooltip,
  InputAdornment,
  IconButton,
  Backdrop,
  Fade,
} from '@material-ui/core';
import { Check, DateRange as DateRangeIcon } from '@material-ui/icons';
import {
  startOfQuarter,
  endOfQuarter,
  startOfWeek,
  endOfWeek,
  startOfMonth,
  endOfMonth,
  getQuarter,
  setMonth,
} from 'date-fns';

import { Creators } from '../../store/actionCreators';
import { AppState } from '../../store/reducers';
import { PeriodResolution, ReportNames } from '../../store/reports/types';
import { CustomSelect, CustomInput } from '../CustomSelect';
import { LargePeriodPicker } from '../Pickers/LargePeriodPicker';
import { MonthPicker } from '../Pickers/MonthPicker';
import { WeekPicker } from '../Pickers/WeekPicker';

import { supportedDevices } from '../../styles/supportedDevices';
import { Container, TooltipSpan, Button, CustomModal } from './styles';

export type PickerType = 'month' | 'week' | 'trimester' | 'semester';

export interface PeriodDateFilterItem {
  resolution: [PeriodResolution, string];
  picker: PickerType;
  label: string;
}

export interface PeriodDateFilterProps {
  /**
   * List with selectable periods
   */
  fixedFilters: PeriodDateFilterItem[];

  /**
   * Report to be fetched
   */
  reportName: ReportNames;
}

/**
 * Week, Month, Trimester and Semester picker
 */
export const PeriodDateFilter: React.FC<PeriodDateFilterProps> = ({
  fixedFilters,
  reportName = 'healthAndIndicatorsLogicalLayerAnalysis',
}) => {
  const { timePeriod, periodResolution } = useSelector(
    (state: AppState) => state.reports
  );
  const dispatch = useDispatch();

  const [selectedPicker, setSelectedPicker] = useState<PickerType>(
    fixedFilters.find((filter) => filter.resolution[0] === periodResolution)
      ?.picker || 'week'
  );
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [selectedResolution, setSelectedResolution] = useState<
    PeriodResolution
  >(
    fixedFilters.find((filter) => filter.picker === selectedPicker)!
      .resolution[0]
  );

  function handlePickerBtnClick() {
    setIsModalOpen(true);
  }

  function handleModalOpen(open = false) {
    setIsModalOpen(open);
  }

  function handleResolutionChange(
    event: React.ChangeEvent<{
      value: unknown;
    }>
  ) {
    setSelectedResolution(event.target.value as PeriodResolution);
  }

  function handlePickerChange(
    event: React.ChangeEvent<{
      value: unknown;
    }>
  ) {
    setSelectedPicker(event.target.value as PickerType);
  }

  function onApplyPress(start: Date, end: Date) {
    handleModalOpen();

    dispatch(Creators.setReportsPeriodResolution(selectedResolution));
    dispatch(Creators.setPickerType(selectedPicker));

    dispatch(
      Creators.getReportsRequest(
        {
          startTime: start.getTime(),
          endTime: end.getTime(),
        },
        reportName
      )
    );
  }

  function string2Date(date: typeof timePeriod.startTime) {
    if (typeof date !== 'number') return new Date();
    return new Date(date);
  }

  function onCheckPress() {
    const start = string2Date(timePeriod.startTime);
    const end = string2Date(timePeriod.endTime);

    if (selectedPicker === 'semester') {
      if (getQuarter(start) <= 2)
        onApplyPress(
          startOfQuarter(setMonth(start, 1)),
          endOfQuarter(setMonth(start, 5))
        );
      else
        onApplyPress(
          startOfQuarter(setMonth(start, 7)),
          endOfQuarter(setMonth(start, 11))
        );
    } else if (selectedPicker === 'trimester')
      onApplyPress(startOfQuarter(start), endOfQuarter(end));
    else if (selectedPicker === 'month')
      onApplyPress(startOfMonth(start), endOfMonth(end));
    else onApplyPress(startOfWeek(start), endOfWeek(end));
  }

  function buildFilters(filters: PeriodDateFilterItem[]) {
    let newList: PeriodDateFilterItem[] = [];
    filters.forEach((filter) => {
      if (!newList.find((item) => item.label === filter.label))
        newList = [...newList, filter];
    });
    return newList;
  }

  return (
    <Media
      queries={{
        tablet: `${supportedDevices.tablet}`,
      }}
    >
      {(matches) => {
        return (
          <>
            <Container data-testid="periodDateFilterContainer">
              {matches.tablet && (
                <>
                  <div className="select-container">
                    <Tooltip
                      enterDelay={1000}
                      placement="left"
                      title={
                        <TooltipSpan>
                          Seletor do tipo de período de tempo
                        </TooltipSpan>
                      }
                    >
                      <CustomSelect
                        data-testid="pickerSelect"
                        value={selectedPicker}
                        onChange={handlePickerChange}
                        fullWidth
                        input={<CustomInput />}
                      >
                        {buildFilters(fixedFilters).map(({ label, picker }) => (
                          <MenuItem key={picker} value={picker}>
                            {label}
                          </MenuItem>
                        ))}
                      </CustomSelect>
                    </Tooltip>
                  </div>
                  <div className="resolution-container">
                    <Tooltip
                      enterDelay={1000}
                      placement="left"
                      title={
                        <TooltipSpan>
                          Seletor da resolução do período selecionado
                        </TooltipSpan>
                      }
                    >
                      <CustomSelect
                        data-testid="resolutionSelect"
                        value={selectedResolution}
                        onChange={handleResolutionChange}
                        fullWidth
                        input={<CustomInput />}
                      >
                        {[
                          ...new Set(
                            fixedFilters.map(({ resolution }) => resolution[0])
                          ),
                        ].map((res) => {
                          const label = fixedFilters.find(
                            (filter) => filter.resolution[0] === res
                          )!.resolution[1];
                          return (
                            <MenuItem key={res} value={res}>
                              {`${label.slice(0, 1).toUpperCase()}${label.slice(
                                1
                              )}`}
                            </MenuItem>
                          );
                        })}
                      </CustomSelect>
                    </Tooltip>
                  </div>
                  <div className="button-container">
                    <Tooltip
                      title={<TooltipSpan>Seletor de período</TooltipSpan>}
                    >
                      <Button
                        isSelected
                        onClick={handlePickerBtnClick}
                        data-testid="pickerButton"
                      >
                        <DateRangeIcon fontSize="large" />
                      </Button>
                    </Tooltip>
                    <Tooltip
                      title={<TooltipSpan>Aplicar Alterações</TooltipSpan>}
                    >
                      <Button
                        isSelected
                        onClick={onCheckPress}
                        data-testid="applyButton"
                      >
                        <Check fontSize="large" />
                      </Button>
                    </Tooltip>
                  </div>
                </>
              )}
              {!matches.tablet && (
                <>
                  <CustomSelect
                    data-testid="pickerSelect"
                    value={selectedPicker}
                    onChange={handlePickerChange}
                    fullWidth
                    input={
                      <CustomInput
                        fullWidth
                        className="select-input"
                        startAdornment={
                          <InputAdornment position="start">
                            <IconButton onClick={handlePickerBtnClick}>
                              <DateRangeIcon />
                            </IconButton>
                          </InputAdornment>
                        }
                      />
                    }
                  >
                    {fixedFilters.map(({ picker, label }) => (
                      <MenuItem key={picker} value={picker}>
                        {label}
                      </MenuItem>
                    ))}
                  </CustomSelect>
                  <CustomSelect
                    data-testid="resolutionSelect"
                    value={selectedResolution}
                    onChange={handleResolutionChange}
                    fullWidth
                    input={<CustomInput />}
                  >
                    {[
                      ...new Set(
                        fixedFilters.map(({ resolution }) => resolution[0])
                      ),
                    ].map((res) => {
                      const label = fixedFilters.find(
                        (filter) => filter.resolution[0] === res
                      )!.resolution[1];
                      return (
                        <MenuItem key={res} value={res}>
                          {`${label.slice(0, 1).toUpperCase()}${label.slice(
                            1
                          )}`}
                        </MenuItem>
                      );
                    })}
                  </CustomSelect>
                </>
              )}
            </Container>
            <CustomModal
              open={isModalOpen}
              onClose={() => handleModalOpen()}
              closeAfterTransition
              BackdropComponent={Backdrop}
              BackdropProps={{
                timeout: 500,
              }}
              data-testid="pickerModal"
            >
              <Fade in={isModalOpen}>
                <div className="outline-0">
                  {selectedPicker === 'week' && (
                    <WeekPicker
                      onApplyPress={onApplyPress}
                      onCancelPress={() => handleModalOpen()}
                      initialDate={string2Date(timePeriod.startTime)}
                    />
                  )}
                  {selectedPicker === 'month' && (
                    <MonthPicker
                      onApplyPress={onApplyPress}
                      onCancelPress={() => handleModalOpen()}
                      initialDate={string2Date(timePeriod.startTime)}
                    />
                  )}
                  {(selectedPicker === 'trimester' ||
                    selectedPicker === 'semester') && (
                    <LargePeriodPicker
                      onApplyPress={onApplyPress}
                      onCancelPress={() => handleModalOpen()}
                      period={selectedPicker}
                      initialDate={string2Date(timePeriod.startTime)}
                    />
                  )}
                </div>
              </Fade>
            </CustomModal>
          </>
        );
      }}
    </Media>
  );
};
