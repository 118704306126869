import qs from 'qs';
import { put, select } from 'redux-saga/effects';

import config from '../../config';
import { Creators } from '../actionCreators';

export function* getTags() {
  try {
    const pageRequest = yield select((state) => state.tags.pageRequest);
    const pageRequestQs = `?${qs.stringify(pageRequest)}`;
    const tags = yield config.api.getTags(pageRequestQs);
    yield put(Creators.getTagsSuccess(tags.data));
  } catch (error) {
    yield put(
      Creators.getTagsFailure(
        'Ocorreu um erro ao buscar os registros deste ativo'
      )
    );
  }
}

export function* getTagsFFT() {
  try {
    const pageRequest = yield select((state) => state.tags.pageRequest);
    const pageRequestQs = `?${qs.stringify(pageRequest)}`;
    const tags = yield config.api.getTagsFFT(pageRequestQs);
    yield put(Creators.getTagsSuccess(tags.data));
  } catch (error) {
    yield put(
      Creators.getTagsFailure(
        'Ocorreu um erro ao buscar os registros deste ativo'
      )
    );
  }
}
