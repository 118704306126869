import { TextField } from '@material-ui/core';
import styled from 'styled-components';

export const CustomTextField = styled(TextField)`
  && {
    box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.04);
    border-radius: 4px;

    height: 48px;
    margin-bottom: 24px;
    width: 100%;

    .search-input {
      background: white;

      .search-icon-button {
        margin-right: -12px;
        color: rgba(0, 0, 0, 0.87);
      }

      svg {
        font-size: 1.8rem;
      }
    }

    input {
      padding: 14px;
    }
  }
`;
