import { Tab, Tabs } from '@material-ui/core';
import styled from 'styled-components';

import { supportedDevices } from '../../styles/supportedDevices';

export const Container = styled.div`
  box-sizing: border-box;
  color: black;
  overflow-y: auto;
  overflow-x: hidden;

  padding: 8px 16px;
  width: 100%;
  height: 100%;

  .card {
    background: white;
    border-radius: 4px;
    box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.04);

    min-height: 100%;
    padding: 16px;

    .title-container {
      h3 {
        font-size: 20px;
        margin: 10px 0;
      }
    }

    .filters-container {
      margin: 10px 0;

      .search-container {
        width: 100%;
      }
    }

    .loader-container {
      display: grid;
      place-items: center;

      height: 250px;
    }
  }

  @media ${supportedDevices.tablet} {
    padding: 20px 28px;

    .card {
      min-height: 100%;
      padding: 20px 24px;

      .title-container {
        h3 {
          font-size: 32px;
          margin: 10px 0;
        }
      }

      .filters-container {
        margin: 10px 0;

        .search-container {
          width: 400px;
        }
      }

      .loader-container {
        height: 500px;
      }
    }
  }

  .attr-container {
    display: inline-flex;
    //background: #009ee2;
    color: black;
    width: 100%;
    height: 70px;
    align-items: center;
    justify-content: center;
    flex-direction: column;
  }
`;

export const CustomTabsContainer = styled(Tabs)`
  && {
    margin: 20px 0;
    min-height: 30px;

    & .MuiTabs-indicator {
      background: #009ee2;
    }

    @media ${supportedDevices.tablet} {
      margin: 30px 0;
      min-height: 48px;
    }
  }
`;

export const CustomTab = styled(Tab)`
  && {
    border: 1px solid #efefef;
    font-size: 10px;
    font-weight: 600;
    color: black;
    background: #f7f7f7;

    &.Mui-selected {
      background: rgba(0, 158, 226, 0.05);
      color: #009ee2;
    }

    @media ${supportedDevices.tablet} {
      font-size: 12px;
    }
  }
`;
