import React, { ReactText, useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useRouteMatch } from 'react-router-dom';

import {
  CircularProgress,
  Input,
  MenuItem,
  Slider,
  Tooltip,
  TextField,
} from '@material-ui/core';
import { Help } from '@material-ui/icons';

import errorIcon from '../../../../../../../assets/imgs/error-icon.png';
import { CustomButton } from '../../../../../../../components/CustomButton';
import { CustomDialog } from '../../../../../../../components/CustomDialog';
import {
  CustomInput,
  CustomSelect,
} from '../../../../../../../components/CustomSelect';
import { AssetCalibration } from '../../../../../../../models/AssetInfo';
import { Creators } from '../../../../../../../store/actionCreators';
import { AppState } from '../../../../../../../store/reducers';

import { Container, SliderValueLabel, TooltipSpan } from './styles';

import moment from 'moment';
import {
  hasRoleAccess,
  useAuthStore,
} from '../../../../../../../zustand/AuthStore';
import { ROLES } from '../../../../../../../utils/authTypes';

interface FieldProps {
  id: string;
  label: string;
  description?: string;
  form:
    | 'select'
    | 'slider'
    | 'slider2'
    | 'select-date'
    | 'date'
    | 'plain'
    | 'plain-view';
  options?: string[];
  unit?: string;
  min?: number;
  max?: number;
  step?: number;
}

const formFields: FieldProps[] = [
  {
    id: 'limit_min_FE',
    label: 'Limite Mínimo do Fundo de Escala',
    description:
      'Valor mínimo que o instrumento é capaz de medir sem que seja danificado.',
    form: 'plain',
    unit: 'U.E.',
    min: -10000,
    max: 10000,
  },
  {
    id: 'limit_max_FE',
    label: 'Limite Máximo do Fundo de Escala',
    description:
      'Valor máximo que o instrumento é capaz de medir sem que seja danificado.',
    form: 'plain',
    unit: 'U.E.',
    min: -10000,
    max: 10000,
  },
  // {
  //   id: 'uncertainty_estimated',
  //   label: 'Incerteza Estimada',
  //   description:
  //     'Parâmetro que quantifica a confiabilidade de uma medição.. É calculada para alguns instrumentos que possuem redundância e fazem parte de uma rotina de reconciliação em estado estacionário.',
  //   form: 'plain-view',
  //   unit: '%',
  //   step: 0.1,
  //   min: -10,
  //   max: 10,
  // },
  {
    id: 'uncertainty_declared',
    label: 'Incerteza Ref.',
    description:
      'Parâmetro que quantifica a confiabilidade de uma medição.. É declarada pelo instrumentista após o procedimento de aferição.',
    form: 'plain',
    unit: '%',
    step: 0.1,
    min: 0,
    max: 100,
  },
  {
    id: 'trend_error_estimated',
    label: 'Erro de Tendência Estimado',
    description:
      'Parâmetro que indica o erro médio entre uma medição realizada e o valor de referência, ou seja, é a parcela previsível do erro.. É calculado para alguns instrumentos que possuem redundância e fazem parte de uma rotina de reconciliação em estado estacionário.',
    form: 'plain-view',
    unit: '%',
    step: 0.1,
    min: -100,
    max: 100,
  },

  {
    id: 'tolerance_samarco',
    label: 'Tolerância para Incerteza Ref. Samarco',
    description:
      'Valor máximo permissível para a incerteza do instrumento, de acordo com as normas internas da empresa.',
    form: 'plain',
    unit: '%',
    step: 0.1,
    min: 0,
    max: 100,
  },
  {
    id: 'trend_error_declared',
    label: 'Erro de Tendência Declarado',
    description:
      'Parâmetro que indica o erro médio entre uma medição realizada e o seu valor de referência, ou seja, é a parcela previsível do erro.. É declarado pelo instrumentista após o procedimento de aferição.',
    form: 'slider',
    unit: '%',
    step: 0.1,
    min: -100,
    max: 100,
  },
  {
    id: 'tolerance_amira',
    label: 'Tolerância para Incerteza Ref. Amira P754',
    description:
      'Valor máximo permissível para a incerteza do instrumento, de acordo com a norma AMIRA P754.',
    form: 'plain',
    unit: '%',
    step: 0.1,
    min: 0,
    max: 100,
  },
  {
    id: 'alpha_systematic_error',
    label: 'Alpha do Erro Sistemático',
    description:
      'Parâmetro de memória para o erro de tendência estimado.. Ele indica a importância dada às estimativas históricas diante da estimativa mais recente para o erro de tendência.',
    form: 'plain',
    unit: '',
    step: 0.1,
    min: -10,
    max: 10,
  },
  {
    id: 'due_date',
    label: 'Data de Validade da Calibração',
    form: 'select-date',
    options: [
      'Data Atual',
      'Daqui a 15 dias',
      'Daqui a 1 mês',
      'Daqui a 2 mês',
      'Daqui a 3 meses',
      'Daqui a 6 meses',
      'Daqui a 1 ano',
    ],
  },
  {
    id: 'last_update',
    label: 'Data da Última Calibração',
    form: 'date',
  },
];

export const CalibrationTab: React.FC = () => {
  const { data, isCalibrationLoading, calibrationError } = useSelector(
    (state: AppState) => state.assetData
  );
  const dispatch = useDispatch();

  // const match = useRouteMatch<{ ativoPath: string }>();

  const { keycloak } = useAuthStore();
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isEditMode, setIsEditMode] = useState(false);
  const [formValues, setFormValues] = useState<{
    [key: string]: string | number;
  }>({});

  const mapCalibrationToForm = useCallback((calibration: AssetCalibration) => {
    let newFormValues: { [key: string]: ReactText } = {};
    Object.entries(calibration).forEach(([key, value]) => {
      let newValue;
      const field = formFields.find((field) => field.id === key);

      if (field?.form === 'slider' || field?.unit === '%') {
        newValue = Math.fround(Number(value * 100)).toFixed(2);
      } else {
        newValue = value;
      }

      newFormValues = { ...newFormValues, [key]: newValue };
    });
    return newFormValues;
  }, []);

  // Requests calibration
  useEffect(() => {
    dispatch(Creators.getAssetCalibrationRequest(data.identifier + '.SVALUE'));
  }, [dispatch, data.identifier]);

  // Set calibration locally
  useEffect(() => {
    if (!Object.keys(formValues).length && data.calibration)
      setFormValues(mapCalibrationToForm(data.calibration));
  }, [data.calibration, formValues, mapCalibrationToForm]);

  function handleFormChange(value: number | string, name: string) {
    setFormValues({
      ...formValues,
      [name]: value,
    });
  }

  function toggleModal(open = false) {
    setIsModalOpen(open);
  }

  function handleEditMode() {
    setIsEditMode(!isEditMode);
  }

  function onSaveClick() {
    toggleModal(true);
  }

  function handleCancelPress() {
    setFormValues(mapCalibrationToForm(data.calibration!));
    handleEditMode();
  }

  function onConfirmSave() {
    let newFormValues: typeof formValues = {};

    formFields.forEach((field) => {
      newFormValues = {
        ...newFormValues,
        [field.id]:
          field.form === 'slider' || field?.unit === '%'
            ? Number(formValues[field.id]) / 100
            : formValues[field.id],
      };
    });

    dispatch(
      Creators.patchAssetCalibrationRequest(
        data.identifier + '.SVALUE',
        newFormValues as AssetCalibration
      )
    );
    toggleModal();
    handleEditMode();
  }

  function onSliderBlur(field: FieldProps) {
    const min = field.min ? field.min : 0;
    const max = field.max ? field.max : 100;
    if (Number(formValues[field.id]) < min) handleFormChange(min, field.id);
    else if (Number(formValues[field.id]) > max)
      handleFormChange(max, field.id);
  }

  function handleSelectDueDate(selected: number, name: string) {
    let dueDate = moment().locale('pt-br');

    handleFormChange(dueDate.format('yyyy-MM-DDTHH:mm:ssZ'), 'last_update');

    switch (selected) {
      case 0:
        //Data Atual
        break;
      case 1:
        dueDate.add(15, 'days');
        break;
      case 2:
        dueDate.add(1, 'month');
        break;
      case 3:
        dueDate.add(2, 'month');
        break;
      case 4:
        dueDate.add(3, 'month');
        break;
      case 5:
        dueDate.add(6, 'month');
        break;
      case 6:
        dueDate.add(1, 'year');
        break;
      default:
        break;
    }

    handleFormChange(dueDate.format('yyyy-MM-DDTHH:mm:ssZ'), name);
  }

  function handleFormatDate(vdata: string) {
    vdata = vdata.replace(/T|\:\d\dZ/g, ' ');
    var dateObject = new Date(vdata);
    var date = moment(dateObject).format('DD-MM-yyyy HH:mm:ss');

    return date;
  }

  // Builds correct field based on the form attr
  function buildField(field: FieldProps) {
    return (
      <div className="field-container" key={field.id}>
        <div className="label-container">
          <label htmlFor={`${field.id}Input`}>{field.label}</label>
          {field.description && (
            <Tooltip
              title={
                <TooltipSpan>
                  {field.description.split('. ').map((text) => (
                    <span key={text}>{text}</span>
                  ))}
                </TooltipSpan>
              }
            >
              <Help className="help-icon" />
            </Tooltip>
          )}
        </div>

        {(() => {
          switch (field.form) {
            case 'slider':
            case 'slider2':
              return (
                <div className="slider-container">
                  <Slider
                    data-testid={`${field.id}Slider`}
                    defaultValue={30}
                    onChange={(_, value) =>
                      handleFormChange(Number(value), field.id)
                    }
                    value={Number(formValues[field.id]) || 0}
                    valueLabelDisplay="auto"
                    valueLabelFormat={(value) => (
                      <SliderValueLabel>
                        {field.form == 'slider' ? `${value}%` : value}
                      </SliderValueLabel>
                    )}
                    step={field.step ? field.step : 1}
                    min={field.min ? field.min : 0}
                    max={field.max ? field.max : 100}
                    disabled={!isEditMode}
                  />
                  <Input
                    value={Number(formValues[field.id]) || 0}
                    margin="dense"
                    onChange={(event) =>
                      handleFormChange(Number(event.target.value), field.id)
                    }
                    onBlur={() => onSliderBlur(field)}
                    disabled={!isEditMode}
                    inputProps={{
                      step: field.step ? field.step : 1,
                      min: field.min ? field.min : 0,
                      max: field.max ? field.max : 100,
                      type: 'number',
                      'aria-labelledby': 'input-slider',
                    }}
                    endAdornment={<>{field.unit}</>}
                  />
                </div>
              );
            case 'select-date':
              return (
                <div className="select-container">
                  {isEditMode ? (
                    <CustomSelect
                      inputProps={{ id: `${field.id}Input` }}
                      data-testid={`${field.id}Select`}
                      defaultValue={0}
                      onChange={(event) =>
                        handleSelectDueDate(
                          event?.target.value as number,
                          field.id
                        )
                      }
                      fullWidth
                      input={<CustomInput />}
                      disabled={!isEditMode}
                    >
                      {field.options!.map((label, index) => (
                        <MenuItem key={label} value={index}>
                          {label}
                        </MenuItem>
                      ))}
                    </CustomSelect>
                  ) : (
                    <div>
                      <Input
                        value={handleFormatDate(String(formValues[field.id]))}
                        disabled={true}
                      ></Input>
                    </div>
                  )}
                </div>
              );
            case 'plain':
              return (
                <div className="plain-container">
                  <Input
                    value={Number(formValues[field.id]) || 0}
                    margin="dense"
                    onChange={(event) =>
                      handleFormChange(Number(event.target.value), field.id)
                    }
                    onBlur={() => onSliderBlur(field)}
                    disabled={!isEditMode}
                    inputProps={{
                      step: field.step ? field.step : 1,
                      min: field.min ? field.min : 0,
                      max: field.max ? field.max : 100,
                      type: 'number',
                      'aria-labelledby': 'input-slider',
                    }}
                    endAdornment={<>{field.unit}</>}
                  />
                </div>
              );
            case 'plain-view':
              return (
                <div className="plain-container">
                  <Input
                    value={Number(formValues[field.id]) || 0}
                    margin="dense"
                    onBlur={() => onSliderBlur(field)}
                    disabled={true}
                    inputProps={{
                      step: field.step ? field.step : 1,
                      min: field.min ? field.min : 0,
                      max: field.max ? field.max : 100,
                      type: 'number',
                      'aria-labelledby': 'input-slider',
                    }}
                    endAdornment={<>{field.unit}</>}
                  />
                </div>
              );
            case 'date':
              return (
                <div>
                  <Input
                    value={handleFormatDate(String(formValues[field.id]))}
                    disabled={true}
                  ></Input>
                </div>
              );
            default:
              return <div>Undefined Input</div>;
          }
        })()}
      </div>
    );
  }

  function buildLoader() {
    return calibrationError ? (
      <>
        <div className="error-container" data-testid="errorContainer">
          <img src={errorIcon} alt="Ícone de erro" />
          <h3>Ocorreu um erro! Recarregue a página para tentar novamente.</h3>
        </div>
      </>
    ) : (
      <CircularProgress size={50} data-testid="loaderIndicator" />
    );
  }

  return (
    <>
      <Container
        data-testid="calibrationContainer"
        className={(isCalibrationLoading || calibrationError) && 'loader'}
      >
        {isCalibrationLoading || calibrationError ? (
          buildLoader()
        ) : data?.calibration === undefined ? (
          <>
            <div className="error-container" data-testid="errorContainer">
              <img src={errorIcon} alt="Ícone de erro" />
              <h3>
                Ocorreu um erro! Não foi encontrada a tag SVALUE para esse ativo
                em nosso banco de dados.
              </h3>
            </div>
          </>
        ) : (
          <>
            <div className="header">
              {hasRoleAccess(
                [ROLES.ADM, ROLES.DEV, ROLES.INSTRUMENTISTA],
                keycloak
              ) && (
                <div className={`button-container ${isEditMode ? 'hide' : ''}`}>
                  <CustomButton
                    data-testid="editButton"
                    onClick={handleEditMode}
                    gradient
                  >
                    editar
                  </CustomButton>
                </div>
              )}
              <div className={`button-container ${isEditMode ? '' : 'hide'}`}>
                <CustomButton
                  data-testid="cancelButton"
                  onClick={handleCancelPress}
                >
                  cancelar
                </CustomButton>
                <CustomButton
                  data-testid="saveButton"
                  onClick={onSaveClick}
                  gradient
                >
                  salvar
                </CustomButton>
              </div>
            </div>
            <form>{formFields.map((field) => buildField(field))}</form>
          </>
        )}
      </Container>
      <CustomDialog
        dataTestid="confirmationModal"
        open={isModalOpen}
        title={`Deseja salvar estes valores para ${data.name}?`}
        body={formFields.map((field) => (
          <p key={field.id}>
            {`${field.label}: ${String(formValues[field.id]).replace(
              '.',
              ','
            )}${field.form === 'slider' ? '%' : ''}`}
          </p>
        ))}
        action={{
          label: 'confirmar',
          onClick: onConfirmSave,
        }}
        onClose={() => toggleModal()}
      />
    </>
  );
};
