import styled from 'styled-components';

interface CollapsibleItemProps {
    isOpen?: boolean;
    isHealth?: boolean;
}

interface CollapsibleContainerProps {
    width?: string | number;
    height?: string | number
}

interface CollapsibleContentProps{
    backgroundColor?: string;
}

export const CollapsibleContainer = styled.div<CollapsibleContainerProps>`
    margin-top: 20px;
    border: 1px solid #e6e8eb;
    width: ${(props) => (props.width ? props.width : '100%')};
    height: ${(props) => (props.height ? props.height : '100%')};
    background-color: white;
    margin-left: 0px;
`;

export const CollapsibleButton = styled.button`
    padding-left: 10px;
    transition: max-height 0.3s ease;
    overflow: hidden;
    background-color: transparent;
    border: none;
`;

export const CollapsibleTitle = styled.div<CollapsibleItemProps>`
    font-size: 2rem;
    font-weight: bold;
    align-items: center;
    color: ${(props) => (props.isHealth ? '#000000' : '#8c8c8c')};

`;

export const CollapsibleContent = styled.div<CollapsibleContentProps>`
    display: flex;
    direction: row;
    justify-content: left;
    gap: 4px;
    padding: 4px;
    background-color: ${(props) => (props.backgroundColor ? props.backgroundColor : '#FFFFFF')};

`;


export const CollapsibleContentItem = styled.div<CollapsibleItemProps>`
    padding-top: ${(props) => (props.isOpen ? '15px' : '0')};
    padding-left: ${(props) => (props.isOpen ? '10px' : '0')};
    padding-right: ${(props) => (props.isOpen ? '10px' : '0')};
    padding-bottom: 0px;
    max-height: ${(props) => (props.isOpen ? '400px' : '0')};
    transition: max-height 0.4s ease;
    overflow: hidden;
`;

