import { Fade } from '@material-ui/core';
import styled from 'styled-components';

interface ContainerProps {
  isTrailShowing: boolean;
}

export const Container = styled.div<ContainerProps>`
  display: flex;
  flex-direction: column;
  align-items: start;
  justify-content: space-around;
  align-items: center;
  color: black;
  height: 100px;

  & h1 {
    font-size: 18px;
    font-weight: bold;
  }

  & h2 {
    font-size: 14px;
  }

  .info-container {
    display: flex;
    justify-content: space-between;
    width: 100%;
    align-items: center;
    flex-wrap: wrap;

    .arrow-container {
      display: flex;
      align-items: center;
      margin-left: -12px;
      user-select: none;
      -webkit-touch-callout: none;
      -webkit-tap-highlight-color: transparent;
    }
  }

  .title-trail-container {
    display: flex;
    height: 40px;
    width: 100%;
    align-items: center;
    position: relative;

    .p-absolute {
      position: absolute;
      left: 0;
    }

    & button.toggle-trail-button {
      max-width: 40px;
      min-width: 40px;
      height: 20px;

      &.forward {
        margin-right: 8px;
      }

      &.backward {
        margin-left: 8px;
      }
    }

    .title-container {
      display: flex;
      justify-content: space-between;
      align-items: center;
      width: 100%;

      & > :first-child {
        display: flex;
        width: 80%;
      }

      .actions-container {
        display: flex;
        align-items: center;

        & > :first-child {
          margin-right: 5px;
        }
      }

      & button.action-btn {
        max-width: 30px;
        min-width: 30px;
        height: 20px;
        background-image: linear-gradient(to right, #19b0a0, #009ee2);
        color: white;
      }
    }

    .trail-container {
      display: flex;
      justify-content: space-between;
      align-items: center;
      width: ${({ isTrailShowing }) => (isTrailShowing ? '100%' : 0)};

      .trail {
        overflow-x: auto;
        display: flex;
        align-items: center;
        height: 40px;

        ::-webkit-scrollbar {
          height: 0%;
        }

        & > :first-child {
          margin-left: 5px;
        }
      }

      & button.breadcrumb {
        height: 20px;
        min-width: 80px;
        margin-right: 5px;
        font-weight: 600;

        &.gradient {
          background-image: linear-gradient(to right, #19b0a0, #009ee2);
          color: white;
        }

        & .MuiTypography-root {
          margin-right: 5px;
          font-size: 1rem;
        }
      }
    }
  }
`;

interface DelayedFadeProps {
  delay?: number;
}

export const DelayedFade = styled(Fade)<DelayedFadeProps>`
  transition-delay: ${(props) => props.delay || 0}ms;
`;
