import React, { useState } from 'react';
import Media from 'react-media';
import { useSelector } from 'react-redux';

import { Zoom, ButtonBase } from '@material-ui/core';
import { GetApp, Add, Remove } from '@material-ui/icons';

import { BottomSheet } from '../../../../../../../components/BottomSheet';
import { CustomButton } from '../../../../../../../components/CustomButton';
import {
  DateFilter,
  DateFilterItem,
} from '../../../../../../../components/DateFilter';
import {
  DoublePeriodDateFilter,
  DoublePeriodDateFilterItem,
} from '../../../../../../../components/DoublePeriodDateFilter';
import {
  PeriodDateFilterItem,
  PeriodDateFilter,
} from '../../../../../../../components/PeriodDateFilter';
import { SearchField } from '../../../../../../../components/SearchField';
import { AppState } from '../../../../../../../store/reducers';
import { ReportNames } from '../../../../../../../store/reports/types';

import { supportedDevices } from '../../../../../../../styles/supportedDevices';
import { Container, BottomSheetContent } from './styles';

export interface HeaderProps {
  /**
   * Header title to be displayed
   */
  title: string;

  /**
   * Header subtitle, if undefined nothing is shown
   */
  subtitle?: string;

  /**
   * Header description, if undefiend nothing is shown
   */
  description?: string;

  /**
   * Selectable time items, should match the type required by the selected date picker
   */
  timeList:
    | DateFilterItem[]
    | PeriodDateFilterItem[]
    | DoublePeriodDateFilterItem[];

  /**
   * If the search bar should or ne be shown
   */
  hideSearchBar?: boolean;

  /**
   * Selected Date Picker to be used
   */
  datePicker: 'normal' | 'period' | 'doublePeriod';

  /**
   * Current report name
   */
  reportName?: ReportNames;

  /**
   * If search field should allow multiple selections
   */
  isSearchFieldMultiSelect?: boolean;

  /**
   * Is search bar loading
   */
  isSearchBarLoading?: boolean;

  /**
   * If search bar allow arbitrary values
   */
  isSearchBarFreeSolo?: boolean;

  /**
   * Handle download csv button click callback
   */
  handleDownloadCsv(): void;

  /**
   * Search bar change callback
   * @param item Selected item on search bar
   */
  handleSearchBarChange?(item: string | string[] | null): void;

  /**
   * toggle chart buttons builder
   * @param iconSize icon size for toggle buttons
   */
  buildToggleChartsButtons(iconSize: number): JSX.Element;

  /**
   * Build search bar options
   */
  buildSearchOptions?: () => string[];
}

/**
 * Reports header
 */
export const Header: React.FC<HeaderProps> = ({
  title,
  subtitle,
  description,
  timeList,
  hideSearchBar = false,
  datePicker,
  reportName = 'healthAndIndicatorsLogicalLayerAnalysis',
  isSearchFieldMultiSelect = false,
  isSearchBarLoading = false,
  isSearchBarFreeSolo = false,
  handleDownloadCsv,
  handleSearchBarChange = () => {},
  buildToggleChartsButtons,
  buildSearchOptions = () => [],
}) => {
  const { data, isLoading, error } = useSelector(
    (state: AppState) => state.reports
  );

  const [isSettingsOpen, setIsSettingsOpen] = useState(false);

  function handleSettings(open = false) {
    setIsSettingsOpen(open);
  }

  function buildDatePicker() {
    if (datePicker === 'normal')
      return (
        <DateFilter
          fixedFilters={timeList as DateFilterItem[]}
          timePeriodState="reports"
          reportName={reportName}
        />
      );
    if (datePicker === 'period')
      return (
        <PeriodDateFilter
          fixedFilters={timeList as PeriodDateFilterItem[]}
          reportName={reportName}
        />
      );
    return (
      <DoublePeriodDateFilter
        fixedFilters={timeList as DoublePeriodDateFilterItem[]}
        reportName={reportName}
      />
    );
  }

  return (
    <>
      <Media
        queries={{
          tablet: supportedDevices.tablet,
        }}
      >
        {(matches) => (
          <>
            <Container
              bottomSheetOpen={isSettingsOpen}
              data-testid="reportHeader"
            >
              <div className="info-navigation">
                <div>
                  <h1>{title}</h1>
                  {subtitle && <h4>{subtitle}</h4>}
                  {description && <h5>{description}</h5>}
                </div>
                {matches.tablet ? (
                  <div className="navigation">
                    <div className="csv-container">
                      <CustomButton
                        onClick={handleDownloadCsv}
                        disabled={Boolean(isLoading || error || !data)}
                      >
                        <span>Download CSV</span>
                        <GetApp fontSize="large" />
                      </CustomButton>
                    </div>
                    <div className="charts-container">
                      {buildToggleChartsButtons(28)}
                    </div>
                  </div>
                ) : (
                  <div className="settings">
                    <ButtonBase
                      className="more-options-button"
                      onClick={() => handleSettings(true)}
                    >
                      <Add className="add-icon" fontSize="large" />
                      <Remove className="remove-icon" fontSize="large" />
                    </ButtonBase>
                  </div>
                )}
              </div>
              <div className="filters">
                <div className="search-select">
                  <Zoom in={!hideSearchBar} unmountOnExit>
                    <div className="search">
                      <SearchField
                        multiple={isSearchFieldMultiSelect}
                        options={buildSearchOptions()}
                        getOptionLabel={(option: string) => option}
                        onChange={(_, value: string | string[]) =>
                          handleSearchBarChange(value)
                        }
                        loading={isLoading || isSearchBarLoading}
                        freeSolo={isSearchBarFreeSolo}
                      />
                    </div>
                  </Zoom>
                </div>
                {matches.tablet && (
                  <div className="date-picker">{buildDatePicker()}</div>
                )}
              </div>
            </Container>
            {!matches.tablet && (
              <BottomSheet open={isSettingsOpen} handleClose={handleSettings}>
                <BottomSheetContent downloadFullWidth={datePicker === 'period'}>
                  <h1>Mais Opções...</h1>
                  <div className="charts-container">
                    {buildToggleChartsButtons(15)}
                  </div>
                  <div className="filters">
                    <div className="csv-container">
                      <CustomButton
                        onClick={handleDownloadCsv}
                        disabled={Boolean(isLoading || error || !data)}
                      >
                        <span>Download CSV</span>
                        <GetApp fontSize="large" />
                      </CustomButton>
                    </div>
                    <div className="date-picker">{buildDatePicker()}</div>
                  </div>
                </BottomSheetContent>
              </BottomSheet>
            )}
          </>
        )}
      </Media>
    </>
  );
};
