import { getMonth, subDays, subHours, subMinutes } from 'date-fns';

import { TreeData } from '../models/UserData';

export function getAssetIdFromPath(path: string) {
  const assetPath = path.substr(23);
  if (assetPath.startsWith('logico')) return assetPath.substr(7, 36);
  return assetPath.substr(0, 36);
}

export function generateCsv(headers: string[], rows: string[][], name: string) {
  let csv = `${headers.join(';')}\n`;
  rows.forEach((row) => {
    csv += `${row.join(';')}\n`;
  });

  const hiddenElement = document.createElement('a');
  const universalBOM = '\uFEFF';
  hiddenElement.href = `data:text/csv;charset=UTF-8,${encodeURIComponent(
    universalBOM + csv
  )}`;
  hiddenElement.target = '_blank';
  hiddenElement.download = name;
  hiddenElement.click();
}

export function getElementFromTree(
  currentEl: TreeData,
  isTheElement: (el: TreeData) => boolean
): TreeData | undefined | null {
  if (isTheElement(currentEl)) return currentEl;

  if (!currentEl.children || currentEl.children?.length === 0) return null;

  return currentEl.children
    .flatMap((child) => getElementFromTree(child, isTheElement))
    .filter((child) => child != null)
    .flat()[0];
}

export function randomDate2Date(date: string | number | null) {
  if (typeof date === 'string') {
    if (date === 'now') return new Date();
    if (date.endsWith('d'))
      return subDays(new Date(), Number(date.slice(4, -1)));
    if (date.endsWith('h'))
      return subHours(new Date(), Number(date.slice(4, -1)));
    if (date.endsWith('m'))
      return subMinutes(new Date(), Number(date.slice(4, -1)));
    return new Date(date);
  }
  if (typeof date === 'number') return new Date(date);
  return new Date();
}

export function getMonthString(date: Date) {
  switch (getMonth(date) + 1) {
    case 1:
      return 'jan';
    case 2:
      return 'fev';
    case 3:
      return 'mar';
    case 4:
      return 'abr';
    case 5:
      return 'mai';
    case 6:
      return 'jun';
    case 7:
      return 'jul';
    case 8:
      return 'ago';
    case 9:
      return 'set';
    case 10:
      return 'out';
    case 11:
      return 'nov';
    default:
      return 'dez';
  }
}
