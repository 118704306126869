import React, { useState } from 'react';
import Media from 'react-media';
import { useDispatch, useSelector } from 'react-redux';

import {
  Backdrop,
  Fade,
  IconButton,
  InputAdornment,
  MenuItem,
  Tooltip,
} from '@material-ui/core';

import latestIcon from '../../assets/new_icons/ic_latest_period.svg';
import oldestIcon from '../../assets/new_icons/ic_oldest_period.svg';
import { Creators } from '../../store/actionCreators';
import { AppState } from '../../store/reducers';
import { PeriodResolution, ReportNames } from '../../store/reports/types';
import { CustomSelect, CustomInput } from '../CustomSelect';
import { TimePeriod } from '../DateFilter';
import { LargePeriodPicker } from '../Pickers/LargePeriodPicker';
import { MonthPicker } from '../Pickers/MonthPicker';
import { WeekPicker } from '../Pickers/WeekPicker';

import { supportedDevices } from '../../styles/supportedDevices';
import { Container, CustomModal, TooltipSpan, Button } from './styles';

export type PickerType = 'month' | 'week' | 'trimester' | 'semester';

export interface DoublePeriodDateFilterItem {
  resolution: PeriodResolution;
  picker: PickerType;
  label: string;
}

export interface DoublePeriodDateFilterProps {
  /**
   * List with selectable periods
   */
  fixedFilters: DoublePeriodDateFilterItem[];

  /**
   * Report to be fetched
   */
  reportName: ReportNames;
}

/**
 * Period date filter for double periods comparison
 */
export const DoublePeriodDateFilter: React.FC<DoublePeriodDateFilterProps> = ({
  fixedFilters,
  reportName,
}) => {
  const { timePeriod, oldestTimePeriod, periodResolution } = useSelector(
    (state: AppState) => state.reports
  );
  const dispatch = useDispatch();

  const [selectedPicker, setSelectedPicker] = useState(
    fixedFilters.find((filter) => filter.resolution === periodResolution)
      ?.picker || 'week'
  );
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [selectedPeriod, setSelectedPeriod] = useState<
    'oldest' | 'latest' | null
  >(null);
  const [periods, setPeriods] = useState<{
    oldest: TimePeriod | null;
    latest: TimePeriod | null;
  }>({
    oldest: null,
    latest: null,
  });

  function handlePickerBtnClick(period: 'oldest' | 'latest') {
    setSelectedPeriod(period);
    setIsModalOpen(true);
  }

  function handleModalOpen(open = false) {
    setIsModalOpen(open);
  }

  function handleSelectChange(
    event: React.ChangeEvent<{
      value: unknown;
    }>
  ) {
    const newPicker = event.target.value as PickerType;

    setPeriods({
      oldest: null,
      latest: null,
    });
    setSelectedPeriod(null);
    setSelectedPicker(newPicker);
  }

  function onApplyPress(start: Date, end: Date) {
    // Close modal
    handleModalOpen();

    // Set new period
    let newPeriods = {} as {
      oldest: TimePeriod | null;
      latest: TimePeriod | null;
    };
    if (selectedPeriod) {
      newPeriods = {
        ...periods,
        [selectedPeriod]: {
          startTime: start.getTime(),
          endTime: end.getTime(),
        },
      };
      setPeriods(newPeriods);
    }

    // Verify if both periods were chosen
    if (Object.values(newPeriods).every((period) => period != null)) {
      // Set period resolution
      const selectedResolution = fixedFilters.find(
        (filter) => filter.picker === selectedPicker
      )!.resolution;
      dispatch(Creators.setReportsPeriodResolution(selectedResolution));
      dispatch(Creators.setPickerType(selectedPicker));

      dispatch(
        Creators.getReportsRequest(
          newPeriods.latest!,
          reportName,
          newPeriods.oldest!
        )
      );
    }
  }

  function getInitialDate() {
    if (selectedPeriod === 'latest' && typeof timePeriod.startTime === 'number')
      return periods.latest?.startTime
        ? new Date(periods.latest.startTime)
        : new Date(timePeriod.startTime);

    if (
      selectedPeriod === 'oldest' &&
      typeof oldestTimePeriod.startTime === 'number'
    )
      return periods.oldest?.startTime
        ? new Date(periods.oldest.startTime)
        : new Date(oldestTimePeriod.startTime);

    return new Date();
  }

  return (
    <Media
      queries={{
        tablet: `${supportedDevices.tablet}`,
      }}
    >
      {(matches) => {
        return (
          <>
            <Container data-testid="doublePeriodDateFilterContainer">
              {matches.tablet && (
                <>
                  <div className="select-container">
                    <CustomSelect
                      data-testid="pickerSelect"
                      value={selectedPicker}
                      onChange={handleSelectChange}
                      fullWidth
                      input={<CustomInput />}
                    >
                      {fixedFilters.map(({ label, picker }) => (
                        <MenuItem key={picker} value={picker}>
                          {label}
                        </MenuItem>
                      ))}
                    </CustomSelect>
                  </div>
                  <div className="button-container">
                    <Tooltip
                      title={
                        <TooltipSpan>Seletor do primeiro período</TooltipSpan>
                      }
                    >
                      <Button
                        isSelected
                        onClick={() => handlePickerBtnClick('oldest')}
                        data-testid="oldestDateButton"
                      >
                        <img
                          src={oldestIcon}
                          alt="Icone primeiro perido"
                          width={18}
                        />
                      </Button>
                    </Tooltip>
                    <Tooltip
                      title={
                        <TooltipSpan>Seletor do segundo período</TooltipSpan>
                      }
                    >
                      <Button
                        isSelected
                        onClick={() => handlePickerBtnClick('latest')}
                        data-testid="latestDateButton"
                      >
                        <img
                          src={latestIcon}
                          alt="Icone segundo perido"
                          width={18}
                        />
                      </Button>
                    </Tooltip>
                  </div>
                </>
              )}
              {!matches.tablet && (
                <CustomSelect
                  data-testid="pickerSelect"
                  value={selectedPicker}
                  onChange={handleSelectChange}
                  fullWidth
                  input={
                    <CustomInput
                      fullWidth
                      className="select-input"
                      startAdornment={
                        <InputAdornment position="start">
                          <IconButton
                            onClick={() => handlePickerBtnClick('oldest')}
                          >
                            <img
                              src={oldestIcon}
                              alt="Icone primeiro perido"
                              width={16}
                            />
                          </IconButton>
                          <IconButton
                            onClick={() => handlePickerBtnClick('latest')}
                          >
                            <img
                              src={latestIcon}
                              alt="Icone segundo perido"
                              width={16}
                            />
                          </IconButton>
                        </InputAdornment>
                      }
                    />
                  }
                >
                  {fixedFilters.map(({ picker, label }) => (
                    <MenuItem key={picker} value={picker}>
                      {label}
                    </MenuItem>
                  ))}
                </CustomSelect>
              )}
            </Container>
            <CustomModal
              open={isModalOpen}
              onClose={() => handleModalOpen()}
              closeAfterTransition
              BackdropComponent={Backdrop}
              BackdropProps={{
                timeout: 500,
              }}
              data-testid="pickerModal"
            >
              <Fade in={isModalOpen}>
                <div className="outline-0">
                  {selectedPicker === 'week' && (
                    <WeekPicker
                      onApplyPress={onApplyPress}
                      onCancelPress={() => handleModalOpen()}
                      initialDate={getInitialDate()}
                    />
                  )}
                  {selectedPicker === 'month' && (
                    <MonthPicker
                      onApplyPress={onApplyPress}
                      onCancelPress={() => handleModalOpen()}
                      initialDate={getInitialDate()}
                    />
                  )}
                  {(selectedPicker === 'trimester' ||
                    selectedPicker === 'semester') && (
                    <LargePeriodPicker
                      onApplyPress={onApplyPress}
                      onCancelPress={() => handleModalOpen()}
                      period={selectedPicker}
                      initialDate={getInitialDate()}
                    />
                  )}
                </div>
              </Fade>
            </CustomModal>
          </>
        );
      }}
    </Media>
  );
};
