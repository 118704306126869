import React from 'react';

import { GraphColor } from '../../../types';

import { Container, ToggleIndexButton } from './styles';
import { SelectableIndicator } from '../../../../../../../../store/treeHealth/types';

interface ColorFiltersProps {
  colors: GraphColor[];
  onFilterToggle: (color: string) => void;
  filteredColors: string[];
  indicator: SelectableIndicator;
}

export const ColorFilters: React.FC<ColorFiltersProps> = ({
  colors,
  onFilterToggle,
  filteredColors,
  indicator,
}) => {
  const indexToRemove = indicator.name === 'Saúde' ? 3 : 2;
  const updatedColors = colors.filter((_, index) => index !== (colors.length - indexToRemove));

  return (
    <Container>
      <div className="buttons-container">
      {[...updatedColors.slice(-2,-1), ...updatedColors.slice(0,-2), ...updatedColors.slice(-1)].map(
          ({ rgb, percentage }) => {
            const color = `rgb(${rgb.join(',')})`;
            return (
              <ToggleIndexButton
                isSelected={filteredColors.includes(color)}
                borderColor={color}
                key={percentage}
                onClick={() => onFilterToggle(color)}
              >
                <span> { percentage } </span>
              </ToggleIndexButton>
            );
          }
        )}
      </div>
    </Container>
  );
};
