import styled from 'styled-components';

import { supportedDevices } from '../../../../../../../styles/supportedDevices';

export const Container = styled.div`
  color: black;

  &.loader {
    display: grid;
    place-items: center;

    height: 500px;

    .error-container {
      display: flex;
      flex-flow: column;
      align-items: center;
      justify-content: center;
      text-align: center;

      img {
        width: 100px;
        margin-bottom: 10px;
      }

      h3 {
        font-size: 18px;
        color: black;
      }
    }
  }

  .header {
    position: relative;

    height: 30px;
    margin-bottom: 15px;

    .button-container {
      position: absolute;
      top: 0;
      bottom: 0;
      right: 0;
      left: 0;

      display: flex;
      align-items: center;

      height: 100%;

      transform: translateX(0);
      opacity: 1;
      pointer-events: all;
      transition: all 0.3s;

      &.hide {
        transform: translateX(-50px);
        opacity: 0;
        pointer-events: none;
      }

      & > button {
        height: 100%;
        padding: 0 30px;
      }

      & > :nth-child(2) {
        margin-left: 15px;
      }
    }

    @media ${supportedDevices.tablet} {
      height: 36px;

      .button-container {
        left: unset;

        & > button {
          padding: 0 20px;
        }
      }
    }
  }

  form {
    display: grid;
    grid-template-columns: 1fr;

    .field-container {
      margin-bottom: 10px;

      .label-container {
        display: flex;
        align-items: center;

        label {
          font-size: 1.6rem;
          margin-right: 10px;
        }

        svg.help-icon {
          color: rgba(0, 0, 0, 0.6);
        }
      }

      .slider-container {
        display: flex;
        align-items: center;
        justify-content: space-around;

        & > :first-child {
          width: 80%;
        }

        & > :last-child {
          width: 10%;
        }
      }
    }

    @media ${supportedDevices.tablet} {
      grid-template-columns: repeat(2, minmax(300px, 1fr));
      grid-gap: 20px;
    }
  }

  @media ${supportedDevices.tablet} {
    &.loader {
      .error-container {
        img {
          width: 150px;
          margin-bottom: 20px;
        }

        h3 {
          font-size: 24px;
        }
      }
    }
  }
`;

export const TooltipSpan = styled.span`
  font-size: 12px;
  line-height: 1.5rem;
  display: flex;
  flex-direction: column;
  white-space: pre-line;
`;

export const SliderValueLabel = styled.span`
  font-size: 12px;
`;
