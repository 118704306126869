import axios from 'axios';
import qs from 'qs';

import {
  AssetDetails,
  AssetIndicator,
  AssetAlert,
  AssetCalibration,
  AssetUpdate,
  FieldAssetCalibration,
} from '../models/AssetInfo';
import { NotificationInfo } from '../models/NotificationInfo';
import { TreeData, meData } from '../models/UserData';
import { getTokenFromFirebase } from './firebase';
import { GetNotificationsResponse } from './types';
import { getTokenFromKeycloak } from '../zustand/AuthStore';

export enum APPLICATIONS {
  BALANCE = 'BALANCE',
  HEALTH = 'HEALTH',
  FIELD = 'FIELD',
}

const baseURL =
  process.env.REACT_APP_STAGE == 'production'
    ? process.env.REACT_APP_API_PROD
    : process.env.REACT_APP_API_DEV;

const baseBalanceURL =
  process.env.REACT_APP_STAGE == 'production'
    ? process.env.REACT_APP_BALANCE_API_PROD
    : process.env.REACT_APP_BALANCE_API_DEV;


const baseFieldURL =
  process.env.REACT_APP_STAGE == 'production'
    ? process.env.REACT_APP_FIELD_API_PROD
    : process.env.REACT_APP_FIELD_API_DEV;

export const httpField = axios.create({
  baseURL: baseFieldURL,
  headers: {
    Accept: 'application/json',
    'Content-Type': 'application/json',
  },
});

export const http = axios.create({
  baseURL,
  headers: {
    Accept: 'application/json',
    // user: 'Cx19rvLkd1aT3hKsiMpmlEkM2Uk1', //Bayer
    'Content-Type': 'application/json',
  },
});

export const httpBalance = axios.create({
  baseURL: baseBalanceURL,
  headers: {
    Accept: 'application/json',
    'Content-Type': 'application/json',
  },
});

http.interceptors.request.use(async (config) => {
  const configKeycloak = await getTokenFromKeycloak(
    config,
    APPLICATIONS.HEALTH
  );
  if (!!configKeycloak) return configKeycloak;

  const token = await getTokenFromFirebase();
  if (token) config.headers.Authorization = `${token}`;
  return config;
});

httpBalance.interceptors.request.use(async (config) => {
  const configKeycloak = await getTokenFromKeycloak(
    config,
    APPLICATIONS.BALANCE
  );
  if (!!configKeycloak) return configKeycloak;

  return config;
});

httpField.interceptors.request.use(async (config) => {
  const configKeycloak = await getTokenFromKeycloak(
    config,
    APPLICATIONS.BALANCE
  );
  if (!!configKeycloak) return configKeycloak;

  return config;
});

// FIELD
export const getAssetCalibrations = (assetUuid: string) => httpField.get<FieldAssetCalibration[]>(`dashboard/calibration/${assetUuid}`);
export const getCalibrationReport = (calibrationFormId: number, assetTag: string) => httpField.get(`dashboard/calibration/report/${calibrationFormId}`, {
  headers: {
    'Content-Type': 'application/pdf',
  },
  responseType: 'blob',
})
  .then(response => response.data)
  .then(blob => {
    const url = window.URL.createObjectURL(new Blob([blob]));
    const link = document.createElement('a');
    link.href = url;
    link.setAttribute('download', `${assetTag}_calibration_report_${calibrationFormId}.pdf`);
    document.body.appendChild(link);
    link.click();
    link.remove();
  }
  )

// USERS
export const getUsers = (search = '') => http.get(`users/all${search}`);
export const getMe = () => http.get<meData>('company/me');
export const getTree = () => http.get<TreeData>('tree');
export const getCompanyEmails = () => http.get('users/company-emails');
export const createUserIfNotExistsHealth = (user: any) => http.post('users/createUserIfNotExists', user, {
  timeout: 30000,
});
export const createUserIfNotExistsBalance = (user: any) => httpBalance.post('user/createUserIfNotExists', user);
export const changeDefaultTreeElement = (treeElementId: String) => http.patch('users/changeDefaultTreeElement', {
  treeElementId
});

// KNOWLEDGE
export const getKnowledges = (search = '') =>
  http.get(`knowledge-base${search}`);
export const getKnowledgeById = (id: string) =>
  http.get(`knowledge-base/${id}`);
export const postKnowledgeBase = (kb: any) => http.post(`knowledge-base`, kb);
export const putKnowledgeBase = (kb: any) =>
  http.put(`knowledge-base/${kb.id}`, kb);

// COST-CENTER
export const getCostCenters = (search = '') =>
  http.get(`cost-centers${search}`);

// NOTIFICATION
export const getNotifications = (search = '') =>
  http.get<GetNotificationsResponse>(`notification${search}`);
export const putNotification = (notification: NotificationInfo) =>
  http.put(`notification/${notification.id}`, notification);
export const getNotificationById = (id = '') => http.get(`notification/${id}`);
export const getNotificationsByActiveId = (search = '') =>
  http.get(`notification/active${search}`);

// SERVICE-ORDER
export const getServiceOrder = (search = '') =>
  http.get(`service-order${search}`);
export const getServiceOrderResume = () => http.get('service-order/summary');
export const getServiceOrderById = (id: string) =>
  http.get(`service-order/${id}`);
export const postServiceOrder = (so: any) => http.post(`service-order`, so);
export const putServiceOrder = (so: any) =>
  http.put(`service-order/${so.id}`, so);

// ASSET DATA
export const getAssetData = (uuid = '') =>
  http.get(`asset/health-datapoints?assetUuid=${uuid}`);

// TREE HEALTH

export const getTreeHealth = (query = '', criticality: string) => http.get(`tree/health${query}?criticality=${encodeURIComponent(criticality)}`);
export const getTreeWithIndicator = (query = '', indicatorUuid: string, criticality: string) =>
  http.get(`tree/${indicatorUuid}?criticality=${encodeURIComponent(criticality)}&${query}`);
export const handleLayerBlock = (uuid: string, blocked: boolean) =>
  http.patch(`tree/block-state?uuid=${uuid}`, { blocked });
export const getTreeFilter = (search = '') =>
  http.get(`children?uuid=${search}`);
export const getTreePossibleIndicators = () => http.get('tree/indicators');

/**
 * Updates asset block state
 * @param assetUuid Asset id to be changed
 * @param blocked if the asset should or not be blocked
 */
export const updateAssetBlock = async (assetUuid = '', blocked = false) =>
  http.patch(`asset?uuid=${assetUuid}`, { blocked });

/**
 * Gets asset indicators with weight and weight percentage
 * @param uuid assets uuid identifier
 */
export const getAssetIndicatorsDetails = (uuid: string) =>
  http.get<{ data: AssetIndicator[] }>(`asset/indicators?assetUuid=${uuid}`);

/**
 * Updates the indicator weight
 * @param indicator Indicator with updated weight
 */
export const updateAssetIndicatorWeight = (indicator: AssetIndicator) =>
  http.patch('asset/indicators', indicator);

/**
 * Get details from chosen asset
 * @param uuid Asset to be accessed
 */
export const getAssetDetails = (uuid: string) =>
  http.get(`asset/${uuid}/details`);

/**
 * Patches asset details
 * @param uuid Asset to be patched
 * @param asset Asset changes
 */
export const patchAssetDetails = (uuid: string, asset: AssetDetails) =>
  http.patch(`asset/${uuid}/details`, asset);

export const getAssetAlert = (uuid: string) => http.get(`alert/${uuid}`);

export const updateAssetAlert = (alertData: AssetAlert) =>
  http.put('alert', alertData);

export const updateAsset = (assetData: AssetUpdate) =>
  http.put(`asset/${assetData.assetUUid}/updateAsset`, assetData);

export const getTags = (search = '') => http.get(`tag/list${search}`);

export const getTagsFFT = (search = '') => http.get(`tag/list/fft${search}`);

export const getTagsDatapoints = (search = '') =>
  http.get(`tag/datapoints/v2${search}`);

export const getTagsByAssets = (assetsUuids: string[]) =>
  http.get(`tag/assets?assetsUuids=${assetsUuids.join(',')}`);

export const setSelectedAlertEmails = (emails: string[], minAvailability: number) =>
  http.post(`users/set-company-alert-emails?emails=${encodeURIComponent(emails.join(','))}&minAvailability=${minAvailability}`);

/**
 * Get calibration info from chosen asset
 * @param identifier Path of asset to be accessed
 */
export const getAssetCalibration = (identifier: string) =>
  httpBalance.get(`tag/${identifier}/calibration`);

/**
 * Patches asset calibration
 * @param identifier Path of asset to be patched
 * @param asset Asset calibration changes
 */
export const patchAssetCalibration = (
  identifier: string,
  asset: AssetCalibration
) => httpBalance.patch(`tag/${identifier}/calibration/update`, asset);

export const getCollectedTagsInfoReport = (
  companyUuid: string,
  tagType: string
) => http.get(`asset/assetCollectedTagsReport?companyUuid=${companyUuid}&tagType=${tagType}`);

export const getTagRatioInfoReport = (
  companyUuid: string
) => http.get(`asset/getTagRatioByCompany?companyUuid=${companyUuid}`);

export const getCompanyAlertInfo = () => http.get('users/get-company-alert-info');

// Reports
export const getHealthAndIndicatorPerElementReport = (
  startTime: string | number | null,
  endTime: string | number | null,
  treeElementUuid: string,
  isLogicalAsset: boolean
) =>
  // http.get<{ data: HealthAndIndicatorsPerElement[] }>(
  http.get(
    `report/indicators-health/resume?${qs.stringify({
      startTime,
      endTime,
      treeElementUuid,
      isLogicalAsset
    })}`
  );
export const getHealthAndIndicatorPerPeriodReport = (
  startTime: string | number | null,
  endTime: string | number | null,
  periodResolution: string,
  assetUuid: string,
  isLogical : boolean
) =>
  http.get(
    `report/indicators-health/asset?${qs.stringify({
      startTime,
      endTime,
      periodResolution,
      assetUuid,
      isLogical
    })}`
  );
export const getHealthAndIndicatorPhysicalAvailability = (
  startTime: string | number | null,
  endTime: string | number | null,
  uuid: string
) =>
  http.get(
    `report/tree/physical-availability?${qs.stringify({
      startTime,
      endTime,
      uuid,
    })}`
  );
export const getHealthAndIndicatorsPeriodComparisonReport = (
  uuid: string,
  oldestStartTime: string | number | null,
  oldestEndTime: string | number | null,
  latestStartTime: string | number | null,
  latestEndTime: string | number | null
) =>
  http.get(
    `report/tree/comparison?${qs.stringify({
      uuid,
      oldestStartTime,
      oldestEndTime,
      latestStartTime,
      latestEndTime,
    })}`
  );

// Data Analysis
export const getTagsComparisonData = (
  startTime: string | number | null,
  endTime: string | number | null,
  metrics: string[],
  periodResolution: string
) =>
  http.get(
    `data-analysis/tags/comparison?${qs.stringify({
      metrics: metrics.join(','),
      startTime,
      endTime,
      periodResolution,
    })}`
  );

const apis = {
  //  Auth
  getMe,
  getTree,
  getCompanyEmails,
  // TreeHealth
  getTreeWithIndicator,
  getTreeFilter,
  getTreeHealth,
  handleLayerBlock,
  getTreePossibleIndicators,
  // AssetData
  getAssetData,
  updateAssetBlock,
  getAssetIndicatorsDetails,
  updateAssetIndicatorWeight,
  getAssetDetails,
  patchAssetDetails,
  getAssetAlert,
  updateAssetAlert,
  updateAsset,
  getAssetCalibration,
  patchAssetCalibration,
  // SERVICE-ORDER
  getServiceOrder,
  getServiceOrderResume,
  getServiceOrderById,
  postServiceOrder,
  putServiceOrder,
  // KNOWLEDGE-BASE
  getKnowledgeById,
  getKnowledges,
  putKnowledgeBase,
  postKnowledgeBase,
  // NOTIFICATION
  getNotifications,
  putNotification,
  getNotificationById,
  getNotificationsByActiveId,
  // COST-CENTER
  getCostCenters,
  // USERS
  getUsers,
  createUserIfNotExistsHealth,
  createUserIfNotExistsBalance,
  changeDefaultTreeElement,
  // TAGS
  getTags,
  getTagsFFT,
  getTagsDatapoints,
  getTagsByAssets,
  //  Reports
  getHealthAndIndicatorPerElementReport,
  getHealthAndIndicatorPerPeriodReport,
  getHealthAndIndicatorPhysicalAvailability,
  getHealthAndIndicatorsPeriodComparisonReport,
  getCollectedTagsInfoReport,
  getTagRatioInfoReport,
  // Data Analysis
  getTagsComparisonData,
  // FIELD
  getAssetCalibrations,
  // Alert
  setSelectedAlertEmails,
  getCompanyAlertInfo,
};

export default apis;
