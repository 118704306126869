import styled from 'styled-components';

import { supportedDevices } from '../../../../../styles/supportedDevices';

export const Container = styled.div`
  display: flex;
  flex-direction: column;

  padding: 16px;
  width: 100%;

  &.no-element {
    align-items: center;
    justify-content: center;
    color: black;

    font-size: 16px;
  }

  & .loader,
  .error {
    height: calc(100% - 160px);
  }

  & .loader {
    display: grid;
    place-items: center;
  }

  & .error {
    display: flex;
    flex-flow: column;
    align-items: center;
    justify-content: center;
    text-align: center;

    img {
      width: 150px;
      margin-bottom: 20px;
    }

    h3 {
      font-size: 24px;
      color: black;
    }
  }

  .chart {
    margin-top: 30px;

    .select-element-container {
      color: black;
      display: flex;
      align-items: center;
      justify-content: center;

      height: 300px;
      font-size: 16px;
    }
  }

  @media ${supportedDevices.tablet} {
    &.no-element {
      font-size: 20px;
    }

    .chart {
      .select-element-container {
        font-size: 20px;
      }
    }
  }

  @media ${supportedDevices.tablet} and (orientation: landscape) {
    padding: 20px 24px;
  }
`;
