import { select, put } from 'redux-saga/effects';

import { TimePeriod } from '../../components/DateFilter';
import config from '../../config';
import { HealthAndIndicatorsPerPeriod } from '../../models/Reports';
import { Types, Creators } from '../actionCreators';
import { AppState } from '../reducers';
import {
  GetCollectedTagsInfoRequestAction,
  GetTagRatioInfoRequestAction,
  GetReportsRequestAction,
  PeriodResolution,
  ReportNames,
  ReportTypes,
} from './types';

function* getReportType(
  report: ReportNames,
  { startTime, endTime }: TimePeriod,
  oldestTimePeriod: TimePeriod,
  periodResolution: PeriodResolution,
  uuid: string,
) {
  const resTuple: [ReportTypes, any] = ['perElement', {}];

  switch (report) {
    case 'healthAndIndicatorsPerPhysicalElement':
      resTuple[0] = 'perElement';
      resTuple[1] = yield config.api.getHealthAndIndicatorPerElementReport(
        startTime,
        endTime,
        uuid,
        false
      );
      return resTuple;
    case 'healthAndIndicatorsPerLogicalElement':
      resTuple[0] = 'perElement';
      resTuple[1] = yield config.api.getHealthAndIndicatorPerElementReport(
        startTime,
        endTime,
        uuid,
        true
      );
      return resTuple;
    case 'healthAndIndicatorsLogicalLayerAnalysis':
      resTuple[0] = 'perPeriod';
      resTuple[1] = yield config.api.getHealthAndIndicatorPerPeriodReport(
        startTime,
        endTime,
        periodResolution,
        uuid,
        true
      );
      (resTuple[1]
        .data as HealthAndIndicatorsPerPeriod).tagsValuesAndEvolution.sort(
          (i1, i2) => {
            if (i1.representationTagName === 'Saúde') return -1;
            if (i2.representationTagName === 'Saúde') return 1;
            return i1.representationTagName < i2.representationTagName ? -1 : 1;
          }
        );
      return resTuple;
    case 'healthAndIndicatorsPhysicalLayerAnalysis':
      resTuple[0] = 'perPeriod';
      resTuple[1] = yield config.api.getHealthAndIndicatorPerPeriodReport(
        startTime,
        endTime,
        periodResolution,
        uuid,
        false
      );
      (resTuple[1]
        .data as HealthAndIndicatorsPerPeriod).tagsValuesAndEvolution.sort(
          (i1, i2) => {
            if (i1.representationTagName === 'Saúde') return -1;
            if (i2.representationTagName === 'Saúde') return 1;
            return i1.representationTagName < i2.representationTagName ? -1 : 1;
          }
        );
      return resTuple;
    case 'healthAndIndicatorsPerPhysicalAvailability':
      resTuple[0] = 'perPhysicalAvailability';
      resTuple[1] = yield config.api.getHealthAndIndicatorPhysicalAvailability(
        startTime,
        endTime,
        uuid
      );
      return resTuple;
    case 'healthAndIndicatorsPeriodComparison':
      resTuple[0] = 'healthPeriodComparison';
      resTuple[1] = yield config.api.getHealthAndIndicatorsPeriodComparisonReport(
        uuid,
        oldestTimePeriod.startTime,
        oldestTimePeriod.endTime,
        startTime,
        endTime
      );
      return resTuple;
    default:
      return resTuple;
  }
}

interface GetReportAction {
  type: typeof Types.GET_REPORTS_REQUEST;
  report: GetReportsRequestAction['report'];
}

export function* getReport({ report }: GetReportAction): any {
  try {
    const {
      timePeriod,
      oldestTimePeriod,
      elementUuid,
      periodResolution,
    } = yield select((state: AppState) => state.reports);

    const latestTime = {} as TimePeriod;
    const oldestTime = {} as TimePeriod;

    if (typeof timePeriod.startTime === 'number') {
      latestTime.startTime = Math.floor(timePeriod.startTime / 1000);
      latestTime.endTime = Math.floor(timePeriod.endTime / 1000);
    } else {
      latestTime.startTime = timePeriod.startTime;
      latestTime.endTime = timePeriod.endTime;
    }

    if (typeof oldestTimePeriod.startTime === 'number') {
      oldestTime.startTime = Math.floor(oldestTimePeriod.startTime / 1000);
      oldestTime.endTime = Math.floor(oldestTimePeriod.endTime / 1000);
    } else {
      oldestTime.startTime = oldestTimePeriod.startTime;
      oldestTime.endTime = oldestTimePeriod.endTime;
    }

    const [type, response] = yield getReportType(
      report,
      latestTime,
      oldestTime,
      periodResolution,
      elementUuid
    );

    yield put(Creators.getReportsSuccess(response.data, type));
  } catch (error) {
    yield put(
      Creators.getReportsFailure('Ocorreu um erro ao buscar o relatório!')
    );
  }
}

interface GetTagRatioInfoAction {
  type: typeof Types.GET_TAG_RATIO_INFO_REQUEST;
  companyUuid: GetTagRatioInfoRequestAction['companyUuid'];
}

export function* getTagRatioInfo(action: GetTagRatioInfoAction){
  try{
    const tagRatio = yield config.api.getTagRatioInfoReport(action.companyUuid);
    yield put(Creators.getTagRatioInfoSuccess(tagRatio.data));
  } catch (error) {
    yield put(Creators.getTagRatioInfoFailure(error));
}

}

interface SetSelectedAlertEmailsRequestAction {
  type: typeof Types.SET_SELECTED_ALERT_EMAILS_REQUEST;
  emails: string[];
  minAvailability: Number;
}

interface GetCollectedTagsInfoAction {
  type: typeof Types.GET_COLLECTED_TAGS_INFO_REQUEST;
  companyUuid: GetCollectedTagsInfoRequestAction['companyUuid'];
  tagType: GetCollectedTagsInfoRequestAction['tagType']
}

export function* getCollectedTagsInfo(action: GetCollectedTagsInfoAction) {
  try {

    let type = 'collectedTags'
    if (action.tagType == "logical") {
      type = 'collectedLogicalTags'
    }

    const collectedTags = yield config.api.getCollectedTagsInfoReport(action.companyUuid, action.tagType);
    yield put(Creators.getCollectedTagsInfoSuccess(collectedTags.data, type));
  } catch (error) {
    yield put(Creators.getCollectedTagsInfoFailure(error));
  }
}
