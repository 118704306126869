import { ButtonBase, Modal } from '@material-ui/core';
import styled from 'styled-components';

import { supportedDevices } from '../../styles/supportedDevices';

export const DesktopContainer = styled.div`
  display: flex;
  align-items: center;
  flex-wrap: wrap;
`;

interface ButtonProps {
  isSelected: boolean;
}

export const Button = styled(ButtonBase).withConfig({
  shouldForwardProp: (prop) => !['isSelected'].includes(prop),
})<ButtonProps>`
  && {
    min-width: 50px;
    height: 50px;
    padding: 5px;
    margin-top: 5px;
    margin-right: 10px;
    border-radius: 4px;
    font-weight: 600;

    border: solid 1px rgba(0, 0, 0, 0.1);

    color: ${({ isSelected }) => (isSelected ? '#000' : 'rgba(0, 0, 0, 0.5)')};

    background: ${({ isSelected, theme }) =>
      isSelected ? theme.palette.background.paper : 'rgba(230,232,235, 0.5)'};

    && .MuiSvgIcon-colorPrimary {
      color: ${({ isSelected }) =>
        isSelected ? '#000' : 'rgba(0, 0, 0, 0.5)'};
    }

    @media ${supportedDevices.tablet} {
      margin-top: 0;
    }
  }
`;

export const CustomModal = styled(Modal)`
  display: flex;
  height: 100%;
  justify-content: center;
  align-items: center;
  font-size: 1.5rem;

  & .date-picker-container {
    width: 300px;

    @media ${supportedDevices.mobileM} {
      width: 325px;
    }

    @media ${supportedDevices.tablet} {
      width: 600px;
    }
  }

  .outline-0 {
    outline: 0;
  }
`;

export const TooltipSpan = styled.span`
  font-size: 12px;
`;

export const MobileContainer = styled.div`
  width: 100%;

  .select-input {
    color: rgba(0, 0, 0, 0.87);
    cursor: pointer;
    display: inline-flex;
    box-sizing: border-box;
    align-items: align-items;
    font-weight: 400;
    background-color: white;
    outline: 0;
    white-space: nowrap;
    text-overflow: ellipsis;
    border: 0.5px solid rgb(0, 0, 0, 0.1);
    background-color: rgba(230, 232, 235, 0.5);
    -webkit-touch-callout: none;
    -webkit-tap-highlight-color: transparent;
    user-select: none;

    min-height: 36px;
    line-height: 1.5rem;
    border-radius: 4px;
    font-size: 16px;

    .MuiInputAdornment-root {
      border-right: 1px solid rgba(0, 0, 0, 0.36);
      height: 20px;
    }

    &.Mui-focused {
      outline: none;
      background-color: ${({ theme }) => theme.palette.background.paper};
      border: 1.5px solid rgb(0, 0, 0, 0.3);
    }

    & .MuiSelect-select {
      display: flex;
      align-items: center;
    }

    & .MuiSelect-select:focus {
      background-color: transparent;
    }

    svg {
      font-size: 3rem;
      color: rgba(0, 0, 0, 0.54);
    }
  }
`;
