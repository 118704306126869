import { Fade } from '@material-ui/core';
import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  align-items: flex-start;
  color: black;
  height: 80px;

  h1 {
    font-size: 20px;
    font-weight: bold;
  }

  h2 {
    font-size: 14px;
  }

  & .breadcrumb-container {
    width: 100%;
    display: flex;
    height: 40px;
    align-items: center;
    position: relative;
  }

  & .w-100 {
    width: 100%;
  }

  & .p-absolute {
    position: absolute;
    left: 0;
  }

  & .center-vertical {
    display: flex;
    align-items: center;
  }

  .title-actions-container {
    justify-content: space-between;

    & > :first-child {
      display: flex;
      width: 80%;
    }
  }

  & button.toggle-breadcrumbs-button {
    max-width: 40px;
    min-width: 40px;
    height: 20px;

    &.forward {
      margin-right: 12px;
    }

    &.backward {
      margin-left: 12px;
    }
  }

  & button.lock-btn {
    max-width: 30px;
    min-width: 30px;
    height: 20px;
    background-image: linear-gradient(to right, #19b0a0, #009ee2);
    color: white;
  }
`;

interface BreadcrumbsAndButtonContainerProps {
  isOpen: boolean;
}

export const BreadcrumbsAndButtonContainer = styled.div<
  BreadcrumbsAndButtonContainerProps
>`
  width: ${({ isOpen }) => (isOpen ? '100%' : 0)};
  display: flex;
  justify-content: space-between;
  align-items: center;

  & .breadcrumbs {
    overflow-x: auto;
    display: flex;
    align-items: center;
    height: 40px;

    ::-webkit-scrollbar {
      height: 0;
    }

    & > :first-child {
      margin-left: 5px;
    }
  }

  & button.breadcrumb {
    height: 20px;
    min-width: 80px;
    margin-right: 5px;
    font-weight: 600;
    display: flex;
    align-items: center;

    &.gradient {
      background-image: linear-gradient(to right, #19b0a0, #009ee2);
      color: white;
    }

    & .MuiTypography-root {
      margin-right: 5px;
      font-size: 1rem;
    }
  }
`;

interface DelayedFadeProps {
  delay?: number;
}

export const DelayedFade = styled(Fade)<DelayedFadeProps>`
  transition-delay: ${(props) => props.delay || 0}ms;
`;
