import styled from 'styled-components';

import { supportedDevices } from '../../../../styles/supportedDevices';

export const LoginTitle = styled.h1`
  color: black;
  font-size: 20px;
  font-weight: bold;

  @media ${supportedDevices.laptop} {
    font-size: 32px;
  }
`;

export const Line = styled.hr`
  width: 100%;
  height: 2px;
`;

export const P = styled.span`
  font-size: 16px;
  color: rgb(0, 158, 226);
`;
