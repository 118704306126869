import styled, { css } from 'styled-components';

interface AsideProps {
  isOpen: boolean;
}

export const Aside = styled.aside<AsideProps>`
  overflow-y: auto;
  display: flex;
  flex-direction: column;
  color: black;
  overflow: hidden;

  height: 100%;
  padding: 20px 0;
  width: ${({ isOpen }) => (isOpen ? '420px' : 0)};
  padding-left: ${({ isOpen }) => (isOpen ? '24px' : 0)};

  transition: width 0.4s, padding 0.4s;

  & > h4 {
    text-align: center;
    height: 100%;
    display: grid;
    place-items: center;
    background: white;
    border-radius: 4px;

    font-size: 16px;
  }

  .search-container {
    .search {
      margin-bottom: 12px;
    }
  }

  .loading-container {
    height: 100%;
    display: grid;
    place-items: center;
    background: white;
    border-radius: 4px;
    box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.04);
  }

  .notifications {
    overflow-y: auto;
    overflow-x: hidden;

    ::-webkit-scrollbar {
      width: 3px;
    }
  }
`;

interface NotificationCard {
  isLast: boolean;
  color: string;
  isOpen: boolean;
  isCurrent?: boolean;
}

export const NotificationCard = styled.div<NotificationCard>`
  && {
    background-color: white;
    ${({ isCurrent }) =>
      isCurrent &&
      css`
        background-color: #f1f1f1;
      `};
    box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.04);
    border-radius: 4px;
    border-left: 4px solid ${({ color }) => color};

    transition: background-color 2s;

    margin-bottom: ${({ isLast }) => (isLast ? 0 : 10)}px;
    height: 160px;

    * {
      opacity: ${({ isOpen }) => Number(isOpen)};
      text-align: start;
    }

    p {
      font-size: 1.1rem;

      &.description {
        margin: 3px 0;
      }
    }

    h4 {
      font-size: 1.5rem;
    }

    .actions-container {
      display: flex;
      align-items: center;
      justify-content: space-between;

      width: 100%;

      .actions {
        & > :not(:last-child) {
          margin-right: 5px;
        }

        svg {
          font-size: 2rem;

          &.commentIcon {
            font-size: 1.8rem;
          }
        }
      }
    }

    .arrow-info-container {
      height: 24%;
      width: 100%;
      display: flex;
      justify-content: space-between;
      align-items: center;

      .info-container {
        height: 100%;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
      }

      .arrow-container {
        display: flex;
        justify-content: flex-end;
        align-items: center;

        svg {
          font-size: 2rem;
        }
      }
    }
  }

  .container{
    display: flex;
    flex-direction: row;
    height: 100%;
    width: 100%;
    gap: 12px;
  }
  
  .sub-container {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: space-evenly;
    height: 100%;
    width: 100%;

    padding: 0 12px;
  }

  .button-container{
    display: flex;
    flex-direction: column;
    height: 100%;
    align-items: center;
    justify-content: space-between;
    padding: 12px;
    padding-left: 0;

    .actions{
      display: flex;
      flex-direction: column;
      align-items: center;
      gap: 4px;
      
      svg {
        font-size: 2rem;

        &.commentIcon {
          font-size: 1.8rem;
        }
      }
    }

    .arrow{
      svg {
        font-size: 1.8rem;
      }
    }

  }

  &.loader {
    display: grid;
    place-items: center;

    margin-top: 16px;
  }

  button {
    border-radius: 4px;
  }
`;

export const TooltipSpan = styled.span`
  font-size: 12px;
`;
