import styled from 'styled-components';

import { kMobileAppBarHeight, kDesktopAppBarHeight } from '../utils/constants';

import { supportedDevices } from '../styles/supportedDevices';

export const Container = styled.div`
  height: 100%;
  width: 100%;
  margin: 0;
  overflow: hidden;
  background-color: ${(props) => props.theme.palette.background.paper};

  &.loader {
    display: grid;
    place-items: center;
  }
`;

export const Content = styled.div`
  display: flex;
  width: 100%;

  height: calc(100% - ${kMobileAppBarHeight}px);

  @media ${supportedDevices.tablet} {
    height: calc(100% - ${kDesktopAppBarHeight}px);
  }
`;
