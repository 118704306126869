import styled from 'styled-components';

export interface ContainerProps {
  maxHeight?: number;
}

export const Container = styled.section<ContainerProps>`
  overflow-y: auto;
  overflow-x: hidden;
  width: 100%;
  height: 100%;
  padding: 20px 28px;

  & .card {
    min-width: calc(90vw - 300px);
    min-height: fit-content;
    max-height: ${({ maxHeight }) => maxHeight || undefined}px;
    transition: max-height 0.5s ease;
    overflow: hidden;

    background-color: #fff;
    border-radius: 4px;
    box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.04);

    & .loader-container {
      height: 86vh;
      width: 100%;
      display: flex;
      justify-content: center;
      align-items: center;
    }
  }

  & .error-container {
    display: flex;
    flex-flow: column;
    align-items: center;
    justify-content: center;
    height: 86vh;
    text-align: center;

    img {
      width: 150px;
      margin-bottom: 20px;
    }

    h3 {
      font-size: 24px;
      color: black;
    }
  }
`;

export const MainContainer = styled.section`
  padding: 20px 24px;
`;

export const ChartContainer = styled.section`
  display: flex;
  flex-direction: row-reverse;
  padding-top: 25px;

  & .center {
    display: flex;
    justify-content: center;

    &.treemap {
      margin-top: -20px;
    }
  }

  & .charts {
    flex: 5;
    flex-flow: row wrap;
    justify-content: space-around;
  }

  & .filters {
    flex: 1;
    display: flex;
    justify-content: flex-end;
  }
`;
