import { difference } from 'lodash';
import { createReducer, createActions } from 'reduxsauce';

import { GetAssetDataFailureAction } from '../assetData/types';
import {
  DataAnalysisActions,
  DataAnalysisState,
  DataAnalysisTypes,
  GetAnalysisDataFailureAction,
  GetAnalysisDataSuccessAction,
  GetAssetTagsSuccessAction,
  ResetAnalysisDataAction,
  SetIsPanelOpenAction,
  SetMetricsAction,
  SetPanelOptionsAction,
  setAnalysisPeriodResolutionAction,
  SetSelectableTreeItemsAction,
  SetSelectedTreeItemsActions,
  SetTimePeriodAction,
} from './types';

const { Types, Creators } = createActions<
  DataAnalysisTypes,
  DataAnalysisActions
>({
  getAssetTagsRequest: ['uuids'],
  getAssetTagsSuccess: ['tags'],
  getAssetTagsFailure: ['error'],
  setMetrics: ['metrics'],
  setIsPanelOpen: ['open'],
  setPanelOptions: ['panelOption'],
  setSelectableTreeItems: ['selectableItems'],
  setSelectedTreeItems: ['items'],
  setTimePeriod: ['timePeriod'],
  getAnalysisDataRequest: ['analysis'],
  getAnalysisDataSuccess: ['analysis', 'data'],
  getAnalysisDataFailure: ['error'],
  setAnalysisPeriodResolution: ['resolution'],
  resetAnalysisData: ['analysis'],
});

const INITIAL_STATE: DataAnalysisState = {
  isPanelOpen: true,
  panelOption: 'tree',
  selectableTreeItems: 'all',
  data: {
    tagsComparisonAnalysis: null,
  },
  metrics: [],
  tags: [],
  isLoading: false,
  error: '',
  timePeriod: {
    startTime: 'now-1h',
    endTime: 'now',
  },
  selectedTreeItems: [],
  periodResolution: '1min',
};

const getAssetTagsRequest = (state = INITIAL_STATE) => ({
  ...state,
  isLoading: true,
  error: '',
});

const getAssetTagsSuccess = (
  state = INITIAL_STATE,
  { tags }: GetAssetTagsSuccessAction
) => ({
  ...state,
  isLoading: false,
  tags,
});

const getAssetTagsFailure = (
  state = INITIAL_STATE,
  { error }: GetAssetDataFailureAction
) => ({
  ...state,
  isLoading: false,
  error,
});

const setMetrics = (state = INITIAL_STATE, { metrics }: SetMetricsAction) => ({
  ...state,
  metrics,
});

const setIsPanelOpen = (
  state = INITIAL_STATE,
  { open }: SetIsPanelOpenAction
) => ({
  ...state,
  isPanelOpen: Boolean(open),
});

const setPanelOptions = (
  state = INITIAL_STATE,
  { panelOption }: SetPanelOptionsAction
) => ({
  ...state,
  panelOption,
});

const setSelectableTreeItems = (
  state = INITIAL_STATE,
  { selectableItems }: SetSelectableTreeItemsAction
) => ({
  ...state,
  selectableTreeItems: selectableItems,
});

const setSelectedTreeItems = (
  state = INITIAL_STATE,
  { items }: SetSelectedTreeItemsActions
) => {
  if (items.length < state.selectedTreeItems.length) {
    const removedItems = difference(state.selectedTreeItems, items);
    const removedTags = state.tags.filter((tag) =>
      removedItems.includes(tag.assetUuid!)
    );

    return {
      ...state,
      metrics: state.metrics.filter(
        (metric) => !removedTags.find((tag) => tag.identifier === metric)
      ),
      tags: state.tags.filter((tag) => !removedTags.includes(tag)),
      selectedTreeItems: items,
    };
  }

  return {
    ...state,
    metrics: state.metrics,
    tags: state.tags,
    selectedTreeItems: items,
  };
};

const setTimePeriod = (
  state = INITIAL_STATE,
  { timePeriod }: SetTimePeriodAction
) => ({
  ...state,
  timePeriod,
});

const getAnalysisDataRequest = (state = INITIAL_STATE) => ({
  ...state,
  isLoading: true,
});

const getAnalysisDataSuccess = (
  state = INITIAL_STATE,
  { analysis, data }: GetAnalysisDataSuccessAction
) => ({
  ...state,
  isLoading: false,
  data: {
    [analysis]: data,
  },
});

const getAnalysisDataFailure = (
  state = INITIAL_STATE,
  { error }: GetAnalysisDataFailureAction
) => ({
  ...state,
  isLoading: false,
  error,
});

const setAnalysisPeriodResolution = (
  state = INITIAL_STATE,
  { resolution }: setAnalysisPeriodResolutionAction
) => ({
  ...state,
  periodResolution: resolution,
});

const resetAnalysisData = (
  state = INITIAL_STATE,
  { analysis }: ResetAnalysisDataAction
): DataAnalysisState => ({
  ...state,
  data: {
    ...state.data,
    [analysis]: null,
  },
});

export const HANDLERS = {
  [Types.GET_ASSET_TAGS_REQUEST]: getAssetTagsRequest,
  [Types.GET_ASSET_TAGS_SUCCESS]: getAssetTagsSuccess,
  [Types.GET_ASSET_TAGS_FAILURE]: getAssetTagsFailure,
  [Types.SET_METRICS]: setMetrics,
  [Types.SET_IS_PANEL_OPEN]: setIsPanelOpen,
  [Types.SET_PANEL_OPTIONS]: setPanelOptions,
  [Types.SET_SELECTABLE_TREE_ITEMS]: setSelectableTreeItems,
  [Types.SET_SELECTED_TREE_ITEMS]: setSelectedTreeItems,
  [Types.SET_TIME_PERIOD]: setTimePeriod,
  [Types.GET_ANALYSIS_DATA_REQUEST]: getAnalysisDataRequest,
  [Types.GET_ANALYSIS_DATA_SUCCESS]: getAnalysisDataSuccess,
  [Types.GET_ANALYSIS_DATA_FAILURE]: getAnalysisDataFailure,
  [Types.SET_ANALYSIS_PERIOD_RESOLUTION]: setAnalysisPeriodResolution,
  [Types.RESET_ANALYSIS_DATA]: resetAnalysisData,
};

const Reducer = createReducer(INITIAL_STATE, HANDLERS);

export {
  Creators as DataAnalysisCreators,
  Types as DataAnalaysisTypes,
  Reducer as DataAnalysisReducer,
};
