import React, { useState } from 'react';
import { toastr } from 'react-redux-toastr';
import { useHistory } from 'react-router-dom';

import { CircularProgress, IconButton } from '@material-ui/core';
import { ArrowBack } from '@material-ui/icons';

import { passwordReset } from '../../../../api/firebase';
import { HorizontalCollapse } from '../../../../components/Animations';
import { CustomTextField } from '../../../../components/CustomTextField';
import { GradientButton } from '../../../../components/GradientButton';
import { Spacer } from '../../../../components/Spacer';
import { InputError } from '../../../../validations/types';

import { ButtonsContainer } from '../../styles';
import { Title, ForgotPassMsg } from './styles';

export const ForgotPassword: React.FC = () => {
  const history = useHistory();

  const [email, setEmail] = useState('');

  const [error, setError] = useState<InputError>({} as InputError);

  const [isLoading, setIsLoading] = useState(false);

  function goBack() {
    history.goBack();
  }

  function handleChange(
    _: string,
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) {
    setEmail(event.target.value);
  }

  async function requestNewPassword(event: React.FormEvent<HTMLFormElement>) {
    event.preventDefault();
    if (email.length > 0) {
      setIsLoading(true);
      try {
        await passwordReset(email);
        toastr.success(
          'Verifique seu email',
          'Favor verficar seu e-mail, e realizar a alteração da senha.'
        );
        goBack();
      } catch (e) {
        toastr.error(
          'Erro',
          'Não foi possível encaminhar o e-mail, verifique que ele realmente está cadastrado.'
        );
      }
    } else
      setError({
        field: 'email',
        msg: 'Digite seu email!',
      });
  }

  return (
    <form onSubmit={requestNewPassword} data-testid="forgotPassForm">
      <Title>
        <IconButton onClick={goBack} data-testid="backButton">
          <ArrowBack fontSize="large" />
        </IconButton>
        <h1>Redefinir Senha</h1>
      </Title>
      <ForgotPassMsg>Insira seu email cadastrado</ForgotPassMsg>
      <CustomTextField
        name="email"
        label="Email"
        placeholder="example@email.com"
        handleChange={handleChange}
        type="email"
        value={email}
        error={error?.msg}
        isDisabled={isLoading}
        cleanError={() => setError({} as InputError)}
      />
      <ButtonsContainer>
        <HorizontalCollapse width={isLoading ? '20%' : '100%'}>
          <GradientButton type="submit" disabled={isLoading}>
            {isLoading ? (
              <CircularProgress color="secondary" size={25} />
            ) : (
              'enviar'
            )}
          </GradientButton>
        </HorizontalCollapse>
        <Spacer height={15} />
      </ButtonsContainer>
    </form>
  );
};
