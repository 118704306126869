import styled from 'styled-components';

import { supportedDevices } from '../../../../../../../styles/supportedDevices';

export const AssetErrorContainer = styled.div`
  color: black;

  .title {
    .error {
      display: flex;
      align-items: center;

      margin-bottom: 10px;

      & > :first-child {
        margin-right: 5px;
      }

      svg {
        color: red;
      }

      h3 {
        font-size: 14px;
      }
    }

    h1 {
      font-size: 20px;
      margin-bottom: 10px;
    }
  }

  .tags-container {
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;

    @media ${supportedDevices.tablet} {
      max-height: 80vh;
    }
  }
`;
