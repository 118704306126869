import { Accordion } from '@material-ui/core';
import styled from 'styled-components';

import { supportedDevices } from '../../../../../../../../styles/supportedDevices';

export const Container = styled.div`
  display: flex;
  flex-direction: column;

  padding: 16px;
  width: 100%;

  &.no-element {
    align-items: center;
    justify-content: center;
    color: black;

    font-size: 20px;
  }

  & .loader,
  .error {
    height: calc(100% - 160px);
  }

  & .loader {
    display: grid;
    place-items: center;
  }

  & .error {
    display: flex;
    flex-flow: column;
    align-items: center;
    justify-content: center;
    text-align: center;

    img {
      width: 150px;
      margin-bottom: 20px;
    }

    h3 {
      font-size: 24px;
      color: black;
    }
  }

  .charts {
    margin-top: 30px;

    & > div {
      width: 100%;
    }

    .bars {
      .bars-container {
        height: 500px;
        width: 100%;
      }
    }
  }

  @media ${supportedDevices.tablet} and (orientation: landscape) {
    padding: 20px 24px;
  }
`;

export const TableCell = styled.div`
  display: flex;
  justify-content: space-evenly;
  margin: 0 auto;

  .cellValue {
    display: flex;
    justify-content: center;
    align-items: center;

    min-width: 50px;
  }
`;

export const CustomAccordion = styled(Accordion)`
  && {
    border-radius: 4px;
    box-shadow: 0 0 2px 0 rgba(0, 0, 0, 0.4);
    margin: 5px 0;

    ::before {
      height: 0;
    }
  }
`;
