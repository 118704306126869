import React, { useState, useEffect, useRef } from 'react';
import InfiniteScroll from 'react-infinite-scroll-component';
import { useSelector, useDispatch } from 'react-redux';
import { useHistory, useLocation } from 'react-router-dom';

import {
  CircularProgress,
  Grow,
  ButtonBase,
  Collapse,
  Tooltip,
  TextField,
  Checkbox,
  FormGroup,
  FormControlLabel,
  withStyles,
  CheckboxProps
} from '@material-ui/core';
import {
  ArrowForward,
  ChatBubble,
  ChatBubbleOutline,
  Close,
  RotateRight,
} from '@material-ui/icons';
import { parse, subMinutes, addMinutes, isValid } from 'date-fns';

import { ResponsiveNotificationsPanelProps } from '..';

import { CustomDialog } from '../../../../../../components/CustomDialog';
import { SimpleSearchField } from '../../../../../../components/SimpleSearchField';
import { NotificationInfo } from '../../../../../../models/NotificationInfo';
import { Creators } from '../../../../../../store/actionCreators';
import { AppState } from '../../../../../../store/reducers';
import { kSunburstColors } from '../../../../../../utils/constants';
import { getAssetIdFromPath } from '../../../../../../utils/methods';

import { Aside, NotificationCard, TooltipSpan } from './styles';
import { WithStyles } from '@material-ui/styles';

const BlueCheckbox = withStyles({
  root: {
    '&$checked': {
      color: '#009ee2',
    },
  },
  checked: {},
})((props: CheckboxProps) => <Checkbox color="default" {...props} />);

export const Panel: React.FC<ResponsiveNotificationsPanelProps> = ({
  isOpen,
  fetchNotifications,
  notificationList,
  handleSearchSubmit,
  searchTextField,
}) => {
  const { notifications } = useSelector((state: AppState) => state);
  const dispatch = useDispatch();

  const history = useHistory();
  const location = useLocation();

  const codeTextField = useRef<HTMLInputElement>(null);
  const commentTextField = useRef<HTMLInputElement>(null);

  const [isFirstLoading, setIsFirstLoading] = useState(true);
  const [dismissingNotification, setDismissingNotification] = useState('');
  const [isCodeModalOpen, setIsCodeModalOpen] = useState(false);
  const [isCommentModalOpen, setIsCommentModalOpen] = useState(false);
  const [isConfirmationModalOpen, setIsConfirmationModalOpen] = useState(false);
  const [
    modalNotification,
    setModalNotification,
  ] = useState<NotificationInfo | null>(null);
  const [nonApplicable, setNonApplicable] = useState(false);

  useEffect(() => {
    if (
      !location.pathname.startsWith('/admin/dashboard/ativo') &&
      !isFirstLoading
    )
      dispatch(Creators.setCurrentNotification(null));
  }, [location, dispatch, isFirstLoading]);

  useEffect(() => {
    const assetId = getAssetIdFromPath(location.pathname);

    if (assetId !== notifications.uuid || assetId.length === 0)
      setIsFirstLoading(true);
  }, [location, notifications.uuid]);

  function getCardColor(criticality: string) {
    let color = kSunburstColors[3].rgb;
    if (criticality === 'Média') color = kSunburstColors[6].rgb;
    else if (criticality === 'Baixa') color = kSunburstColors[8].rgb;

    return `rgb(${color.join(',')})`;
  }

  function buildLoader() {
    return (
      <NotificationCard
        className="loader"
        isLast
        color="transparent"
        isOpen={isOpen}
      >
        <CircularProgress size={50} />
      </NotificationCard>
    );
  }

  function handleCardEnter() {
    setIsFirstLoading(false);
  }

  function handleCardClick(notification: NotificationInfo) {
    const dateFormat = isValid(new Date(notification.createDate))
      ? Intl.DateTimeFormat('pt-br', {
        year: 'numeric',
        month: '2-digit',
        day: '2-digit',
        hour: '2-digit',
        minute: '2-digit',
      }).format(new Date(notification.createDate))
      : isValid(new Date(`${notification.createDate.split('U')[0]}Z`))
        ? Intl.DateTimeFormat('pt-br', {
          year: 'numeric',
          month: '2-digit',
          day: '2-digit',
          hour: '2-digit',
          minute: '2-digit',
        })
          .format(new Date(`${notification.createDate.split('U')[0]}Z`))
          .toString()
        : 'Data Inválida';

    const date = parse(dateFormat, 'dd/MM/yyyy, HH:mm', new Date());

    // dispatch(
    //   Creators.setTreeHealthTimePeriod({
    //     startTime: subMinutes(date, 30).getTime(),
    //     endTime: addMinutes(date, 30).getTime(),
    //   })
    // );
    // dispatch(Creators.getTreeHealthRequest());

    dispatch(
      Creators.setAssetTimePeriod({
        startTime: subMinutes(date, 60).getTime(),
        endTime: addMinutes(date, 60).getTime(),
      })
    );

    dispatch(Creators.setCurrentNotification(notification.id));

    // TODO: use asset type instead of assetClass
    var assetType = 'ativo';
    var tagType = 'analisededados';
    if (notification.asset.assetClass === 'Malha de Controle') {
      assetType = 'ativo-logico';
    } else {
      tagType = notification.uuidTag === null ?
        'saude' : 'analisededados';
    }

    history.push(
      `/admin/dashboard/${assetType}/${notification.asset.uuid}/${tagType}`
    );
  }

  function handleModalOpen(
    modal: 'comment' | 'confirm' | 'code',
    open = false
  ) {
    if (modal === 'comment') setIsCommentModalOpen(open);
    else if (modal === 'confirm') setIsConfirmationModalOpen(open);
    else setIsCodeModalOpen(open);
  }

  function handleNotificationStatusChangeClick(
    notification: NotificationInfo,
    status: 'Analise' | 'Encerrada'
  ) {
    setModalNotification({ ...notification, status });
    handleModalOpen('confirm', true);
  }

  function onCommentConfirm() {
    if (modalNotification && commentTextField.current?.value !== undefined) {
      modalNotification.observations = commentTextField.current?.value;
      dispatch(Creators.saveNotificationRequest(modalNotification));
      handleModalOpen('comment');
    }
  }

  function onConfirmationConfirm() {
    if (modalNotification) {
      setDismissingNotification(String(modalNotification.id));
      dispatch(Creators.saveNotificationRequest({ ...modalNotification, nonApplicable }));
      handleModalOpen('confirm');
    }
  }

  function onCodeConfirm() {
    if (modalNotification && codeTextField.current?.value !== undefined) {
      modalNotification.code = codeTextField.current?.value;
      dispatch(Creators.saveNotificationRequest(modalNotification));
      handleModalOpen('code');
    }
  }

  function buildNotificationCard() {
    if (notifications.isLoading && notificationList.length === 0)
      return (
        <div className="loading-container">
          <CircularProgress size={50} />
        </div>
      );

    return (
      <div className="notifications" id="scrollableTarget">
        <InfiniteScroll
          scrollableTarget="scrollableTarget"
          dataLength={notificationList.length}
          next={fetchNotifications}
          hasMore={!notifications.pageResponse.lastPage}
          loader={buildLoader()}
        >
          {notificationList.map((item, index, array) => (
            <Collapse
              key={item.id}
              in={dismissingNotification !== String(item.id)}
            >
              <Grow
                in={dismissingNotification !== String(item.id)}
                timeout={(index + 1) * 200 < 800 ? (index + 1) * 200 : 800}
                appear={isFirstLoading}
                exit
                onEntered={handleCardEnter}
                unmountOnExit
              >
                <NotificationCard
                  isLast={index === array.length - 1}
                  color={getCardColor(item.criticality)}
                  isOpen={isOpen}
                  isCurrent={item.id === notifications.currentNotification}
                >
                  <div className="container">
                    <div className="sub-container">
                      <div className="actions-container">
                        <p>{`${item.asset.unit} | ${item.asset.plant} | ${item.asset.area} | ${item.asset.subarea}`}</p>
                      </div>
                      <h4>{`${item.asset.equipment} | ${item.asset.name}`}</h4>
                      <p className="description">{item.description}</p>
                      <div className="arrow-info-container">
                        <div className="info-container">
                          <p>
                            {`Primeira ocorrência: ${isValid(new Date(item.createDate))
                              ? Intl.DateTimeFormat('pt-br', {
                                year: 'numeric',
                                month: '2-digit',
                                day: '2-digit',
                                hour: '2-digit',
                                minute: '2-digit',
                              }).format(new Date(item.createDate))
                              : isValid(
                                new Date(
                                  `${item.createDate.split('U')[0]}Z`
                                )
                              )
                                ? Intl.DateTimeFormat('pt-br', {
                                  year: 'numeric',
                                  month: '2-digit',
                                  day: '2-digit',
                                  hour: '2-digit',
                                  minute: '2-digit',
                                })
                                  .format(
                                    new Date(
                                      `${item.createDate.split('U')[0]}Z`
                                    )
                                  )
                                  .toString()
                                : 'Data Inválida'
                              }`}
                          </p>
                          <p>
                            {`Útima ocorrência: ${isValid(new Date(item.lastOccurrence))
                              ? Intl.DateTimeFormat('pt-br', {
                                year: 'numeric',
                                month: '2-digit',
                                day: '2-digit',
                                hour: '2-digit',
                                minute: '2-digit',
                              }).format(new Date(item.lastOccurrence))
                              : isValid(
                                new Date(
                                  `${item.lastOccurrence.split('U')[0]}Z`
                                )
                              )
                                ? Intl.DateTimeFormat('pt-br', {
                                  year: 'numeric',
                                  month: '2-digit',
                                  day: '2-digit',
                                  hour: '2-digit',
                                  minute: '2-digit',
                                })
                                  .format(
                                    new Date(
                                      `${item.lastOccurrence.split('U')[0]}Z`
                                    )
                                  )
                                  .toString()
                                : 'Data Inválida'
                              }`}
                          </p>
                        </div>
                      </div>
                    </div>
                    <div className="button-container">
                      <div className="actions">
                        <Tooltip title={<TooltipSpan>Finalizada</TooltipSpan>}>
                          <ButtonBase
                            onClick={() =>
                              handleNotificationStatusChangeClick(
                                item,
                                'Encerrada'
                              )
                            }
                          >
                            <Close />
                          </ButtonBase>
                        </Tooltip>
                        <Tooltip title={<TooltipSpan>Em analise</TooltipSpan>}>
                          <ButtonBase
                            onClick={() =>
                              handleNotificationStatusChangeClick(
                                item,
                                'Analise'
                              )
                            }
                          >
                            <RotateRight />
                          </ButtonBase>
                        </Tooltip>
                        <Tooltip title={<TooltipSpan>Observações</TooltipSpan>}>
                          <ButtonBase
                            onClick={() => {
                              setModalNotification(item);
                              handleModalOpen('comment', true);
                            }}
                          >
                            {item.observations ? (
                              <ChatBubble className="commentIcon" />
                            ) : (
                              <ChatBubbleOutline className="commentIcon" />
                            )}
                          </ButtonBase>
                        </Tooltip>
                      </div>
                      <div className="arrow">
                        <Tooltip
                          title={<TooltipSpan>Mais Detalhes</TooltipSpan>}
                        >
                          <ButtonBase
                            onClick={() => handleCardClick(item)}
                            className="arrow-container"
                          >
                            <ArrowForward />
                          </ButtonBase>
                        </Tooltip>
                      </div>
                    </div>
                  </div>
                </NotificationCard>
              </Grow>
            </Collapse>
          ))}
        </InfiniteScroll>
      </div>
    );
  }

  return (
    <>
      <Aside isOpen={isOpen}>
        <>
          <div className="search-container">
            <Collapse in={notifications.searchAbility}>
              <SimpleSearchField
                handleSearchSubmit={handleSearchSubmit}
                searchTextField={searchTextField}
              />
            </Collapse>
          </div>
          {!notifications.isLoading && notificationList.length === 0 ? (
            <h4>Nenhuma notificação encontrada</h4>
          ) : (
            buildNotificationCard()
          )}
        </>
      </Aside>

      <CustomDialog
        open={isCommentModalOpen}
        title="Observações"
        width={500}
        body={
          <TextField
            inputRef={commentTextField}
            placeholder="Adicione observações"
            multiline
            rows={4}
            rowsMax={8}
            defaultValue={modalNotification?.observations || undefined}
            variant="outlined"
            fullWidth
          />
        }
        action={{
          label: 'salvar',
          onClick: onCommentConfirm,
        }}
        onClose={() => handleModalOpen('comment')}
      />

      <CustomDialog
        open={isCodeModalOpen}
        title="Observações"
        width={500}
        body={
          <TextField
            inputRef={codeTextField}
            placeholder="Adicione observações"
            multiline
            rows={4}
            rowsMax={8}
            defaultValue={modalNotification?.observations || undefined}
            variant="outlined"
            fullWidth
          />
        }
        action={{
          label: 'salvar',
          onClick: onCodeConfirm,
        }}
        onClose={() => handleModalOpen('code')}
      />

      <CustomDialog
        open={isConfirmationModalOpen}
        title={`Alterar estado da notificação para "${modalNotification?.status === 'Analise' ? 'Em Análise' : 'Encerrada'
          }"`}
        body={
          <div>
            <p>Você tem certeza que deseja alterar o estado da notificação?</p>
            <FormGroup>
              <FormControlLabel
                control={
                  <BlueCheckbox checked={nonApplicable} onChange={(event) => setNonApplicable(event.target.checked)} />
                }
                label="Notificação Não Aplicável"
              />
            </FormGroup>
          </div>
        }
        action={{
          label: 'confirmar',
          onClick: onConfirmationConfirm,
        }}
        onClose={() => handleModalOpen('confirm')}
      />
    </>
  );
};
