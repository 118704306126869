import { put } from 'redux-saga/effects';

import config from '../../config';
import { Creators } from '../actionCreators';

export function* getCostCenters(action) {
  try {
    const centers = yield config.api.getCostCenters();
    yield put(Creators.getCostCentersSuccess(centers.data));
  } catch (error) {
    yield put(
      Creators.getCostCentersFailure('Ocorreu um erro ao buscar os registros!')
    );
  }
}
