import { FormControl } from '@material-ui/core';
import styled from 'styled-components';

import { supportedDevices } from '../../../../styles/supportedDevices';

export const Container = styled.div`
  overflow-x: auto;

  .row {
    transition: background-color 0.2s;

    &.grey-row {
      background-color: rgb(242, 242, 242);
    }

    &:hover {
      background-color: ${({ theme }) => theme.palette.background.paper};
    }
  }

  .header-cell {
    background: ${({ theme }) => theme.palette.background.paper};
    border: 2px solid white;
    font-size: 1.6rem;
  }

  .body-cell {
    border: 0;
    font-size: 1.6rem;
    padding: 0px 0px;

    &.clickable {
      cursor: pointer;
    }
  }

  .table-menu {
    display: flex;
    align-items: center;
    flex-wrap: wrap;

    min-height: 64px;
    margin: 10px 0;

    .rows-per-page-container {
      display: flex;
      align-items: center;
      flex-wrap: wrap;

      min-width: 100%;

      h6 {
        font-weight: normal;

        margin-right: 10px;
        font-size: 14px;
      }

      & > :last-child {
        margin-right: 0;
        margin-top: 5px;
        width: 100%;
      }

      .MuiSelect-root {
        padding: 12px 14px;
      }

      svg {
        top: unset;
      }
    }

    .pages-container {
      margin-top: 10px;

      button {
        background-color: rgba(230, 232, 235, 0.5);

        margin: 0;
        height: 36px;
        width: 36px;
        padding: unset;

        transition: background-color 0.2s;

        &.Mui-selected,
        &:hover {
          background-color: rgb(230, 232, 235);
        }
      }
    }
  }

  @media ${supportedDevices.tablet} {
    .table-menu {
      .rows-per-page-container {
        min-width: 150px;

        & > :last-child {
          margin-right: 30px;
          margin-top: 0;
          width: 75px;
        }

        .MuiSelect-root {
          padding: 14px;
        }
      }

      .pages-container {
        margin: 0;

        button {
          height: 48px;
          width: 48px;
          padding: 20px;
        }
      }
    }
  }
`;

export const CodeContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;

  p {
    margin-right: 5px;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
    max-width: 65px;
  }
`;

interface CriticalTagProps {
  color: string;
}

export const CriticalContainer = styled.div<CriticalTagProps>`
  display: flex;
  align-items: center;
  justify-content: center;

  .critical-tag {
    background: ${({ color }) => color};
    width: 25px;
    height: 4px;
    border-radius: 4px;
    margin-right: 5px;
  }
`;

export const StatusSelectContainer = styled(FormControl)`
  width: 110px;

  .MuiSelect-root {
    font-size: 1.2rem;
    padding: 14px;
    border: 1px solid white;
  }

  svg {
    top: unset;
  }
`;
