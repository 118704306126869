import React from 'react';
import { useSelector } from 'react-redux';
import { RouteComponentProps, Switch, Route, Redirect } from 'react-router-dom';

import { AppState } from '../../../../../store/reducers';
import { Screen404 } from '../../../../404';
import { HealthPeriodComparison } from './components/menus/HealthPeriodComparison';
import { IndicatorsPerElementLogical } from './components/menus/IndicatorsPerLogicalElement';
import { IndicatorsPerElementPhysical } from './components/menus/IndicatorsPerPhysicalElement';
import { IndicatorsPerPeriod } from './components/menus/IndicatorsPerPeriod';
import { PhysicalAvailability } from './components/menus/PhysicalAvailability';
import { useAuthStore } from '../../../../../zustand/AuthStore';
import { PhysicalIndicatorsPerPeriod } from './components/menus/PhysicalIndicatorsPerPeriod';

export const Router: React.FC<RouteComponentProps> = ({ match }) => {
  // const { auth } = useSelector((state: AppState) => state);

  const company = useAuthStore((state) => state.user.company);

  return (
    <Switch>
      <Route
        path={`${match.path}/porelementofisico`}
        component={IndicatorsPerElementPhysical}
      />
       <Route
        path={`${match.path}/porelementologico`}
        component={IndicatorsPerElementLogical}
      />
      <Route
        path={`${match.path}/porperiodo`}
        component={IndicatorsPerPeriod}
      />
      <Route
        path={`${match.path}/fisicosporperiodo`}
        component={PhysicalIndicatorsPerPeriod}
      />
      <Route
        path={`${match.path}/disponibilidadefisica`}
        component={PhysicalAvailability}
      />
      <Route
        path={`${match.path}/comparacaodesaude`}
        component={HealthPeriodComparison}
      />
      <Redirect
        exact
        from={`${match.path}`}
        to={{
         pathname: company === 'Gerdau' || company === 'CMOC'
            ? `${match.path}/comparacaodesaude`
            : `${match.path}/comparacaodesaude`,
          state: { isRedirect: true }
        }}
      />
      <Route path={`${match.path}/404`} component={Screen404} />
      <Redirect from={`${match.path}/*`} to="/404" />
    </Switch>
  );
};
