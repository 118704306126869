import { create } from 'zustand';
import config from '../config';
import { toastr } from 'react-redux-toastr';

type CompanyAlertInfo = {
  emailsList: string[];
  minimumAvailability: number | null;
};

type AlertState = {
  companyAlertInfo: CompanyAlertInfo;
  isLoading: boolean;
  error: string | null;
  fetchCompanyAlertInfo: () => Promise<void>;
  setSelectedAlertEmails: (emails: string[], availability: number) => Promise<void>;
};

export const useAlertStore = create<AlertState>((set) => ({
  companyAlertInfo: {
    emailsList: [],
    minimumAvailability: null,
  },
  isLoading: false,
  error: null,

  fetchCompanyAlertInfo: async () => {
    set({ isLoading: true, error: null });

    try {
      const response = await config.api.getCompanyAlertInfo();
      const data = response.data;

      set({
        companyAlertInfo: {
          emailsList: data[0] || [],
          minimumAvailability: data[1] || null,
        },
        isLoading: false,
        error: null,
      });
    } catch (error) {
      set({
        isLoading: false,
        error: 'Ocorreu um erro ao obter as informações de alerta.',
      });
      toastr.error('Erro', 'Ocorreu um erro ao obter as informações de alerta.');
    }
  },

  setSelectedAlertEmails: async (emails: string[], availability: number) => {
    try {
      await config.api.setSelectedAlertEmails(emails, availability);
    } catch (error) {
      toastr.error('Erro', 'Ocorreu um erro ao configurar os emails.');
    }
  },
}));
