import { Accordion } from '@material-ui/core';
import styled from 'styled-components';

import { supportedDevices } from '../../../../../../../../styles/supportedDevices';

export const Container = styled.div`
  color: black;
  display: flex;
  flex-direction: column;

  padding: 16px;
  width: 100%;

  &.no-element {
    align-items: center;
    justify-content: center;

    font-size: 20px;
  }

  & .loader,
  .error {
    height: calc(100% - 160px);
  }

  & .loader {
    display: grid;
    place-items: center;
  }

  & .error {
    display: flex;
    flex-flow: column;
    align-items: center;
    justify-content: center;
    text-align: center;

    img {
      width: 150px;
      margin-bottom: 20px;
    }

    h3 {
      font-size: 24px;
      color: black;
    }
  }

  .charts {
    margin-top: 30px;

    & > div {
      width: 100%;
    }

    .table {
      .obs-icons {
        display: flex;
        justify-content: space-around;

        .MuiBadge-badge {
          background-color: #01a0c6;
          color: white;

          height: 15px;
        }
      }
    }

    .pareto {
      .pareto-container {
        height: 500px;
        width: 100%;
      }
    }
  }

  @media ${supportedDevices.tablet} and (orientation: landscape) {
    padding: 20px 24px;
  }
`;

export const CustomAccordion = styled(Accordion)`
  && {
    border-radius: 4px;
    box-shadow: 0 0 2px 0 rgba(0, 0, 0, 0.4);
    margin: 5px 0;

    ::before {
      height: 0;
    }
  }
`;

export const ParetoTooltip = styled.div`
  padding: 10px;

  & > * {
    margin-bottom: 3px;
  }

  h3.title {
    text-transform: uppercase;
    font-size: 14px;
  }

  .topic-data-container {
    display: flex;
    align-items: center;

    font-size: 12px;

    .topic {
      text-transform: capitalize;
      font-weight: 600;

      margin-right: 5px;
    }
  }
`;

export const TooltipSpan = styled.span`
  font-size: 12px;
  line-height: 1.5rem;
`;
