import { Menu } from '@material-ui/core';
import styled from 'styled-components';

import { kDesktopAppBarHeight } from '../../../utils/constants';

export const Toolbar = styled.div`
  min-height: ${kDesktopAppBarHeight}px;
  display: grid;
  grid-template-columns: 20% 14% 56% 10%;
  align-items: center;
`;

export const LogoContainer = styled.div`
  display: flex;
  justify-content: center;

  img {
    height: 30px;
  }
`;

export const ToggleButtonsContainer = styled.div`
  display: flex;
  justify-content: space-around;
`;

export const NavBar = styled.nav`
  display: flex;
  justify-content: flex-start;

  ul {
    display: flex;
    list-style-type: none;

    li {
      margin: 0 20px;
      display: flex;
      align-items: center;
    }

    Button {
      color: rgba(241, 243, 246, 0.6);
      font-size: 14px;
      font-weight: bold;

      &.active {
        color: #fff;
      }
    }
  }
`;

export const MenuContainer = styled.div``;

export const CustomMenu = styled(Menu)`
  & .MuiPaper-root {
    background-color: white;

    & .MuiMenuItem-root {
      font-size: 14px;
      font-weight: 600;
    }
  }
`;
