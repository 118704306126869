import React, { useState, MutableRefObject, useRef, useEffect } from 'react';

import { Button, ButtonBase } from '@material-ui/core';
import {
  eachYearOfInterval,
  addYears,
  isFuture,
  setYear,
  getQuarter,
  startOfQuarter,
  setQuarter,
  endOfQuarter,
  addQuarters,
  startOfYear,
  endOfYear,
} from 'date-fns';

import { Container, BodyContainer, PickerContainer } from './styles';

type Period = 'trimester' | 'semester' | 'year';

export interface LargePeriodPickerProps {
  /**
   * Selected period
   */
  period?: Period;

  /**
   * On cancel press callback
   */
  onCancelPress?: () => void;

  /**
   * On apply press callback
   */
  onApplyPress: (startDate: Date, endDate: Date) => void;

  /**
   * Initial date
   */
  initialDate?: Date;
}

/**
 * Large period such as Trimester, semester or year picker
 */
export const LargePeriodPicker: React.FC<LargePeriodPickerProps> = ({
  period = 'year',
  onCancelPress = () => {},
  onApplyPress,
  initialDate = new Date(),
}) => {
  function generateYearList() {
    return eachYearOfInterval({
      start: new Date('01/01/2019'),
      end: addYears(new Date(), 5),
    }).map((date) => [String(date.getFullYear()), String(date.getFullYear())]);
  }

  // Generates periods list base on selected period
  let periodList: string[][] = [];
  if (period === 'year') periodList = generateYearList();
  else if (period === 'semester')
    periodList = [
      ['1', 'Pimeiro semestre'],
      ['2', 'Segundo semestre'],
    ];
  else if (period === 'trimester')
    periodList = [
      ['1', 'Pimeiro trimestre'],
      ['2', 'Segundo trimestre'],
      ['3', 'Terceiro trimestre'],
      ['4', 'Quarto trimestre'],
    ];

  const yearRefs = generateYearList().reduce((prev, [val]) => {
    prev[val] = React.createRef();
    return prev;
  }, {} as { [k: string]: MutableRefObject<HTMLButtonElement | null> });

  const refs = periodList.reduce((prev, [val]) => {
    prev[val] = React.createRef();
    return prev;
  }, {} as { [k: string]: MutableRefObject<HTMLButtonElement | null> });
  const listContainerRef = useRef<HTMLDivElement | null>(null);

  const [selectedDate, setSelectedDate] = useState(initialDate);
  // User only for trimester/semester to change year
  const [selectedMode, setSelectedMode] = useState<'period' | 'year'>('period');

  useEffect(() => {
    if (
      listContainerRef.current &&
      yearRefs[String(selectedDate.getFullYear())].current &&
      yearRefs[String(selectedDate.getFullYear())].current?.scrollIntoView
    )
      yearRefs[String(selectedDate.getFullYear())].current!.scrollIntoView({
        behavior: 'smooth',
        block: 'center',
      });
  }, [listContainerRef, yearRefs, selectedDate]);

  function getSemester(date: Date) {
    return Number(getQuarter(date) > 2) + 1;
  }

  function onPeriodClick(newPeriod: string, periodType: string) {
    if (periodType === 'year')
      setSelectedDate(setYear(setQuarter(selectedDate, 1), Number(newPeriod)));
    else if (periodType === 'trimester')
      setSelectedDate(
        startOfQuarter(setQuarter(selectedDate, Number(newPeriod)))
      );
    else if (periodType === 'semester')
      setSelectedDate(
        startOfQuarter(
          setQuarter(selectedDate, Number(newPeriod) === 1 ? 1 : 3)
        )
      );
  }

  function buildPeriodItemClasses(
    currPeriod: string,
    value: number,
    initialClass: string
  ) {
    let itemClass: string = initialClass;
    if (initialClass === 'year') {
      if (String(selectedDate.getFullYear()) === currPeriod)
        itemClass += ' selected';
      if (isFuture(setYear(new Date(), Number(currPeriod))))
        itemClass += ' future';
    } else if (initialClass === 'trimester') {
      if (getQuarter(selectedDate) === value) itemClass += ' selected';
      if (
        getQuarter(new Date()) < value &&
        selectedDate.getFullYear() === new Date().getFullYear()
      )
        itemClass += ' future';
    } else if (initialClass === 'semester') {
      if (getSemester(selectedDate) === value) itemClass += ' selected';
      if (
        getSemester(new Date()) < value &&
        selectedDate.getFullYear() === new Date().getFullYear()
      )
        itemClass += ' future';
    }

    return itemClass;
  }

  function handleApplyClick() {
    if (period === 'semester')
      onApplyPress(
        startOfQuarter(selectedDate),
        endOfQuarter(addQuarters(selectedDate, 1))
      );
    else if (period === 'trimester')
      onApplyPress(startOfQuarter(selectedDate), endOfQuarter(selectedDate));
    else if (period === 'year')
      onApplyPress(startOfYear(selectedDate), endOfYear(selectedDate));
  }

  function buildPickerList(periods: string[][]) {
    return (
      <BodyContainer ref={listContainerRef} data-testid={`${period}Container`}>
        {periods.map(([value, label]) => {
          const classes = buildPeriodItemClasses(label, Number(value), period);
          return (
            <ButtonBase
              ref={refs[value]!}
              key={label}
              onClick={
                classes.includes('future')
                  ? undefined
                  : () => onPeriodClick(value, period)
              }
              className={classes}
            >
              {label}
            </ButtonBase>
          );
        })}
      </BodyContainer>
    );
  }

  function buildPickerHeader() {
    return (
      <PickerContainer
        className={period === 'year' ? 'center' : ''}
        data-testid={`${period}Header`}
      >
        {period === 'year' ? (
          <h3>{selectedDate.getFullYear()}</h3>
        ) : (
          <>
            <ButtonBase
              data-testid="yearModeButton"
              className={selectedMode === 'year' ? '' : 'inactive'}
              onClick={() => setSelectedMode('year')}
            >
              <h6>{selectedDate.getFullYear()}</h6>
            </ButtonBase>
            <ButtonBase
              data-testid="periodModeButton"
              className={selectedMode === 'period' ? '' : 'inactive'}
              onClick={() => setSelectedMode('period')}
            >
              <h4>
                {
                  periodList[
                    (period === 'semester'
                      ? getSemester(selectedDate)
                      : getQuarter(selectedDate)) - 1
                  ][1]
                }
              </h4>
            </ButtonBase>
          </>
        )}
      </PickerContainer>
    );
  }

  function buildYearList() {
    return (
      <BodyContainer ref={listContainerRef} data-testid="yearContainer">
        {generateYearList().map(([value, label]) => {
          const classes = buildPeriodItemClasses(label, Number(value), 'year');

          buildPeriodItemClasses(label, Number(value), 'year');

          return (
            <ButtonBase
              ref={yearRefs[value]!}
              key={label}
              onClick={
                classes.includes('future')
                  ? undefined
                  : () => onPeriodClick(value, 'year')
              }
              className={classes}
            >
              {label}
            </ButtonBase>
          );
        })}
      </BodyContainer>
    );
  }

  return (
    <Container data-testid="container">
      <div className="date-picker-container" data-testid="datePicker">
        {buildPickerHeader()}
        {selectedMode === 'period' && period !== 'year'
          ? buildPickerList(periodList)
          : buildYearList()}
      </div>
      <div className="picker-actions" data-testid="actionsContainer">
        <Button
          className="action"
          data-testid="cancelButton"
          onClick={onCancelPress}
        >
          cancelar
        </Button>
        <Button
          className="action gradient"
          data-testid="applyButton"
          onClick={handleApplyClick}
        >
          aplicar
        </Button>
      </div>
    </Container>
  );
};
