import React, { ChangeEvent } from 'react';

import { Grid, IconButton, Select, MenuItem } from '@material-ui/core';
import ChevronLeft from '@material-ui/icons/ChevronLeft';
import ChevronRight from '@material-ui/icons/ChevronRight';
import {
  setMonth,
  getMonth,
  setYear,
  getYear,
  eachYearOfInterval,
} from 'date-fns';

import { IconContainer } from './styles';

interface HeaderProps {
  date: Date;
  setDate: (date: Date) => void;
  nextDisabled: boolean;
  prevDisabled: boolean;
  onClickNext: () => void;
  onClickPrevious: () => void;
  months: string[];
  minDate: Date;
  maxDate: Date;
}

const Header: React.FunctionComponent<HeaderProps> = ({
  date,
  setDate,
  nextDisabled,
  prevDisabled,
  onClickNext,
  onClickPrevious,
  months,
  minDate,
  maxDate,
}) => {
  const handleMonthChange = (
    event: ChangeEvent<{ name?: string | undefined; value: unknown }>
  ) => {
    setDate(setMonth(date, parseInt(String(event.target.value), 10)));
  };

  const handleYearChange = (
    event: ChangeEvent<{ name?: string | undefined; value: unknown }>
  ) => {
    setDate(setYear(date, parseInt(String(event.target.value), 10)));
  };

  return (
    <Grid container justify="space-between" alignItems="center">
      <IconContainer item>
        <IconButton disabled={prevDisabled} onClick={onClickPrevious}>
          <ChevronLeft color={prevDisabled ? 'disabled' : 'action'} />
        </IconButton>
      </IconContainer>
      <Grid item>
        <Select
          value={getMonth(date)}
          onChange={handleMonthChange}
          MenuProps={{ disablePortal: true }}
        >
          {months.map((month, idx) => (
            <MenuItem key={month} value={idx}>
              {month}
            </MenuItem>
          ))}
        </Select>
      </Grid>

      <Grid item>
        <Select
          value={getYear(date)}
          onChange={handleYearChange}
          MenuProps={{ disablePortal: true }}
        >
          {eachYearOfInterval({
            start: minDate,
            end: maxDate,
          }).map((year) => (
            <MenuItem key={getYear(year)} value={getYear(year)}>
              {getYear(year)}
            </MenuItem>
          ))}
        </Select>
      </Grid>
      <IconContainer item>
        <IconButton disabled={nextDisabled} onClick={onClickNext}>
          <ChevronRight color={nextDisabled ? 'disabled' : 'action'} />
        </IconButton>
      </IconContainer>
    </Grid>
  );
};

export default Header;
