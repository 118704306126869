import { createGlobalStyle } from 'styled-components';

import { supportedDevices } from './supportedDevices';
import { theme } from './theme';

export const GlobalStyle = createGlobalStyle`
  *,
  *::after,
  *::before {
    margin: 0;
    padding: 0;
    box-sizing: inherit;
  }

  html,
  body,
  #root {
    height: 100%;
    width: 100%;
    padding: 0;
    margin: 0;
  }

  html {
    font-size: 62.5%;

    @media (max-width: 56.25em) {
      font-size: 50%;
    }

    @media (max-width: 75em) {
      font-size: 56.25%;
    }

    @media (min-width: 112.5em) {
      font-size: 75%;
    }
  }

  body {
    font-family: 'Roboto', sans-serif !important;
    font-weight: 400;
    line-height: 1.7;
    color: #e8e8e8;

    box-sizing: border-box;

    a {
      text-decoration: none;
    }

    @media (max-width: 56.25em) {
      padding: 0;
    }
  }

  * {
    margin: 0;
    padding: 0;
  }

  *,
  *::before,
  *::after {
    box-sizing: inherit;
  }

  html {
    box-sizing: border-box;
    font-size: 62.5%;

    @media only screen and (max-width: 68.75em) {
      font-size: 50%;
    }
  }

  @media ${supportedDevices.laptop} {
    /* Scrollbar style */
    & ::-webkit-scrollbar {
      width: 10px;
    }

    & ::-webkit-scrollbar-thumb {
      background: ${() => theme.palette.secondary.dark};
    }
    & ::-webkit-scrollbar-thumb:hover {
      background: ${() => theme.palette.background.default};
    }
  }
`;
