import { ButtonBase } from '@material-ui/core';
import styled, { DefaultTheme } from 'styled-components';

interface BaseButtonProps {
  isSelected: boolean;
  theme: DefaultTheme;
}

export const BaseButton = styled(ButtonBase).withConfig({
  shouldForwardProp: (prop) => !['isSelected'].includes(prop),
})<BaseButtonProps>`
  width: fit-content;

  .MuiTouchRipple-root {
    color: ${({ isSelected, theme }) =>
      isSelected ? theme.palette.primary.main : theme.palette.secondary.main};
  }
`;
