import React from 'react';

import { InputAdornment, IconButton } from '@material-ui/core';
import { Search, ArrowForward } from '@material-ui/icons';

import { CustomTextField } from './styles';

interface SimpleSearchFieldProps {
  searchTextField: React.RefObject<HTMLInputElement>;
  handleSearchSubmit(): void;
}

export const SimpleSearchField: React.FC<SimpleSearchFieldProps> = ({
  searchTextField,
  handleSearchSubmit,
}) => {
  return (
    <CustomTextField
      inputRef={searchTextField}
      className="search"
      variant="outlined"
      placeholder="Procurar notificações"
      onKeyDown={(e) => {
        if (e.keyCode === 13) handleSearchSubmit();
      }}
      InputProps={{
        className: 'search-input',
        startAdornment: (
          <InputAdornment position="start">
            <Search />
          </InputAdornment>
        ),
        endAdornment: (
          <InputAdornment position="end">
            <IconButton
              onClick={handleSearchSubmit}
              className="search-icon-button"
            >
              <ArrowForward />
            </IconButton>
          </InputAdornment>
        ),
      }}
    />
  );
};
