import React, { useState } from 'react';

import { Button } from '@material-ui/core';
import { DatePicker } from '@material-ui/pickers';
import { MaterialUiPickersDate } from '@material-ui/pickers/typings/date';
import { addYears, startOfMonth, endOfMonth } from 'date-fns';

import { Container } from './styles';

export interface MonthPickerProps {
  /**
   * On cancel press callback
   */
  onCancelPress?: () => void;

  /**
   * On apply press callback
   */
  onApplyPress(startDate: Date, endDate: Date): void;

  /**
   * initial passed date
   */
  initialDate?: Date;
}

/**
 * Date Picker for months
 */
export const MonthPicker: React.FC<MonthPickerProps> = ({
  onCancelPress = () => {},
  onApplyPress,
  initialDate = new Date(),
}) => {
  const [selectedDate, setSelectedDate] = useState(initialDate);

  function handleMonthChange(newDate: MaterialUiPickersDate) {
    setSelectedDate(newDate!);
  }

  return (
    <Container data-testid="container">
      <DatePicker
        views={['year', 'month']}
        openTo="month"
        label="Month Picker"
        value={selectedDate}
        onChange={handleMonthChange}
        okLabel="aplicar"
        cancelLabel="cancelar"
        disableFuture
        variant="static"
        maxDate={addYears(new Date(), 5)}
        minDate={new Date('01/01/2019')}
      />
      <div className="picker-actions" data-testid="actionsContainer">
        <Button
          className="action"
          data-testid="cancelButton"
          onClick={onCancelPress}
        >
          cancelar
        </Button>
        <Button
          className="action gradient"
          data-testid="applyButton"
          onClick={() =>
            onApplyPress(startOfMonth(selectedDate), endOfMonth(selectedDate))
          }
        >
          aplicar
        </Button>
      </div>
    </Container>
  );
};
