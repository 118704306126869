/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from 'react';
import Media from 'react-media';
import { useSelector } from 'react-redux';

import { IconButton, Collapse } from '@material-ui/core';
import { Visibility, VisibilityOff, ArrowDropDown } from '@material-ui/icons';

import { SearchField } from '../../../../../../../../components/SearchField';
import { TagInfo } from '../../../../../../../../models/TagInfo';
import { AppState } from '../../../../../../../../store/reducers';
import { kSunburstColors } from '../../../../../../../../utils/constants';
import { TagContainer } from '../../../components/TagContainer';

import { supportedDevices } from '../../../../../../../../styles/supportedDevices';
import {
  ToggleButton,
  CircleIndicator,
  CategoriesContainer,
  HeaderContainer,
  ArrowContainer,
} from './styles';

interface Columns {
  [key: string]: TagInfo[];
}

interface TagsProps {
  selectedTags: string[];
  handleSelectTag(tag: string): void;
}

export const Tags: React.FC<TagsProps> = ({
  selectedTags,
  handleSelectTag,
}) => {
  const { assetData, tags } = useSelector((state: AppState) => state);

  const [showDescription, setShowDescription] = useState(false);
  const [columns, setColumns] = useState<Columns>({} as Columns);
  const [selectedTag, setSelectedTag] = useState<string | null>(null);
  const [openIndicators, setOpenIndicators] = useState<number[]>([]);

  //  Creates an object for each category
  let healthyIndicators: { [key: string]: boolean } = {};
  Object.keys(assetData.data.healthyIndicators).forEach((key) => {
    healthyIndicators = {
      ...healthyIndicators,
      ...{ [key]: assetData.data.healthyIndicators[key] },
    };
  });
  healthyIndicators = { ...healthyIndicators, Monitoramento: true };

  //  Adds each tag to its category
  useEffect(() => {
    const newColumns: Columns = {};
    const categories = Object.keys(healthyIndicators);
    categories.forEach((item) => {
      newColumns[item] = [];
    });

    const tagsData = tags.data.sort((a, b) =>
      (a.unhealthy ? a.unhealthy.length : 0) >
      (b.unhealthy ? b.unhealthy.length : 0)
        ? -1
        : 1
    );

    tagsData.forEach((tag) => {
      if (
        !Object.keys(tag.indicators.hasKPI).find(
          (indicator) => tag.indicators.hasKPI[indicator]
        )
      )
        newColumns[categories[6]].push(tag);
      else {
        if (tag.indicators.hasKPI.co) newColumns[categories[0]].push(tag); // Comunicação
        if (tag.indicators.hasKPI.pa) newColumns[categories[1]].push(tag); // Disponibilidade Física
        if (tag.indicators.hasKPI.tv) newColumns[categories[2]].push(tag); // Congelamento
        if (tag.indicators.hasKPI.ol) newColumns[categories[3]].push(tag); // Fora dos Limites
        if (tag.indicators.hasKPI.pf) newColumns[categories[4]].push(tag); // Performace
        if (tag.indicators.hasKPI.sq) newColumns[categories[5]].push(tag); // Qualidade
        if (tag.indicators.hasKPI.informativo) newColumns[categories[6]].push(tag); // Monitoramento
      }
    });

    setColumns(newColumns);
  }, []);

  function toggleDescription() {
    setShowDescription(!showDescription);
  }

  function handleSearchChange(_: any, tag: string | null) {
    if (tag) setSelectedTag(tag);
    else setSelectedTag(null);
  }

  function handleOpenIndicators(indicator: number) {
    const index = openIndicators.indexOf(indicator);
    if (index === -1) setOpenIndicators([...openIndicators, indicator]);
    else setOpenIndicators(openIndicators.filter((_, i) => i !== index));
  }

  function getCategoryColor(category: boolean | null) {
    if (category === null) return 'grey';
    return category ? 'green' : 'red';
  }

  function getTagColor(
    tag: TagInfo,
    category: { name: string; value: boolean | null }
  ) {
    if (getCategoryColor(category.value) === 'grey') return 'grey';
    if (
      tag.unhealthy &&
      tag.unhealthy.length !== 0 &&
      tag.unhealthy.includes(category.name)
    )
      if (
        tag.unhealthyMotive &&
        tag.unhealthyMotive.toLowerCase() === 'sem dados'
      )
        return `rgb(${kSunburstColors[11].rgb.join(
          ','
        )})`;
      else return 'red';
    return 'green';
  }

  return (
    <Media
      queries={{
        tablet: supportedDevices.tablet,
      }}
    >
      {(matches) => (
        <>
          <HeaderContainer>
            <div className="info-container">
              <h3>Indicadores</h3>
              <p>Clique para selecionar as TAGs</p>
            </div>
            <div className="search-and-details-container">
              <div className="search-container">
                <SearchField
                  options={tags.data.map((tag) => tag.tag)}
                  getOptionLabel={(tag) => tag}
                  onChange={handleSearchChange}
                  placeholder="Procurar tags"
                  showSearchIcon
                  freeSolo
                />
              </div>
              <ToggleButton
                isSelected={showDescription}
                onClick={toggleDescription}
              >
                {showDescription ? <VisibilityOff /> : <Visibility />}
                {showDescription ? 'Ocultar Detalhes' : 'Exibir Detalhes'}
              </ToggleButton>
            </div>
          </HeaderContainer>
          <CategoriesContainer>
            {Object.keys(columns)
              .filter((key) => columns[key].length !== 0)
              .map((key, index) => (
                <div key={key} className="category-column">
                  <div className="category-header">
                    <div className="title-container">
                      <h4>{key}</h4>
                      {key !== 'Monitoramento' && (
                        <CircleIndicator
                          color={getCategoryColor(healthyIndicators[key])}
                        />
                      )}
                    </div>
                    {!matches.tablet && (
                      <ArrowContainer isOpen={openIndicators.includes(index)}>
                        <IconButton onClick={() => handleOpenIndicators(index)}>
                          <ArrowDropDown />
                        </IconButton>
                      </ArrowContainer>
                    )}
                  </div>
                  <Collapse
                    in={openIndicators.includes(index) || matches.tablet}
                    unmountOnExit
                    mountOnEnter
                  >
                    <div className="tags-container">
                      {columns[key]
                        .filter((tag) =>
                          selectedTag ? tag.tag.includes(selectedTag) : true
                        )
                        .map((tag) => (
                          <TagContainer
                            key={tag.identifier}
                            tag={tag}
                            isDescriptionShown={showDescription}
                            isSelected={selectedTags.includes(tag.tag)}
                            handleSelectTag={() => handleSelectTag(tag.tag)}
                            getTagColor={() =>
                              getTagColor(tag, {
                                name: key,
                                value: healthyIndicators[key],
                              })
                            }
                            indicator={key as string}
                          />
                        ))}
                    </div>
                  </Collapse>
                </div>
              ))}
          </CategoriesContainer>
        </>
      )}
    </Media>
  );
};
