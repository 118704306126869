import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { toastr } from 'react-redux-toastr';

import { TreeView } from '../../../../../components/TreeView';
import { Creators } from '../../../../../store/actionCreators';

import { Container } from './styles';

export const TreePanel: React.FC = () => {
  const {
    dataAnalysis: { selectableTreeItems, selectedTreeItems },
  } = useSelector((state) => state);
  const dispatch = useDispatch();

  // Adds or removes element from list
  function handleItemSelect(id: string) {
    if (selectedTreeItems.includes(id)) {
      const index = selectedTreeItems.indexOf(id);
      if (selectedTreeItems.length === 1) {
        toastr.info(
          'Seleção de elemento',
          'Ao menos um elemento deve ser selecionado'
        );
      } else {
        const newArray = [...selectedTreeItems];
        newArray.splice(index, 1);

        dispatch(Creators.setSelectedTreeItems(newArray));
      }
    } else dispatch(Creators.setSelectedTreeItems([...selectedTreeItems, id]));
  }

  // function handleChangePanel() {
  //   dispatch(Creators.setPanelOptions('navigation'));
  // }

  return (
    <Container data-testid="treePanel">
      <div className="title" data-testid="title">
        <h1>Estrutura</h1>
      </div>
      <div>
        <TreeView
          selectedItems={selectedTreeItems}
          onItemSelect={handleItemSelect}
          selectionMode="multi"
          selectableTreeItems={selectableTreeItems}
          showZeroImportance={true}
          showLeaves={true}
        />
      </div>
    </Container>
  );
};

// <Tooltip
// title={<TooltipSpan>Visualizar outros relatórios</TooltipSpan>}
// enterDelay={1000}
// >
// <span>
//   <IconButton
//     data-testid="changePanelBtn"
//     onClick={() => handleChangePanel()}
//     disabled={selectableTreeItems.length === 0}
//   >
//     <AccountTree fontSize="large" />
//   </IconButton>
// </span>
// </Tooltip>
