import styled, { createGlobalStyle } from 'styled-components';

import { supportedDevices } from '../../../../../../../styles/supportedDevices';

export const GlobalStyle = createGlobalStyle`
  & li.MuiMenuItem-root {
    font-size: 12px;
  }

  .MuiPickersCalendarHeader-iconButton svg {
      font-size: 2rem;
  }

  .MuiPickerDTTabs-tabs svg {
      font-size: 2.4rem
  }

  .MuiPickersCalendarHeader-transitionContainer p, .MuiPickersDay-day p {
      font-size: 1.8rem;
  }

  span.MuiPickersCalendarHeader-dayLabel {
    font-size: 1.6rem;
  }

  & div.MuiDialogActions-root {
    justify-content: space-evenly;
    padding-bottom: 28px;

  }

  & div.MuiDialogActions-spacing span {
    font-size: 12px;
    width: 80px;
  }

  & div.MuiDialogActions-spacing > :first-child {
    background-color: ${({ theme }) => theme.palette.primary.main};
    color: white;
  }

  & div.MuiDialogActions-spacing > :not(:first-child) {
    background-image: linear-gradient(to right, #19b0a0, #009ee2);
    color: white;
    margin: 0;
  }

  @media ${supportedDevices.tablet} {
    & span.MuiPickersCalendarHeader-dayLabel, .MuiPickersDay-day p {
    font-size: 1.4rem;
    }
  }
`;

export const LoaderContainer = styled.div`
  height: 500px;
  display: grid;
  place-items: center;
`;

export const Container = styled.div`
  min-height: 500px;
  color: black;

  & h3 {
    font-size: 14px;
  }

  .time-selector-container {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    margin-bottom: 30px;
    flex-wrap: wrap;

    & > :first-child {
      margin-bottom: 5px;
    }

    .selector-container {
      display: flex;
      align-items: center;
      width: fit-content;
      flex-wrap: wrap;
      width: 100%;

      .date-time-picker {
        width: 100%;
        background-color: rgba(230, 232, 235, 0.5);
        transition: background-color 0.2s;

        &:hover {
          background-color: ${({ theme }) => theme.palette.background.paper};
        }

        & .MuiInputBase-root {
          font-size: 14px;

          input {
            padding: 12px 14px;
            cursor: pointer;
            text-transform: capitalize;
          }

          .MuiOutlinedInput-notchedOutline {
            border: 1px solid rgba(0, 0, 0, 0.1);
          }
        }
      }
    }

    & .minutes-selector {
      width: 100%;

      & .MuiInputBase-root {
        font-size: 14px;

        div {
          padding: 12px 14px;
        }
      }

      & svg {
        font-size: 2.3rem;
      }
    }
  }

  .graph-container {
    height: 820px;
  }

  @media ${supportedDevices.tablet} {
    .time-selector-container {
      & > :first-child {
        margin-bottom: 0;
      }

      .selector-container {
        width: unset;

        h3 {
          margin-right: 15px;
        }

        .date-time-picker {
          margin-right: 10px;
          width: 180px;

          & .MuiInputBase-root {
            font-size: 14px;

            input {
              padding: 18.5px 14px;
            }
          }
        }
      }

      & .minutes-selector {
        width: unset;

        & .MuiInputBase-root {
          div {
            padding: 18.5px 14px;
            padding-right: 32px;
          }
        }
      }
    }
  }
`;
