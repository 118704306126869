import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import {
  CircularProgress,
  Collapse,
  AccordionSummary,
  Typography,
  AccordionDetails,
  TableHead,
  TableRow,
  TableCell,
  Table as MaterialTable,
} from '@material-ui/core';
import { ExpandMore } from '@material-ui/icons';
import { format } from 'date-fns';

import errorIcon from '../../../../../../../../assets/imgs/error-icon.png';
import pieOnIcon from '../../../../../../../../assets/new_icons/ic-switch-pie-select.svg';
import pieOffIcon from '../../../../../../../../assets/new_icons/ic-switch-pie-unselect.svg';
import tableOnIcon from '../../../../../../../../assets/new_icons/ic-switch-table-select.svg';
import tableOffIcon from '../../../../../../../../assets/new_icons/ic-switch-table-unselect.svg';
import { Pie } from '../../../../../../../../components/charts/Pie';
import { Table } from '../../../../../../../../components/charts/Table';
import { ToggleChartButton } from '../../../../../../../../components/ToggleChartButton';
import { HealthAndIndicatorsPerPhysicalAvailability } from '../../../../../../../../models/Reports';
import { Creators } from '../../../../../../../../store/actionCreators';
import { AppState } from '../../../../../../../../store/reducers';
import { generateCsv } from '../../../../../../../../utils/methods';
import { Header } from '../../Header';

import { Container, CustomAccordion } from './styles';
import { useAuthStore } from '../../../../../../../../zustand/AuthStore';

const fixedTimePeriods = [
  ['24h', '24 H', '24 Horas'],
  ['7d', '7 D', '7 Dias'],
  ['30d', '30 D', '30 Dias'],
].map((time) => {
  return {
    id: time[0],
    content: time[1],
    tooltip: time[2],
  };
});

export const PhysicalAvailability: React.FC = () => {
  const { reports } = useSelector((state: AppState) => state);
  const { isLoading, error, data, timePeriod, elementUuid } = reports;
  const { perPhysicalAvailability } = data;
  const dispatch = useDispatch();

  const tree = useAuthStore((state) => state.user.tree);

  const [selectedChart, setSelectedChart] = useState<'table' | 'pie'>('table');

  useEffect(() => {
    dispatch(Creators.setNavigationOption('treeView'));
    dispatch(Creators.setTreeOptions('all'));
  }, [dispatch]);

  useEffect(() => {
    if (
      (Array.isArray(elementUuid)) &&
      tree
    )
      dispatch(Creators.setElementUuid(tree.uuid));
  }, [tree, elementUuid, dispatch]);

  useEffect(() => {
    if (elementUuid)
      dispatch(
        Creators.getReportsRequest(
          timePeriod,
          'healthAndIndicatorsPerPhysicalAvailability'
        )
      );

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch, elementUuid]);

  function buildHeaders() {
    return ['', 'Tempo Disponível', 'Quantidade de ativos'];
  }

  function buildRows(data: HealthAndIndicatorsPerPhysicalAvailability) {
    return data.treeElementChildrenReport;
  }

  function createTableRow(item: HealthAndIndicatorsPerPhysicalAvailability) {
    const availability =
      item.physicalAvailability === null
        ? '-'
        : `${Math.fround(item.physicalAvailability * 100).toFixed(5)}%`.replace(
            '.',
            ','
          );

    return [item.name, availability, item.assetNumber];
  }

  function buildChartValues(item: HealthAndIndicatorsPerPhysicalAvailability) {
    const availability =
      item.physicalAvailability === null
        ? 0
        : Number(Math.fround(item.physicalAvailability * 100).toFixed(5));

    return [
      ['Disponível', availability],
      ['Indisponível', 100 - availability],
    ] as [string, number][];
  }

  function handleDownloadCsv() {
    if (perPhysicalAvailability) {
      const timeStamp =
        typeof timePeriod.startTime === 'string'
          ? timePeriod.startTime.split('-')[1]
          : `${format(
              new Date(timePeriod.startTime!),
              'dd/MM/yyyy'
            )}-${format(new Date(timePeriod.endTime!), 'dd/MM/yyyy')}`;
      generateCsv(
        buildHeaders(),
        [
          [
            perPhysicalAvailability.name,
            `${Math.fround(perPhysicalAvailability.physicalAvailability * 100)
              .toFixed(5)
              .replace('.', ',')}%`,
            perPhysicalAvailability.assetNumber,
          ],
          ...buildRows(perPhysicalAvailability).map((row) =>
            createTableRow(row)
          ),
        ] as any,
        `Relatorio_${perPhysicalAvailability.name}_Disponibilidade_Fisica_${timeStamp}.csv`
      );
    }
  }

  function buildElementHeader(
    item: HealthAndIndicatorsPerPhysicalAvailability
  ) {
    return (
      <div className="element-header">
        <MaterialTable>
          <TableHead>
            <TableRow>
              <TableCell align="center" className="header-cell">
                {item.name}
              </TableCell>
              <TableCell align="center" className="header-cell green">
                {`${Math.fround(item.physicalAvailability * 100).toFixed(5)}%`}
              </TableCell>
              <TableCell align="center" className="header-cell">
                {`Ativos: ${item.assetNumber}`}
              </TableCell>
            </TableRow>
          </TableHead>
        </MaterialTable>
      </div>
    );
  }

  function buildToggleChartButtons(iconSize: number) {
    return (
      <>
        <ToggleChartButton
          onIcon={tableOnIcon}
          offIcon={tableOffIcon}
          alt="Mostrar ou esconder tabela"
          isSelected={selectedChart === 'table'}
          onToggle={() => setSelectedChart('table')}
          iconSize={iconSize}
          height={48}
          name="Tabela"
        />
        <ToggleChartButton
          onIcon={pieOnIcon}
          offIcon={pieOffIcon}
          alt="Mostrar ou esconder gráfico de setores"
          isSelected={selectedChart === 'pie'}
          onToggle={() => setSelectedChart('pie')}
          iconSize={iconSize}
          height={48}
          name="Setores"
        />
      </>
    );
  }

  function buildLoaderAndError() {
    if (error)
      return (
        <div className="error">
          <img src={errorIcon} alt="Ícone de erro" />
          <h3>Ocorreu um erro! Recarregue a página para tentar novamente.</h3>
        </div>
      );
    return (
      <div className="loader">
        <CircularProgress size={50} />
      </div>
    );
  }

  if (!elementUuid)
    return (
      <Container className="no-element">
        <h2>Selecione um elemento para iniciar</h2>
      </Container>
    );

  return (
    <Container>
      <Header
        title="Disponibilidade Física"
        subtitle={perPhysicalAvailability?.name}
        timeList={fixedTimePeriods}
        handleDownloadCsv={handleDownloadCsv}
        buildToggleChartsButtons={buildToggleChartButtons}
        datePicker="normal"
        hideSearchBar
        reportName="healthAndIndicatorsPerPhysicalAvailability"
      />
      {error || isLoading || !perPhysicalAvailability ? (
        buildLoaderAndError()
      ) : (
        <div className="charts">
          <Collapse in={selectedChart === 'table'} timeout="auto" unmountOnExit>
            <div className="table">
              {buildElementHeader(perPhysicalAvailability)}
              <Table
                headers={buildHeaders()}
                rows={buildRows(perPhysicalAvailability)}
                createRow={createTableRow}
              />
            </div>
          </Collapse>
          <Collapse in={selectedChart === 'pie'} timeout="auto" unmountOnExit>
            <div className="pie">
              <CustomAccordion defaultExpanded>
                <AccordionSummary expandIcon={<ExpandMore />}>
                  <Typography>{perPhysicalAvailability.name}</Typography>
                </AccordionSummary>
                <AccordionDetails>
                  <div className="pie-container">
                    <Pie
                      subject="Disponibilidade Física"
                      values={buildChartValues(perPhysicalAvailability)}
                    />
                  </div>
                </AccordionDetails>
              </CustomAccordion>
            </div>
          </Collapse>
        </div>
      )}
    </Container>
  );
};
