import React, { useEffect, useState } from 'react';
import { Chart } from 'react-google-charts';

import { CircularProgress } from '@material-ui/core';

import { kChartGradientColors } from '../../../utils/constants';

import { Container, Loader } from './styles';

import { AppState } from '../../../store/reducers';

import { useSelector, useDispatch } from 'react-redux';

export type ParetoValue = [string, number, string, number, string];

interface ParetoProps {
  subtitle: string;
  values: ParetoValue[];
  yAxisTitle?: string;
  xAxisTitle: string;
  columnColor?: string;
  lineColor?: string;
}

export const Pareto: React.FC<ParetoProps> = ({
  subtitle,
  values,
  yAxisTitle,
  xAxisTitle,
  columnColor,
  lineColor,
}) => {
  const ticks = Array.from(Array(6), (_, i) => {
    return { v: i * 20, f: `${i * 20}%` };
  });

  const { reports } = useSelector((state: AppState) => state);

  const [graphId, setGraphId] = useState("graphID");

  useEffect(() => {
    const timeout = setInterval( ()=>{
      if(graphId == "graphID")
        setGraphId("graphIDtoogle")
      else
      setGraphId("graphID")
    },  1000)

    return () => clearTimeout(timeout)

}, [reports.isNavigationPanelOpen]);

  return (
    <Container className='paretoContainer'>
      <Chart
        width="100%"
        height="100%"
        graphID={graphId}
        chartType="ComboChart"
        loader={
          <Loader>
            <CircularProgress size={50} />
          </Loader>
        }
        data={[
          [
            'Element',
            subtitle,
            { role: 'style' },
            { type: 'string', role: 'tooltip', p: { html: true } },
            'Total',
            { type: 'string', role: 'tooltip', p: { html: true } },
          ],
          ...values.map((item, index) => [
            ...item.slice(0, 2),
            kChartGradientColors[index],
            ...item.slice(2),
          ]),
        ]}
        options={{
          backgroundColor: 'transparent',
          vAxes: {
            0: {
              title: yAxisTitle,
              titleTextStyle: { fontName: 'Roboto', italic: false },
              ticks,
            },
            1: {
              title: 'Total',
              titleTextStyle: { fontName: 'Roboto', italic: false },
              ticks,
            },
          },
          hAxis: {
            title: xAxisTitle,
            titleTextStyle: { fontName: 'Roboto', italic: false },
            textPosition: 'none',
          },
          seriesType: 'bars',
          series: {
            0: {
              type: 'bars',
              color: columnColor,
              targetAxisIndex: 0,
            },
            1: {
              type: 'line',
              color: lineColor,
              targetAxisIndex: 1,
            },
          },
          tooltip: { isHtml: true },
          animation: {
            startup: true,
            easing: 'linear',
            duration: 1000,
          },
          fontName: 'Roboto',
          legend: 'none',
          colors: ['#01A0C6', 'red'],
        }}
      />
    </Container>
  );
};
