import React, { ReactText } from 'react';
import Chart from 'react-google-charts';

import { CircularProgress } from '@material-ui/core';

import { kChartMultiColors } from '../../../utils/constants';

import { Container, Loader } from './styles';

export interface LinesProps {
  /**
   * Lines to be displayed
   */
  lines: string[];

  /**
   * values to be displayed in chart
   */
  values: ReactText[][];

  /**
   * V axis title
   */
  yAxisTitle?: string;
}

/**
 * Lines chart component
 */
export const Lines: React.FC<LinesProps> = ({ lines, values, yAxisTitle }) => {
  const ticks = Array.from(Array(6), (_, i) => {
    return { v: i * 20, f: `${i * 20}%` };
  });

  return (
    <Container data-testid="linesChart">
      <Chart
        width="100%"
        height="100%"
        chartType="LineChart"
        loader={
          <Loader>
            <CircularProgress size={50} />
          </Loader>
        }
        data={[['date', ...lines], ...values]}
        options={{
          backgroundColor: 'transparent',
          tooltip: { isHtml: true },
          animation: {
            startup: true,
            easing: 'linear',
            duration: 1000,
          },
          hAxis: {
            textPosition: values.length > 21 ? 'none' : undefined,
          },
          vAxis: {
            title: yAxisTitle,
            ticks,
          } as any,
          fontName: 'Roboto',
          colors: kChartMultiColors,
        }}
      />
    </Container>
  );
};
