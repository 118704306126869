import React, { useState, useEffect } from 'react';
import Media from 'react-media';
import { useSelector, useDispatch } from 'react-redux';
import { toastr } from 'react-redux-toastr';
import { useRouteMatch } from 'react-router-dom';
import { Select, MenuItem } from '@material-ui/core'; 

import {
  FormControlLabel,
  Checkbox,
  Slider,
  Input,
  CircularProgress,
  Divider
} from '@material-ui/core';
import {
  CheckBoxOutlineBlank,
  CheckBox,
  IndeterminateCheckBox,
} from '@material-ui/icons';

import errorIcon from '../../../../../../../../../assets/imgs/error-icon.png';
import { CustomButton } from '../../../../../../../../../components/CustomButton';
import { SearchField } from '../../../../../../../../../components/SearchField';
import { IndicatorAlert } from '../../../../../../../../../models/AssetInfo';
import { Creators } from '../../../../../../../../../store/actionCreators';

import { supportedDevices } from '../../../../../../../../../styles/supportedDevices';
import { Container, SliderValueLabel } from './styles';

const formColumns: [string][] = [
  ['description'],
  ['importance'],
  ['criticality'],
  ['limits'],
  ['modelDescription'],
  ['weights']
];

const indicators: [string][] = [
  ['CO e PA'],
  ['OL'],
  ['TV'],
  ['PF'],
  ['SQ']
];

interface MatchParams {
  ativoPath: string;
}

interface AlertFormState {
  importance?: number;
  criticality?: number;
  description?: string;
  modelDescription?: string;
  tagsIndicator?: TagsIndicatorsTvOl[];
  weights?: number[];
}

interface TagsIndicatorsTvOl {
  tagName?: string;
  hasTv?: Boolean;
  hasOl?: Boolean;
  minTv?: Number;
  maxTv?: Number;
  minOl?: Number;
  maxOl?: Number;
}

export interface EditAssetModalProps {
  /**
   * On cancel button click callback
   */
  handleCancel(): void;
  isLogicalAsset: boolean;
}

/**
 * Asset alerts editing modal
 */
export const EditAssetModal: React.FC<EditAssetModalProps                                                                                                                                                                                                                                                                                                                                                       > = ({
  handleCancel,
  isLogicalAsset
}) => {
  const {
    assetData, tags,
  } = useSelector((state) => state);

  const dispatch = useDispatch();

  const match = useRouteMatch<MatchParams>();

  const [formState, setFormState] = useState<AlertFormState>({});
  const [dispatchCompleted, setDispatchCompleted] = useState(false);

  useEffect(() => {
    function dispatchPromise(){
      return new Promise<void>((resolve) => {
        if(!isLogicalAsset){
          dispatch(Creators.getTagsRequest({ assetUuid: assetData.data.uuid }));
        }

            resolve()
      });
    }
    dispatchPromise().then(() => setDispatchCompleted(true));
  }, [dispatch, assetData.data.uuid]);

  useEffect(() => {
    if (assetData.data && !Object.keys(formState).length && (isLogicalAsset || tags.data.length) && (isLogicalAsset || dispatchCompleted)) {
      let newFormState: AlertFormState = {};

      let NewtagsIndicator: TagsIndicatorsTvOl[] = [];
      tags.data.forEach((tag) => {

        let tagIndicator : TagsIndicatorsTvOl  = {};

        if (tag.indicators.hasKPI.tv){

          if (tag.indicators.limits.tv.includes('[') && tag.indicators.limits.tv.includes(']')) {

            const content = tag.indicators.limits.tv.match(/\[(.*?)\]/)[1];

            const numbersArray = content.split(',');

            let min = parseFloat(numbersArray[0]);
            let max = parseFloat(numbersArray[1]);

            tagIndicator.hasTv = true;
            tagIndicator.minTv = min;
            tagIndicator.maxTv = max;
          }
        }
        if (tag.indicators.hasKPI.ol){
          if (tag.indicators.limits.ol.includes('[') && tag.indicators.limits.ol.includes(']')) {
            
            const content = tag.indicators.limits.ol.match(/\[(.*?)\]/)[1];
            const numbersArray = content.split(',');

            let min = parseFloat(numbersArray[0]);
            let max = parseFloat(numbersArray[1]);

            tagIndicator.hasOl = true;
            tagIndicator.minOl = min;
            tagIndicator.maxOl = max;
          }
        }

        if (tagIndicator.hasOl || tagIndicator.hasTv) {
          tagIndicator.tagName = tag.tag;
          NewtagsIndicator.push(tagIndicator);
        }
        
      });

      newFormState = {
        ...newFormState,
        importance: assetData.data?.importance,
        criticality: assetData.data?.criticality,
        description: assetData.data?.description,
        modelDescription: assetData.data?.modelDescription,
        tagsIndicator: NewtagsIndicator,
        weights: assetData.data?.weights
      };

      setFormState(newFormState);
    }
  }, [assetData.data, formState, tags, isLogicalAsset, dispatchCompleted]);

  function handleConfirm() {

    let weightsCopy : number[] = [];
    let weightTotal = 0.0;
    if(formState['weights']) {
      for(let weight of formState['weights']){
        weightsCopy.push(Number(weight))
        weightTotal += Number(weight);
      }
    }

    if(weightTotal > 1.0){
      toastr.error(
        'Valor total do peso não permitido',
        'A soma dos valores dos pesos devem ser menores que 1.0'
      );

      return;
    }

    let limitsCopy = {};
    formState.tagsIndicator.forEach((tag) => {
      if (tag.hasOl) {
        let key = "OL_" + tag.tagName;
        let value = [Number(tag.minOl), Number(tag.maxOl)];
        limitsCopy[key] = value;
      }
        if(tag.hasTv){
        let key = "TV_" + tag.tagName;
        let value = [Number(tag.minTv), Number(tag.maxTv)];
        limitsCopy[key] = value;
      }
    });

    dispatch(
      Creators.updateAssetRequest({
        assetUUid: assetData.data.uuid,
        description: formState['description'],
        modelDescription: formState['modelDescription'],
        importance: Number(formState['importance']),
        criticality: Number(formState['criticality']),
        weights: weightsCopy,
        limits: limitsCopy
      })
    );

    handleCancel();

  }

  function handleFormChange(
     key: string,
     value: any
  ) {
    setFormState({
      ...formState,
      [key]: value,
    });
  }

  function handleLimitChanges(
    key: string,
    value: any,
    tag: string
 ) {
    setFormState({
      ...formState,
    tagsIndicator: formState.tagsIndicator.map( tagIndicator => {
       if (tagIndicator.tagName === tag) {
          return {
            ...tagIndicator,
            [key]: value,
          };
        }
        return tagIndicator;
      }),
    });
  }

 function handleWeightChanges(
  index: number,
  value: any,
) {
    setFormState({
      ...formState,
        weights: formState.weights.map( (weight,indexWeight) => {
        return index === indexWeight ? value : weight;
      }),
    });
  }

  function onSliderBlur() {
    if (formState["importance"] < 0)
      handleFormChange("importance", 0);
    else if (formState["importance"] > 100)
      handleFormChange("importance", 100);
  }

  function buildSlider(
  ) {
    return (
      <div className="slider-container">
        <p className='sub-title'>Importância:</p>
        <Input
          data-testid={`ImportanceInput`}
          margin="dense"
          onChange={(event) =>
            handleFormChange("importance", Number(event.target.value))
          }
          value={Number(
            formState["importance"] ? formState["importance"] : 0
          )}
          onBlur={() => onSliderBlur()}
          inputProps={{
            type: 'number',
          }}
        />
      </div>
    );
  }

  function buildDropDown() {
    return (
      <div className="dropdown-container campo">
        <p className='sub-title'>Criticidade:</p>
        <Select
          className='dropdownSelectCriticality'
          value={Number(formState.criticality) || 0}
          onChange={(event) =>
            handleFormChange("criticality", Number(event.target.value))
          }
          displayEmpty
          inputProps={{ 'aria-label': 'Criticality Dropdown' }}
        >
          <MenuItem value={0} disabled>
            ─
          </MenuItem>
          
          {[...Array(5)].map((_, index) => (
            <MenuItem key={index + 1} value={index + 1}>
              {index + 1}
            </MenuItem>
          ))}
        </Select>
      </div>
    );
  }

  function buildFormInput(key: string) {
    switch (key) {
      case 'description':
        return (
          <>
            <h3 className='titulo-modal'>Dados do ativo</h3>
            <div className="plain-container">
              <p className='sub-title'>Descrição do ativo:</p>
              <Input
                fullWidth
                value={formState.description}
                margin="dense"
                onChange={(event) =>
                  handleFormChange("description", event.target.value)
                }
                inputProps={{
                  type: 'text',
                }}
              />
            </div>
          </>
        );
      case 'modelDescription':
        return (
          <>
          <Divider  variant="fullWidth" className='divider'/>
            <h3 className='titulo-modal'>Dados do modelo do ativo</h3>
            <div className="plain-container">
              <p className='sub-title'>Descrição do ativo:</p>
              <Input
                value={formState.modelDescription}
                className='input-texto'
                margin="dense"
                onChange={(event) =>
                  handleFormChange("modelDescription", event.target.value)
                }
                inputProps={{
                  type: 'text',
                }}
              />
            </div>
          </>
        );
      case 'importance':
        return buildSlider();
      case 'criticality':
        return buildDropDown();
      case 'limits':
        if(formState.tagsIndicator && formState.tagsIndicator.length){
          return createLimitsInput();
        }
        break;
      case 'weights':
        if(formState.weights && formState.weights.length){
          return buildWeightsInput();
        }
        break;
    }
  }

  function buildWeightsInput() {
    return (
      <div>
        {(formState.weights && formState.weights.length)  && (
          <div>
            <p className='sub-title'>Peso dos indicadores:</p>
            <div className='indicators'>
              {formState.weights.map((value, index) => (
                <div key={index}>
                  <label>{indicators[index]}: </label>
                  <Input
                    value={value}
                    className='input-indicador'
                    margin="dense"
                    onChange={(event) =>
                      handleWeightChanges(index, Number(event.target.value))
                    }
                    inputProps={{
                      type: 'number',
                    }}
                  />
                </div>
              ))}
            </div>
          </div>
        )}
      </div>
    );
  }

  function createLimitsInput() {
    return (
      <div>
        {(formState.tagsIndicator && formState.tagsIndicator.length) && (
          <div>
            <p className='sub-title'>Limites das tags:</p>
            {formState.tagsIndicator.map(tagIndicator => (
              <div className='limits' key={tagIndicator.tagName}>
                <h6>{tagIndicator.tagName}</h6>

                {tagIndicator.hasTv && (
                  <div>
                    <p>Variação Temporal:</p>
                    <Input
                      value={tagIndicator.minTv || 0.0}
                      margin="dense"
                      className='limit-input'
                      onChange={(event) =>
                        handleLimitChanges("minTv", Number(event.target.value), tagIndicator.tagName)
                      }
                      inputProps={{
                        type: 'number',
                      }}
                    />
                    <Input
                      value={tagIndicator.maxTv || 0.0}
                      margin="dense"
                      className='limit-input'
                      onChange={(event) =>
                        handleLimitChanges("maxTv", Number(event.target.value), tagIndicator.tagName)
                      }
                      inputProps={{
                        type: 'number',
                      }}
                    />
                  </div>
                )}

                {tagIndicator.hasOl && (
                  <div>
                    <p>Fora Dos limites:</p>
                    <Input
                      value={tagIndicator.minOl || 0.0}
                      margin="dense"
                      className='limit-input'
                      onChange={(event) =>
                        handleLimitChanges("minOl", Number(event.target.value), tagIndicator.tagName)
                      }
                      inputProps={{
                        type: 'number',
                      }}
                    />
                    <Input
                      value={tagIndicator.maxOl || 0.0}
                      margin="dense"
                      className='limit-input'
                      onChange={(event) =>
                        handleLimitChanges("maxOl", Number(event.target.value), tagIndicator.tagName)
                      }
                      inputProps={{
                        type: 'number',
                      }}
                    />
                  </div>
                )}
              </div>
            ))}
          </div>
        )}
      </div>
    );
  }

  function buildErrorAndLoader() {
    return assetData.error ? (
      <>
        <div className="error-container" data-testid="error">
          <img src={errorIcon} alt="Ícone de erro" />
          <h3>Ocorreu um erro! Recarregue a página para tentar novamente.</h3>
        </div>
      </>
    ) : (
      <CircularProgress size={50} data-testid="loader" />
    );
  }

  return (
    <Media query={supportedDevices.tablet}>
      {(isTablet) => (
        <Container
          data-testid="modalContainer"
          className={assetData.isLoading ? 'loading' : ''}
        >
          {assetData.isLoading || assetData.error ? (
            buildErrorAndLoader()
          ) : (
            <>
              <div className="title-container">
              </div>
              <div className="indicators-form">
                <div className='form-column'>
                  {formColumns.map(([key], index) => (
                    <div className={`key-form ${key}-area`} key={index}>
                      {!isTablet && (index === 1 || index === 2) && (
                        <p className="slider-label">{key}</p>
                      )}
                      {buildFormInput(key)}
                    </div>
                  ))}
                </div>
              </div>
              <div className="action-buttons">
                <CustomButton data-testid="cancelButton" onClick={handleCancel}>
                  cancelar
                </CustomButton>
                <CustomButton
                  data-testid="confirmButton"
                  onClick={handleConfirm}
                  gradient
                >
                  confirmar
                </CustomButton>
              </div>
            </>
          )}
        </Container>
      )}
    </Media>
  );
};
