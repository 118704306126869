import styled from 'styled-components';

import { supportedDevices } from '../../styles/supportedDevices';

export const Main = styled.main`
  display: flex;
  flex-flow: column;
  align-items: center;
  justify-content: center;
  height: 100%;
  width: 100%;
  overflow: hidden;
  background-color: rgb(235, 235, 235);
`;

export const Container = styled.div`
  height: 400px;
  width: 300px;
  background-color: white;
  display: flex;
  flex-flow: column;
  justify-content: center;
  padding: 30px;
  border-radius: 10px;
  box-shadow: 0 0 3px rgba(0, 0, 0, 0.2);
  color: black;

  h1 {
    font-weight: bold;
    font-size: 36px;
  }

  h3 {
    font-size: 24px;
  }

  h6 {
    font-size: 16px;
    font-weight: 400;
  }

  Button {
    width: 100%;
    font-size: 12px;
  }

  @media ${supportedDevices.laptop} {
    width: 500px;

    Button {
      font-size: 12px;
    }
  }
`;
