import React from 'react';
import { Switch, Route, Redirect, useRouteMatch } from 'react-router-dom';

import { Screen404 } from '../404';
import { ForgotPassword } from './components/ForgotPassword';
import { Login } from './components/Login';

export const Router: React.FC = () => {
  const match = useRouteMatch();

  return (
    <Switch>
      <Route path={`${match.path}/login`} component={Login} />
      <Route
        path={`${match.path}/esqueciMinhaSenha`}
        component={ForgotPassword}
      />
      <Route path={`${match.path}/404`} component={Screen404} />
      <Redirect exact from={`${match.path}`} to={`${match.path}/login`} />
      <Redirect from={`${match.path}/*`} to="/404" />
    </Switch>
  );
};
