import React from 'react';

import { Panels } from './panels';
import { Router } from './router';

import { Content } from './styles';

export const DataAnalysis: React.FC = () => {
  return (
    <>
      <Panels />
      <Content data-testid="content">
        <Router />
      </Content>
    </>
  );
};
