import styled from 'styled-components';

export const LabelContainer = styled.div`
  display: flex;
  align-items: center;
  padding: 4px;

  .label-text {
    flex-grow: 1;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    width: 150px;
  }
`;

export const TooltipSpan = styled.span`
  font-size: 12px;
`;
