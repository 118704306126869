import { difference } from 'lodash';
import { createReducer, createActions } from 'reduxsauce';

import { GetAssetDataFailureAction } from '../assetData/types';
import {
  HistoryIndicatorActions,
  HistoryIndicatorState,
  HistoryIndicatorTypes,
  GetHistoryIndicatorFailureAction,
  GetHistoryIndicatorSuccessAction,
  GetAssetTagsSuccessAction,
  ResetHistoryIndicatorAction,
  SetIsPanelOpenAction,
  SetMetricsAction,
  SetPanelOptionsAction,
  setHistoryIndicatorResolutionAction,
  SetSelectableTreeItemsAction,
  SetSelectedTreeItemsActions,
  SetHistoryTimePeriodAction,
} from './types';

const { Types, Creators } = createActions<
  HistoryIndicatorTypes,
  HistoryIndicatorActions
>({
  getAssetTagsRequest: ['uuids'],
  getAssetTagsSuccess: ['tags'],
  getAssetTagsFailure: ['error'],
  setMetrics: ['metrics'],
  setIsPanelOpen: ['open'],
  setPanelOptions: ['panelOption'],
  setSelectableTreeItems: ['selectableItems'],
  setSelectedTreeItems: ['items'],
  setHistoryTimePeriod: ['timePeriod'],
  getHistoryIndicatorRequest: ['analysis'],
  getHistoryIndicatorSuccess: ['analysis', 'data'],
  getHistoryIndicatorFailure: ['error'],
  setHistoryIndicatorPeriodResolution: ['resolution'],
  resetHistoryIndicator: ['analysis'],
});

const INITIAL_STATE: HistoryIndicatorState = {
  isPanelOpen: true,
  panelOption: 'tree',
  selectableTreeItems: 'all',
  data: {
    tagsComparisonAnalysis: null,
  },
  metrics: [],
  tags: [],
  isLoading: false,
  error: '',
  timePeriod: {
    startTime: 'yesterday',
    endTime: 'now',
  },
  selectedTreeItems: [],
  periodResolution: '1min',
};

const getAssetTagsRequest = (state = INITIAL_STATE) => ({
  ...state,
  isLoading: true,
  error: '',
});

const getAssetTagsSuccess = (
  state = INITIAL_STATE,
  { tags }: GetAssetTagsSuccessAction
) => ({
  ...state,
  isLoading: false,
  tags,
});

const getAssetTagsFailure = (
  state = INITIAL_STATE,
  { error }: GetAssetDataFailureAction
) => ({
  ...state,
  isLoading: false,
  error,
});

const setMetrics = (state = INITIAL_STATE, { metrics }: SetMetricsAction) => ({
  ...state,
  metrics,
});

const setIsPanelOpen = (
  state = INITIAL_STATE,
  { open }: SetIsPanelOpenAction
) => ({
  ...state,
  isPanelOpen: Boolean(open),
});

const setPanelOptions = (
  state = INITIAL_STATE,
  { panelOption }: SetPanelOptionsAction
) => ({
  ...state,
  panelOption,
});

const setSelectableTreeItems = (
  state = INITIAL_STATE,
  { selectableItems }: SetSelectableTreeItemsAction
) => ({
  ...state,
  selectableTreeItems: selectableItems,
});

const setSelectedTreeItems = (
  state = INITIAL_STATE,
  { items }: SetSelectedTreeItemsActions
) => {
  if (items.length < state.selectedTreeItems.length) {
    const removedItems = difference(state.selectedTreeItems, items);
    const removedTags = state.tags.filter((tag) =>
      removedItems.includes(tag.assetUuid!)
    );

    return {
      ...state,
      metrics: state.metrics.filter(
        (metric) => !removedTags.find((tag) => tag.identifier === metric)
      ),
      tags: state.tags.filter((tag) => !removedTags.includes(tag)),
      selectedTreeItems: items,
    };
  }

  return {
    ...state,
    metrics: state.metrics,
    tags: state.tags,
    selectedTreeItems: items,
  };
};

const setHistoryTimePeriod = (
  state = INITIAL_STATE,
  { timePeriod }: SetHistoryTimePeriodAction
) => ({
  ...state,
  timePeriod,
});

const getHistoryIndicatorRequest = (state = INITIAL_STATE) => ({
  ...state,
  isLoading: true,
});

const getHistoryIndicatorSuccess = (
  state = INITIAL_STATE,
  { analysis, data }: GetHistoryIndicatorSuccessAction
) => ({
  ...state,
  isLoading: false,
  data: {
    [analysis]: data,
  },
});

const getHistoryIndicatorFailure = (
  state = INITIAL_STATE,
  { error }: GetHistoryIndicatorFailureAction
) => ({
  ...state,
  isLoading: false,
  error,
});

const setHistoryIndicatorPeriodResolution = (
  state = INITIAL_STATE,
  { resolution }: setHistoryIndicatorResolutionAction
) => ({
  ...state,
  periodResolution: resolution,
});

const resetHistoryIndicator = (
  state = INITIAL_STATE,
  { analysis }: ResetHistoryIndicatorAction
): HistoryIndicatorState => ({
  ...state,
  data: {
    ...state.data,
    [analysis]: null,
  },
});

export const HANDLERS = {
  [Types.GET_ASSET_TAGS_REQUEST]: getAssetTagsRequest,
  [Types.GET_ASSET_TAGS_SUCCESS]: getAssetTagsSuccess,
  [Types.GET_ASSET_TAGS_FAILURE]: getAssetTagsFailure,
  [Types.SET_METRICS]: setMetrics,
  [Types.SET_IS_PANEL_OPEN]: setIsPanelOpen,
  [Types.SET_PANEL_OPTIONS]: setPanelOptions,
  [Types.SET_SELECTABLE_TREE_ITEMS]: setSelectableTreeItems,
  [Types.SET_SELECTED_TREE_ITEMS]: setSelectedTreeItems,
  [Types.SET_HISTORY_TIME_PERIOD]: setHistoryTimePeriod,
  [Types.GET_HISTORY_INDICATOR_REQUEST]: getHistoryIndicatorRequest,
  [Types.GET_HISTORY_INDICATOR_SUCCESS]: getHistoryIndicatorSuccess,
  [Types.GET_HISTORY_INDICATOR_FAILURE]: getHistoryIndicatorFailure,
  [Types.SET_HISTORY_INDICATOR_PERIOD_RESOLUTION]: setHistoryIndicatorPeriodResolution,
  [Types.RESET_HISTORY_INDICATOR]: resetHistoryIndicator,
};

const Reducer = createReducer(INITIAL_STATE, HANDLERS);

export {
  Creators as HistoryIndicatorCreators,
  Types as HistoryIndicatorTypes,
  Reducer as HistoryIndicatorReducer,
};
