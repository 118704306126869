import { createReducer, createActions } from 'reduxsauce';

import {
  DrawerState,
  ToggleDrawerAction,
  DrawerTypes,
  DrawerActions,
} from './types';

const { Types, Creators } = createActions<DrawerTypes, DrawerActions>({
  toggleDrawer: ['open'],
});

const INITIAL_STATE: DrawerState = {
  isOpen: false,
};

const toggleDrawer = (state = INITIAL_STATE, { open }: ToggleDrawerAction) => ({
  ...state,
  isOpen: Boolean(open),
});

const HANDLERS = {
  [Types.TOGGLE_DRAWER]: toggleDrawer,
};

const Reducer = createReducer(INITIAL_STATE, HANDLERS);

export {
  Creators as DrawerCreators,
  Types as DrawerTypes,
  Reducer as DrawerReducer,
};
