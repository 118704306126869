import { toastr } from 'react-redux-toastr';

import * as d3 from 'd3';
import { cloneDeep } from 'lodash';
import qs from 'qs';
import { put, select, call } from 'redux-saga/effects';

import config from '../../config';
import { Creators, Types } from '../actionCreators';
import { AppState } from '../reducers';
import {
  GetTreeHealthRequestWithTimePeriodAction,
  HandleLayerBlockRequestAction,
  SelectableIndicator,
} from './types';

/**
 * Make an API call to get the TreeHealth data using the TreeHealth states's time period and selected indicator.
 */
export function* getTreeHealth() {
  try {
    //  Calculate time request
    const { timePeriod, selectedIndicator, selectedCriticality } = yield select(
      (state: AppState) => state.treeHealth
    );

    let startTime;
    let endTime;

    if (typeof timePeriod.startTime === 'number') {
      startTime = timePeriod.startTime / 1000;
      endTime = timePeriod.endTime / 1000;
    } else {
      startTime = timePeriod.startTime;
      endTime = timePeriod.endTime;
    }

    //  Create query
    const query =
      !timePeriod.startTime && !timePeriod.endTime
        ? { time: { startTime, endTime } }
        : { startTime, endTime };

    const timeRequestQs = `${qs.stringify(query)}`;
    let criticalityString = JSON.stringify(selectedCriticality);

    if(criticalityString == undefined){
      criticalityString = "[0,1,2,3,4,5]"
    }

    // const treeHealthResponse = yield config.api.getTreeHealth(timeRequestQs);
    const treeHealthResponse = yield config.api.getTreeWithIndicator(
      timeRequestQs,
      selectedIndicator.uuid,
      criticalityString
    );

    //  Work with response
    const hierarchy = d3.hierarchy(treeHealthResponse.data);
    const initialSequence = hierarchy.ancestors().reverse();
    const treeMapData = cloneDeep(treeHealthResponse.data);
    const nodes = hierarchy.descendants();

    const selectedNode = initialSequence[initialSequence.length - 1];

    //  Nullify treeMapData children
    treeMapData.children.forEach((element: any) => {
      element.children = null;
    });

    yield put(
      Creators.getTreeHealthSuccess(
        treeHealthResponse.data,
        hierarchy,
        treeMapData,
        initialSequence,
        selectedNode,
        nodes
      )
    );

    yield put(
      Creators.setTreeHealthPrevTimePeriod(
        timePeriod
      )
    );
  } catch (error) {
    yield put(
      Creators.getTreeHealthFailure(
        'Ocorreu um erro ao buscar os registros para montagem do grafíco!'
      )
    );
  }
}

interface GetTreeHealthWithTimePeriodAction {
  type: typeof Types.GET_TREE_HEALTH_REQUEST_WITH_TIME_PERIOD;
  timePeriod: GetTreeHealthRequestWithTimePeriodAction['timePeriod'];
}

/**
 * Make an API call to get the TreeHealth data using the TreeHealth state's selected indicator, but with a passed time period.
 */
export function* getTreeHealthWithSpecificTimePeriod({
  timePeriod,
}: GetTreeHealthWithTimePeriodAction): any {
  try {
    //  Calculate time request
    const { selectedIndicator, selectedCriticality } = yield select(
      (state: AppState) => state.treeHealth
    );

    let startTime;
    let endTime;

    if (
      typeof timePeriod.startTime === 'number' &&
      typeof timePeriod.endTime === 'number'
    ) {
      startTime = timePeriod.startTime / 1000;
      endTime = timePeriod.endTime / 1000;
    } else {
      startTime = timePeriod.startTime;
      endTime = timePeriod.endTime;
    }

    //  Create query
    const query =
      !timePeriod.startTime && !timePeriod.endTime
        ? { time: { startTime, endTime } }
        : { startTime, endTime };

    const timeRequestQs = `${qs.stringify(query)}`;
    let criticalityString = JSON.stringify(selectedCriticality);

    if(criticalityString == undefined){
      criticalityString = "[0,1,2,3,4,5]"
    }
    // const treeHealthResponse = yield config.api.getTreeHealth(timeRequestQs);
    const treeHealthResponse = yield config.api.getTreeWithIndicator(
      timeRequestQs,
      selectedIndicator.uuid,
      criticalityString
    );

    //  Work with response
    const hierarchy = d3.hierarchy(treeHealthResponse.data);
    const initialSequence = hierarchy.ancestors().reverse();
    const treeMapData = cloneDeep(treeHealthResponse.data);
    const nodes = hierarchy.descendants();

    const selectedNode = initialSequence[initialSequence.length - 1];

    //  Nullify treeMapData children
    treeMapData.children.forEach((element: any) => {
      element.children = null;
    });

    yield put(
      Creators.getTreeHealthSuccess(
        treeHealthResponse.data,
        hierarchy,
        treeMapData,
        initialSequence,
        selectedNode,
        nodes
      )
    );
  } catch (error) {
    yield put(
      Creators.getTreeHealthFailure(
        'Ocorreu um erro ao buscar os registros para montagem do grafíco!'
      )
    );
  }
}

export function* getSelectableIndicators() {
  try {
    const { data } = yield config.api.getTreePossibleIndicators();
    const selectableIndicators = data as SelectableIndicator[];

    yield put(Creators.getSelectableIndicatorsSuccess(selectableIndicators));
  } catch (error) {
    yield put(
      Creators.getSelectableIndicatorsFailure(
        'Ocorreu um erro ao buscar os indicadores selecionáveis para filtrar o gŕafico!'
      )
    );
  }
}

interface HandleLayerBlockAction {
  type: typeof Types.HANDLE_LAYER_BLOCK_REQUEST;
  uuid: HandleLayerBlockRequestAction['uuid'];
  blocked: HandleLayerBlockRequestAction['blocked'];
}

export function* handleLayerBlock(action: HandleLayerBlockAction) {
  try {
    yield config.api.handleLayerBlock(action.uuid, action.blocked);
    yield put(Creators.handleLayerBlockSuccess());
    yield call(getTreeHealth as any);
  } catch (error) {
    const msg = 'Ocorreu um erro ao tentar bloquear o nível da árvore.';
    toastr.error('Erro', msg);
    yield put(Creators.handleLayerBlockFailure(msg));
  }
}
