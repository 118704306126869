import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import { useLocation, useHistory } from 'react-router-dom';

import {
  AppBar,
  Modal,
  Grow,
  List,
  ListItem,
  ListItemText,
  Divider,
  Zoom,
  ButtonBase,
} from '@material-ui/core';

import logo from '../../../assets/logo.png';
import menuIcon from '../../../assets/new_icons/menu_icon_mobile.svg';
import { Creators } from '../../../store/actionCreators';

import {
  Toolbar,
  LogoContainer,
  ToggleButtonsContainer,
  ModalContainer,
  CustomBackdrop,
} from './styles';
import { CustomIconButton } from '../styles';

import { SubHeaderProps } from '..';
import { destroyAuth, isFirebaseDev, useAuthStore } from '../../../zustand/AuthStore';
import { ROLES } from '../../../utils/authTypes';

export const MobileHeader: React.FC<SubHeaderProps> = (props) => {

  const keycloak = useAuthStore((state) => state.keycloak);

  const dispatch = useDispatch();

  const location = useLocation();
  const history = useHistory();

  const [isMenuOpen, setIsMenuOpen] = useState(false);

  function handleMenuOpen() {
    setIsMenuOpen(true);
  }

  function handleMenuClose() {
    setIsMenuOpen(false);
  }

  async function onLogoutPress() {
    handleMenuClose();
    await destroyAuth(dispatch, keycloak)
    // dispatch(Creators.destroyAuthRequest());
  }

  function onLinkClick(link: string) {
    handleMenuClose();
    if (!location.pathname.startsWith(link)) history.push(link);
  }

  function onLogoClick() {
    if (location.pathname === '/admin/dashboard') {
      history.push('/');
      history.goBack();
    } else history.push('/admin/dashboard');
  }

  return (
    <>
      <AppBar position="static">
        <Toolbar>
          <ToggleButtonsContainer>
            {props.navOptions.map((item) =>
              item.mobileToggleButtons.map((button) => (
                <Zoom
                  in={location.pathname.startsWith(item.path)}
                  unmountOnExit
                  mountOnEnter
                  key={item.name}
                  timeout={500}
                >
                  <div>{button}</div>
                </Zoom>
              ))
            )}
          </ToggleButtonsContainer>
          <LogoContainer>
            <ButtonBase onClick={onLogoClick} disableRipple>
              <img src={logo} alt="logo nexum" />
            </ButtonBase>
          </LogoContainer>
          <CustomIconButton
            aria-label="mostrar menu de navegação"
            onClick={handleMenuOpen}
            active={isMenuOpen}
          >
            <img src={menuIcon} alt="Icon Menu" />
          </CustomIconButton>
        </Toolbar>
      </AppBar>

      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        open={isMenuOpen}
        onClose={handleMenuClose}
        closeAfterTransition
        BackdropComponent={CustomBackdrop}
        BackdropProps={{
          timeout: 500,
        }}
      >
        <Grow in={isMenuOpen} style={{ transformOrigin: 'right top 0' }}>
          <ModalContainer>
            <h1>Menu</h1>
            <List component="nav" aria-label="itens de navegação">
              {props.navOptions.map((item) => (
                <ListItem
                  button
                  key={item.name}
                  onClick={() => onLinkClick(item.path)}
                >
                  <ListItemText primary={item.name.toUpperCase()} />
                </ListItem>
              ))}
              <Divider />
            </List>
            {(isFirebaseDev() || (keycloak && keycloak.hasRealmRole(ROLES.DEV))) && (
              <>
                <h1>Administrador</h1>
                <List component="nav" aria-label="itens de navegação de administrador">
                  <ListItem button onClick={() => onLinkClick('/admin/configuracao')}>
                    <ListItemText primary="CONFIGURAÇÕES" />
                  </ListItem>
                  <ListItem button onClick={() => onLinkClick('/admin/collectedTags')}>
                    <ListItemText primary="RELATÓRIO TAGS" />
                  </ListItem>
                </List>
                <Divider />
              </>
            )}
             
              <ListItem button onClick={onLogoutPress}>
                <ListItemText primary="SAIR" />
              </ListItem>
          </ModalContainer>
        </Grow>
      </Modal>
    </>
  );
};
