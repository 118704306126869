import React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';

import {
  TableContainer,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  Table as MaterialTable,
  IconButton,
  FormControl,
  Select,
  MenuItem,
  Checkbox,
} from '@material-ui/core';
import {
  Comment,
  ChatBubble,
  ChatBubbleOutline,
  Edit,
} from '@material-ui/icons';
import { Pagination } from '@material-ui/lab';
import { parse, subMinutes, addMinutes, isValid } from 'date-fns';
import shortid from 'shortid';

import { CustomSelect, CustomInput } from '../../../../components/CustomSelect';
import { NotificationInfo } from '../../../../models/NotificationInfo';
import { Creators } from '../../../../store/actionCreators';
import { AppState } from '../../../../store/reducers';
import { kSunburstColors } from '../../../../utils/constants';
import { TableHeaderItem } from '../../../../utils/types';

import {
  CodeContainer,
  Container,
  CriticalContainer,
  StatusSelectContainer,
} from './styles';

interface TableProps {
  onCodeClick: (notification: NotificationInfo) => void;
  onCommentClick(notification: NotificationInfo): void;
  onNotificationStatusChange(
    notification: NotificationInfo,
    newStatus: string
  ): void;
  onNotificationNonApplicableChange(
    notification: NotificationInfo,
    newNonApplicable: boolean
  ): void;
}

export const Table: React.FC<TableProps> = ({
  onCodeClick,
  onCommentClick,
  onNotificationStatusChange,
  onNotificationNonApplicableChange,
}) => {
  const notifications = useSelector((state: AppState) => state.notifications);
  const dispatch = useDispatch();

  const history = useHistory();

  const headers: TableHeaderItem[] = [
    { id: 'criticality', label: 'Criticidade' },
    { id: 'unit', label: 'Unidade' },
    { id: 'Equipamento', label: 'Equipamento' },
    { id: 'Ativo', label: 'Ativo' },
    { id: 'description', label: 'Descrição' },
    { id: 'createDate', label: 'Primeira Ocorrência' },
    { id: 'lastOccurrence', label: 'Última Ocorrência' },
    { id: 'code', label: 'Código' },
    {
      id: 'notas',
      label: <Comment />,
    },
    { id: 'nonApplicable', label: 'Não Aplicável' },
    { id: 'status', label: 'Status' },
  ];

  function buildCode(notification: NotificationInfo) {
    return (
      <CodeContainer>
        <p>{!notification.code ? '-' : notification.code}</p>
        <IconButton size="small" onClick={() => onCodeClick(notification)}>
          <Edit />
        </IconButton>
      </CodeContainer>
    );
  }

  function buildCriticality(criticality: 'Alta' | 'Média' | 'Baixa') {
    let color = kSunburstColors[3].rgb;
    if (criticality === 'Média') color = kSunburstColors[6].rgb;
    else if (criticality === 'Baixa') color = kSunburstColors[8].rgb;

    return (
      <CriticalContainer color={`rgb(${color.join(',')})`}>
        <div className="critical-tag" />
        <p>{criticality}</p>
      </CriticalContainer>
    );
  }

  function buildComment(notification: NotificationInfo) {
    return (
      <IconButton onClick={() => onCommentClick(notification)}>
        {notification.observations ? <ChatBubble /> : <ChatBubbleOutline />}
      </IconButton>
    );
  }

  function buildNonApplicable(notification: NotificationInfo) {
    return (
      <Checkbox
        color="primary"
        checked={notification.nonApplicable}
        onChange={(event) => onNotificationNonApplicableChange(notification, event.target.checked)}
        disabled={notification.status === 'Encerrada'}
        inputProps={{ 'aria-label': 'secondary checkbox' }}
      />
    );
  }

  function buildStatusSelect(notification: NotificationInfo) {
    return (
      <StatusSelectContainer variant="outlined">
        <Select
          value={notification.status}
          onChange={(
            event: React.ChangeEvent<{
              value: unknown;
            }>
          ) =>
            onNotificationStatusChange(notification, String(event.target.value))
          }
          fullWidth
          style={{
            border: '0',
          }}
        >
          {[
            ['Pendente', 'Pendente'],
            ['Analise', 'Em análise'],
            ['Encerrada', 'Encerrada'],
          ].map((item) => (
            <MenuItem
              key={item[0]}
              value={item[0]}
              disabled={item[0] === notification.status}
              style={{
                border: 0,
              }}
            >
              {item[1]}
            </MenuItem>
          ))}
        </Select>
      </StatusSelectContainer>
    );
  }

  function createRow(notification: NotificationInfo) {

    return [
      buildCriticality(notification.criticality),
      notification.asset.unit,
      notification.asset.equipment,
      notification.asset.name,
      notification.description,
      // eslint-disable-next-line no-nested-ternary
      isValid(new Date(notification.createDate))
        ? Intl.DateTimeFormat('pt-br', {
          year: 'numeric',
          month: '2-digit',
          day: '2-digit',
          hour: '2-digit',
          minute: '2-digit',
        }).format(new Date(notification.createDate))
        : isValid(new Date(`${notification.createDate.split('U')[0]}Z`))
          ? Intl.DateTimeFormat('pt-br', {
            year: 'numeric',
            month: '2-digit',
            day: '2-digit',
            hour: '2-digit',
            minute: '2-digit',
          })
            .format(new Date(`${notification.createDate.split('U')[0]}Z`))
            .toString()
          : 'Data Inválida',
      // eslint-disable-next-line no-nested-ternary
      isValid(new Date(notification.lastOccurrence))
        ? Intl.DateTimeFormat('pt-br', {
          year: 'numeric',
          month: '2-digit',
          day: '2-digit',
          hour: '2-digit',
          minute: '2-digit',
        }).format(new Date(notification.lastOccurrence))
        : isValid(new Date(`${notification.lastOccurrence.split('U')[0]}Z`))
          ? Intl.DateTimeFormat('pt-br', {
            year: 'numeric',
            month: '2-digit',
            day: '2-digit',
            hour: '2-digit',
            minute: '2-digit',
          })
            .format(new Date(`${notification.lastOccurrence.split('U')[0]}Z`))
            .toString()
          : 'Data Inválida',
      buildCode(notification),
      buildComment(notification),
      buildNonApplicable(notification),
      buildStatusSelect(notification),
    ];
  }

  function handleNotificationClick(notification: NotificationInfo) {
    const dateFormat = isValid(new Date(notification.createDate))
      ? Intl.DateTimeFormat('pt-br', {
        year: 'numeric',
        month: '2-digit',
        day: '2-digit',
        hour: '2-digit',
        minute: '2-digit',
      }).format(new Date(notification.createDate))
      : isValid(new Date(`${notification.createDate.split('U')[0]}Z`))
        ? Intl.DateTimeFormat('pt-br', {
          year: 'numeric',
          month: '2-digit',
          day: '2-digit',
          hour: '2-digit',
          minute: '2-digit',
        })
          .format(new Date(`${notification.createDate.split('U')[0]}Z`))
          .toString()
        : 'Data Inválida';

    const date = parse(dateFormat, 'dd/MM/yyyy, HH:mm', new Date());

    // dispatch(
    //   Creators.setTreeHealthTimePeriod({
    //     startTime: subMinutes(date, 30).getTime(),
    //     endTime: addMinutes(date, 30).getTime(),
    //   })
    // );
    // dispatch(Creators.getTreeHealthRequest());

    dispatch(
      Creators.setAssetTimePeriod({
        startTime: subMinutes(date, 60).getTime(),
        endTime: addMinutes(date, 60).getTime(),
      })
    );

    dispatch(Creators.setCurrentNotification(notification.id));

    history.push(
      `/admin/dashboard/${handleNotificationURL(notification)}`
    );
  }

  function handlePageChange(page: number) {
    dispatch(
      Creators.getNotificationsRequest({
        ...notifications.pageRequest,
        page,
      })
    );
  }

  function handleRowsPerPageChange(lines: number) {
    dispatch(
      Creators.getNotificationsRequest({
        ...notifications.pageRequest,
        lines,
      })
    );
  }

  function handleNotificationURL(notification: NotificationInfo) {
    var assetType = 'ativo';
    var tagType = 'analisededados';

    // TODO: use asset type instead of assetClass
    if (notification.asset.assetClass === 'Malha de Controle') {
      assetType = 'ativo-logico';
    } else {
      tagType = notification.uuidTag === null ?
        'saude' : 'analisededados';
    }

    return `${assetType}/${notification.asset.uuid}/${tagType}`;
  }

  return (
    <Container>
      <TableContainer>
        <MaterialTable aria-label="table">
          <TableHead>
            <TableRow>
              {headers.map((item) => (
                <TableCell align="center" className="header-cell" key={item.id}>
                  {item.label}
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {notifications.data.map((notification, index) => (
              <TableRow
                key={notification.id}
                className={index % 2 !== 0 ? 'grey-row row' : 'row'}
              >
                {createRow(notification).map((attr) => (
                  <TableCell
                    key={shortid.generate()}
                    className={
                      typeof attr !== typeof (<p />)
                        ? 'body-cell clickable'
                        : 'body-cell'
                    }
                    onClick={
                      typeof attr !== typeof (<p />)
                        ? () => handleNotificationClick(notification)
                        : undefined
                    }
                    component="th"
                    scope="row"
                    align="center"
                  >
                    {/* Essa implementação é para abrir a notificação em uma nova aba. Mas é preciso mandar os dados da notificação para essa nova aba.
                    <a
                      href={`/admin/dashboard/${handleNotificationURL(notification)}`}
                      className = "attr-container"
                      >{attr}</a> */}
                    {attr}
                  </TableCell>
                ))}
              </TableRow>
            ))}
          </TableBody>
        </MaterialTable>
      </TableContainer>
      <div className="table-menu">
        <div className="rows-per-page-container">
          <h6>Itens por página</h6>
          <FormControl variant="outlined" fullWidth>
            <CustomSelect
              value={notifications.pageRequest.lines}
              onChange={(
                event: React.ChangeEvent<{
                  value: unknown;
                }>
              ) => handleRowsPerPageChange(Number(event.target.value))}
              fullWidth
              input={<CustomInput />}
            >
              {Array.from(Array(4), (_, i) => (
                <MenuItem key={i} value={(i + 1) * 5}>
                  {(i + 1) * 5}
                </MenuItem>
              ))}
            </CustomSelect>
          </FormControl>
        </div>
        <div className="pages-container">
          <Pagination
            count={notifications.pageResponse.totalPages}
            variant="outlined"
            shape="rounded"
            siblingCount={0}
            boundaryCount={1}
            page={notifications.pageResponse.actualPage + 1}
            onChange={(_: any, value: number) => handlePageChange(value - 1)}
          />
        </div>
      </div>
    </Container>
  );
};
