import { UserLoginState } from '../../screens/Auth/components/Login';
import { InputError } from '../types';

export function validateLoginPassword(item: UserLoginState): InputError[] {
  const errors: InputError[] = [];

  if (!item.password) {
    const msg = 'Digite sua senha!';
    errors[1] = { field: 'Senha', msg };
  }

  return errors;
}

export function validateLoginEmail(item: UserLoginState): InputError[] {
  const errors: InputError[] = [];

  const emailRegex = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

  if (!item.email) {
    const msg = 'Digite seu email!';
    errors[0] = { field: 'Email', msg };
  } else if (!emailRegex.test(item.email)) {
    const msg = 'Digite um email valido!';
    errors[0] = { field: 'Email', msg };
  }

  return errors;
}

export function validateLogin(item: UserLoginState): InputError[]{

const erros = [...  validateLoginEmail(item),   ...validateLoginPassword(item)]

return erros
}
