import React, { useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
    faChevronRight,
    faChevronDown
} from "@fortawesome/fontawesome-free-solid";
import { IconProp } from "@fortawesome/fontawesome-svg-core";
import { CollapsibleButton, CollapsibleTitle, CollapsibleContent, CollapsibleContentItem, CollapsibleContainer } from "./styles";

interface IProps {
    title: string;
    isHealth: boolean;
    isMobile?: boolean;
}

export const Collapsible: React.FC<IProps> = ({isHealth, children, title, isMobile}) => {
    const [isOpen, setIsOpen] = useState(false);

    const handleFilterOpening = () => {
        setIsOpen(!isOpen);
    };

    return (
        <CollapsibleContainer>
            <CollapsibleContent backgroundColor={isMobile? '#FFFFFF' : '#e6e8eb' }>
                <CollapsibleButton type="button" className="btn" onClick={handleFilterOpening} disabled ={!isHealth}>
                    {!isOpen ? (
                        <FontAwesomeIcon icon={faChevronRight as IconProp}  color= {isHealth? "#00000": "#8c8c8c"}/>
                    ) : (
                        <FontAwesomeIcon icon={faChevronDown as IconProp} />
                    )}
                </CollapsibleButton>
                <CollapsibleTitle isHealth={isHealth}>
                    {title}
                </CollapsibleTitle>

            </CollapsibleContent >
            <div>
                <CollapsibleContentItem isOpen={isOpen}>
                    {children}
                </CollapsibleContentItem>
            </div>
        </CollapsibleContainer>
    );
};
