import React, { useEffect, useState, useRef } from 'react';
import Media from 'react-media';
import { useSelector, useDispatch } from 'react-redux';
import { useLocation } from 'react-router-dom';

import { NotificationInfo } from '../../../../../models/NotificationInfo';
import { Creators } from '../../../../../store/actionCreators';
import { AppState } from '../../../../../store/reducers';
import { getAssetIdFromPath } from '../../../../../utils/methods';
import { Panel } from './Panel';

import { deviceSizes } from '../../../../../styles/supportedDevices';

interface NotificationsPanelProps {
  isOpen: boolean;
}

export interface ResponsiveNotificationsPanelProps {
  isOpen: boolean;
  fetchNotifications(): void;
  notificationList: NotificationInfo[];
  searchTextField: React.RefObject<HTMLInputElement>;
  handleSearchSubmit(): void;
}

export const NotificationsPanel: React.FC<NotificationsPanelProps> = ({
  isOpen,
}) => {
  const notifications = useSelector((state: AppState) => state.notifications);
  const dispatch = useDispatch();

  const location = useLocation();

  const [notificationList, setNotificationList] = useState<NotificationInfo[]>(
    []
  );

  const searchTextField = useRef<HTMLInputElement>(null);

  function fetchNotifications(page?: number) {
    if (page) {
      const params = {
        ...notifications.pageRequest,
        page,
      };

      const assetId = getAssetIdFromPath(location.pathname);

      if (assetId.length === 36)
        dispatch(Creators.getNotificationsByAssetIdRequest(params));
      else dispatch(Creators.getNotificationsRequest(params));
    } else dispatch(Creators.getNotificationsRequest());
  }

  useEffect(() => {
    const assetId = getAssetIdFromPath(location.pathname);

    if (assetId !== notifications.uuid || assetId.length === 0) {
      setNotificationList([]);

      dispatch(
        Creators.searchNotification(searchTextField.current?.value.trim() || '')
      );
      dispatch(Creators.changeNotificationTab('Pendente'));

      const params = {
        page: 0,
        lines: 10,
        orderBy: ['criticality', 'lastOccurrence'],
        direction: 'DESC' as 'DESC',
      };

      if (assetId.length === 36) {
        dispatch(Creators.setUuid(assetId));
        dispatch(Creators.setSearchAbility(false));
        dispatch(Creators.getNotificationsByAssetIdRequest(params));
      } else {
        dispatch(Creators.setUuid(''));
        dispatch(Creators.setSearchAbility(true));
        dispatch(Creators.getNotificationsRequest(params));
      }
    }
  }, [dispatch, location, notifications.searchText, notifications.uuid]);

  useEffect(() => {
    if (notifications.pageRequest.page === 0)
      setNotificationList(notifications.data);
    else setNotificationList((oldList) => [...oldList, ...notifications.data]);
  }, [notifications.data, notifications.pageRequest.page]);

  function handleSearchSubmit() {
    if (searchTextField.current?.value !== undefined)
      dispatch(Creators.searchNotification(searchTextField.current.value));
  }

  const params = {
    isOpen,
    fetchNotifications: () =>
      fetchNotifications(notifications.pageRequest.page! + 1),
    notificationList,
    searchTextField,
    handleSearchSubmit,
  };

  return (
    <Media
      queries={{
        tablet: `(max-width: ${deviceSizes.tablet}px)`,
      }}
    >
      {(matches) => <>{!matches.tablet && <Panel {...params} />}</>}
    </Media>
  );
};
