import React from 'react';
import { Redirect, RouteComponentProps, Route, Switch } from 'react-router-dom';

import { Screen404 } from './404';
import { Dashboard } from './Dashboard';
import { DataAnalysis } from './DataAnalysis';
import { Notification } from './Notification';
import { Reports } from './Reports';
import { useAuthStore } from '../zustand/AuthStore';
import { CollectedTags } from './Reports/components/views/HealthAndIndicatorView/components/menus/tags';
import { Configurations } from './ConfigurationScreen';

export interface RouterProps extends RouteComponentProps {
  notification: boolean;
  os: boolean;
}

function ProtectedRoute({ children }: { children: React.ReactElement }) {
  const { isAuth } = useAuthStore();

  if (!isAuth) {
    return <Redirect to="/login" />;
  }

  return children;
}

export const Router: React.FC<RouterProps> = ({ notification, os, match }) => {
  return (
    <Switch>
      <Route
        path={`${match.path}/dashboard`}
        render={(props) => (
          <ProtectedRoute>
            <Dashboard {...props} isNotificationOpen={notification} isOsOpen={os} />
          </ProtectedRoute>
        )}
      />
      <Route
        path={`${match.path}/notificacoes`}
        render={(props) => (
          <ProtectedRoute>
            <Notification {...props} />
          </ProtectedRoute>
        )}
      />
      <Route
        path={`${match.path}/collectedTags`}
        render={(props) => (
          <ProtectedRoute>
            <CollectedTags {...props} />
          </ProtectedRoute>
        )}
      />
      <Route
        path={`${match.path}/configuracao`}
        render={(props) => (
          <ProtectedRoute>
            <Configurations {...props} />
          </ProtectedRoute>
        )}
      />
      <Route
        path={`${match.path}/relatorios`}
        render={(props) => (
          <ProtectedRoute>
            <Reports {...props} />
          </ProtectedRoute>
        )}
      />
      <Route
        path={`${match.path}/comparative-analysis`}
        render={() => (
          <ProtectedRoute>
            <DataAnalysis />
          </ProtectedRoute>
        )}
      />
      <Route
        path={`${match.path}/404`}
        render={() => (
          <ProtectedRoute>
            <Screen404 />
          </ProtectedRoute>
        )}
      />
      <Redirect exact from={`${match.path}`} to={`${match.path}/dashboard`} />
      <Redirect from={`${match.path}/*`} to="/404" />
    </Switch>
  );
};
