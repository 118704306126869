import { Grid, Theme, Paper } from '@material-ui/core';
import styled from 'styled-components';

import { supportedDevices } from '../../../../styles/supportedDevices';

export const HeaderContainer = styled.div`
  padding: 28px 36px;
  border-radius: 4px 4px 0 0;
  background: ${(props: { theme: Theme }) =>
    props.theme.palette.background.default};
  color: white;

  h5 {
    font-weight: bold;
    font-size: 24px;
  }

  @media ${supportedDevices.tablet} {
    padding: 28px 60px;
  }
`;

export const IconContainer = styled(Grid)`
  padding: 5px;

  button {
    padding: 10px;

    &:hover {
      background: none;
    }
  }
`;

export const MonthContainer = styled(Paper)`
  && {
    background: ${(props) => props.theme.palette.background.paper};

    && li,
    && div {
      font-size: 16px;
    }

    && svg {
      top: unset;
      font-size: 18px;
    }

    & .weekDaysContainer {
      margin-top: 10px;
      padding-left: 30px;
      padding-right: 30px;
    }

    & .daysContainer {
      padding-left: 15px;
      padding-right: 15px;
      margin-top: 15px;
      margin-bottom: 20px;
    }
  }
`;

export const DayContainer = styled.div`
  && {
    && p {
      font-size: 14px;
    }

    &.leftBorderRadius {
      border-radius: 50% 0 0 50%;
    }

    &.rightBorderRadius {
      border-radius: 0 50% 50% 0;
    }

    &.highlighted {
      background-color: ${(props) => props.theme.palette.action.hover};
    }

    & .buttonContainer {
      display: flex;
    }

    & .button {
      height: 36px;
      width: 36px;
      padding: 0px;
    }

    & .buttonText {
      line-height: 1.6px;
    }

    & .outlined {
      border: 1px solid ${(props) => props.theme.palette.primary.dark};
    }

    & .filled {
      background-color: ${(props) => props.theme.palette.primary.dark};

      &:hover {
        background-color: ${(props) => props.theme.palette.primary.dark};
      }
    }

    & .contrast {
      color: ${(props) => props.theme.palette.primary.contrastText};
    }
  }
`;

export const TimeFilterContainer = styled.div`
  min-height: 48px;
  display: flex;
  justify-content: space-around;
  align-items: center;

  .time-picker-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    .MuiFormControl-root {
      width: 90px;

      div.MuiSelect-outlined.MuiSelect-outlined {
        padding: 10px;
      }

      svg {
        top: unset;
      }
    }
  }
`;

export const ButtonsContainer = styled.div`
  background: ${(props) => props.theme.palette.background.paper};
  display: flex;
  padding: 28px;
  justify-content: space-evenly;
  border-radius: 0 0 4px 4px;

  && button {
    color: white;
    width: 100px;
    background: #484848;
    font-size: 14px;

    &.gradient {
      background-image: linear-gradient(to right, #19b0a0, #009ee2);
    }
  }
`;
