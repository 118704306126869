import { Button } from '@material-ui/core';
import styled from 'styled-components';

export const Container = styled.div`
  height: 50px;
  display: flex;
  align-items: center;

  & .buttons-container {
    display: flex;
    overflow-y: auto;

    ::-webkit-scrollbar {
      height: 0;
    }
  }
`;

interface ToggleIndexButtonProps {
  isSelected: boolean;
  borderColor: string;
}

export const ToggleIndexButton = styled(Button).withConfig({
  shouldForwardProp: (prop) =>
    !['isSelected'].includes(prop) && !['borderColor'].includes(prop),
})<ToggleIndexButtonProps>`
  && {
    min-width: 100px;
    margin-right: 5px;
    border-left: solid 5px ${(props) => props.borderColor};
    font-weight: 600;
    box-shadow: 0 0 5px 0 rgba(0, 0, 0, 0.1);

    background: ${({ isSelected }) => (isSelected ? '#C8C9CC' : '#E6E8EB')};

    font-size: 1rem;
  }

  &&:hover {
    background: ${({ isSelected }) => (isSelected ? '#B6B7BA' : '#D7D9DB')};
  }
`;
