import React from 'react';
import { TreeView } from '../../../../components/TreeView';
import { Container } from './style';
import {
  setDefaultTreeElement,
  useAuthStore,
} from '../../../../zustand/AuthStore';
import { toastr } from 'react-redux-toastr';

async function handleTreeItemSelect(id: string) {
  try {
    await setDefaultTreeElement(id);
  } catch (error) {
    toastr.error('Erro', 'Ocorreu um erro ao trocar nível inicial da árvore.');
  }
}

export const TreeViewWrapper: React.FC = () => {
  const { user } = useAuthStore((state) => state);

  return (
    <Container>
      <TreeView
        selectedItems={
          user.defaultTreeElementUuid
            ? [user.defaultTreeElementUuid]
            : user.tree
            ? [user.tree.uuid]
            : []
        }
        onItemSelect={handleTreeItemSelect}
        selectionMode={'single'}
        selectableTreeItems={'all'}
        showZeroImportance={false}
        showLeaves={false}
      />
    </Container>
  );
};
