import styled from 'styled-components';

interface IndicatorContainerProps {
  fontSize: number;
  size: number;
  width?: number;
  spaceBetween: number;
}

export const IndicatorContainer = styled.section<IndicatorContainerProps>`
  display: flex;
  align-items: center;
  justify-content: center;
  background: white;
  height: ${(props) => props.size * 2}px;
  width: ${(props) => props.width || 140}px;

  & #indicatorSvg {
    position: relative;
    width: 100%;
    height: 100%;
    left: 0px;
    top: 0px;
    margin-right: ${({ spaceBetween }) => spaceBetween}px;
  }

  #textDiv {
    display: flex;
    flex-direction: column;
  }

  & .text {
    font-size: ${(props) => props.fontSize}px;
    font-weight: bold;
    color: black;
    text-align: center;
    max-height: 50px;
  }

  & .indicatorText {
    font-size: ${(props) => props.fontSize / 2.3}px;
    font-weight: bold;
    color: black;
    text-align: center;
    line-height: 1.1em;
  }
`;
