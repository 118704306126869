import React from 'react';

import { Chip, InputAdornment, InputBase, TextField } from '@material-ui/core';
import { Search as SearchIcon } from '@material-ui/icons';
import { Autocomplete } from '@material-ui/lab';

import { AutocompleteContainer, GlobalStyle } from './styles';

export interface SearchFieldProps {
  /**
   * Pre-fixed options to be displayed
   */
  options: any[];

  /**
   * Field placeholder text
   */
  placeholder?: string;

  /**
   * Should show search icon boolean
   */
  showSearchIcon?: boolean;

  /**
   * Current selected value for controlled usage
   */
  value?: any;

  /**
   * If options are loading
   */
  loading?: boolean;

  /**
   * Should use arbitrary values
   */
  freeSolo?: boolean;

  /**
   * If the search field should allow for multiple selections
   */
  multiple?: boolean;

  /**
   * Maximum tags limit for multiple select
   */
  limitTags?: number;

  /**
   * On change callback
   * @param event Dispatched event
   * @param value New selected value
   */
  onChange?: (event: React.ChangeEvent<{}>, value: any) => void;

  /**
   * Get label from complex options
   * @param option Option to extract label from
   */
  getOptionLabel(option: any): string;

  /**
   * Extract option value to match selection
   * @param option new selected option
   * @param value option value
   */
  getOptionSelected?(option: any, value: any): boolean;

  /**
   * If the input can be cleared.
   */
  disableClearable?: boolean

  renderOption?(option: any, state: { selected: boolean }): React.ReactNode;

  open?: boolean;
  onOpen?: () => void; 
  onClose?: () => void; 
}

/**
 * Search field with combo-box behavior
 */
export const SearchField: React.FC<SearchFieldProps> = ({
  options,
  onChange,
  getOptionLabel,
  placeholder = 'Procurar',
  showSearchIcon = true,
  value,
  getOptionSelected,
  loading = false,
  freeSolo = false,
  multiple = false,
  limitTags = 1,
  disableClearable = false,
  renderOption,
  open,
  onOpen,
  onClose
}) => (
  <>
    <GlobalStyle />
    <AutocompleteContainer
      hasSearchIcon={showSearchIcon}
      data-testid="searchField"
    >
      <Autocomplete
        disableClearable={disableClearable}
        multiple={multiple}
        options={options}
        value={value}
        onChange={onChange}
        getOptionLabel={getOptionLabel}
        getOptionSelected={getOptionSelected}
        filterSelectedOptions
        fullWidth
        loading={loading}
        loadingText="Carregando..."
        noOptionsText="Não encontrado"
        freeSolo={freeSolo}
        limitTags={limitTags}
        renderGroup={() => <div className="group" />}
        renderOption={renderOption}
        renderInput={(params) =>
          multiple ? (
            <TextField
              {...params}
              variant="outlined"
              ref={params.InputProps.ref}
              inputProps={params.inputProps}
              className="search-input text-field"
              data-testid="searchInput"
              fullWidth
              placeholder={placeholder}
            />
          ) : (
            <InputBase
              ref={params.InputProps.ref}
              inputProps={params.inputProps}
              className="search-input"
              data-testid="searchInput"
              fullWidth
              placeholder={placeholder}
              startAdornment={
                showSearchIcon && (
                  <InputAdornment className="search-icon" position="start">
                    <SearchIcon fontSize="large" />
                  </InputAdornment>
                )
              }
              endAdornment={params.InputProps.endAdornment}
            />
          )
        }
        renderTags={(tags: string[], getTagProps) =>
          tags.map((option: string, index: number) => (
            <Chip className="chip" label={option} {...getTagProps({ index })} />
          ))
        }
        open={open}
        onOpen={onOpen}
        onClose={onClose}
      />
    </AutocompleteContainer>
  </>
);
