import React, { useState } from 'react';

import {
  TableContainer,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  Table as MaterialTable,
  MenuItem,
  FormControl,
} from '@material-ui/core';
import { Pagination } from '@material-ui/lab';
import shortid from 'shortid';

import { useHorizontalScroll } from '../../../utils/useHorizontalScroll';
import { CustomSelect, CustomInput } from '../../CustomSelect';

import { Container } from './styles';

interface TableProps {
  headers: (string | JSX.Element)[];
  rows: any[];
  firstColumnStyle?: React.CSSProperties;
  createRow(item: any): (string | any | JSX.Element)[];
  onClick?: (element: any) => void;
  secondHeaders?: (string | JSX.Element)[] | null;
}

export const Table: React.FC<TableProps> = ({
  headers,
  rows,
  createRow,
  onClick,
  firstColumnStyle,
  secondHeaders = null,
}) => {
  const tableRef = useHorizontalScroll<HTMLDivElement>();

  const [itensPerPage, setItensPerPage] = useState(20);
  const [page, setPage] = useState(0);

  function buildPaginatedData() {
    const startingItem = page * itensPerPage;
    return rows?.slice(startingItem, startingItem + itensPerPage);
  }

  return (
    <Container data-testid="tableContainer">
      <TableContainer ref={tableRef}>
        <MaterialTable aria-label="table">
          <TableHead>
            <TableRow>
              {headers.map((item) => (
                <TableCell
                  align="center"
                  className="header-cell"
                  key={shortid.generate()}
                >
                  {item}
                </TableCell>
              ))}
            </TableRow>
            {secondHeaders && (
              <TableRow>
                {secondHeaders.map((item) => (
                  <TableCell
                    align="center"
                    className="header-cell"
                    key={shortid.generate()}
                  >
                    {item}
                  </TableCell>
                ))}
              </TableRow>
            )}
          </TableHead>
          <TableBody>
            {buildPaginatedData()?.map((item, index) => (
              <TableRow
                key={shortid.generate()}
                className={index % 2 !== 0 ? 'grey-row row' : 'row'}
              >
                {createRow(item)?.map((attr, i) => (
                  <TableCell
                    key={shortid.generate()}
                    className={`body-cell ${onClick ? 'clickable' : ''}`}
                    component="th"
                    scope="row"
                    style={i === 0 ? firstColumnStyle : undefined}
                    align="center"
                    onClick={onClick ? () => onClick(item) : undefined}
                  >
                    {attr}
                  </TableCell>
                ))}
              </TableRow>
            ))}
          </TableBody>
        </MaterialTable>
      </TableContainer>

      <div className="table-menu">
        <div className="rows-per-page-container">
          <h6>Itens por página</h6>
          <FormControl variant="outlined" fullWidth>
            <CustomSelect
              value={itensPerPage}
              onChange={(
                event: React.ChangeEvent<{
                  value: unknown;
                }>
              ) => setItensPerPage(Number(event.target.value))}
              fullWidth
              input={<CustomInput />}
            >
              {Array.from(Array(4), (_, i) => (
                <MenuItem key={i} value={(i + 1) * 10}>
                  {(i + 1) * 10}
                </MenuItem>
              ))}
            </CustomSelect>
          </FormControl>
        </div>
        <div className="pages-container">
          <Pagination
            count={
              Math.floor(rows?.length / itensPerPage) +
              Number(rows?.length / itensPerPage !== 0)
            }
            variant="outlined"
            shape="rounded"
            siblingCount={0}
            boundaryCount={1}
            page={page + 1}
            onChange={(_: any, value: number) => setPage(value - 1)}
          />
        </div>
      </div>
    </Container>
  );
};
