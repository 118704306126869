import React, { useState, useEffect } from 'react';
import Media from 'react-media';
import { useDispatch, useSelector } from 'react-redux';

import {
  Typography,
  Checkbox,
} from '@material-ui/core';

import{
  CriticalityOption,
  CriticalityConfirmationButton
} from './styles'

import { CircularProgress, Collapse, Slide } from '@material-ui/core';

import { ResponsiveGraphViewProps } from '..';

import errorIcon from '../../../../../../assets/imgs/error-icon.png';
import sunburstOnIcon from '../../../../../../assets/new_icons/ic-switch-sunburst-select.svg';
import sunburstOffIcon from '../../../../../../assets/new_icons/ic-switch-sunburst-unselect.svg';
import treeMapOnIcon from '../../../../../../assets/new_icons/ic-switch-treemap-select.svg';
import treeMapOffIcon from '../../../../../../assets/new_icons/ic-switch-treemap-unselect.svg';
import { Sunburst } from '../../../../../../components/charts/Sunburst';
import { Treemap } from '../../../../../../components/charts/Treemap';
import { DateFilter } from '../../../../../../components/DateFilter';
import { SearchField } from '../../../../../../components/SearchField';
import { ToggleChartButton } from '../../../../../../components/ToggleChartButton';
import { Creators } from '../../../../../../store/actionCreators';
import { AppState } from '../../../../../../store/reducers';
import { SelectableIndicator } from '../../../../../../store/treeHealth/types';
import { kSunburstColors } from '../../../../../../utils/constants';
import { ColorFilters } from './components/ColorFilters';
import { HistoryTab } from '../DesktopGraphView/components/History';
import { Collapsible } from '../DesktopGraphView/components/Collapsible';
import { Header } from './components/Header';

import {
  deviceSizes,
  supportedDevices,
} from '../../../../../../styles/supportedDevices';
import { ChartsContainer, Container } from './styles';
import { match } from 'assert';
import { Creators as NotificationsCreators } from '../../../../../../store/actionCreators';

export const MobileGraphView: React.FC<ResponsiveGraphViewProps> = ({
  filteredColors,
  selectedHealth,
  visibleGraph,
  onSelect,
  onHover,
  toggleChartVisible,
  onFilterClick,
  getNodeColor,
  onTreemapSelect,
  onTreemapHover,
  handleLayerBlock,
  timeList,
}) => {
  const {
    isLoading,
    error,
    selectedNode,
    hierarchy,
    treeMapData,
    selectableIndicatorsOptions,
    selectedIndicator,
  } = useSelector((state: AppState) => state.treeHealth);

  const treeHealth = useSelector((state: AppState) => state.treeHealth);

  const dispatch = useDispatch();

  const handleIndicatorSelection = (
    _: React.ChangeEvent,
    value: SelectableIndicator
  ) => {
    dispatch(Creators.setSelectedIndicator(value));
    dispatch(Creators.getTreeHealthRequest());
  };

  useEffect(() => {
    if (selectedNode.data != null) {
      const selectedAssetUuid = selectedNode.data.uuid;
      dispatch(NotificationsCreators.setSelectedNodeUuid(selectedAssetUuid));
      dispatch(NotificationsCreators.getNotificationsRequest());
    }
  }, [selectedNode]);

  function buildLoaderOrError() {
    if (error)
      return (
        <div className="card error-container">
          <img src={errorIcon} alt="Ícone de erro" />
          <h3>Ocorreu um erro! Recarregue a página para tentar novamente.</h3>
        </div>
      );
    return (
      <div className="card loader-container">
        <CircularProgress size={35} />
      </div>
    );
  }

  const getInitialCriticalLevelsSize = () => {

    if (treeHealth.selectedCriticality != null) {
      const initialLevels = Array.from({ length: 7 }, (_, index) => ({
        index,
        checked: treeHealth.selectedCriticality.includes(index)
      }));
      return initialLevels;
    }
  };
  const [open, setOpen] = useState(false);

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };


  const [criticalLevelsSize, setCriticalLevelsSize] = useState(getInitialCriticalLevelsSize());
  
  useEffect(() => {
    localStorage.setItem('criticalLevelsSize', JSON.stringify(criticalLevelsSize));
  }, [criticalLevelsSize]);

  const [selectedOptions, setSelectedOptions] = useState([]);

  const handleChangeCriticality = (event, value) => {
    if (value !== null) {
      const index = value;
      const newCriticalLevelsSize = [...criticalLevelsSize];
      newCriticalLevelsSize[index] = {
        ...newCriticalLevelsSize[index],
        checked: !newCriticalLevelsSize[index].checked,
      };
      setCriticalLevelsSize(newCriticalLevelsSize);
      setSelectedOptions((prev) => {
        if (prev.includes(value)) {
          return prev.filter((item) => item !== value);
        } else {
          return [...prev, value];
        }
      });
    }
  };

  const criticalLevels = ["Sem Criticidade", "Criticidade 1", "Criticidade 2", "Criticidade 3", "Criticidade 4", "Criticidade 5", "Confirmar"];

  function handleCriticalityConfirm() {
    const checkedIndexes = [];
    criticalLevelsSize.forEach((item, index) => {
      if (item.checked && index < criticalLevelsSize.length - 1) {
        checkedIndexes.push(index);
      }
    });

    if (checkedIndexes.length !== 0) {
      dispatch(Creators.setSelectedCriticality(checkedIndexes));
      dispatch(Creators.getTreeHealthRequest());
    }
  }

  const handleOptionClick = (index) => (event) => {
    event.stopPropagation();
    const newCriticalLevelsSize = [...criticalLevelsSize];
    newCriticalLevelsSize[index] = {
      ...newCriticalLevelsSize[index],
      checked: !newCriticalLevelsSize[index].checked,
    };
    setCriticalLevelsSize(newCriticalLevelsSize);
  };

  return (
    <Container>
      <Slide in direction="left" timeout={500}>
        {isLoading || error || !selectedNode ? (
          buildLoaderOrError()
        ) : (
          <div className="card main-container">
            <div className="main-card">
              <Header
                onFilterSelect={onSelect}
                handleLayerBlock={handleLayerBlock}
                selectedIndicator={selectedIndicator.name}
              />
              <DateFilter
                fixedFilters={timeList}
                initialValue="ATUAL"
                breakpoint={supportedDevices.laptop}
              />
              <div className="mobileIndicatorSelector">
                <SearchField
                  getOptionLabel={(option: SelectableIndicator) => option.name}
                  options={selectableIndicatorsOptions}
                  onChange={handleIndicatorSelection}
                  showSearchIcon={false}
                  placeholder="Selecionar Indicador"
                  value={selectedIndicator}
                  getOptionSelected={(
                    option: SelectableIndicator,
                    value: SelectableIndicator
                  ) => option.uuid === value.uuid}
                  disableClearable
                />
              </div>
              <div className="mobileIndicatorSelector">
              <SearchField
                showSearchIcon={false}
                disableClearable={true}
                getOptionLabel={(option) =>
                  "Criticidade"
                }
                options={criticalLevelsSize.map((_, index) => index)}
                value={treeHealth.selectedCriticality}
                onChange={(event, value) => {
                  handleChangeCriticality(event, value);
                }}
                placeholder="Criticidade"
                renderOption={(option) => {
                  if (option !== criticalLevelsSize.length - 1) {
                    return (
                      <CriticalityOption onClick={handleOptionClick(option)}>
                      <Checkbox
                        color="default"
                        checked={criticalLevelsSize[option].checked}
                        onChange={(e) => e.stopPropagation()}
                      />
                      <Typography>{criticalLevels[option]}</Typography>
                    </CriticalityOption>
                    );
                  } else {
                    return (
                      <CriticalityConfirmationButton
                      variant="contained"
                      onClick={handleCriticalityConfirm}
                >
                {"Confirmar"}
              </CriticalityConfirmationButton>
              );
            }
          }}
          open={open}
          onOpen={handleOpen}
          onClose={handleClose}
                />
              </div>
              <Media
                queries={{
                  mobileS: `(max-width: ${deviceSizes.mobileS}px)`,
                }}
              >
                {(match) => (
                  <ChartsContainer>
                    <Collapse
                      in={visibleGraph.sunburst}
                      timeout={500}
                      unmountOnExit
                      mountOnEnter
                    >
                      <div className="center">
                        <Sunburst
                          data={hierarchy}
                          onSelect={onSelect}
                          onHover={onHover}
                          filteredColors={filteredColors}
                          definingNodeColor={getNodeColor}
                          selectedNode={selectedNode}
                          keyId="sunburst"
                          size={500}
                          containerSize={match.mobileS ? 280 : 300}
                          healthType={selectedHealth}
                        />
                      </div>
                    </Collapse>
                    <Collapse
                      in={visibleGraph.treemap}
                      timeout={500}
                      unmountOnExit
                      mountOnEnter
                    >
                      <div className="center treemap">
                        <Treemap
                          data={treeMapData}
                          onSelect={onTreemapSelect}
                          onHover={onTreemapHover}
                          definingNodeColor={getNodeColor}
                          filteredColors={filteredColors}
                          keyId="treemap"
                          width={match.mobileS ? 250 : 300}
                          height={match.mobileS ? 350 : 350}
                          mobileHeight={300}
                          healthType={selectedHealth}
                        />
                      </div>
                    </Collapse>

                  </ChartsContainer>
                )}

              </Media>
              <ColorFilters
                colors={kSunburstColors}
                onFilterToggle={onFilterClick}
                filteredColors={filteredColors}
                indicator={selectedIndicator}
              />
            </div>
            <div className="toggle-charts-container">
              <ToggleChartButton
                onIcon={sunburstOnIcon}
                offIcon={sunburstOffIcon}
                alt="Mostrar ou esconder gráfico sunburst"
                isSelected={visibleGraph.sunburst}
                onToggle={() => toggleChartVisible('sunburst')}
                iconSize={20}
                name="sunburst"
              />
              <ToggleChartButton
                onIcon={treeMapOnIcon}
                offIcon={treeMapOffIcon}
                alt="Mostrar ou esconder gráfico sunburst"
                isSelected={visibleGraph.treemap}
                onToggle={() => toggleChartVisible('treemap')}
                iconSize={15}
                name="treemap"
              />
            </div>


            <Collapsible isHealth={treeHealth.selectedIndicator.name === 'Saúde'} title="Histórico" isMobile={true}>

                <HistoryTab
                  identifier={selectedNode.data.identifier}
                />
              </Collapsible>
          </div>

          

          
        )}
      </Slide>
    </Container>
  );
};
