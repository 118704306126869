import React from 'react';
import { RouteComponentProps } from 'react-router-dom';

import { NotificationsPanel } from './components/panels/NotificationPanel';
import { Router } from './router';

interface DashboardProps extends RouteComponentProps {
  isNotificationOpen: boolean;
  isOsOpen: boolean;
}

export const Dashboard: React.FC<DashboardProps> = (props) => {
  const { isNotificationOpen } = props;

  return (
    <>
      <NotificationsPanel isOpen={isNotificationOpen} />
      <Router {...props} />
    </>
  );
};
