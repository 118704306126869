import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import {
  Switch,
  Route,
  Redirect,
  useLocation,
  useRouteMatch,
  useHistory,
} from 'react-router-dom';
import { ROLES } from '../../../../../utils/authTypes';
import { CircularProgress, Slide } from '@material-ui/core';
import { HierarchyNode } from 'd3';

import { NodeData } from '../../../../../models/TreeMapNode';
import { Creators } from '../../../../../store/actionCreators';
import { AppState } from '../../../../../store/reducers';
import { TabItem } from '../../../../../utils/types';
import { Screen404 } from '../../../../404';
import { Header } from './components/Header';
import { TagsList } from './components/TagsList';
import { DataAnalysisTab } from './tabs/DataAnalysisTab';
import { DetailsTab } from './tabs/DetailsTab';
import { FFTTab } from './tabs/FFTTab';
import { HealthTab } from './tabs/HealthTab';
import { CalibrationTab } from './tabs/CalibrationTab';
import { Container, CustomTab, CustomTabsContainer } from './styles';
import { FieldCalibrationTab } from './tabs/FieldCalibrationTab';
import {
  hasRoleAccess,
  isFirebaseDev,
  useAuthStore,
} from '../../../../../zustand/AuthStore';

interface MatchParams {
  ativoPath: string;
}

interface AssetViewRouteState {
  lastNode?: HierarchyNode<NodeData>;
}

export interface AssetViewProps {
  logicalAsset?: boolean;
}

export const AssetView: React.FC<AssetViewProps> = ({
  logicalAsset = false,
}) => {
  const { assetData } = useSelector((state: AppState) => state);
  const { keycloak } = useAuthStore();
  const dispatch = useDispatch();

  const location = useLocation<AssetViewRouteState | undefined | null>();
  const match = useRouteMatch<MatchParams>();
  const history = useHistory();

  const [currentTab, setCurrentTab] = useState('saude');
  const [lastNode, setLastNode] = useState<
    HierarchyNode<NodeData> | undefined
  >();

  useEffect(() => {
    if (location.state?.lastNode) setLastNode(location.state.lastNode);
  }, [location.state]);

  useEffect(() => {
    dispatch(Creators.getAssetDataRequest(match.params.ativoPath));
  }, [dispatch, match.params.ativoPath]);

  // Sets initial tab based on route
  useEffect(() => {
    const rgx = RegExp(`${match.params.ativoPath}/(.*)`, 'g');
    const tab = rgx.exec(location.pathname)?.pop() || 'saude';

    setCurrentTab(tab);
  }, [assetData.data, location.pathname, match.params.ativoPath]);

  function handleTabChange(value: string) {
    history.replace(`${match.url}/${value}`);
  }

  function buildTabItems() {
    const tabs: TabItem[] = [
      {
        label: 'Saúde',
        route: 'saude',
        component: <HealthTab isLogicalAsset={logicalAsset} />,
      },
      {
        label: 'Análise de Dados',
        route: 'analisededados',
        component: <DataAnalysisTab isLogicalAsset={logicalAsset} />,
      },
    ];

    if (
      assetData.data.assetClass === 'Motor' ||
      assetData.data.assetClass === 'Máquina Rotativa'
    )
      tabs.push({
        label: 'FFT',
        route: 'fft',
        component: <FFTTab />,
      });

    if (logicalAsset)
      tabs.push({
        label: 'Detalhes',
        route: 'detalhes',
        component: <DetailsTab />,
      });

    if (
      hasRoleAccess(
        [ROLES.ADM, ROLES.DEV, ROLES.BALANCO, ROLES.INSTRUMENTISTA],
        keycloak
      )
    ) {
      if (
        assetData.data.assetClass === 'Instrumentação' &&
        assetData.data.company === 'Samarco'
      ) {
        tabs.push({
          label: 'Fatores de Correção',
          route: 'calibracao',
          component: <CalibrationTab />,
        });
      }

      if (assetData.data.calibrable) {
        tabs.push({
          label: 'Relatórios de Calibração (Field)',
          route: 'calibracaoApp',
          component: <FieldCalibrationTab />,
        });
      }
    }

    return tabs;
  }

  if (assetData.error) return <TagsList isLogicalAsset={logicalAsset} />;

  return (
    <Container data-testid="assetViewContainer">
      <Slide in timeout={500} direction="left">
        {assetData.isLoading ||
        (Object.keys(assetData.data).length === 0 && !assetData.error) ? (
          <div className="card loader-container" data-testid="loaderContainer">
            <CircularProgress size={50} />
          </div>
        ) : (
          <div className="card main-container">
            <Header isLogical={logicalAsset} graphViewState={{ lastNode }} />
            <CustomTabsContainer
              value={currentTab}
              onChange={(_event, value) => handleTabChange(value)}
              variant="fullWidth"
              data-testid="tabContainer"
            >
              {buildTabItems().map((item) => (
                <CustomTab
                  key={item.label}
                  label={item.label}
                  value={item.route}
                />
              ))}
            </CustomTabsContainer>
            <Switch>
              {buildTabItems().map((item) => (
                <Route
                  key={item.label}
                  path={`${match.path}/${item.route}`}
                  render={() => item.component}
                  data-testid={`${item.route}Route`}
                />
              ))}
              <Route
                path={`${match.path}/404`}
                component={Screen404}
                data-testid="404Route"
              />
              <Redirect
                exact
                from={`${match.path}`}
                to={`${match.path}/saude`}
              />
              <Redirect from={`${match.path}/*`} to="/404" />
            </Switch>
          </div>
        )}
      </Slide>
    </Container>
  );
};
