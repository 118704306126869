import React from 'react';
import Media from 'react-media';

import { supportedDevices } from '../../styles/supportedDevices';
import { DesktopButton, MobileButton } from './styles';

export interface ToggleChartButtonProps {
  onIcon: string;
  offIcon: string;
  alt: string;
  isSelected: boolean;
  onToggle: () => void;
  iconSize?: number;
  height?: number;
  name?: string;
  disabled?: boolean;
}

export const ToggleChartButton: React.FC<ToggleChartButtonProps> = ({
  onIcon,
  offIcon,
  alt,
  isSelected,
  onToggle,
  iconSize = 50,
  height = 60,
  name,
  disabled,
}) => {
  const icons = (
    <>
      <img className="on" src={onIcon} alt={alt} />
      <img className="off" src={offIcon} alt={alt} />
    </>
  );

  return (
    <Media
      queries={{
        tablet: supportedDevices.tablet,
      }}
    >
      {(matches) => (
        <>
          {matches.tablet ? (
            <DesktopButton
              selected={isSelected}
              onClick={onToggle}
              iconSize={iconSize}
              height={height}
              data-testid={`${name}Button`}
              disabled={disabled}
            >
              {icons}
            </DesktopButton>
          ) : (
            <MobileButton
              selected={isSelected}
              onClick={onToggle}
              iconSize={iconSize}
              disabled={disabled}
            >
              <div className="img-container">{icons}</div>
              <h4>{name}</h4>
            </MobileButton>
          )}
        </>
      )}
    </Media>
  );
};
