import * as React from 'react';

import {
  addMonths,
  isSameDay,
  isWithinInterval,
  isAfter,
  isBefore,
  isSameMonth,
  addYears,
  max,
  min,
  subMonths,
} from 'date-fns';

import Menu from './components/Menu';
import {
  DateRange as DateRangeType,
  NavigationAction,
  I18nArrays,
  TimeRange,
} from './types';
import { parseOptionalDate } from './utils';

type Marker = symbol;

export const MARKERS: { [key: string]: Marker } = {
  FIRST_MONTH: Symbol('firstMonth'),
  SECOND_MONTH: Symbol('secondMonth'),
};

const getValidatedMonths = (
  range: DateRangeType,
  minDate: Date,
  maxDate: Date
) => {
  const { startDate, endDate } = range;
  if (startDate && endDate) {
    const newStart = max([startDate, minDate]);
    const newEnd = min([endDate, maxDate]);

    return [
      newStart,
      isSameMonth(newStart, newEnd) ? addMonths(newStart, 1) : newEnd,
    ];
  }
  return [startDate, endDate];
};

interface DateRangePickerProps {
  open: boolean;
  initialDateRange?: DateRangeType;
  minDate?: Date | string;
  maxDate?: Date | string;
  onChange: (dateRange: DateRangeType) => void;
  i18n?: I18nArrays;
  onCancelClick(): void;
  onApplyClick(): void;
  showSingleMonth?: boolean;
  selectedTime: TimeRange;
  handleTimeClick(time: TimeRange): void;
}

const DateRangePickerImpl: React.FunctionComponent<DateRangePickerProps> = (
  props
) => {
  const today = new Date();

  const {
    open,
    onChange,
    initialDateRange,
    minDate,
    maxDate,
    i18n,
    onCancelClick,
    onApplyClick,
    showSingleMonth = false,
    selectedTime,
    handleTimeClick,
  } = props;

  const minDateValid = parseOptionalDate(minDate, addYears(today, -10));
  const maxDateValid = parseOptionalDate(maxDate, addYears(today, 10));
  const [intialFirstMonth, initialSecondMonth] = getValidatedMonths(
    initialDateRange || {},
    minDateValid,
    maxDateValid
  );

  const [dateRange, setDateRange] = React.useState<DateRangeType>({
    ...initialDateRange,
  });
  const [hoverDay, setHoverDay] = React.useState<Date>();

  const [firstMonth, setFirstMonth] = React.useState<Date>(
    intialFirstMonth || (showSingleMonth ? today : subMonths(today, 1))
  );
  const [secondMonth, setSecondMonth] = React.useState<Date>(
    initialSecondMonth || addMonths(firstMonth, 1)
  );

  const { startDate, endDate } = dateRange;

  // handlers
  const setFirstMonthValidated = (date: Date) => {
    if (isBefore(date, secondMonth) || showSingleMonth) setFirstMonth(date);
  };

  const setSecondMonthValidated = (date: Date) => {
    if (isAfter(date, firstMonth) || showSingleMonth) {
      setSecondMonth(date);
    }
  };

  const onDayClick = (day: Date) => {
    if (startDate && !endDate && !isBefore(day, startDate)) {
      const newRange = { startDate, endDate: day };
      onChange(newRange);
      setDateRange(newRange);
    } else {
      setDateRange({ startDate: day, endDate: undefined });
    }
    setHoverDay(day);
  };

  const onMonthNavigate = (marker: Marker, action: NavigationAction) => {
    if (marker === MARKERS.FIRST_MONTH) {
      const firstNew = addMonths(firstMonth, action);
      if (isBefore(firstNew, secondMonth) || showSingleMonth)
        setFirstMonth(firstNew);
    } else {
      const secondNew = addMonths(secondMonth, action);
      if (isBefore(firstMonth, secondNew)) setSecondMonth(secondNew);
    }
  };

  const onDayHover = (date: Date) => {
    if (startDate && !endDate) {
      if (!hoverDay || !isSameDay(date, hoverDay)) {
        setHoverDay(date);
      }
    }
  };

  // helpers
  const inHoverRange = (day: Date) => {
    return (startDate &&
      !endDate &&
      hoverDay &&
      isAfter(hoverDay, startDate) &&
      isWithinInterval(day, { start: startDate, end: hoverDay })) as boolean;
  };

  const helpers = {
    inHoverRange,
  };

  const handlers = {
    onDayClick,
    onDayHover,
    onMonthNavigate,
  };

  return open ? (
    <Menu
      dateRange={dateRange}
      minDate={minDateValid}
      maxDate={maxDateValid}
      firstMonth={firstMonth}
      secondMonth={secondMonth}
      setFirstMonth={setFirstMonthValidated}
      setSecondMonth={setSecondMonthValidated}
      helpers={helpers}
      handlers={handlers}
      i18n={i18n}
      onApplyClick={onApplyClick}
      onCancelClick={onCancelClick}
      showSingleMonth={showSingleMonth}
      handleTimeClick={handleTimeClick}
      selectedTime={selectedTime}
    />
  ) : null;
};

export type DateRange = DateRangeType;
export const DateRangePicker = DateRangePickerImpl;
