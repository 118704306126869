import React from 'react';
import Media from 'react-media';
import { useSelector } from 'react-redux';

import { Slide } from '@material-ui/core';

import { CustomDrawer } from '../../../components/CustomDrawer';
import { PanelOption } from '../../../store/dataAnalysis/types';
import { NavigationPanel } from './components/NavigationPanel';
import { TreePanel } from './components/TreePanel';

import { supportedDevices } from '../../../styles/supportedDevices';
import { Aside, Container } from './styles';

export const Panels: React.FC = () => {
  const { isPanelOpen, panelOption } = useSelector(
    (state) => state.dataAnalysis
  );

  function buildPanel(panel: PanelOption) {
    if (panel === 'tree')
      return (
        <Container
          className={!isPanelOpen || panelOption !== 'tree' ? 'hidden' : ''}
        >
          <TreePanel />
        </Container>
      );
    return (
      <Container
        className={!isPanelOpen || panelOption !== 'navigation' ? 'hidden' : ''}
      >
        <NavigationPanel />
      </Container>
    );
  }

  return (
    <Media queries={{ minTablet: supportedDevices.tablet }}>
      {(matches) => (
        <>
          {matches.minTablet && (
            <Aside isOpen={isPanelOpen} data-testid="dataAnalysisPanel">
              <Slide
                in={isPanelOpen && panelOption === 'tree'}
                direction="right"
                unmountOnExit
                timeout={500}
              >
                {buildPanel('tree')}
              </Slide>
              <Slide
                in={isPanelOpen && panelOption === 'navigation'}
                direction="right"
                unmountOnExit
                timeout={500}
              >
                {buildPanel('navigation')}
              </Slide>
            </Aside>
          )}
          {!matches.minTablet && (
            <CustomDrawer>{buildPanel(panelOption)}</CustomDrawer>
          )}
        </>
      )}
    </Media>
  );
};
