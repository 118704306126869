import React from 'react';
import { Redirect, Route, Switch, useRouteMatch } from 'react-router-dom';

import { Screen404 } from '../404';
import { TagsComparison } from './views/TagsComparison';

export const Router: React.FC = () => {
  const match = useRouteMatch();

  return (
    <Switch>
      <Route
        path={`${match.path}/comparacaraodetags`}
        component={TagsComparison}
      />
      <Redirect
        exact
        from={`${match.path}`}
        to={`${match.path}/comparacaraodetags`}
      />
      <Route path={`${match.path}/404`} component={Screen404} />
      <Redirect from={`${match.path}/*`} to="/404" />
    </Switch>
  );
};
