import { Modal, Button } from '@material-ui/core';
import styled, { css } from 'styled-components';

interface CustomModalProps {
  width?: number;
}

export const CustomModal = styled(Modal)<CustomModalProps>`
  display: flex;
  height: 100%;
  justify-content: center;
  align-items: center;
  font-size: 1.5rem;
  color: black;

  .outline-0 {
    outline: 0;
  }

  .dialog-content {
    background: ${({ theme }) => theme.palette.background.paper};
    border-radius: 4px;
    font-size: 110%;

    padding: 16px;

    ${({ width }) =>
      width
        ? css`
            width: ${width}px;
          `
        : css`
            min-width: 300px;
            max-width: 350px;
          `}

    h3 {
      margin-bottom: 5px;
    }

    .actions-container {
      display: flex;
      margin: 16px 0 8px;
      justify-content: space-evenly;
    }
  }
`;

export const CustomButton = styled(Button)`
  && {
    min-width: 86px;
    color: white;
    background: #484848;

    font-size: 10px;

    &:hover {
      background: #484848;
    }

    &.gradient {
      background-image: linear-gradient(to right, #19b0a0, #009ee2);
    }
  }
`;
