import { ButtonBase } from '@material-ui/core';
import styled from 'styled-components';

interface DesktopButtonProps {
  selected: boolean;
  iconSize: number;
  height: number;
}

export const DesktopButton = styled(ButtonBase).withConfig({
  shouldForwardProp: (prop) => !['iconSize'].includes(prop),
})<DesktopButtonProps>`
  width: fit-content;

  && {
    padding: 5px 10px;
    border-radius: 4px;
    border: solid 1px #efefef;
    position: relative;
    height: ${({ height }) => height}px;
    width: ${({ height }) => (7 * height) / 6}px;

    background: ${({ selected }) =>
      selected ? 'rgba(0, 158, 226, 0.05)' : 'rgba(230,232,235,0.5)'};

    border-bottom: 2px solid
      ${({ selected }) => (selected ? '#009ee2' : '#969696')};

    img {
      width: ${({ iconSize }) => iconSize}px;
      position: absolute;
      transition: opacity 0.5s ease;
    }

    img.on {
      opacity: ${({ selected }) => (selected ? 1 : 0)};
    }

    img.off {
      opacity: ${({ selected }) => (selected ? 0 : 1)};
    }
  }
`;

interface MobileButtonProps {
  selected: boolean;
  iconSize: number;
}

export const MobileButton = styled(ButtonBase).withConfig({
  shouldForwardProp: (prop) => !['iconSize'].includes(prop),
})<MobileButtonProps>`
  width: fit-content;

  && {
    padding: 5px 10px;
    border-radius: 2px;
    border: solid 1px #efefef;
    width: 50%;
    height: 40px;
    display: flex;
    justify-content: space-evenly;
    align-items: center;
    color: ${({ selected }) =>
      selected ? 'rgba(0,0,0,0.7)' : 'rgba(0, 0, 0, 0.3)'};

    background: ${({ selected }) =>
      selected ? 'rgba(0, 158, 226, 0.05)' : 'rgba(230,232,235,0.5)'};

    border-bottom: 2px solid
      ${({ selected }) => (selected ? '#009ee2' : '#969696')};

    & .img-container {
      position: relative;
      width: ${({ iconSize }) => iconSize}px;
      height: ${({ iconSize }) => iconSize}px;
    }

    h4 {
      text-transform: capitalize;
    }

    img {
      width: ${({ iconSize }) => iconSize}px;
      position: absolute;
      transition: opacity 0.5s ease;
      left: 0;
    }

    img.on {
      opacity: ${({ selected }) => (selected ? 1 : 0)};
    }

    img.off {
      opacity: ${({ selected }) => (selected ? 0 : 1)};
    }
  }
`;
