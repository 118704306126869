import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { toastr } from 'react-redux-toastr';

import { differenceInDays, format } from 'date-fns';

import config from '../../../../config';
import { TagsComparisonAnalysis } from '../../../../models/DataAnalysis';
import { SimpleTagInfo } from '../../../../models/TagInfo';
import { Creators } from '../../../../store/actionCreators';
import { generateCsv, randomDate2Date } from '../../../../utils/methods';
import { Card } from '../components/Card';
import { DataAnalysisPage } from '../components/DataAnalysisPage';

const buildChartUrl = config.grafana.getTagsComparisonChartUrl;

export const TagsComparison: React.FC = () => {
  const {
    dataAnalysis: {
      isLoading,
      error,
      tags,
      selectedTreeItems,
      metrics,
      timePeriod,
      data: { tagsComparisonAnalysis },
    },
  } = useSelector((state) => state);
  const dispatch = useDispatch();

  const [requestedDownload, setRequestedDownload] = useState(false);

  // Set initial configs
  useEffect(() => {
    dispatch(Creators.setSelectableTreeItems(['Asset', 'LogicalAsset']));
    dispatch(Creators.setPanelOptions('tree'));
  }, [dispatch]);

  // Download data on request complete
  useEffect(() => {
    if (
      requestedDownload &&
      tagsComparisonAnalysis &&
      (tagsComparisonAnalysis as TagsComparisonAnalysis)
    ) {
      setRequestedDownload(false);
      const buildTagLabel = (label: string) => {
        const splitLabel = label.split('.');

        return `${splitLabel[splitLabel.length - 2]} - ${
          splitLabel[splitLabel.length - 1]
        }`;
      };

      const timeStamp =
        typeof timePeriod.startTime === 'string'
          ? timePeriod.startTime.split('-')[1]
          : `${format(new Date(timePeriod.startTime!), 'dd/MM/yyyy')}-${format(
              new Date(timePeriod.endTime!),
              'dd/MM/yyyy'
            )}`;

      generateCsv(
        [
          'Timestamp',
          ...Object.keys(tagsComparisonAnalysis).flatMap((label) => [
            buildTagLabel(label),
          ]),
        ],
        Object.values(tagsComparisonAnalysis)[0].map(({ time }, index) => [
          format(new Date(time), 'dd/MM/yy HH:mm'),
          ...Object.keys(tagsComparisonAnalysis).map((key) =>
            tagsComparisonAnalysis[key][index].value === null
              ? ''
              : (String(tagsComparisonAnalysis[key][index].value)).replace(/\./g, ',')
          ),
        ]),
        `Analise_Comparativa_${timeStamp}.csv`
      );
    }
  }, [tagsComparisonAnalysis, requestedDownload, timePeriod]);

  // Get tags on asset selected
  useEffect(() => {
    if (selectedTreeItems.length)
      dispatch(Creators.getAssetTagsRequest(selectedTreeItems));
  }, [selectedTreeItems, dispatch]);

  function handleSearchChange(_: React.ChangeEvent, values: string[]) {
    if (values.length <= 10) {
      const newTags = values.map(
        (value) =>
          tags.find((tag) =>
            tag.identifier.endsWith(value.split(' - ').join('.'))
          )!.identifier
      );

      dispatch(Creators.setMetrics(newTags));
    } else
      toastr.info(
        'Seleção de Tags',
        'É possível comparar um máximo de 10 tags por vez'
      );
  }

  async function handleDownloadCsv() {
    setRequestedDownload(true);

    dispatch(
      Creators.setAnalysisPeriodResolution(
        differenceInDays(
          randomDate2Date(timePeriod.endTime),
          randomDate2Date(timePeriod.startTime)
        ) <= 7
          ? '1min'
          : '1h'
      )
    );

    dispatch(Creators.resetAnalysisData('tagsComparisonAnalysis'));

    dispatch(Creators.getAnalysisDataRequest('tagsComparisonAnalysis'));
  }

  function buildSearchTagLabel(tag: SimpleTagInfo) {
    const asset = tag.identifier.split('.')[
      tag.identifier.split('.').length - 2
    ];

    return `${asset} - ${tag.name}`;
  }

  return (
    <Card>
      <DataAnalysisPage
        headerProps={{
          title: 'Comparação de Tags',
          dateFilterProps: {
            datePicker: 'normal',
            timeList: [
              ['1h', '1 H', '1 Hora'],
              ['12h', '12 H', '12 Horas'],
              ['24h', '24 H', '24 Horas'],
              ['7d', '7 D', '7 Dias'],
            ].map((time) => {
              return {
                id: time[0],
                content: time[1],
                tooltip: time[2],
              };
            }),
          },
          searchFieldProps: {
            getOptionLabel: (tag: string) => tag,
            onChange: handleSearchChange,
            options: tags.map((tag) => buildSearchTagLabel(tag)),
            multiple: true,
            loading: isLoading || !tags || !metrics,
            value: metrics.map((metric) =>
              buildSearchTagLabel(
                tags.find((tag) => tag.identifier === metric)!
              )
            ),
            placeholder: 'Pesquisar Tags',
          },
          csvButtonProps: {
            handleDownloadCsv,
            disabled: !tags.length || !metrics.length,
            isLoading,
          },
        }}
        isLoading={!tags.length && !metrics.length}
        error={error}
        selectElementData={{
          shouldShow: !selectedTreeItems.length,
          shouldShowWithHeader: Boolean(tags.length && !metrics.length),
          message: `Selecione um${!selectedTreeItems.length ? '' : 'a'} ${
            !selectedTreeItems.length ? 'ativo' : 'tag'
          } para começar`,
        }}
        url={buildChartUrl(metrics, timePeriod)}
      />
    </Card>
  );
};
