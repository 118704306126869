export interface DateRange {
  startDate?: Date;
  endDate?: Date;
}

export interface TimeRange {
  startTime: string;
  endTime: string;
}

export type Setter<T> =
  | React.Dispatch<React.SetStateAction<T>>
  | ((value: T) => void);

export enum NavigationAction {
  Previous = -1,
  Next = 1,
}

export type I18nArrays = {
  months: [
    string,
    string,
    string,
    string,
    string,
    string,
    string,
    string,
    string,
    string,
    string,
    string
  ];
  days: [string, string, string, string, string, string, string];
};
