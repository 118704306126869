import { Tab, Tabs } from '@material-ui/core';
import styled from 'styled-components';

import { supportedDevices } from '../../../../../styles/supportedDevices';

export const Container = styled.section`
  overflow-y: scroll;
  overflow-x: hidden;
  width: 100%;
  height: 100%;
  padding: 8px 16px;

  & .card {
    overflow: hidden;
    display: flex;
    flex-direction: column;
    min-height: 100%;

    background-color: #fff;
    border-radius: 4px;
    box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.04);

    &.loader-container {
      justify-content: center;
      align-items: center;
    }

    &.main-container {
      padding: 16px;
    }
  }

  @media ${supportedDevices.tablet} and (orientation: landscape) {
    padding: 20px 28px;

    .card {
      min-height: 100%;
      min-width: calc(90vw - 300px);

      &.main-container {
        padding: 20px 24px;
      }
    }
  }
`;

export const CustomTabsContainer = styled(Tabs)`
  && {
    margin: 20px 0;
    min-height: 30px;

    & .MuiTabs-indicator {
      background: #009ee2;
    }

    @media ${supportedDevices.tablet} {
      margin: 30px 0;
      min-height: 48px;
    }
  }
`;

export const CustomTab = styled(Tab)`
  && {
    border: 1px solid #efefef;
    font-size: 10px;
    font-weight: 600;
    color: black;
    background: #f7f7f7;

    &.Mui-selected {
      background: rgba(0, 158, 226, 0.05);
      color: #009ee2;
    }

    @media ${supportedDevices.tablet} {
      font-size: 12px;
    }
  }
`;
