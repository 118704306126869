import React from 'react';
import Media from 'react-media';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';

import { Slide } from '@material-ui/core';
import { Menu } from '@material-ui/icons';

import navigationOff from '../../assets/new_icons/navigation_icon_off.svg';
import navigationOn from '../../assets/new_icons/navigation_icon_on.svg';
import notificationIcon from '../../assets/new_icons/notification_icon_mobile.svg';
import notificationOff from '../../assets/new_icons/notification_icon_off.svg';
import notificationOn from '../../assets/new_icons/notification_icon_on.svg';
import { Creators } from '../../store/actionCreators';
import { AppState } from '../../store/reducers';
import { ToggleButton } from './components/ToggleButton';
import { DesktopHeader } from './DesktopHeader';
import { MobileHeader } from './MobileHeader';

import { deviceSizes } from '../../styles/supportedDevices';
import { CustomIconButton } from './styles';
import { useAuthStore } from '../../zustand/AuthStore';
import { Movidesk } from '../Movidesk';

// ? It may be interesting to convert the notification and OS state to a reducer

export interface HeaderProps {
  toggleNotificationPanel: () => void;
  isNotificationOpen: boolean;
  toggleOsPanel: () => void;
  isOsOpen: boolean;
}

export interface SubHeaderProps extends HeaderProps {
  navOptions: NavOption[];
}

export interface NavOption {
  path: string;
  name: string;
  toggleButtons: JSX.Element[];
  mobileToggleButtons: JSX.Element[];
}

export const Header: React.FC<HeaderProps> = (props) => {
  const { reports, drawer, dataAnalysis } = useSelector(
    (state: AppState) => state
  );


  const moviedeskChatClientEnable = process.env.REACT_APP_MOVIDESK_CHAT_CLIENT_ENABLE === 'true'? true : false
  const moviedeskChatClientId = process.env.REACT_APP_MOVIDESK_CHAT_CLIENT_ID

  const { user } = useAuthStore();

  const dispatch = useDispatch();

  function toggleReportsNavigation() {
    dispatch(
      Creators.toggleReportsNavigationPanel(!reports.isNavigationPanelOpen)
    );
  }

  function toggleDataAnalysisPanel() {
    dispatch(Creators.setIsPanelOpen(!dataAnalysis.isPanelOpen));
  }

  function toggleDrawer() {
    dispatch(Creators.toggleDrawer(!drawer.isOpen));
  }

  // Add nav options here
  const availableNavOptions: NavOption[] = [
    {
      path: '/admin/dashboard',
      name: 'dashboard',
      toggleButtons: [
        <ToggleButton
          isSelected={props.isNotificationOpen}
          onToggle={props.toggleNotificationPanel}
          offIcon={notificationOff}
          onIcon={notificationOn}
          alt="Icone mostrar ou esconder painel de notificações"
        />,
      ],
      mobileToggleButtons: [
        <Link to="/admin/notificacoes">
          <CustomIconButton aria-label="mostrar notificações" active={false}>
            <img src={notificationIcon} alt="Icon Notificações" />
          </CustomIconButton>
        </Link>,
      ],
    },
    {
      path: '/admin/notificacoes',
      name: 'notificações',
      toggleButtons: [],
      mobileToggleButtons: [],
    },
  ];

  if (user.company !== '') {
    availableNavOptions.push(
      {
        path: '/admin/relatorios',
        name: 'relatórios',
        toggleButtons: [
          <ToggleButton
            isSelected={reports.isNavigationPanelOpen}
            onToggle={toggleReportsNavigation}
            offIcon={navigationOff}
            onIcon={navigationOn}
            alt="Icone mostrar ou esconder painel de navegação"
          />,
        ],
        mobileToggleButtons: [
          <CustomIconButton
            aria-label="mostrar gaveta"
            active={false}
            onClick={toggleDrawer}
          >
            <Menu fontSize="large" />
          </CustomIconButton>,
        ],
      },
      {
        path: '/admin/comparative-analysis',
        name: 'análise comparativa',
        toggleButtons: [
          <ToggleButton
            isSelected={dataAnalysis.isPanelOpen}
            onToggle={toggleDataAnalysisPanel}
            offIcon={navigationOff}
            onIcon={navigationOn}
            alt="Icone mostrar ou esconder painel de navegação"
          />,
        ],
        mobileToggleButtons: [
          <CustomIconButton
            aria-label="mostrar gaveta"
            active={false}
            onClick={toggleDrawer}
          >
            <Menu fontSize="large" />
          </CustomIconButton>,
        ],
      }
    );
  }

  return (
    <Slide in direction="down">
      <Media
        queries={{
          phone: `(max-width: ${deviceSizes.tablet}px)`,
        }}
      >
        {(matches) => (
          <>
            {!matches.phone && (
              <DesktopHeader {...props} navOptions={availableNavOptions} />
            )}
            {matches.phone && (
              <MobileHeader {...props} navOptions={availableNavOptions} />
            )}
             {
            moviedeskChatClientEnable &&
            moviedeskChatClientId &&
            <Movidesk />
          }
          </>
        )}
      </Media>
    </Slide>
  );
};
