import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { AppState } from '../../../../../../../store/reducers';

import { Table } from '../../../../../../../components/charts/Table';
import { CircularProgress, IconButton } from '@material-ui/core';

import { getAssetCalibrations, getCalibrationReport } from '../../../../../../../api';
import errorIcon from '../../../../../../../assets/imgs/error-icon.png';
import { Container } from './styles';
import { GetApp} from '@material-ui/icons';

interface RowProps {
	sentBy: string;
	createdAt: string;
	expiresAt: string;
	calibrationFormId: number;
	reportUrl?: string;
}

const getCalibrations = async (assetUuid: string): Promise<RowProps[]> => {
	const response = await getAssetCalibrations(assetUuid);

	const tableRows: RowProps[] = response.data.map((calibration) => {
		return {
			sentBy: calibration.sentBy.name,
			createdAt: new Date(calibration.createdAt).toLocaleDateString('pt-BR'),
			expiresAt: new Date(calibration.expiresAt).toLocaleDateString('pt-BR'),
			calibrationFormId: calibration.calibrationFormId,
		};
	});

	tableRows.sort((a, b) => {
		if (a.expiresAt < b.expiresAt) {
			return 1;
		}
		if (a.expiresAt > b.expiresAt) {
			return -1;
		}
		return 0;
	});

	return tableRows;
    
}

export const FieldCalibrationTab: React.FC = () => {
	const { data } = useSelector(
			(state: AppState) => state.assetData
	);

	const dispatch = useDispatch();

	const [calibrationData, setCalibrationData] = useState<RowProps[]>(
		null
	);

	useEffect(() => {

		async function fetchCalibration() {
			const assetUuid = data?.uuid;

			if (assetUuid) {
				try{
					const newCalibrationData = await getCalibrations(assetUuid)
					setCalibrationData(newCalibrationData);
					setIsCalibrationLoading(false)
				}
				catch (error) {
					setCalibrationError(true);
				}
			}
		}
		fetchCalibration();
	}, []);

	const [calibrationError, setCalibrationError] = useState(false);
	const [isCalibrationLoading, setIsCalibrationLoading] = useState(true);

	useEffect(() => {
			if (calibrationData) {
				setIsCalibrationLoading(false);
			}
					
	}, [dispatch, calibrationData]);
  
	function buildLoader() {
    return calibrationError ? (
      <>
        <div className="error-container" data-testid="errorContainer">
          <img src={errorIcon} alt="Ícone de erro" />
          <h3>Ocorreu um erro! Recarregue a página para tentar novamente.</h3>
        </div>
      </>
    ) : (
      <CircularProgress size={50} data-testid="loaderIndicator" />
    );
  }

  return (
    <>
			<Container
        data-testid="calibrationContainer"
        className={(isCalibrationLoading || calibrationError) && 'loader'}
      >
        {isCalibrationLoading || calibrationError ? (
          buildLoader()
        )  : (
          <>
            <Table headers={['Responsável', 'Expira em', 'Preenchido em', 'Baixar']} rows={calibrationData} createRow={
							item => [
							item.sentBy,
							item.expiresAt,
							item.createdAt,
							<IconButton onClick={() => getCalibrationReport(item.calibrationFormId, data.equipment)}>
								<GetApp/>
							</IconButton>
							]
							}>
     			 </Table>
          </>
        )}
      </Container>

    </>
  );
};
