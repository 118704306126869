/* eslint-disable no-use-before-define */
import React from 'react';

import * as d3 from 'd3';
import _ from 'lodash';
import { isEqual } from 'lodash/lang';

import { ChartContainer } from './styles';

/**
 * Sunburst Chart React Stateless Component with the following allowable Props *
 * data => d3.HierarchyNode<NodeData> - Typically same for every Sunburst Chart *
 * scale => String - Options: linear | exponential - Linear renders each arc with same radii, Exponential reduces gradually by SquareRoot *
 * onSelect => Function - Called on Arc Click for re-rendering the chart and passing back to User as props *
 * keyId => String - Unique Id for Chart SVG *
 * size => number - Must be greater than or equal to 450
 * containerSize => number
 */
export class Sunburst extends React.Component {
  componentDidMount() {
    this.renderSunburst(this.props);
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (!isEqual(this.props.healthType, nextProps.healthType)) {
      d3.select('.svg-sunburst').selectAll('*').remove();
      this.renderSunburst(nextProps);
    }
    if (!isEqual(this.props.selectedNode, nextProps.selectedNode)) {
      // Zoom into selected node
      this.selectNode(nextProps.selectedNode);
    } else if (!isEqual(this.props.filteredColors, nextProps.filteredColors)) {
      this.filterColors(nextProps.filteredColors);
    } else if (!isEqual(this.props.healthType, nextProps.healthType)) {
      d3.select('.svg-sunburst').selectAll('*').remove();
      this.renderSunburst(nextProps);
    } else if (!isEqual(this.props, nextProps)) {
      d3.select('.svg-sunburst').selectAll('*').remove();
      this.renderSunburst(nextProps);
    }
  }

  getHealthFromNode = (item) => {
    return item.data.value;
  };

  selectNode(node) {
    const self = this;
    const radius = self.props.size / 2 - 10;
    const svg = d3.select(`#${self.props.keyId}-svg`);
    const x = d3.scaleLinear().range([0, 2 * Math.PI]);
    const y =
      self.props.scale === 'linear'
        ? d3.scaleLinear().range([0, radius])
        : d3.scaleSqrt().range([0, radius]);
    const arc = d3
      .arc()
      .startAngle((d) => Math.max(0, Math.min(2 * Math.PI, x(d.x0))))
      .endAngle((d) => Math.max(0, Math.min(2 * Math.PI, x(d.x1))))
      .innerRadius((d) => Math.max(0, y(d.y0)))
      .outerRadius((d) => Math.max(0, y(d.y1)));
    const healthLabel = d3.select('.sunburst-health-label');
    const nameLabel = d3.select('.sunburst-name-label');

    svg
      .selectAll('path')
      .transition()
      .duration(800)
      .attrTween('d', self.arcTweenZoom(node, y, x, radius, arc))
      .style('fill', (e) => {
        if (e.y0 === 0 || e === node.parent) return '#ffffff';
        return self.color(e.data);
      })
      .style('display', '')
      .filter((f) => {
        return node.parent == null ? null : f === node.parent.parent;
      })
      .style('display', 'none');

    nameLabel.text(node.data.name);
    healthLabel.text(self.formatPercentage(self.getHealthFromNode(node)));
  }

  arcTweenZoom = (d, y, x, radius, arc) => {
    const xd = d3.interpolate(x.domain(), [d.x0, d.x1]);
    const yd = d3.interpolate(y.domain(), [d.y0, 1]);
    const yr = d3.interpolate(y.range(), [90, radius]);

    return (data, i) => {
      return i
        ? () => arc(data)
        : (t) => {
            x.domain(xd(t));
            y.domain(yd(t)).range(yr(t));
            return arc(data);
          };
    };
  };

  filterColors(colors) {
    const self = this;
    const svg = d3.select(`#${this.props.keyId}-svg`);

    svg
      .selectAll('path')
      .style('opacity', (d) =>
        colors.includes(self.color(d.data)) ? 0.05 : 1
      );
  }

  color(data) {
    return `rgb(${this.props.definingNodeColor(data).join(',')})`;
  }

  colorArray(data) {
    return this.props.definingNodeColor(data);
  }

  betweencolors = (color, colorMin, colorMax) => {
    if ((color === colorMax) === colorMin) return true;
    const teste =
      _.inRange(color[0], colorMin[0], colorMax[0] + 1) &&
      _.inRange(color[1], colorMin[1], colorMax[1] + 1) &&
      _.inRange(color[2], colorMin[2], colorMax[2] + 1);
    return teste;
  };

  formatPercentage = (health) => {
    return health >= 0 && health <= 1
      ? d3.format('.2%')(health).replace('.', ',')
      : '';
  };

  arcTweenData = (a, i, node, x, arc) => {
    const oi = d3.interpolate(
      { x0: a.x0s ? a.x0s : 0, x1: a.x1s ? a.x1s : 0 },
      a
    );
    function tween(t) {
      const b = oi(t);
      a.x0s = b.x0;
      a.x1s = b.x1;
      return arc(b);
    }
    if (i === 0) {
      const xd = d3.interpolate(x.domain(), [node.x0, node.x1]);
      return (t) => {
        x.domain(xd(t));
        return tween(t);
      };
    }
    return tween;
  };

  update(root, firstBuild, svg, partition, x, arc, self) {
    if (firstBuild) {
      firstBuild = false;

      // eslint-disable-next-line no-inner-declarations
      function mouseover(d) {
        element.text(d.data.name);
        health.text(self.formatPercentage(self.getHealthFromNode(d)));

        const sequenceArray = d.ancestors().reverse();
        // sequenceArray.shift();

        svg
          .selectAll('path')
          .filter(
            (e) => !self.props.filteredColors.includes(self.color(e.data))
          )
          .style('opacity', 0.4);

        svg
          .selectAll('path')
          .filter((e) => sequenceArray.indexOf(e) >= 0)
          .style('opacity', (e) =>
            self.props.filteredColors.includes(self.color(e.data)) ? 0.05 : 1
          );

        self.props.onHover(d);
      }

      svg
        .selectAll('path')
        .data(partition(root).descendants())
        .enter()
        .append('path')
        .style('fill', (current) =>
          current.y0 === 0 ? '#ffffff' : this.color(current.data)
        )
        .attr('stroke', '#fff')
        .attr('stroke-width', '1')
        .on('click', self.props.onSelect)
        .on('mouseover', mouseover)
        .on('auxclick', self.props.onAuxClick);

      svg.on('mouseleave', () => {
        svg
          .selectAll('path')
          .filter(
            (e) => !self.props.filteredColors.includes(self.color(e.data))
          )
          .style('opacity', 1);

        element.text(self.props.selectedNode.data.name);
        health.text(
          self.formatPercentage(self.getHealthFromNode(self.props.selectedNode))
        );

        self.props.onHover(self.props.selectedNode);
      });

      const display = svg.append('g').attr('id', 'display');

      const health = display
        .append('text')
        .attr('class', 'sunburst-health-label')
        .attr('text-anchor', 'middle')
        .attr('font-size', '2.5em');

      const element = display
        .append('text')
        .attr('class', 'sunburst-name-label')
        .attr('text-anchor', 'middle')
        .attr('font-size', '1em')
        .attr('dy', '1.5em');

      element.text(self.props.selectedNode.data.name);
      health.text(
        self.formatPercentage(self.getHealthFromNode(self.props.selectedNode))
      );
    } else {
      svg.selectAll('path').data(partition(root).descendants());
    }
    svg
      .selectAll('path')
      .transition()
      .duration(800)
      .attrTween('d', (d, i) =>
        self.arcTweenData(d, i, self.props.selectedNode, x, arc)
      );
  }

  renderSunburst(props) {
    if (props.data) {
      const self = this;
      const gSize = props.size;
      const radius = gSize / 2 - 10;
      const svg = d3
        .select(`#${props.keyId}-svg`)
        .append('g')
        .attr('id', 'container')
        .attr('transform', `translate(${gSize / 2},${gSize / 2})`);
      const x = d3.scaleLinear().range([0, 2 * Math.PI]);
      const y =
        props.scale === 'linear'
          ? d3.scaleLinear().range([0, radius])
          : d3.scaleSqrt().range([0, radius]);
      const partition = d3.partition();
      const arc = d3
        .arc()
        .startAngle((d) => Math.max(0, Math.min(2 * Math.PI, x(d.x0))))
        .endAngle((d) => Math.max(0, Math.min(2 * Math.PI, x(d.x1))))
        .innerRadius((d) => Math.max(0, y(d.y0)))
        .outerRadius((d) => Math.max(0, y(d.y1)));
      const rootData = props.data;
      const firstBuild = true;

      rootData.sum((d) => d.weight);
      self.setState({ selectedHealth: props.healthType }, () => {
        self.update(rootData, firstBuild, svg, partition, x, arc, self);
      });
    }
  }

  render() {
    return (
      <ChartContainer
        id={this.props.keyId}
        size={this.props.size}
        containerSize={this.props.containerSize}
      >
        <svg
          className="svg-sunburst"
          viewBox={`0 0 ${this.props.size} ${this.props.size}`}
          id={`${this.props.keyId}-svg`}
          preserveAspectRatio="xMidYMid meet"
        />
      </ChartContainer>
    );
  }
}
