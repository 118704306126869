import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';

import { CircularProgress, FormControl, MenuItem } from '@material-ui/core';
import { DateTimePicker } from '@material-ui/pickers';
import { MaterialUiPickersDate } from '@material-ui/pickers/typings/date';
import { subMinutes } from 'date-fns';

import {
  CustomSelect,
  CustomInput,
} from '../../../../../../../components/CustomSelect';
import config from '../../../../../../../config';
import { Creators } from '../../../../../../../store/actionCreators';
import { AppState } from '../../../../../../../store/reducers';
import { randomDate2Date } from '../../../../../../../utils/methods';
import { Tags } from '../components/Tags';

import { Container, LoaderContainer, GlobalStyle } from './styles';

const baseUrl = config.grafana.FFT_PANEL_URL;

function stringToMinutes(date: string | number | null) {
  if (typeof date === 'string' && date.endsWith('m'))
    return Number(date.slice(4, -1));
  return 30;
}

export const FFTTab: React.FC = () => {
  const { assetData, tags, treeHealth, notifications } = useSelector(
    (state: AppState) => state
  );
  const dispatch = useDispatch();

  const [selectedTag, setSelectedTag] = useState<string | null>(null);
  const [selectedDate, setSelectedDate] = useState(
    treeHealth.timePeriod.endTime
      ? randomDate2Date(treeHealth.timePeriod.endTime)
      : new Date()
  );
  const [selectedMinutes, setSelectedMinutes] = useState(
    stringToMinutes(treeHealth.timePeriod.startTime)
  );

  // Get tags request
  useEffect(() => {
    dispatch(Creators.getTagsRequestFFT({ assetUuid: assetData.data.uuid }));
  }, [dispatch, assetData.data.uuid]);

  // Sets selected tag and period based on selected notification
  useEffect(() => {
    if (notifications.currentNotification) {
      const notification = notifications.data
        .find((n) => n.id === notifications.currentNotification);
      const tag = notification?.uuidTag?.split('.')[0];
      if (tag && tags.data.find((t) => t.tag === tag)) {
        setSelectedTag(tag);
        setSelectedDate(randomDate2Date(notification?.createDate as string)
        );
        setSelectedMinutes(5);
      }
    }
  }, [
    notifications.currentNotification,
    notifications.data,
    tags.data,
  ]);

  function handleSelectedTag(tag: string) {
    if (selectedTag === tag) setSelectedTag(null);
    else setSelectedTag(tag);
  }

  function handleMinutesChange(
    event: React.ChangeEvent<{
      value: unknown;
    }>
  ) {
    setSelectedMinutes(Number(event.target.value));
  }

  function handleConfirmDate(date: MaterialUiPickersDate) {
    if (date) setSelectedDate(date);
  }

  let grafanaUrl = `${baseUrl}&var-identifier=${assetData.data.identifier}`;

  if (selectedTag) grafanaUrl += `&var-tag=${selectedTag}`;
  else grafanaUrl += `&var-tag=${tags.data[0]?.tag}`;

  grafanaUrl += `&from=${subMinutes(
    selectedDate,
    selectedMinutes
  ).getTime()}&to=${selectedDate.getTime()}`;

  if (tags.isLoading)
    return (
      <LoaderContainer data-testid="loaderContainer">
        <CircularProgress size={50} />
      </LoaderContainer>
    );

  return (
    <>
      <GlobalStyle />
      <Container data-testid="fftContainer">
        <div className="time-selector-container">
          <div className="selector-container">
            <h3>Data</h3>
            <div className="date-time-picker">
              <DateTimePicker
                fullWidth
                ampm={false}
                disableFuture
                inputVariant="outlined"
                minDate={new Date(2018, 0, 1, 0, 0, 0)}
                value={selectedDate}
                onChange={(date) => setSelectedDate(date as Date)}
                onAccept={handleConfirmDate}
                okLabel="aplicar"
                cancelLabel="cancelar"
                data-testid="datePicker"
              />
            </div>
          </div>
          <div className="selector-container">
            <h3>Período</h3>
            <div className="minutes-selector">
              <FormControl variant="outlined" fullWidth>
                <CustomSelect
                  value={selectedMinutes}
                  onChange={handleMinutesChange}
                  fullWidth
                  input={<CustomInput />}
                  data-testid="minutesPicker"
                >
                  {Array.from(Array(30), (_, i) => (
                    <MenuItem key={i} value={i + 1}>{`${i + 1} m`}</MenuItem>
                  ))}
                </CustomSelect>
              </FormControl>
            </div>
          </div>
        </div>
        <div className="graph-container" data-testid="iframeContainer">
          <iframe
            title="FFT"
            src={grafanaUrl}
            width="100%"
            height="100%"
            frameBorder="0"
          />
        </div>
        <div className="tags-container" data-testid="tagsContainer">
          <Tags
            selectedTags={selectedTag ? [selectedTag] : []}
            handleSelectTag={handleSelectedTag}
          />
        </div>
      </Container>
    </>
  );
};
