import styled from 'styled-components';

import { supportedDevices } from '../../../styles/supportedDevices';

export const Container = styled.div`
  color: black;
  width: 310px;

  & li.MuiMenuItem-root {
    font-size: 12px;
  }

  .MuiPickersCalendarHeader-iconButton svg {
    font-size: 2rem;
  }

  .MuiPickerDTTabs-tabs svg {
    font-size: 2.4rem;
  }

  .MuiPickersSlideTransition-transitionContainer.MuiPickersCalendar-transitionContainer {
    min-height: 250px;
  }

  .MuiPickersCalendarHeader-transitionContainer p,
  .MuiPickersDay-day p {
    font-size: 1.8rem;
  }

  span.MuiPickersCalendarHeader-dayLabel {
    font-size: 1.6rem;
  }

  .picker-actions {
    display: flex;
    align-items: center;
    justify-content: space-evenly;
    background-color: ${({ theme }) => theme.palette.background.paper};

    padding: 28px;

    button.action {
      font-size: 12px;
      width: 100px;
      background-color: ${({ theme }) => theme.palette.primary.main};
      color: white;

      &.gradient {
        background-image: linear-gradient(to right, #19b0a0, #009ee2);
        color: white;
        margin: 0;
      }
    }
  }

  && .MuiPickersMonth-monthSelected,
  && .MuiPickersYear-yearSelected {
    font-weight: 600;
    font-size: 2.5rem;
  }

  @media ${supportedDevices.tablet} {
    & span.MuiPickersCalendarHeader-dayLabel,
    .MuiPickersDay-day p {
      font-size: 1.4rem;
    }
  }
`;
