import { createMuiTheme } from '@material-ui/core';

// ? Theme to predefine some colors and styles

export const theme = createMuiTheme({
  palette: {
    primary: {
      main: 'rgb(58,58,58)',
      dark: 'rgb(58,58,58)',
      light: 'rgb(58,58,58)',
      contrastText: '#FFF',
    },
    secondary: {
      main: 'rgb(230, 232, 235)',
      dark: '#c6c6c6',
      light: 'rgb(230, 232, 235)',
      contrastText: '#000',
    },
    background: {
      paper: 'rgb(230, 232, 235)',
      default: '#373738',
    },
    action: {
      hover: 'rgba(106, 106, 106, 0.5)',
    },
  },
  typography: {
    h3: {
      fontSize: '4.8rem',
    },
    h4: {
      fontSize: '3.4rem',
    },
    subtitle1: {
      fontSize: '1.6rem',
    },
    body1: {
      fontSize: '1.6rem',
    },
    body2: {
      fontSize: '1.4rem',
    },
    caption: {
      fontSize: '1.2rem',
    },
  },
});
