import React from 'react';

import * as d3 from 'd3';
import d3Tip from 'd3-tip';
import _ from 'lodash';
import { isEqual } from 'lodash/lang';

import { TreeMapContainer, GlobalStyle } from './styles';

export class Treemap extends React.Component {
  componentDidMount() {
    this.renderTreemap(this.props);
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (!isEqual(this.props.filteredColors, nextProps.filteredColors)) {
      this.filterColors(nextProps.filteredColors);
    } else if (!isEqual(this.props.healthType, nextProps.healthType)) {
      d3.select('.svg-treemap').selectAll('*').remove();
      this.renderTreemap(nextProps);
    } else if (!isEqual(this.props, nextProps)) {
      d3.select('.svg-treemap').selectAll('*').remove();
      this.renderTreemap(nextProps);
    }
  }

  getHealthFromNode = (item) => {
    // if (this.props.healthType === "global")
    //   return (item.data.physical + item.data.logical) / 2;
    // else return item.data[this.props.healthType];
    return item.data.value;
  };

  filterColors(colors) {
    const self = this;
    const svg = d3.select(`#${this.props.keyId}-svg`);

    svg
      .selectAll('rect')
      .style('opacity', (d) =>
        colors.includes(self.color(d.data)) ? 0.05 : 1
      );
  }

  color(data) {
    return `rgb(${this.props.definingNodeColor(data).join(',')})`;
  }

  colorArray(data) {
    return this.props.definingNodeColor(data);
  }

  betweencolors = (color, colorMin, colorMax) => {
    const teste =
      _.inRange(color[0], colorMin[0], colorMax[0] + 1) &&
      _.inRange(color[1], colorMin[1], colorMax[1] + 1) &&
      _.inRange(color[2], colorMin[2], colorMax[2] + 1);
    return teste;
  };

  formatPercent = (value) => {
    return value >= 0 && value <= 1
      ? d3.format('.2%')(value).replace('.', ',')
      : '';
  };

  update = (svg, specs, margin, node, tooltip, self) => {
    function mouseover(d) {
      if (d.data.tip) tooltip.show(d, this);

      svg
        .selectAll('g')
        .filter(
          (e) =>
            e !== d && !self.props.filteredColors.includes(self.color(e.data))
        )
        .style('opacity', 0.4);

      self.props.onHover(d);
    }

    function mouseleave(d) {
      tooltip.hide(d);

      svg
        .selectAll('g')
        .filter((e) => !self.props.filteredColors.includes(self.color(e.data)))
        .style('opacity', 1);

      self.props.onHover(node);
    }

    function fitText(d) {
      const textWidth = this.getBBox().width;
      const textHeight = this.getBBox().height;
      const rectWidth = d.x1 - d.x0;
      const rectHeight = d.y1 - d.y0;
      if (
        textWidth + specs.titleXAlign > rectWidth ||
        textHeight + specs.titleYAlign > rectHeight
      ) {
        d3.select(this).text('');
        const linesData = d.data.name.split(' ');
        for (let i = 0; i < linesData.length; i++) {
          d3.select(this)
            .append('tspan')
            .text(linesData[i])
            .attr(
              'y',
              i === 0
                ? d.y0 + specs.titleYAlign
                : d.y0 + specs.titleYAlign + i * specs.titleSize
            )
            .attr('x', d.x0 + specs.titleXAlign);
        }

        // Still Fit?
        const linesWidth = this.getBBox().width;
        const linesHeight = this.getBBox().height;
        if (
          linesWidth + specs.titleXAlign > rectWidth ||
          linesHeight + specs.titleYAlign > rectHeight
        ) {
          d3.select(this).text('');
          d.data.textLineNumber = 0;
          d.data.tip = true;
        } else {
          // Fit
          d.data.textLineNumber = linesData.length;
          d.data.tip = false;
        }
      } else {
        d.data.textLineNumber = 1;
        d.data.tip = false;
      }
    }

    const treemap = d3
      .treemap()
      .size([self.props.width, self.props.height])
      .padding(1);

    const nodes = treemap(node).descendants();

    const element = svg
      .selectAll('g')
      .data(nodes)
      .enter()
      .append('g')
      // Don't display the parent element
      .style('display', (d) => {
        return d.parent == null ? 'none' : '';
      });

    element.call(tooltip);

    element
      .append('rect')
      .attr('preserveAspectRatio', 'xMinYMin meet')
      .attr('x', (d) => {
        return d.x0;
      })
      .attr('y', (d) => {
        return d.y0 + margin.top;
      })
      .attr('width', (d) => {
        return d.x1 - d.x0;
      })
      .attr('height', (d) => {
        return d.y1 - d.y0;
      })
      .attr('stroke', '#fff')
      .style('fill', (d) => {
        return self.color(d.data);
      })
      .style('opacity', (d) => {
        return self.props.filteredColors.includes(self.color(d.data))
          ? 0.05
          : 1;
      })
      .style('cursor', 'pointer')
      .on('mouseover', mouseover)
      .on('mouseleave', mouseleave)
      .on('click', self.props.onSelect)
      .on('auxclick', self.props.onAuxClick);

    element
      .append('text')
      .attr('preserveAspectRatio', 'xMinYMin meet')
      .attr('x', (d) => {
        return d.x0 + specs.titleXAlign;
      })
      .attr('y', (d) => {
        return d.y0 + specs.titleYAlign;
      })
      .style('font-size', specs.titleSize)
      .style('font-family', specs.titleFont)
      .style('fill', specs.titleColor)
      .style('cursor', 'pointer')
      .text((d) => {
        return d.data.name;
      })
      .each(fitText)
      .on('mouseover', mouseover)
      .on('mouseleave', mouseleave);

    element
      .append('text')
      .attr('preserveAspectRatio', 'xMinYMin meet')
      .attr('x', (d) => {
        return d.x0 + specs.indicatorXAlign;
      })
      .attr('y', (d) => {
        if (d.data.textLineNumber > 1) {
          return (
            d.y0 +
            specs.indicatorYAlign +
            (d.data.textLineNumber - 1) * specs.titleSize
          );
        }
        return d.y0 + specs.indicatorYAlign;
      })
      .style('font-family', specs.indicatorFont)
      .style('font-size', specs.indicatorSize)
      .style('cursor', 'pointer')
      .style('fill', specs.indicatorColor)
      // eslint-disable-next-line func-names
      .text(function (d) {
        const healthIndicatorWidth = this.getBBox().width;
        const healthIndicatorHeight = this.getBBox().height;
        const rectWidth = d.x1 - d.x0;
        const rectHeight = d.y1 - d.y0;
        if (
          healthIndicatorWidth + specs.indicatorXAlign > rectWidth ||
          healthIndicatorHeight + specs.indicatorYAlign > rectHeight
        ) {
          d.data.tip = true;
          return '';
        }
        return self.formatPercent(self.getHealthFromNode(d));
      })
      .on('mouseover', mouseover)
      .on('mouseleave', mouseleave);
  };

  renderTreemap(props) {
    if (props.data) {
      const self = this;
      const svg = d3
        .select(`#${props.keyId}-svg`)
        .attr('viewBox', `0 0 ${props.width} ${props.height}`)
        .attr('preserveAspectRatio', 'xMinYMin meet');
      const rootData = d3
        .hierarchy(props.data)
        .sum((d) => {
          return d.importance;
        })
        .sort((a, b) => {
          return b.data.importance - a.data.importance;
        });

      const margin = { top: 30, right: 0, bottom: 20, left: 0 };
      const tooltip = d3Tip()
        .attr('class', 'd3-tip')
        .offset([-10, 0])
        .html((d) => {
          return `<span>${
            d.data.name
          }</span><br><span style='color: ${self.color(
            d.data
          )}'>${self.formatPercent(d.data.value)}</span>`;
        });

      const titleFont = "'roboto-regular', 'Roboto', 'Open Sans', sans-serif";
      const titleSize = 16;
      const titleXAlign = margin.left + 10;
      const titleYAlign = margin.top + 25;
      const titleColor = 'black';
      const indicatorColor = 'white';
      const indicatorFont = titleFont;
      const indicatorSize = 16;
      const indicatorXAlign = titleXAlign + 5;
      const indicatorYAlign = titleYAlign + indicatorSize + 1.5;
      const parentFont = titleFont;
      const parentSize = titleSize - 4;
      const parentXAlign = titleXAlign;
      const parentYAlign = margin.top - parentSize;
      const parentColor = titleColor;

      const specs = {
        titleFont,
        titleSize,
        titleXAlign,
        titleYAlign,
        titleColor,
        indicatorColor,
        indicatorFont,
        indicatorSize,
        indicatorXAlign,
        indicatorYAlign,
        parentFont,
        parentSize,
        parentXAlign,
        parentYAlign,
        parentColor,
      };
      self.setState({ selectedHealth: props.healthType }, () => {
        self.update(svg, specs, margin, rootData, tooltip, self);
      });
    }
  }

  componentWillUnmount() {
    d3.selectAll('.d3-tip').remove();
  }

  render() {
    return (
      <>
        <GlobalStyle />
        <TreeMapContainer
          id={this.props.keyId}
          width={this.props.width}
          height={this.props.height}
        >
          <svg className="svg-treemap" id={`${this.props.keyId}-svg`} />
        </TreeMapContainer>
      </>
    );
  }
}
