import { toastr } from 'react-redux-toastr';

import { put, select } from 'redux-saga/effects';

import config from '../../config';
import { Creators, Types } from '../actionCreators';
import { AppState } from '../reducers';
import {
  DataAnalysisData,
  DataAnalysisState,
  GetAnalysisDataRequestAction,
  GetAssetTagsRequestAction,
} from './types';

interface GetAssetTagsAction extends GetAssetTagsRequestAction {
  type: typeof Types.GET_ASSET_TAGS_REQUEST;
}

export function* getAssetTags({ uuids }: GetAssetTagsAction) {
  try {
    const response = yield config.api.getTagsByAssets(uuids);
    yield put(Creators.getAssetTagsSuccess(response.data));
  } catch (err) {
    const msg = 'Ocorreu um erro ao recuperar as tags do ativo';
    toastr.error('Erro de Rede', msg);
    yield put(Creators.getAssetTagsFailure(msg));
  }
}

interface GetAnalysisDataAction extends GetAnalysisDataRequestAction {
  type: typeof Types.GET_ANALYSIS_DATA_REQUEST;
}

export function* getAnalysisData({ analysis }: GetAnalysisDataAction) {
  try {
    const {
      timePeriod,
      metrics,
      periodResolution,
    }: DataAnalysisState = yield select(
      (state: AppState) => state.dataAnalysis
    );

    let response: { data: DataAnalysisData };

    let { startTime, endTime } = timePeriod;

    if (typeof startTime === 'number' && typeof endTime === 'number') {
      startTime = Math.floor(startTime / 1000);
      endTime = Math.floor(endTime / 1000);
    }

    switch (analysis) {
      case 'tagsComparisonAnalysis':
        response = yield config.api.getTagsComparisonData(
          startTime,
          endTime,
          metrics,
          periodResolution
        );
        break;
      default:
        response = { data: {} as DataAnalysisData };
        break;
    }

    yield put(Creators.getAnalysisDataSuccess(analysis, response.data));
  } catch (err) {
    const msg =
      'Ocorreu um erro ao recuperar os dados. Recarregue a página e tente novamente.';
    toastr.error('Erro de Rede', msg);
    yield put(Creators.getAnalysisDataFailure(msg));
  }
}
