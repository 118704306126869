import React, { useState } from 'react';
import ReduxToastr from 'react-redux-toastr';
import { Redirect, RouteComponentProps } from 'react-router-dom';

import { CircularProgress } from '@material-ui/core';

import { Header } from '../components/Header';
import { Router } from './router';

import { deviceSizes } from '../styles/supportedDevices';
import { Container, Content } from './styles';
import { useAuthStore } from '../zustand/AuthStore';

export interface HomeScreenProps extends RouteComponentProps {
  // ? Other props here
}

export const HomeScreen: React.FC<HomeScreenProps> = (props) => {
  const { isAuth, isAuthing } = useAuthStore();

  const [notification, setNotification] = useState(
    window.innerWidth > deviceSizes.tablet
  );
  const [os, setOs] = useState(false);

  function toggleNotification() {
    setNotification(!notification);
  }

  function toggleOs() {
    setOs(!os);
  }

  if (isAuthing)
    return (
      <Container className="loader">
        <CircularProgress size={50} />
      </Container>
    );

  if (!isAuth) return <Redirect to="/" />;
  return (
    <Container>
      <Header
        toggleNotificationPanel={toggleNotification}
        isNotificationOpen={notification}
        toggleOsPanel={toggleOs}
        isOsOpen={os}
      />
      <Content>
        <Router notification={notification} os={os} {...props} />
        <ReduxToastr
          timeOut={4000}
          position="top-center"
          preventDuplicates
          progressBar
          closeOnToastrClick
        />
      </Content>
    </Container>
  );
};
