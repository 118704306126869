import React, { Component } from 'react';
import Media from 'react-media';
import { connect } from 'react-redux';
import { RouteComponentProps } from 'react-router-dom';

import { Update as UpdateIcon } from '@material-ui/icons';
import { HierarchyNode } from 'd3';
import { cloneDeep, xor } from 'lodash';

import { CustomDialog } from '../../../../../components/CustomDialog';
import {
  DateFilterItem,
  TimePeriod,
} from '../../../../../components/DateFilter';
import { NodeData } from '../../../../../models/TreeMapNode';
import { Creators } from '../../../../../store/actionCreators';
import { AppState } from '../../../../../store/reducers';
import { kSunburstColors } from '../../../../../utils/constants';
import { DesktopGraphView } from './DesktopGraphView';
import { MobileGraphView } from './MobileGraphView';
import { HealthType, ChartType, SelectableKPIType } from './types';

import { deviceSizes } from '../../../../../styles/supportedDevices';
import { useAuthStore } from '../../../../../zustand/AuthStore';

export interface GraphViewRouteState {
  lastNode?: HierarchyNode<NodeData>;
  shouldReload?: boolean;
}

interface GraphViewProps
  extends RouteComponentProps<{}, any, GraphViewRouteState | undefined> {
  load: typeof Creators.getTreeHealthRequest;
  onHover: typeof Creators.onHoverHealth;
  onSelectHealth: typeof Creators.onSelectHealth;
  treeHealth: AppState['treeHealth'];
  // user: AppState['auth'];
  loadOsResume: () => {};
  handleLayerBlock: typeof Creators.handleLayerBlockRequest;
  loadSelectableIndicators: typeof Creators.getSelectableIndicatorsRequest;
  setTreeHealthTimePeriod: typeof Creators.setTreeHealthTimePeriod;
  setAssetTimePeriod: typeof Creators.setAssetTimePeriod;
  getTreeHealthInitialRequest: typeof Creators.getTreeHealthInitialRequest;
  setHistoryTimePeriod: typeof Creators.setHistoryTimePeriod;
}

interface GraphViewState {
  visibleGraph: VisibleGraph;
  filteredColors: string[];
  selectedHealth: HealthType;
  selectedKPI: SelectableKPIType;
  isModalOpen: boolean;
  lastNode?: HierarchyNode<NodeData>;
  shouldRenderLastNode: boolean;
}

interface VisibleGraph {
  sunburst: boolean;
  treemap: boolean;
}

export interface ResponsiveGraphViewProps {
  filteredColors: string[];
  selectedHealth: HealthType;
  selectedKPI: SelectableKPIType;
  visibleGraph: VisibleGraph;
  onSelect: (node: HierarchyNode<NodeData>) => void;
  onAuxClick: (node: HierarchyNode<NodeData>) => void;
  onHover: (node: HierarchyNode<NodeData>) => void;
  toggleChartVisible: (chart: ChartType) => void;
  onFilterClick: (color: string) => void;
  getNodeColor: (node: NodeData) => [number, number, number];
  onTreemapSelect: (node: HierarchyNode<NodeData>) => void;
  onTreemapHover: (node: HierarchyNode<NodeData>) => void;
  handleLayerBlock: () => void;
  timeList: DateFilterItem[];
}

//  Periodos do Filtro por tempo
const timeList = [
  ['yesterday', 'D - 1', 'Ontem'],
  ['24h', '24 H', 'Últimas 24 Horas'],
  ['7d', '7 D', 'Últimos 7 Dias'],
  ['30d', '30 D', 'Últimos 30 Dias'],
];

const fixedTimePeriods = [
  {
    id: 'ATUAL',
    content: <UpdateIcon fontSize="large" />,
    tooltip: 'Tempo Real',
  },
  ...timeList.map((time) => {
    return {
      id: time[0],
      content: time[1],
      tooltip: time[2],
    };
  }),
];

class GraphView extends Component<GraphViewProps, GraphViewState> {
  constructor(props: GraphViewProps) {
    super(props);
    this.state = {
      visibleGraph: {
        sunburst: true,
        treemap: false,
      },
      filteredColors: [],
      selectedHealth: 'global',
      selectedKPI: 'global',
      isModalOpen: false,
      lastNode: props.location.state?.lastNode,
      shouldRenderLastNode: false,
    };
  }

  componentDidMount() {
    const {
      load,
      loadOsResume,
      loadSelectableIndicators,
      setAssetTimePeriod,
      setHistoryTimePeriod,
    } = this.props;

    // setTreeHealthTimePeriod({ startTime: null, endTime: null });
    load();


    setHistoryTimePeriod({
      startTime: 'yesterday',
      endTime: 'now',
    });

    setAssetTimePeriod({
      startTime: '30m',
      endTime: 'now',
    });

    loadSelectableIndicators();
    loadOsResume();
  }

  static findNodeFromIdentifier(
    lastNode: HierarchyNode<NodeData>,
    props: GraphViewProps
  ) {
    const lastNodeTrail = lastNode!.data.identifier;

    let currentNode: d3.HierarchyNode<NodeData> | undefined =
      props.treeHealth.selectedNode;
    while (
      currentNode &&
      currentNode?.data.identifier !== lastNodeTrail &&
      currentNode.children
    ) {
      currentNode = currentNode?.children!.find((child) =>
        lastNodeTrail?.includes(child.data.identifier)
      );
    }

    if (currentNode?.data.identifier === lastNodeTrail) {
      const sequence = currentNode.ancestors().reverse();
      // Manipulate the object to handle Treemap visualization
      const selectedNode = cloneDeep(currentNode.data);
      selectedNode.importance = 0;

      if (selectedNode.children != null) {
        selectedNode.children.forEach((element) => {
          element.children = null;
        });
        props.onSelectHealth(
          selectedNode,
          sequence,
          sequence[sequence.length - 1]
        );
      }
    }
  }

  static getDerivedStateFromProps(
    props: GraphViewProps,
    state: GraphViewState
  ) {
    const { treeHealth } = props;

    if (
      !treeHealth.isLoading &&
      Object.keys(treeHealth.data).length > 0 &&
      state.shouldRenderLastNode &&
      state.lastNode
    ) {
      setTimeout(
        () => GraphView.findNodeFromIdentifier(state.lastNode!, props),
        600
      );
      return {
        ...state,
        lastNode: undefined,
      };
    }

    return {
      ...state,
      shouldRenderLastNode: true,
    };
  }

  toggleChartVisible = (chart: ChartType) => {
    const { visibleGraph } = this.state;

    if (
      (visibleGraph.sunburst && visibleGraph.treemap) ||
      (visibleGraph.sunburst && chart === 'treemap') ||
      (visibleGraph.treemap && chart === 'sunburst')
    )
      this.setState({
        visibleGraph: {
          ...visibleGraph,
          [chart]: !visibleGraph[chart],
        },
      });
  };

  toggleMobileChartVisible = (chart: ChartType) => {
    let newVisibleGraph = {} as VisibleGraph;

    if (chart === 'treemap')
      newVisibleGraph = {
        treemap: true,
        sunburst: false,
      };
    else
      newVisibleGraph = {
        treemap: false,
        sunburst: true,
      };

    this.setState({
      visibleGraph: newVisibleGraph,
    });
  };

  findNodeInHierarchy = (node: HierarchyNode<NodeData>) => {
    return this.props.treeHealth.selectedNode!.children!.find(
      (e) => e.data.identifier === node.data.identifier
    );
  };

  onTreemapSelect = (e: HierarchyNode<NodeData>) => {
    const node = this.findNodeInHierarchy(e);
    this.onSelect(node!);
  };

  onTreemapHover = (e: HierarchyNode<NodeData>) => {
    const node =
      this.findNodeInHierarchy(e) || this.props.treeHealth.selectedNode;
    this.onHover(node!);
  };

  onHover = (e: HierarchyNode<NodeData>) => {
    this.props.onHover(e.ancestors().reverse());
  };

  onSelect = (node: HierarchyNode<NodeData>) => {
    if (node !== this.props.treeHealth.selectedNode) {
      // If an asset is selected, go to the asset's details
      if (node.data.type === 'Asset') {
        this.props.getTreeHealthInitialRequest()
        this.props.history.push(`/admin/dashboard/ativo/${node.data.uuid}`, {
          lastNode: this.props.treeHealth.selectedNode,
        });
      }
      else if (node.data.type === 'LogicalAsset') {
        this.props.getTreeHealthInitialRequest()
        this.props.history.push(
          `/admin/dashboard/ativo-logico/${node.data.uuid}`,
          {
            lastNode: this.props.treeHealth.selectedNode,
          }
        );
      }

      this.handleTreemapVisualization(node)
    }
  };

  handleTreemapVisualization = (node: HierarchyNode<NodeData>) => {
    const sequence = node.ancestors().reverse();
    // Manipulate the object to handle Treemap visualization
    const selectedNode = cloneDeep(node.data);
    selectedNode.importance = 0;

    if (selectedNode.children != null) {
      selectedNode.children.forEach((element) => {
        element.children = null;
      });
      this.props.onSelectHealth(
        selectedNode,
        sequence,
        sequence[sequence.length - 1]
      );
    }
  };

  componentDidUpdate(prevProps: GraphViewProps) {
    if (!prevProps.treeHealth.isFirstLoadingComplete && this.props.treeHealth.isFirstLoadingComplete) {
      // const targetUuid = this.props.user.user.defaultTreeElementUuid

      const targetUuid = useAuthStore.getState().user.defaultTreeElementUuid;

      if (targetUuid) {
        const initialNode = this.props.treeHealth.hierarchy
        const node = this.findNodeInHierarchyByUuid(initialNode, targetUuid)

        if (node) {
          setTimeout(
            () => {
              this.handleTreemapVisualization(node)
            },
            500
          );
        }
      }
    }
  }

  findNodeInHierarchyByUuid = (node: HierarchyNode<NodeData> | null, targetUuid: string): d3.HierarchyNode<NodeData> | null => {
    if (!node) {
      return null;
    }

    if (node.data.uuid === targetUuid) {
      return node;
    }

    // Recursive search in child nodes
    for (const child of node.children || []) {
      const result = this.findNodeInHierarchyByUuid(child, targetUuid);
      if (result) {
        return result;
      }
    }

    return null;
  }

  onAuxClick = (node: HierarchyNode<NodeData>) => {
    if (node !== this.props.treeHealth.selectedNode) {
      // If an asset is selected, go to the asset's details
      if (node.data.type === 'Asset')
        window.open(`/admin/dashboard/ativo/${node.data.uuid}`)

      else if (node.data.type === 'LogicalAsset')
        window.open(`/admin/dashboard/ativo-logico/${node.data.uuid}`)

    }
  };

  onFilterClick = (color: string) => {
    this.setState((prevState) => {
      let newFilteredColors = prevState.filteredColors.slice();

      newFilteredColors = xor(newFilteredColors, [color]);

      return { filteredColors: newFilteredColors };
    });
  };

  getNodeColor = (data: NodeData) => {

    let index: number;

    if (this.props.treeHealth.timePeriod.startTime && this.props.treeHealth.timePeriod.endTime) {
      index = (data.value === null || data.value === undefined) ? kSunburstColors.length - 1 : Math.floor(data.value * 10);
    } else if (data.blocked) {
      index = 12;
    } else if (this.isDisabled(data)) {
      index = 13;
    } else {
      index = (data.value === null || data.value === undefined) ? (this.props.treeHealth.selectedIndicator.name === 'Saúde' ? 11 : 13) : Math.floor(data.value * 10);
    }
    
    return kSunburstColors[index].rgb;
  };

  isDisabled = (node: NodeData): boolean => {
    if (node.children) {
      return node.children.every(this.isDisabled);
    } else {
      return !!node.disabled;
    }
  };  

  handleToggleModal = (open = false) => {
    this.setState({ isModalOpen: open });
  };

  handleConfirmLayerBlock = () => {
    const { uuid, blocked } = this.props.treeHealth.selectedNode.data;
    this.props.handleLayerBlock(uuid, !blocked);
    this.handleToggleModal();
  };

  getOrientation = () => {
    return window.screen.orientation?.type || 'landscape-primary';
  };

  render() {
    const params = {
      ...this.state,
      onSelect: this.onSelect,
      onAuxClick: this.onAuxClick,
      onHover: this.onHover,
      toggleChartVisible: this.toggleChartVisible,
      onFilterClick: this.onFilterClick,
      getNodeColor: this.getNodeColor,
      onTreemapSelect: this.onTreemapSelect,
      onTreemapHover: this.onTreemapHover,
      handleLayerBlock: () => this.handleToggleModal(true),
      timeList: fixedTimePeriods,
    };

    const { data } = this.props.treeHealth.selectedNode;

    return (
      <Media
        queries={{
          tablet: `(max-width: ${deviceSizes.laptop}px)`,
          ipad: `(min-width: ${deviceSizes.tablet}px)`,
        }}
      >
        {(matches) => (
          <>
            {!matches.tablet &&
              this.getOrientation().startsWith('landscape') && (
                <DesktopGraphView {...params} />
              )}
            {matches.tablet && (
              <MobileGraphView
                {...params}
                toggleChartVisible={
                  matches.ipad
                    ? this.toggleChartVisible
                    : this.toggleMobileChartVisible
                }
              />
            )}
            <CustomDialog
              open={this.state.isModalOpen}
              title={`${data?.blocked ? 'Desbloquear' : 'Bloquear'} ${
                data?.name
              }`}
              body={
                <p>
                  {`Você tem certeza que deseja ${
                    data?.blocked ? 'desbloquear' : 'bloquear'
                  } todos os ativos de ${data?.name}?`}
                </p>
              }
              action={{
                label: 'confirmar',
                onClick: this.handleConfirmLayerBlock,
              }}
              onClose={() => this.handleToggleModal()}
            />
          </>
        )}
      </Media>
    );
  }
}

const mapStateToProps = (state: AppState) => ({
  treeHealth: state.treeHealth,
  os: state.os,
  // user: state.auth,
});
const mapDispacthToProps = (dispatch: any) => ({
  load: () => dispatch(Creators.getTreeHealthRequest()),
  setTreeHealthTimePeriod: (timePeriod: TimePeriod) =>
    dispatch(Creators.setTreeHealthTimePeriod(timePeriod)),
  setAssetTimePeriod: (timePeriod: TimePeriod) =>
    dispatch(Creators.setAssetTimePeriod(timePeriod)),
  setHistoryTimePeriod: (timePeriod: TimePeriod) =>
    dispatch(Creators.setAssetTimePeriod(timePeriod)),
  loadSelectableIndicators: () =>
    dispatch(Creators.getSelectableIndicatorsRequest()),
  onHover: (sequence: HierarchyNode<NodeData>[]) =>
    dispatch(Creators.onHoverHealth(sequence)),
  onSelectHealth: (
    treeMapData: NodeData,
    sequence: HierarchyNode<NodeData>[],
    selectedNode: HierarchyNode<NodeData>
  ) => dispatch(Creators.onSelectHealth(treeMapData, sequence, selectedNode)),
  loadOsResume: () => dispatch(Creators.getOsResumeRequest()),
  handleLayerBlock: (uuid: string, blocked: boolean) =>
    dispatch(Creators.handleLayerBlockRequest(uuid, blocked)),
  getTreeHealthInitialRequest: () => dispatch(Creators.getTreeHealthInitialRequest()),
});

export default connect(mapStateToProps, mapDispacthToProps)(GraphView);
