import styled, { createGlobalStyle } from 'styled-components';

export const GlobalStyle = createGlobalStyle`
  .d3-tip {
    line-height: 1.5;
    font-weight: bold;
    font-size: 1.5em;
    font-family: "roboto-regular", "Roboto", "Open Sans", sans-serif;
    padding: 1%;
    background: ${(props) => props.theme.palette.background.default};
    color: #fff;
    border-radius: 4px;
  }
`;

export interface TreeMapContainerProps {
  width: number;
  height: number;
}

export const TreeMapContainer = styled.div<TreeMapContainerProps>`
  height: ${(props) => props.height}px;
  width: ${(props) => props.width}px;

  svg.svg-treemap {
    position: relative;
    left: 0px;
    top: 0px;
  }
`;
