import { endOfWeek, startOfWeek, subWeeks } from 'date-fns';
import { createReducer, createActions } from 'reduxsauce';

import {
  ReportsTypes,
  ReportsActions,
  ReportsState,
  GetReportsFailureAction,
  GetReportsSuccessAction,
  GetReportsRequestAction,
  ToggleNavigationPanelAction,
  SetElementUuidAction,
  SetSelectedAlertEmailsRequestAction,
  SetSelectedAlertEmailsSuccessAction,
  SetSelectedAlertEmailsFailureAction,
  setReportsPeriodResolutionAction,
  SetNavigationOptionAction,
  SetTreeOptionsAction,
  SetPickerTypeAction,
  GetCollectedTagsInfoRequestAction,
  GetCollectedTagsInfoSuccessAction,
  GetCollectedTagsInfoFailureAction,
  GetTagRatioInfoRequestAction,
  GetTagRatioInfoSuccessAction,
  GetTagRatioInfoFailureAction
} from './types';

const { Types, Creators } = createActions<ReportsTypes, ReportsActions>({
  toggleReportsNavigationPanel: ['open'],
  getReportsRequest: ['time', 'report', 'oldestTime'],
  getReportsSuccess: ['data', 'reportType'],
  getReportsFailure: ['error'],
  getCollectedTagsInfoRequest: ['companyUuid', 'tagType'],
  getCollectedTagsInfoSuccess: ['data', 'tagType'],
  getCollectedTagsInfoFailure: ['error'],
  getTagRatioInfoRequest: ['companyUuid'],
  getTagRatioInfoSuccess: ['data'],
  getTagRatioInfoFailure: ['error'],
  setElementUuid: ['uuid'],
  setReportsPeriodResolution: ['resolution'],
  setNavigationOption: ['option'],
  setTreeOptions: ['types', 'mode'],
  resetReports: null,
  setPickerType: ['pickerType'],
  setSelectedAlertEmailsRequest: ['emails', 'minAvailability'],
  setSelectedAlertEmailsSuccess: ['data'],
  setSelectedAlertEmailsFailure: ['error'],
});

const INITIAL_STATE: ReportsState = {
  isNavigationPanelOpen: true,
  navigationOption: 'menus',
  selectableTreeItems: [],
  treeItemsSelectionMode: 'single',
  isLoading: false,
  error: '',
  data: {
    perElement: null,
    perPeriod: null,
    perPhysicalAvailability: null,
    healthPeriodComparison: null,
    collectedTagsInfo: null,
    collectedLogicalTags: null,
    tagRatio: null
  },
  timePeriod: {
    startTime: startOfWeek(new Date()).getTime(),
    endTime: new Date().getTime(),
  },
  oldestTimePeriod: {
    startTime: startOfWeek(subWeeks(new Date(), 1)).getTime(),
    endTime: endOfWeek(subWeeks(new Date(), 1)).getTime(),
  },
  pickerType: 'week',
  elementUuid: '',
  periodResolution: '1d',
  selectedAlertEmails: {
    user: '',
    emails: [],
    minAvailability: 0
  },
};

const toggleNavigationPanel = (
  state = INITIAL_STATE,
  action: ToggleNavigationPanelAction
) => {
  return {
    ...state,
    isNavigationPanelOpen: Boolean(action.open),
  };
};

const getReportsRequest = (
  state = INITIAL_STATE,
  action: GetReportsRequestAction
) => {
  return {
    ...state,
    isLoading: true,
    error: '',
    timePeriod: action.time,
    oldestTimePeriod: action.oldestTime || state.oldestTimePeriod,
  };
};

const getReportsSuccess = (
  state = INITIAL_STATE,
  { data, reportType }: GetReportsSuccessAction
) => {
  return {
    ...state,
    data: {
      ...state.data,
      [reportType]: data,
    },
    isLoading: false,
  };
};

const getReportsFailure = (
  state = INITIAL_STATE,
  action: GetReportsFailureAction
) => {
  return {
    ...state,
    isLoading: false,
    error: action.error,
  };
};

const getCollectedTagsInfoRequest = (
  state = INITIAL_STATE,
  action: GetCollectedTagsInfoRequestAction
) => {
  return {
    ...state,
    isLoading: true,
    error: '',
  };
};

const getCollectedTagsInfoSuccess = (
  state = INITIAL_STATE,
  { data, tagType }: GetCollectedTagsInfoSuccessAction
) => {
  return {
    ...state,
    data: {
      ...state.data,
      collectedTagsInfo: data,
      [tagType]: data,
    },
    isLoading: false,
  };
};

const getCollectedTagsInfoFailure = (
  state = INITIAL_STATE,
  action: GetCollectedTagsInfoFailureAction
) => {
  return {
    ...state,
    isLoading: false,
    error: action.error,
  };
};

const getTagRatioInfoRequest = (
  state = INITIAL_STATE,
  action: GetTagRatioInfoRequestAction
) => {
  return {
    ...state,
    isLoading: true,
    error: '',
  };
};

const getTagRatioInfoSuccess = (
  state = INITIAL_STATE,
  { data }: GetTagRatioInfoSuccessAction
) => {
  return {
    ...state,
    data: {
      ...state.data,
      tagRatio: data
    },
    isLoading: false,
  };
};

const getTagRatioInfoFailure = (
  state = INITIAL_STATE,
  action: GetTagRatioInfoFailureAction
) => {
  return {
    ...state,
    isLoading: false,
    error: action.error,
  };
};

const setElementUuid = (
  state = INITIAL_STATE,
  { uuid: elementUuid }: SetElementUuidAction
) => {
  return {
    ...state,
    elementUuid,
  };
};

const setSelectedAlertEmailsRequest = (
  state = INITIAL_STATE,
  { emails, minAvailability }: SetSelectedAlertEmailsRequestAction
) => {
  return {
    ...state,
    selectedAlertEmails: {
      emails,
      minAvailability,
    },
  };
};

const setSelectedAlertEmailsSuccess = (
  state = INITIAL_STATE,
  { data }: SetSelectedAlertEmailsSuccessAction
) => {
  return {
    ...state,
  };
};

const setSelectedAlertEmailsFailure = (
  state = INITIAL_STATE,
  { error }: SetSelectedAlertEmailsFailureAction
) => {
  return {
    ...state,
    error,
  };
};

const setReportPeriodResolution = (
  state = INITIAL_STATE,
  { resolution: periodResolution }: setReportsPeriodResolutionAction
) => {
  return {
    ...state,
    periodResolution,
  };
};

const setNavigationOption = (
  state = INITIAL_STATE,
  { option: navigationOption }: SetNavigationOptionAction
) => {
  return {
    ...state,
    navigationOption,
  };
};

const setTreeOptions = (
  state = INITIAL_STATE,
  { types: selectableTreeItems, mode }: SetTreeOptionsAction
) => {
  return {
    ...state,
    selectableTreeItems,
    treeItemsSelectionMode: mode || 'single',
  };
};

const setPickerType = (
  state = INITIAL_STATE,
  { pickerType }: SetPickerTypeAction
) => ({
  ...state,
  pickerType,
});

const resetReports = () => INITIAL_STATE;

export const HANDLERS = {
  [Types.TOGGLE_REPORTS_NAVIGATION_PANEL]: toggleNavigationPanel,
  [Types.GET_REPORTS_REQUEST]: getReportsRequest,
  [Types.GET_REPORTS_SUCCESS]: getReportsSuccess,
  [Types.GET_REPORTS_FAILURE]: getReportsFailure,
  [Types.GET_COLLECTED_TAGS_INFO_REQUEST]: getCollectedTagsInfoRequest,
  [Types.GET_COLLECTED_TAGS_INFO_SUCCESS]: getCollectedTagsInfoSuccess,
  [Types.GET_COLLECTED_TAGS_INFO_FAILURE]: getCollectedTagsInfoFailure,
  [Types.GET_TAG_RATIO_INFO_REQUEST]: getTagRatioInfoRequest,
  [Types.GET_TAG_RATIO_INFO_SUCCESS]: getTagRatioInfoSuccess,
  [Types.GET_TAG_RATIO_INFO_FAILURE]: getTagRatioInfoFailure,
  [Types.SET_ELEMENT_UUID]: setElementUuid,
  [Types.SET_REPORTS_PERIOD_RESOLUTION]: setReportPeriodResolution,
  [Types.SET_NAVIGATION_OPTION]: setNavigationOption,
  [Types.SET_TREE_OPTIONS]: setTreeOptions,
  [Types.RESET_REPORTS]: resetReports,
  [Types.SET_PICKER_TYPE]: setPickerType,
  [Types.SET_SELECTED_ALERT_EMAILS_REQUEST]: setSelectedAlertEmailsRequest,
  [Types.SET_SELECTED_ALERT_EMAILS_SUCCESS]: setSelectedAlertEmailsSuccess,
  [Types.SET_SELECTED_ALERT_EMAILS_FAILURE]: setSelectedAlertEmailsFailure,
};

const Reducer = createReducer(INITIAL_STATE, HANDLERS);

export {
  Creators as ReportsCreators,
  Types as ReportsTypes,
  Reducer as ReportsReducer,
};
