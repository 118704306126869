import styled from 'styled-components';

import { supportedDevices } from '../../../styles/supportedDevices';

export const Container = styled.div`
  color: black;
  width: 300px;

  & li.MuiMenuItem-root {
    font-size: 12px;
  }

  .MuiPickersCalendarHeader-iconButton svg {
    font-size: 2rem;
  }

  .MuiPickerDTTabs-tabs svg {
    font-size: 2.4rem;
  }

  .MuiPickersSlideTransition-transitionContainer.MuiPickersCalendar-transitionContainer {
    min-height: 250px;
  }

  .MuiPickersCalendarHeader-transitionContainer p,
  .MuiPickersDay-day p {
    font-size: 1.8rem;
  }

  span.MuiPickersCalendarHeader-dayLabel {
    font-size: 1.6rem;
  }

  .picker-actions {
    display: flex;
    align-items: center;
    justify-content: space-evenly;
    background-color: ${({ theme }) => theme.palette.background.paper};

    padding: 28px;

    button.action {
      font-size: 12px;
      width: 100px;
      background-color: ${({ theme }) => theme.palette.primary.main};
      color: white;

      &.gradient {
        background-image: linear-gradient(to right, #19b0a0, #009ee2);
        color: white;
        margin: 0;
      }
    }
  }

  @media ${supportedDevices.tablet} {
    width: 310px;

    & span.MuiPickersCalendarHeader-dayLabel,
    .MuiPickersDay-day p {
      font-size: 1.4rem;
    }
  }
`;

export const PickerContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  background-color: ${({ theme }) => theme.palette.primary.main};
  color: white;

  height: 100px;
  padding: 0 24px;

  &.center {
    align-items: center;
  }

  h3,
  h4,
  h6 {
    line-height: 1.75;
    letter-spacing: 0.00938em;
  }

  h3 {
    font-size: 4.5rem;
  }

  h4 {
    font-size: 2.8rem;
  }

  h6 {
    font-size: 1.6rem;
    font-weight: 400;

    margin-bottom: 5px;
  }

  button {
    transition: opacity 0.2s;
  }

  button.inactive {
    opacity: 0.5;
  }
`;

export const BodyContainer = styled.div`
  && {
    overflow-y: auto;
    scroll-behavior: smooth;
    color: rgba(0, 0, 0, 0.87);
    background-color: ${({ theme }) => theme.palette.background.paper};

    height: 300px;
    font-size: 1.6rem;
    font-weight: 400;
    line-height: 1.75;
    letter-spacing: 0.00938em;

    &::-webkit-scrollbar {
      width: 5px;
    }

    button.year,
    button.trimester,
    button.semester {
      cursor: pointer;
      display: flex;
      align-items: center;
      justify-content: center;
      outline: none;

      height: 40px;
      width: 100%;

      transition: font-size 0.2s;

      &.trimester {
        height: 22%;
      }

      &.semester {
        height: 48%;
      }

      &.selected {
        color: ${({ theme }) => theme.palette.primary.main};

        margin: 10px 0;
        font-weight: 600;
        font-size: 2.3rem;
      }

      &.future {
        color: ${({ theme }) => theme.palette.text.disabled};
        pointer-events: none;
      }
    }
  }
`;
