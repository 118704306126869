import { GraphColor } from '../screens/Dashboard/components/views/GraphView/types';

//  Desktop AppBar height
export const kDesktopAppBarHeight = 80;

//  Mobile AppBar height
export const kMobileAppBarHeight = 70;

//  Dashboard card height
export const kDashboardCardHeight = `calc(90vh - ${kDesktopAppBarHeight}px)`;

// Sunburst Colors
export const kSunburstColors: GraphColor[] = [
  { percentage: '0% - 9%', rgb: [196, 61, 64] },
  { percentage: '10% - 19%', rgb: [213, 71, 74] },
  { percentage: '20% - 29%', rgb: [231, 80, 84] },
  { percentage: '30% - 39%', rgb: [248, 90, 94] },
  { percentage: '40% - 49%', rgb: [235, 113, 10] },
  { percentage: '50% - 59%', rgb: [247, 143, 32] },
  { percentage: '60% - 69%', rgb: [255, 224, 29] },
  { percentage: '70% - 79%', rgb: [220, 219, 52] },
  { percentage: '80% - 89%', rgb: [97, 200, 133] },
  { percentage: '90% - 99%', rgb: [71, 189, 106] },
  { percentage: '100%', rgb: [44, 178, 79] },
  { percentage: 'Sem Dados', rgb: [93, 93, 93] },
  { percentage: 'Bloqueado', rgb: [190, 190, 190] },
  { percentage: 'Desativado', rgb: [240, 240, 240] },
  { percentage: 'Sem Cálculo', rgb: [93, 93, 93] },
];

//  Chart colors multicolors
export const kChartMultiColors = [
  '#5e64f8',
  '#FFCE38',
  '#367D7D',
  '#FAA818',
  '#D33502',
  '#6EBCBC',
  '#37526D',
  '#41A30D',
];

//  Chart gradient colors
export const kChartGradientColors = [
  '#43B187',
  '#3FB08A',
  '#3cAF8D',
  '#38AE90',
  '#35AD94',
  '#31AC97',
  '#2EAB9A',
  '#2AAA9E',
  '#27A9A1',
  '#23A8A4',
  '#20A8A8',
  '#1CA7AB',
  '#19A6AE',
  '#15A5B2',
  '#12A4B5',
  '#0EA3B8',
  '#0BA2BC',
  '#07A1BF',
  '#04A0C2',
  '#01A0C6',
];

// Less gradient colors
export const kChartSmallGradientColors = [
  '#43B187',
  '#38AE90',
  '#2EAB9A',
  '#23A8A4',
  '#19A6AE',
  '#0EA3B8',
  '#04A0C2',
];
