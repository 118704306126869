import styled from 'styled-components';

export const Container = styled.div`
  width: 100%;
  height: 100%;
`;

export const Loader = styled.div`
  display: grid;
  place-items: center;

  height: 100%;
  width: 100%;
`;
