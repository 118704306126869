import React from 'react';

import { Slide } from '@material-ui/core';

import { Container } from './styles';

interface BottomSheetProps {
  open: boolean;
  handleClose(): void;
  children: JSX.Element;
}

export const BottomSheet: React.FC<BottomSheetProps> = ({
  open,
  handleClose,
  children,
}) => {
  function handleBackdropClick(
    event: React.MouseEvent<HTMLDivElement, MouseEvent>
  ) {
    const elementClass = (event.target as HTMLDivElement).className;

    if (
      elementClass &&
      typeof elementClass === 'string' &&
      elementClass.includes('MuiBackdrop-root')
    )
      handleClose();
  }

  return (
    <Container open={open} onClick={handleBackdropClick}>
      <Slide in={open} direction="up">
        <div className="sheet">{children}</div>
      </Slide>
    </Container>
  );
};
