import React, { useEffect } from 'react';

import * as d3 from 'd3';

import { HealthType } from '../../../screens/Dashboard/components/views/GraphView/types';
import { kSunburstColors } from '../../../utils/constants';

import { IndicatorContainer } from './styles';

// Constants
const twoPi = 2 * Math.PI;

const colors = kSunburstColors
  .slice(0, -4)
  .map((color) => `rgb(${color.rgb.join(',')})`);

export interface HealthIndicatorProps {
  size: number;
  width?: number;
  value: number;
  healthType: HealthType;
  innerRadius?: number;
  outerRadius?: number;
  fontSize: number;
  spaceBetween?: number;
  selectedIndicator?: string;
}

// Circular indicator of a total value
// React memo avoids it's rerender without needing to
export const HealthIndicator: React.FC<HealthIndicatorProps> = React.memo(
  (props) => {
    const {
      size,
      value,
      healthType,
      innerRadius,
      outerRadius,
      fontSize,
      width,
      spaceBetween = 15,
      selectedIndicator,
    } = props;

    /** Formats number to avoid binary floating point  */
    function formatPercentage(n: number) {
      return n >= 0 && n <= 1 ? d3.format('.2%')(n).replace('.', ',') : 'N/A';
    }

    /** Renders the indicator  */
    function renderIndicator() {
      const svg = d3.select('#indicatorSvg');

      // Create arc
      const drawArc = d3
        .arc()
        .innerRadius(innerRadius || size * 0.74)
        .outerRadius(outerRadius || size)
        .startAngle(0);

      // Indicator meter
      const meter = svg
        .append('g')
        .attr('class', 'progress-meter')
        .attr('transform', `translate(${size},${size})`);

      // Indicator background
      meter
        .append('path')
        .attr('class', 'background')
        .style('fill', 'whitesmoke')
        .attr('d', drawArc.endAngle(twoPi) as any);

      // Indicator foreground
      const foreground = meter.append('path').attr('class', 'foreground');

      // Get correct icon
      const healthTypes = {
        global: 'saudeglobal',
        physical: 'saudefisica',
        logical: 'saudelogica',
      };
      const healthIcon = healthTypes[healthType];

      // Icon
      const iconSize = size * 0.8;
      meter
        .append('image')
        .attr('href', `/img/${healthIcon}.svg`)
        .attr('width', `${iconSize}px`)
        .attr('height', `${iconSize}px`)
        .attr('x', `-${iconSize / 2}px`)
        .attr('y', `-${iconSize / 2}px`)
        .attr('data-testid', `${healthType}Icon`);

      // Add div to wrap all the added text
      d3.select('#indicatorContainer').append('div').attr('id', 'textDiv');

      // Percentage indicator
      const text = d3
        .select('#textDiv')
        .append('text')
        .attr('text-anchor', 'middle')
        .attr('dominant-baseline', 'middle')
        .attr('class', 'text')
        .attr('x', '50%')
        .attr('y', '50%');

      function transition(t: number) {
        // Creates interpolation between 0 and the current health
        const interpolateText = d3.interpolateNumber(0, value);
        const progress = interpolateText(t);

        // Creates interpolation through colors
        const rgbInterpolate = d3.piecewise(d3.interpolateRgb, colors);
        const rgbForeground = (p: number) => colors[Math.floor(p * 10)];

        const colorForeground =
          Math.floor(progress * 10) === Math.floor(value * 10)
            ? rgbForeground(progress)
            : rgbInterpolate(progress);

        // Attr the color to foreground and text to text
        foreground
          .attr('d', drawArc.endAngle(twoPi * progress) as any)
          .style('fill', colorForeground);
        text.text(formatPercentage(progress));
      }

      d3.transition()
        .tween('progress', () => transition)
        .duration(2000);
    }

    function addSelectedIndicatorText() {
      d3.select('#textDiv')
        .append('text')
        .attr('class', 'indicatorText')
        .text(selectedIndicator as string);
    }

    /**  Builds base svg */
    function buildIndicator() {
      d3.select('#indicatorContainer').selectAll('*').remove();
      d3.select('#indicatorContainer')
        .append('svg:svg')
        .attr('id', 'indicatorSvg')
        .attr('viewBox', `0 0 ${size * 2} ${size * 2}`)
        .attr('preserveAspectRatio', 'xMinYMin meet');

      renderIndicator();
      if (selectedIndicator) {
        addSelectedIndicatorText();
      }
    }

    useEffect(() => {
      buildIndicator();
    });

    return (
      <IndicatorContainer
        id="indicatorContainer"
        data-testid="indicatorContainer"
        fontSize={fontSize}
        size={size}
        width={width}
        spaceBetween={spaceBetween}
      />
    );
  }
);
