import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useRouteMatch } from 'react-router-dom';

import { CircularProgress } from '@material-ui/core';
import { Warning } from '@material-ui/icons';

import { Creators } from '../../../../../../../store/actionCreators';
import { AppState } from '../../../../../../../store/reducers';
import { TagContainer } from '../TagContainer';

import { Container } from '../../styles';
import { AssetErrorContainer } from './styles';

interface TagsListProps {
  isLogicalAsset: boolean;
}

export const TagsList: React.FC<TagsListProps> = ({ isLogicalAsset }) => {
  const { tags } = useSelector((state: AppState) => state);
  const dispatch = useDispatch();

  const match = useRouteMatch<{ ativoPath: string }>();

  useEffect(() => {
    if (!isLogicalAsset) {
      dispatch(Creators.getTagsRequest({ assetUuid: match.params.ativoPath }));
    }
  }, [dispatch, isLogicalAsset, match.params.ativoPath]);

  if (tags.isLoading || tags.data.length === 0)
    return (
      <Container>
        <div className="card loader-container">
          <CircularProgress size={50} />
        </div>
      </Container>
    );

  return (
    <Container>
      <div className="card main-container">
        <AssetErrorContainer>
          <div className="title">
            <div className="error">
              <Warning fontSize="large" />
              <h3>
                Este ativo ainda está sendo configurado. Em breve ele estará
                disponível.
              </h3>
            </div>
            <h1>{tags.data[0].identifier.split('.')[7]}</h1>
          </div>
          <div className="tags-container">
            {tags.data.map((tag) => (
              <TagContainer
                key={tag.identifier}
                tag={tag}
                isDescriptionShown
                handleSelectTag={() => {}}
                getTagColor={() =>
                  tag.unhealthy?.length !== 0 ? 'red' : 'green'
                }
                indicator=""
              />
            ))}
          </div>
        </AssetErrorContainer>
      </div>
    </Container>
  );
};
