import { createActions } from 'reduxsauce';

import { AssetDataCreators, AssetDataTypes } from './assetData';
import { HistoryIndicatorCreators, HistoryIndicatorTypes } from './historyIndicator';
// import { AuthCreators, AuthTypes } from './auth';
import { DataAnalaysisTypes, DataAnalysisCreators } from './dataAnalysis';
import { DrawerCreators, DrawerTypes } from './drawer';
import { NotificationsCreators, NotificationsTypes } from './notifications';
import { ReportsTypes, ReportsCreators } from './reports';
import { TagsCreators, TagsTypes } from './tags';
import { TreeHealthCreators, TreeHealthTypes } from './treeHealth';
import { ActionTypes, CreatorsTypes } from './types';

const actions = createActions<ActionTypes, CreatorsTypes>({
  // KNOWLEDGE
  getKnowledgesRequest: ['pageRequest', 'isAssociate'],
  getKnowledgesSuccess: ['knowledges', 'isAssociate'],
  getKnowledgeByIdRequest: ['id'],
  getKnowledgeByIdSuccess: ['knowledge'],
  setKnowledgeForm: null,
  resetKnowledgeForm: null,
  saveKnowledgeRequest: ['knowledge'],
  saveKnowledgeSuccess: ['knowledge'],
  knowledgeFailure: ['error', 'errorMsg'],

  // SERVICE ORDER
  getOsRequest: ['pageRequest'],
  getOsSuccess: ['os'],
  getOsByIdRequest: ['id'],
  getOsByIdSuccess: ['os'],
  getOsResumeRequest: null,
  getOsResumeSuccess: ['resume'],
  osFailure: ['error'],
  searchOs: ['text'],

  saveOsRequest: ['os'],
  saveOsSuccess: null,
  saveOsFailure: ['error'],
  setOsAsset: ['asset'],
  changeOsTab: ['id'],
  setEnableForm: null,
  resetOsForm: null,

  getTagsDatapointsRequest: ['pageRequest'],
  getTagsDatapointsSuccess: ['datapoints'],
  getTagsDatapointsFailure: ['error'],

  // COST-CENTER
  getCostCentersRequest: null,
  getCostCentersSuccess: ['data'],
  getCostCentersFailure: ['error'],
  // USERS
  getUsersRequest: null,
  getUsersSuccess: ['data'],
  getUsersFailure: ['error'],
});

export const Creators = {
  ...actions.Creators,
  // ...AuthCreators,
  ...DrawerCreators,
  ...AssetDataCreators,
  ...NotificationsCreators,
  ...ReportsCreators,
  ...TagsCreators,
  ...TreeHealthCreators,
  ...DataAnalysisCreators,
  ...HistoryIndicatorCreators,
};
export const Types = {
  ...actions.Types,
  // ...AuthTypes,
  ...DrawerTypes,
  ...AssetDataTypes,
  ...NotificationsTypes,
  ...ReportsTypes,
  ...TagsTypes,
  ...TreeHealthTypes,
  ...DataAnalaysisTypes,
  ...HistoryIndicatorTypes,
};
