import styled from 'styled-components';

export interface ChartContainerProps {
  size: number;
  containerSize?: number;
}

export const ChartContainer = styled.div<ChartContainerProps>`
  height: fit-content;
  display: flex;
  align-items: center;
  height: ${(props) => props.containerSize || props.size}px;
  width: ${(props) => props.containerSize || props.size}px;

  svg.svg-sunburst {
    position: relative;
    width: 100%;
    height: 100%;
    left: 0px;
    top: 0px;
  }
`;
