/* eslint-disable import/no-duplicates */
import firebase, { User } from 'firebase/app';
import 'firebase/analytics';
import 'firebase/auth';

const config = {
  apiKey: 'AIzaSyBdX4GbdKmzuyCr_ZHhbWSZuLyt2CG6vxo',
  authDomain: 'tagna-actus-test.firebaseapp.com',
  databaseURL: 'https://tagna-actus-test.firebaseio.com',
  projectId: 'tagna-actus-test',
  storageBucket: 'tagna-actus-test.appspot.com',
  messagingSenderId: '1051289214462',
  appId: '1:1051289214462:web:c85cd3ad09251eae2df4af',
  measurementId: 'G-RWLZQTWCBQ',
};
firebase.initializeApp(config);

// Run analystics if app is not in test mode
if (process.env.NODE_ENV !== 'test') firebase.analytics();

export const auth = firebase.auth();
if (process.env.NODE_ENV !== 'test')
  auth.setPersistence(firebase.auth.Auth.Persistence.LOCAL);

export async function getCurrentUser(): Promise<User | null> {
  return new Promise((resolve, reject) =>
    auth.onAuthStateChanged((user) => resolve(user || null), reject)
  );
}

export async function getTokenFromFirebase() {
  return new Promise((resolve, reject) => {
    auth.onAuthStateChanged(async (user) => {
      if (user) {
        const idToken = auth.currentUser?.getIdToken(false);
        resolve(await idToken);
      } else {
        reject(new Error('Token not found'));
      }
    });
  });
}

export async function passwordReset(email: string) {
  return auth.sendPasswordResetEmail(email);
}
