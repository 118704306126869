import styled from 'styled-components';

import { supportedDevices } from '../../../styles/supportedDevices';

export const Container = styled.div`
  color: black;
  width: 310px;

  & li.MuiMenuItem-root {
    font-size: 12px;
  }

  .MuiPickersCalendarHeader-iconButton svg {
    font-size: 2rem;
  }

  .MuiPickerDTTabs-tabs svg {
    font-size: 2.4rem;
  }

  .MuiPickersSlideTransition-transitionContainer.MuiPickersCalendar-transitionContainer {
    min-height: 250px;
  }

  .MuiPickersCalendarHeader-transitionContainer p,
  .MuiPickersDay-day p {
    font-size: 1.8rem;
  }

  span.MuiPickersCalendarHeader-dayLabel {
    font-size: 1.6rem;
  }

  .picker-actions {
    display: flex;
    align-items: center;
    justify-content: space-evenly;
    background-color: ${({ theme }) => theme.palette.background.paper};

    padding: 28px;

    button.action {
      font-size: 12px;
      width: 100px;
      background-color: ${({ theme }) => theme.palette.primary.main};
      color: white;

      &.gradient {
        background-image: linear-gradient(to right, #19b0a0, #009ee2);
        color: white;
        margin: 0;
      }
    }
  }

  @media ${supportedDevices.tablet} {
    & span.MuiPickersCalendarHeader-dayLabel,
    .MuiPickersDay-day p {
      font-size: 1.4rem;
    }
  }
`;

export const DayContainer = styled.div`
  && {
    &.highlight,
    &.first-highlight,
    &.end-highlight {
      border-radius: 0;
      background: ${({ theme }) => theme.palette.primary.main};

      &:hover,
      &:focus {
        background-color: ${({ theme }) => theme.palette.primary.dark};
      }

      button {
        color: white;
      }
    }

    &.first-highlight {
      border-top-left-radius: 50%;
      border-bottom-left-radius: 50%;
    }

    &.end-highlight {
      border-top-right-radius: 50%;
      border-bottom-right-radius: 50%;
    }

    .day {
      width: 40.69px;
      height: 41px;
      font-size: 12px;
      color: black;
    }

    .future-day,
    .non-current-month-day {
      color: ${({ theme }) => theme.palette.text.disabled};
    }

    .highlight-non-current-month-day {
      color: #676767;
    }
  }
`;
