import React, { Component, useEffect, useState } from 'react';
import Media from 'react-media';
import { useSelector, useDispatch } from 'react-redux';
import { AppState } from '../../../../../../../../store/reducers';
import { Update as UpdateIcon } from '@material-ui/icons';
import { DateFilter, TimePeriod } from '../../../../../../../../components/DateFilter';

import { supportedDevices } from '../../../../../../../../styles/supportedDevices';
import { Container} from './styles';
import config from '../../../../../../../../config';

const calculateTimeFromYesterday = () => {
  const now = new Date();
  const yesterday = new Date(now);
  yesterday.setDate(now.getDate() - 1);
  yesterday.setHours(0, 0, 0, 0);

  const differenceInTime = now.getTime() - yesterday.getTime();
  const startHoursAgo = Math.floor(differenceInTime / (1000 * 3600)); // horas desde ontem até agora
  const endHoursAgo = startHoursAgo - 24; // 24 horas atrás do momento atual

  const startTime = `now-${startHoursAgo}h`;
  const endTime = `now-${endHoursAgo}h`;

  return { startTime, endTime };
};

const getTimePeriod = (timePeriod: TimePeriod) => {

  if (timePeriod.startTime === 'yesterday') {
    return calculateTimeFromYesterday();
  }

  if (timePeriod.startTime === null || timePeriod.endTime === null) {
    return { startTime: 'now-30m', endTime: 'now' };
  }

  return timePeriod;

}


  interface HistoryTabProps {
    identifier: string;
  }

  export const HistoryTab: React.FC<HistoryTabProps> = ({
    identifier,

  }) => {


  const {treeHealth} = useSelector(
    (state: AppState) => state
  );


  return (
    <Media queries={{ tablet: supportedDevices.tablet }}>
      {(matches) => (
        <Container>
          <div className="graph-container">
                <iframe
                    title="Informações do ativo"
                    src={config.grafana.getHealthHistoryPanelUrl(identifier, {
                        startTime: getTimePeriod(treeHealth.timePeriod).startTime,
                        endTime: getTimePeriod(treeHealth.timePeriod).endTime,
                        })}
                    width='100%'
                    height='100%'
                    frameBorder="0"
                />
              </div>
        </Container>
      )}
    </Media>
  );
};

