import React, { useState } from 'react';
import Media from 'react-media';

import { ButtonBase, CircularProgress, Zoom } from '@material-ui/core';
import { Add, GetApp, Remove } from '@material-ui/icons';

import { BottomSheet } from '../../../../../components/BottomSheet';
import { CustomButton } from '../../../../../components/CustomButton';
import {
  DateFilter,
  DateFilterItem,
} from '../../../../../components/DateFilter';
import {
  SearchField,
  SearchFieldProps,
} from '../../../../../components/SearchField';
import { BottomSheetContent } from '../../../../Reports/components/views/HealthAndIndicatorView/components/Header/styles';

import { supportedDevices } from '../../../../../styles/supportedDevices';
import { Container } from './styles';

interface DateFilterProps {
  /**
   * Selectable time items, should match the type required by the selected date picker
   */
  timeList: DateFilterItem[];

  /**
   * Selected Date Picker to be used
   */
  datePicker: 'normal' | 'period' | 'doublePeriod';
}

interface CsvButtonProps {
  /**
   * On download CSV press callback
   */
  handleDownloadCsv(): void;

  /**
   * If button is disabled
   */
  disabled?: boolean;

  /**
   * If download is loading
   */
  isLoading?: boolean;
}

export interface HeaderProps {
  /**
   * Header title to be displayed
   */
  title: string;

  /**
   * Props for date filter to be shown
   */
  dateFilterProps: DateFilterProps;

  /**
   * Props for search field
   */
  searchFieldProps?: SearchFieldProps;

  /**
   * Props for Csv Button
   */
  csvButtonProps: CsvButtonProps;
}

/**
 * Data Analysis Header Template
 */
export const Header: React.FC<HeaderProps> = ({
  title,
  dateFilterProps: { datePicker, timeList },
  csvButtonProps: { handleDownloadCsv, disabled, isLoading },
  searchFieldProps,
}) => {
  const [isSettingsOpen, setIsSettingsOpen] = useState(false);

  function handleSettingsOpen(open = false) {
    setIsSettingsOpen(open);
  }

  function buildDatePicker() {
    if (datePicker === 'normal')
      return (
        <DateFilter
          fixedFilters={timeList as DateFilterItem[]}
          timePeriodState="dataAnalysis"
        />
      );
    return <div />;
  }

  return (
    <Media queries={{ minTablet: supportedDevices.tablet }}>
      {(matches) => (
        <>
          <Container
            bottomSheetOpen={isSettingsOpen}
            data-testid="headerContainer"
          >
            <div className="info-download">
              <h1 data-testid="title">{title}</h1>
              {matches.minTablet ? (
                <div className="csv-container">
                  <CustomButton
                    onClick={handleDownloadCsv}
                    disabled={disabled || isLoading}
                    data-testid="downloadCsvButton"
                  >
                    {isLoading ? (
                      <CircularProgress size={20} />
                    ) : (
                      <>
                        <span>Download CSV</span>
                        <GetApp fontSize="large" />
                      </>
                    )}
                  </CustomButton>
                </div>
              ) : (
                <div className="settings">
                  <ButtonBase
                    className="more-options-button"
                    onClick={() => handleSettingsOpen(true)}
                  >
                    <Add className="add-icon" fontSize="large" />
                    <Remove className="remove-icon" fontSize="large" />
                  </ButtonBase>
                </div>
              )}
            </div>
            <div className="filters">
              <div className="search-select">
                <Zoom in={Boolean(searchFieldProps)} unmountOnExit>
                  <div className="search" data-testid="searchFilter">
                    {searchFieldProps && <SearchField {...searchFieldProps} />}
                  </div>
                </Zoom>
              </div>
              {matches.minTablet && (
                <div className="date-picker" data-testid="dateFilter">
                  {buildDatePicker()}
                </div>
              )}
            </div>
          </Container>
          {!matches.minTablet && (
            <BottomSheet open={isSettingsOpen} handleClose={handleSettingsOpen}>
              <BottomSheetContent downloadFullWidth={datePicker === 'normal'}>
                <h1>Mais Opções...</h1>
                <div className="filters">
                  <div className="csv-container">
                    <CustomButton
                      onClick={handleDownloadCsv}
                      disabled={disabled || isLoading}
                    >
                      {isLoading ? (
                        <CircularProgress size={20} />
                      ) : (
                        <>
                          <span>Download CSV</span>
                          <GetApp fontSize="large" />
                        </>
                      )}
                    </CustomButton>
                  </div>
                  <div className="date-picker">{buildDatePicker()}</div>
                </div>
              </BottomSheetContent>
            </BottomSheet>
          )}
        </>
      )}
    </Media>
  );
};
