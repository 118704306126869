import React, { useCallback, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';

import { CircularProgress } from '@material-ui/core';
import { ArrowDropDown, ArrowRight } from '@material-ui/icons';
import { TreeView as TreeViewContainer } from '@material-ui/lab';

import { TreeData } from '../../models/UserData';
import { AppState } from '../../store/reducers';
import { getElementFromTree } from '../../utils/methods';
import { SearchField } from '../SearchField';
import { CustomTreeItem } from './components/CustomTreeItem';

import { Container } from './styles';
import { useAuthStore } from '../../zustand/AuthStore';

export type SelectionModes = 'single' | 'multi';

export interface TreeNode {
  name: string;
  uuid: string;
  identifier: string;
}

export interface TreeViewProps {
  /**
   * Item selection mode
   */
  selectionMode?: SelectionModes;

  /**
   * selected items
   */
  selectedItems: string[];

  /**
   * Selectable tree items
   */
  selectableTreeItems: string[] | 'all';

  /**
   * Option to show nodes with 0 importance
   */
  showZeroImportance: boolean;

  /**
   * Option to show leaves of the tree
   */
  showLeaves: boolean;

  /**
   * On item select callback
   */
  onItemSelect(id: string): void;
}

/**
 * Get the node identifier from the node Uuid from the tree.
 */
function getNodeIdentifierFromUuid(tree: TreeData, nodeUuid: string): string {
  const nodeElement = getElementFromTree(
    tree,
    (element) => element.uuid === nodeUuid
  );

  if (nodeElement) {
    return nodeElement.identifier;
  }

  return '';
}

/**
 * Get the parents node from the tree items.
 */
function getParents(treeItems: TreeData[], id: string) {
  return treeItems.reduce((acc, item) => {
    if (id.includes(item.identifier)) {
      acc.push(item);

      if (item.children && !id.endsWith(item.identifier))
        acc = acc.concat(getParents(item.children, id));

      return acc;
    }

    return acc;
  }, [] as TreeData[]);
}

/**
 * Get all parents from an array of nodes uuids.
 */
function getAllParentsFromArray(
  tree: TreeData | undefined,
  nodesUuid: string[]
) {
  if (!tree) {
    return [];
  }

  const nodesIdentifiers = nodesUuid.map((uuid) =>
    getNodeIdentifierFromUuid(tree, uuid)
  );

  const values = nodesIdentifiers.reduce((previousValues, current) => {
    const parentsUuid = getParents([tree], current).map((item) => item.uuid);
    return previousValues.concat(parentsUuid);
  }, [] as string[]);

  return values;
}

/**
 * Tree navigation view
 */
export const TreeView: React.FC<TreeViewProps> = ({
  selectionMode = 'single',
  selectedItems,
  onItemSelect,
  selectableTreeItems,
  showZeroImportance,
  showLeaves,
}) => {
  // const {
  //   auth: {
  //     user: { tree },
  //   },
  // } = useSelector((state: AppState) => state);

  const tree = useAuthStore((state) => state.user.tree);

  const [expanded, setExpanded] = useState<string[]>(
    // getAllParentsFromArray(tree, selectedItems)
    []
  );
  const [selected, setSelected] = useState<string[]>([]);

  useEffect(() => {
    setExpanded(getAllParentsFromArray(tree, selectedItems));
  }, [tree, selectedItems]);

  function handleToggle(_: React.ChangeEvent<{}>, nodeIds: string[]) {
    setExpanded(nodeIds);
  }

  function handleSelect(_: React.ChangeEvent<{}>, nodeIds: string[]) {
    setSelected(nodeIds);
  }

  const buildSearchOptions = useCallback(() => {
    const getList = (items: TreeData[]) =>
      items.reduce((acc, { name, uuid, identifier, children }) => {
        acc.push({ name, uuid, identifier });

        if (children) acc = acc.concat(getList(children));

        return acc;
      }, [] as TreeNode[]);

    if (tree) return getList([tree]);

    return [];
  }, [tree]);

  function handleSearchChange(
    _: React.ChangeEvent<{}>,
    value: TreeNode | null
  ) {
    if (value && tree) {
      setExpanded(
        getParents([tree], value.identifier).map((item) => item.uuid)
      );
      setSelected([value.uuid]);
    }
  }

  function buildTreeChildren(treeItem: TreeData) {
    //doesnt show 0 importance items nor their children
    if (!showZeroImportance && treeItem.importance === 0) return null;

    if (
      !treeItem.children ||
      treeItem.children?.length === 0 ||
      (!showLeaves &&
        (!treeItem.children[0].children ||
          treeItem.children[0].children.length === 0))
    )
      return (
        <CustomTreeItem
          key={treeItem.uuid}
          checked={selectedItems.includes(treeItem.uuid)}
          handleChange={() => onItemSelect(treeItem.uuid)}
          nodeId={treeItem.uuid}
          labelText={treeItem.name}
          selectionMode={selectionMode}
          showSelect={
            selectableTreeItems === 'all' ||
            selectableTreeItems.includes(treeItem.type)
          }
        />
      );

    return (
      <CustomTreeItem
        key={treeItem.uuid}
        checked={selectedItems.includes(treeItem.uuid)}
        handleChange={() => onItemSelect(treeItem.uuid)}
        nodeId={treeItem.uuid}
        labelText={treeItem.name}
        selectionMode={selectionMode}
        showSelect={
          selectableTreeItems === 'all' ||
          selectableTreeItems.includes(treeItem.type)
        }
      >
        {treeItem.children.map((child) => buildTreeChildren(child))}
      </CustomTreeItem>
    );
  }

  return (
    <Container data-testid="container">
      {!tree ? (
        <CircularProgress size={30} data-testid="progressIndicator" />
      ) : (
        <div className="tree-container" data-testid="treeContainer">
          <SearchField
            options={buildSearchOptions()}
            getOptionLabel={(data: TreeData) => data.name}
            onChange={handleSearchChange}
            getOptionSelected={(option, value) => option?.uuid === value?.uuid}
          />

          <TreeViewContainer
            className="tree-view"
            defaultCollapseIcon={<ArrowDropDown />}
            defaultExpandIcon={<ArrowRight />}
            expanded={expanded}
            selected={selected}
            onNodeToggle={handleToggle}
            onNodeSelect={handleSelect}
          >
            {buildTreeChildren(tree)}
          </TreeViewContainer>
        </div>
      )}
    </Container>
  );
};
