import React, { useRef, useEffect } from 'react';

export function useHorizontalScroll<T extends HTMLElement>() {
  const elRef = useRef<T>();
  useEffect(() => {
    const el = elRef.current;
    if (el) {
      const onWheel = (e: React.WheelEvent<T>) => {
        if (el.scrollWidth > el.clientWidth) {
          e.preventDefault();
          el.scrollTo({
            left: el.scrollLeft + e.deltaY,
            behavior: 'smooth',
          });
        }
      };
      el.addEventListener('wheel', onWheel as any);
      return () => el.removeEventListener('wheel', onWheel as any);
    }
    return undefined;
  }, []);
  return elRef as React.MutableRefObject<T>;
}
