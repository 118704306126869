import React from 'react';
import { Link } from 'react-router-dom';

import { GradientButton } from '../../components/GradientButton';
import { Spacer } from '../../components/Spacer';

import { Main, Container } from './styles';

export const Screen404 = () => {
  return (
    <Main>
      <Container>
        <h1>Ooops!</h1>
        <h6>Código de erro 404</h6>
        <h3>Essa página não foi encontrada</h3>
        <Spacer height={50} />
        <Link to="/">
          <GradientButton>voltar à página inicial</GradientButton>
        </Link>
      </Container>
    </Main>
  );
};
