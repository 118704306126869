import { Button } from '@material-ui/core';
import styled from 'styled-components';

import { supportedDevices } from '../../../../../../../styles/supportedDevices';

export const Container = styled.div`
  min-height: 560px;
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;

  & h3 {
    font-size: 14px;
  }

  .loader {
    display: flex;
    justify-content: center;
  }

  & .time-selector-container {
    display: flex;
    align-items: center;
    color: black;

    h3 {
      margin-right: 15px;
    }
  }

  .graph-container {
    display: flex;
    color: black;
    flex-direction: column-reverse;
    align-items: center;
    justify-content: space-between;

    .chart {
      flex: 5;
      height: 400px;
      width: 100%;
    }
  }

  .logical-charts {
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-wrap: wrap;
    background-color: #f7f8fa;
    border-radius: 4px;
    box-shadow: 0 0 2px 0 rgba(0, 0, 0, 0.4);

    margin-top: 20px;
    padding: 10px;

    & > :first-child {
      width: 100%;
    }

    & > :nth-child(2) {
      width: 100%;
    }

    .indicator-editor {
      display: flex;
      align-items: center;
      justify-content: center;
      flex-wrap: wrap;

      width: 100%;
      min-height: 60px;
      margin-bottom: 30px;

      .iframe-container {
        width: 100%;

        h4 {
          color: black;
          font-size: 14px;
          font-weight: 500;
          text-align: center;
        }

        .bar-gauge__value {
          width: 80px;
        }
      }
    }
  }

  @media ${supportedDevices.laptop} {
    min-height: 500px;

    .graph-container {
      flex-direction: row;
      margin-top: 30px;
    }

    .logical-charts {
      & > :first-child {
        width: 60%;
      }

      & > :nth-child(2) {
        width: 40%;
      }

      .indicator-editor {
        margin: 0;

        .iframe-container {
          flex: 2;
          width: unset;

          h4 {
            text-align: start;
            padding-left: 8px;
          }
        }
      }
    }
  }
`;

interface WeightContainerProps {
  isEditMode: boolean;
}

export const WeightContainer = styled.div<WeightContainerProps>`
  position: relative;
  color: black;

  height: 42px;
  width: 100%;

  .select-container,
  .label-container {
    position: absolute;
    left: 0;
    top: 0;
    display: flex;
    align-items: center;
    justify-content: center;

    width: 100%;
    height: 100%;

    transition: all 0.2s;
  }

  .label-container {
    transform: translateX(${({ isEditMode }) => (isEditMode ? '-100%' : 0)});
    opacity: ${({ isEditMode }) => Number(!isEditMode)};

    .label {
      display: flex;

      & > :first-child {
        margin-right: 5px;
      }

      p {
        font-size: 14px;
        font-weight: 500;
      }
    }
  }

  .select-container {
    transform: translateX(${({ isEditMode }) => (isEditMode ? 0 : '100%')});
    opacity: ${({ isEditMode }) => Number(isEditMode)};
    pointer-events: ${({ isEditMode }) => (isEditMode ? 'all' : 'none')};

    height: 42px;
    width: 100%;
  }

  @media ${supportedDevices.tablet} {
    flex: 1;

    .label-container {
      transform: translateY(${({ isEditMode }) => (isEditMode ? '-100%' : 0)});

      .label {
        p {
          margin: 0;
        }
      }
    }

    .select-container {
      transform: translateY(${({ isEditMode }) => (isEditMode ? 0 : '100%')});
    }
  }
`;

export const IndexesContainer = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  width: 100%;

  .indexes {
    display: flex;
    overflow: auto;
    align-items: center;
    width: 100%;
    margin-bottom: 10px;

    ::-webkit-scrollbar {
      height: 0;
    }
  }

  h3 {
    font-size: 10px;
    font-weight: normal;
    margin-bottom: 10px;
  }

  @media ${supportedDevices.tablet} {
    margin-bottom: 10px;
  }

  @media ${supportedDevices.laptop} {
    margin: 0;

    .indexes {
      flex-direction: column;
      align-items: flex-start;
    }

    h3 {
      font-size: 14px;
      font-weight: bold;
      margin-bottom: 20px;
    }
  }
`;

interface ToggleIndexButtonProps {
  isSelected: boolean;
  borderColor: string;
}

export const ToggleIndexButton = styled(Button).withConfig({
  shouldForwardProp: (prop) =>
    !['isSelected'].includes(prop) && !['borderColor'].includes(prop),
})<ToggleIndexButtonProps>`
  && {
    min-width: 120px;
    font-size: 1rem;
    margin-right: 5px;
    border-left: solid 5px ${(props) => props.borderColor};
    font-weight: 600;
    box-shadow: 0 0 5px 0 rgba(0, 0, 0, 0.1);

    background: ${({ isSelected }) => (isSelected ? '#C8C9CC' : '#E6E8EB')};

    @media ${supportedDevices.laptop} {
      min-width: 140px;
      font-size: 8px;
      margin: 10px 0;
    }
  }

  &&:hover {
    background: ${({ isSelected }) => (isSelected ? '#B6B7BA' : '#D7D9DB')};
  }
`;
