import React from 'react';

import { GraphColor } from '../../../types';

import { Container, ToggleIndexButton } from './styles';
import { SelectableIndicator } from '../../../../../../../../store/treeHealth/types';
import { TimePeriod } from '../../../../../../../../components/DateFilter';

export interface FilterButtonsProps {
  colors: GraphColor[];
  onFilterToggle: (color: string) => void;
  filteredColors: string[];
  indicator: SelectableIndicator;
  timePeriod: TimePeriod;
}

export const FilterButtons: React.FC<FilterButtonsProps> = ({
  colors,
  onFilterToggle,
  filteredColors,
  indicator,
  timePeriod,
}) => {

  const colorfulStripe = colors.slice(0, 11);
  let grayStripe: GraphColor[];

  if(!timePeriod.startTime && !timePeriod.endTime) {
    grayStripe = colors.slice(indicator.name === 'Saúde' ? 11 : 12, colors.length - 1);
  } else {
    grayStripe = [colors[colors.length - 1]];
  }

  const updatedColors = colorfulStripe.concat(grayStripe);

  return (
    <Container>
      <h2>Faixas de saúde</h2>
      <p>Clique para ocultar</p>
      {[...updatedColors].map(
        ({ rgb, percentage }) => {
          const color = `rgb(${rgb.join(',')})`;
          return (
            <ToggleIndexButton
              isSelected={filteredColors.includes(color)}
              borderColor={color}
              key={percentage}
              onClick={() => onFilterToggle(color)}
            >
            <span> { percentage } </span>
            </ToggleIndexButton>
          );
        }
      )}
    </Container>
  );
};
