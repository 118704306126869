import React from 'react';

import { Slide } from '@material-ui/core';

import { Container } from './styles';

export const Card: React.FC = ({ children }) => {
  return (
    <Slide in direction="left" timeout={500}>
      <Container data-testid="cardContainer">{children}</Container>
    </Slide>
  );
};
