import React from 'react';
import { useSelector, useDispatch } from 'react-redux';

import { Drawer } from '@material-ui/core';

import { Creators } from '../../store/actionCreators';
import { AppState } from '../../store/reducers';

import { DrawerContent } from './styles';

interface DrawerProps {
  children: JSX.Element;
}

export const CustomDrawer: React.FC<DrawerProps> = ({ children }) => {
  const drawer = useSelector((state: AppState) => state.drawer);
  const dispatch = useDispatch();

  function toggleDrawer() {
    dispatch(Creators.toggleDrawer());
  }

  return (
    <Drawer anchor="left" open={drawer.isOpen} onClose={toggleDrawer}>
      <DrawerContent>{children}</DrawerContent>
    </Drawer>
  );
};
