import React, { ReactText, useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useRouteMatch } from 'react-router-dom';

import {
  CircularProgress,
  Input,
  MenuItem,
  Slider,
  Tooltip,
} from '@material-ui/core';
import { Help } from '@material-ui/icons';

import errorIcon from '../../../../../../../assets/imgs/error-icon.png';
import { CustomButton } from '../../../../../../../components/CustomButton';
import { CustomDialog } from '../../../../../../../components/CustomDialog';
import {
  CustomInput,
  CustomSelect,
} from '../../../../../../../components/CustomSelect';
import { AssetDetails } from '../../../../../../../models/AssetInfo';
import { Creators } from '../../../../../../../store/actionCreators';
import { AppState } from '../../../../../../../store/reducers';

import { Container, SliderValueLabel, TooltipSpan } from './styles';

interface FieldProps {
  id: string;
  label: string;
  description?: string;
  form: 'select' | 'slider';
  options?: string[];
}

const formFields: FieldProps[] = [
  {
    id: 'algorithm',
    label: 'Algoritmo',
    form: 'select',
    options: ['PID', 'P_ID', 'TYPE', 'TYPE2'],
  },
  {
    id: 'monitoredError',
    label: 'Erro Monitorado',
    form: 'select',
    options: ['Positivo', 'Negativo', 'Ambos'],
    description:
      'Erro Monitorado Positivo: PV>SP+DB. Erro Monitorado Negativo: PV<SP-DB',
  },
  {
    id: 'action',
    label: 'Tipo de Atuação',
    form: 'select',
    options: ['Direta', 'Reversa'],
  },
  {
    id: 'spTolerangeMarginForStabilizePV',
    label: 'Tolerância de PV',
    description:
      'Parâmetro percentual para aumento da região em torno de SP, dentro da qual se considera PV estabilizado.',
    form: 'slider',
  },
  {
    id: 'lmsl',
    label: 'LMSL',
    description:
      'Parâmetro percentual de aumento do limite mínimo da região de operação da MV, para se considerar saturado.',
    form: 'slider',
  },
  {
    id: 'lmsh',
    label: 'LMSH',
    description:
      'Parâmetro percentual de redução do limite máximo da região de operação da MV, para se considerar saturado.',
    form: 'slider',
  },
  {
    id: 'lsl',
    label: 'LSL',
    description:
      'Parâmetro percentual de aumento do limite mínimo da região de operação da PV, para se considerar saturado.',
    form: 'slider',
  },
  {
    id: 'lsh',
    label: 'LSH',
    description:
      'Parâmetro percentual de redução do limite máximo da região de operação da PV, para se considerar saturado.',
    form: 'slider',
  },
];

export const DetailsTab: React.FC = () => {
  const { data, areDetailsLoading, detailsError } = useSelector(
    (state: AppState) => state.assetData
  );
  const dispatch = useDispatch();

  const match = useRouteMatch<{ ativoPath: string }>();

  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isEditMode, setIsEditMode] = useState(false);
  const [formValues, setFormValues] = useState<{
    [key: string]: string | number;
  }>({});

  const mapDetailsToForm = useCallback((details: AssetDetails) => {
    let newFormValues: { [key: string]: ReactText } = {};
    Object.entries(details).forEach(([key, value]) => {
      const newValue =
        formFields.find((field) => field.id === key)?.form === 'slider'
          ? Math.fround(Number(value * 100)).toFixed(2)
          : value;
      newFormValues = { ...newFormValues, [key]: newValue };
    });
    return newFormValues;
  }, []);

  // Requests details
  useEffect(() => {
    dispatch(Creators.getAssetDetailsRequest(match.params.ativoPath));
  }, [dispatch, match.params.ativoPath]);

  // Set details locally
  useEffect(() => {
    if (!Object.keys(formValues).length && data.details)
      setFormValues(mapDetailsToForm(data.details));
  }, [data.details, formValues, mapDetailsToForm]);

  function handleFormChange(value: number | string, name: string) {
    setFormValues({
      ...formValues,
      [name]: value,
    });
  }

  function toggleModal(open = false) {
    setIsModalOpen(open);
  }

  function handleEditMode() {
    setIsEditMode(!isEditMode);
  }

  function onSaveClick() {
    toggleModal(true);
  }

  function handleCancelPress() {
    setFormValues(mapDetailsToForm(data.details!));
    handleEditMode();
  }

  function onConfirmSave() {
    let newFormValues: typeof formValues = {};

    formFields.forEach((field) => {
      newFormValues = {
        ...newFormValues,
        [field.id]:
          field.form === 'slider'
            ? Number(formValues[field.id]) / 100
            : formValues[field.id],
      };
    });

    dispatch(
      Creators.patchAssetDetailsRequest(
        data.uuid,
        newFormValues as AssetDetails
      )
    );
    toggleModal();
    handleEditMode();
  }

  function onSliderBlur(fieldId: string) {
    if (formValues[fieldId] < 0) handleFormChange(0, fieldId);
    else if (formValues[fieldId] > 100) handleFormChange(100, fieldId);
  }

  // Builds correct field based on the form attr
  function buildField(field: FieldProps) {
    return (
      <div className="field-container" key={field.id}>
        <div className="label-container">
          <label htmlFor={`${field.id}Input`}>{field.label}</label>
          {field.description && (
            <Tooltip
              title={
                <TooltipSpan>
                  {field.description.split('. ').map((text) => (
                    <span key={text}>{text}</span>
                  ))}
                </TooltipSpan>
              }
            >
              <Help className="help-icon" />
            </Tooltip>
          )}
        </div>
        {field.form === 'select' ? (
          <div className="select-container">
            <CustomSelect
              inputProps={{ id: `${field.id}Input` }}
              data-testid={`${field.id}Select`}
              value={formValues[field.id] || ''}
              onChange={(event) =>
                handleFormChange(event?.target.value as string, field.id)
              }
              fullWidth
              input={<CustomInput />}
              disabled={!isEditMode}
            >
              {field.options!.map((label) => (
                <MenuItem key={label} value={label}>
                  {label}
                </MenuItem>
              ))}
            </CustomSelect>
          </div>
        ) : (
          <div className="slider-container">
            <Slider
              data-testid={`${field.id}Slider`}
              defaultValue={30}
              onChange={(_, value) => handleFormChange(Number(value), field.id)}
              value={Number(formValues[field.id]) || 0}
              valueLabelDisplay="auto"
              valueLabelFormat={(value) => (
                <SliderValueLabel>{`${value}%`}</SliderValueLabel>
              )}
              disabled={!isEditMode}
            />
            <Input
              value={Number(formValues[field.id]) || 0}
              margin="dense"
              onChange={(event) =>
                handleFormChange(Number(event.target.value), field.id)
              }
              onBlur={() => onSliderBlur(field.id)}
              disabled={!isEditMode}
              inputProps={{
                step: 10,
                min: 0,
                max: 100,
                type: 'number',
                'aria-labelledby': 'input-slider',
              }}
              endAdornment={<>%</>}
            />
          </div>
        )}
      </div>
    );
  }

  function buildLoader() {
    return detailsError ? (
      <>
        <div className="error-container" data-testid="errorContainer">
          <img src={errorIcon} alt="Ícone de erro" />
          <h3>Ocorreu um erro! Recarregue a página para tentar novamente.</h3>
        </div>
      </>
    ) : (
      <CircularProgress size={50} data-testid="loaderIndicator" />
    );
  }

  return (
    <>
      <Container
        data-testid="detailsContainer"
        className={(areDetailsLoading || detailsError) && 'loader'}
      >
        {areDetailsLoading || detailsError ? (
          buildLoader()
        ) : (
          <>
            <div className="header">
              <div className={`button-container ${isEditMode ? 'hide' : ''}`}>
                <CustomButton
                  data-testid="editButton"
                  onClick={handleEditMode}
                  gradient
                >
                  editar
                </CustomButton>
              </div>
              <div className={`button-container ${isEditMode ? '' : 'hide'}`}>
                <CustomButton
                  data-testid="cancelButton"
                  onClick={handleCancelPress}
                >
                  cancelar
                </CustomButton>
                <CustomButton
                  data-testid="saveButton"
                  onClick={onSaveClick}
                  gradient
                >
                  salvar
                </CustomButton>
              </div>
            </div>
            <form>{formFields.map((field) => buildField(field))}</form>
          </>
        )}
      </Container>
      <CustomDialog
        dataTestid="confirmationModal"
        open={isModalOpen}
        title={`Deseja salvar estes valores para ${data.name}?`}
        body={formFields.map((field) => (
          <p key={field.id}>
            {`${field.label}: ${String(formValues[field.id]).replace(
              '.',
              ','
            )}${field.form === 'slider' ? '%' : ''}`}
          </p>
        ))}
        action={{
          label: 'confirmar',
          onClick: onConfirmSave,
        }}
        onClose={() => toggleModal()}
      />
    </>
  );
};
