import React from 'react';
import { Switch, Route, Redirect, RouteComponentProps } from 'react-router-dom';

import { Screen404 } from '../404';
import { AssetView } from './components/views/AssetView';
import GraphView from './components/views/GraphView';

export const Router: React.FC<RouteComponentProps> = ({ match }) => {
  return (
    <Switch>
      <Route path={`${match.path}/ativo/:ativoPath`} component={AssetView} />
      <Route
        path={`${match.path}/ativo-logico/:ativoPath`}
        render={(props) => <AssetView {...props} logicalAsset />}
      />
      <Route exact path={`${match.path}`} component={GraphView} />
      <Route path={`${match.path}/404`} component={Screen404} />
      <Redirect from={`${match.path}/*`} to="/404" />
      {/* <Route
        exact
        path={match.path + '/ativo/:ativoPath/os/:osId'}
        component={OSForm}
      />
      <Route
        exact
        path={match.path + '/knowledges/:kbId'}
        component={KnowledgeForm}
      />{' '}
      <Route
        exact
        path={match.path + '/knowledges'}
        component={KnowledgeList}
      />
      <Route
        exact
        path={match.path + '/equipamento/:ativoPath'}
        component={EquipamentView}
      />
      <Route
        exact
        path={match.path + '/ativo-logico/:ativoPath/os/:osId'}
        component={OSForm}
      /> */}
    </Switch>
  );
};
