import { Select, InputBase } from '@material-ui/core';
import styled from 'styled-components';

import { supportedDevices } from '../styles/supportedDevices';

export const CustomSelect = styled(Select)`
  && {
    border: 0.5px solid rgba(0, 0, 0, 0.1);
    background-color: rgba(230, 232, 235, 0.5);
    height: 100%;

    .MuiSelect-root {
      padding: 12px 14px;

      @media ${supportedDevices.tablet} {
        padding: 14px;
      }
    }

    svg {
      top: unset;
      right: 7px;
      font-size: 2.3rem;
    }
  }
`;

export const CustomInput = styled(InputBase)`
  border-radius: 4px;
  color: rgba(0, 0, 0, 0.87);
  border: 0.5px solid rgb(0, 0, 0, 0.1);
  background-color: rgba(230, 232, 235, 0.5);
  cursor: pointer;
  display: inline-flex;
  box-sizing: border-box;
  align-items: align-items;
  font-weight: 400;
  background-color: white;
  outline: 0;
  white-space: nowrap;
  text-overflow: ellipsis;
  -webkit-touch-callout: none;
  -webkit-tap-highlight-color: transparent;
  user-select: none;

  &.Mui-focused {
    outline: none;
    border: 1.5px solid rgb(0, 0, 0, 0.3);
  }
`;
