import React from 'react';
import { useEffect, useState } from 'react';
import { useAuthStore } from '../../zustand/AuthStore';


// @ts-ignore
window.mdChatClient = process.env.REACT_APP_MOVIDESK_CHAT_CLIENT_ID;

enum OrganizationCodeReference {
  tagna = "1724317777",
  samarco = "1724319999",
  samprojetos = "1724319999",
  bayer = "",
  arcerlormittal = "",
}

// Documentação: https://atendimento.movidesk.com/kb/pt-br/article/1507/integracao-do-chat-do-movidesk?ticketId=&q=
export function Movidesk() {

  const [isAuthReady, setIsAuthReady] = useState(false);
  const {user, isAuth} = useAuthStore();

  function getEmailDomainWithoutDotComBR(email: string) : string{
    return email?.split('@')[1]?.split('.')[0];
  }

  useEffect(() => {

    if (!isAuth || user.email === "") {

      setIsAuthReady(false);

      return;
    }

    setIsAuthReady(true);

  }, [isAuth, user]);

  useEffect(() => {

    if (!isAuthReady) {
      return;
    }

    const script = document.createElement('script');

    script.src = "https://chat.movidesk.com/Scripts/chat-widget.min.js";
    script.async = true;

    const organizaton = getEmailDomainWithoutDotComBR(user.email) as keyof typeof OrganizationCodeReference;

    const organizationCodeReference  = OrganizationCodeReference[organizaton];

    script.onload = () => {

      // @ts-ignore
      movideskLogin({
        name: user.name,
        email: user.email,
        // codeReference: "0000", // Código de referência do cliente, apenas necessário se não informar email
        organizationCodeReference: organizationCodeReference, // TODO: Criar enum de códigos de organização e add informação dinâmica para cada user
        stayConnected: false,
        emptySubject: false,
        startChat: false
      })

    }

    document.body.appendChild(script);

    return () => {
      document.body.removeChild(script);
    }
  }, [isAuthReady]);

  return (
    <>
    </>
  );
}
