import React, { useState } from 'react';
import { Link } from 'react-router-dom';

import { Button, CircularProgress } from '@material-ui/core';

import { HorizontalCollapse } from '../../../../components/Animations';
import { CustomTextField } from '../../../../components/CustomTextField';
import { GradientButton } from '../../../../components/GradientButton';
import { Spacer } from '../../../../components/Spacer';
import { validateLoginEmail, validateLoginPassword } from '../../../../validations/Login';
import { InputError } from '../../../../validations/types';

import { ButtonsContainer } from '../../styles';
import { Line, LoginTitle, P } from './styles';
import { login, advance, useAuthStore, loginRequest } from '../../../../zustand/AuthStore';

export interface UserLoginState {
  email: string;
  password: string;
}

export const Login: React.FC = () => {
  const { isSigningin, keycloak, step, user } = useAuthStore();

  const [currentuser, setCurrentUser] = useState<UserLoginState>({
    email: '',
    password: '',
  });

  const [errors, setErrors] = useState<(InputError | undefined)[]>([]);
  const [isBack, setIsBack] = useState(true)

  async function onFormSubmit(event: React.FormEvent<HTMLFormElement>) {
    event.preventDefault();
    var newErrors = []
    if (step === "advance") newErrors = validateLoginEmail(currentuser);
    if (step === "login" && !keycloak) newErrors = validateLoginPassword(currentuser);


    if (newErrors.length === 0 && step === "advance") {
      const { email } = currentuser;
      await advance(email)
    } else setErrors(newErrors);

    if (newErrors.length === 0 && step === "login") {
      setIsBack(false)
      const { password } = currentuser;
      await login({ email: user.email, password, keycloak });
    } else setErrors(newErrors);
  }

  function handleChange(
    fieldName: string,
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) {
    setCurrentUser({ ...currentuser, [fieldName]: event.target.value });
  }

  function cleanError(index: number) {
    const newErrors = [...errors];
    newErrors[index] = undefined;
    setErrors(newErrors);
  }

  return (
    <form onSubmit={onFormSubmit} data-testid="loginForm">
      <LoginTitle>Login</LoginTitle>
      {
        step === "advance" &&
        <CustomTextField
          name="email"
          label="Email"
          placeholder="example@email.com"
          handleChange={handleChange}
          type="email"
          value={currentuser.email}
          error={errors[0]?.msg}
          cleanError={() => cleanError(0)}
          isDisabled={isSigningin}
        />
      }
      {
        step === "login" &&
        <CustomTextField
          name="email"
          label="Email"
          placeholder="example@email.com"
          handleChange={handleChange}
          type="email"
          value={currentuser.email}
          error={errors[0]?.msg}
          cleanError={() => cleanError(0)}
          isDisabled
        />
      }
      {
        (step === "login" && !keycloak) &&
        <CustomTextField
          name="password"
          label="Senha"
          placeholder="********"
          error={errors[1]?.msg}
          value={currentuser.password}
          handleChange={handleChange}
          type="password"
          cleanError={() => cleanError(1)}
          isDisabled={isSigningin}
        />
      }
      <ButtonsContainer>
        <HorizontalCollapse width={isSigningin ? '20%' : '100%'}>
          {
            (step === "login" && isBack) &&
            <GradientButton onClick={loginRequest} >
              voltar
            </GradientButton>
          }

          <GradientButton type="submit" disabled={isSigningin} title={step === 'login' ? 'entrar' : 'avançar'}>
            {
              isSigningin ?
                <CircularProgress color="secondary" size={25} />
                :
                <>
                  {step === 'login' ? 'entrar' : 'avançar'}
                </>
            }
          </GradientButton>
        </HorizontalCollapse>
        <Spacer height={15} />
        <Link to="esqueciMinhaSenha">
          <P>Esqueci minha senha</P>
        </Link>
        <Spacer height={10} />
        <Line />
        <Spacer height={10} />
        <Link to="esqueciMinhaSenha">
          <Button
            variant="outlined"
            style={{
              fontSize: '12px',
            }}
          >
            meu primeiro acesso
          </Button>
        </Link>
      </ButtonsContainer>
    </form>
  );
};
