import styled, { createGlobalStyle } from 'styled-components';

import { supportedDevices } from '../../styles/supportedDevices';

export const GlobalStyle = createGlobalStyle`
  div.MuiAutocomplete-paper {
      font-size: 12px;
  }
`;

interface AutocompleteContainer {
  hasSearchIcon: boolean;
}

export const AutocompleteContainer = styled.div<AutocompleteContainer>`
  display: flex;
  align-items: center;
  width: 100%;

  && .search-input {
    color: rgba(0, 0, 0, 0.87);
    display: inline-flex;
    box-sizing: border-box;
    align-items: center;
    font-weight: 400;
    background-color: rgba(230, 232, 235, 0.5);
    outline: 0;
    white-space: nowrap;
    text-overflow: ellipsis;
    border: 0.5px solid rgb(0, 0, 0, 0.1);
    -webkit-touch-callout: none;
    -webkit-tap-highlight-color: transparent;
    user-select: none;

    min-height: 36px;
    line-height: 1.5rem;
    border-radius: 4px;
    font-size: 12px;

    padding-left: ${({ hasSearchIcon }) => (!hasSearchIcon ? '9px' : 0)};

    &.Mui-focused,
    .Mui-focused {
      outline: none;
      background-color: ${({ theme }) => theme.palette.background.paper};
      border: 1px solid rgb(0, 0, 0, 0.3);
    }

    &.text-field > div {
      padding-right: 0;
    }

    & .MuiOutlinedInput-notchedOutline {
      border: 0.5px solid rgb(0, 0, 0, 0.1);
    }

    & .search-icon {
      padding: 8px;
    }

    & .MuiAutocomplete-endAdornment {
      position: inherit;
    }

    & svg {
      font-size: 2rem;
    }

    .MuiChip-label {
      font-size: 1.2rem;
    }

    @media ${supportedDevices.tablet} {
      min-height: 48px;
      font-size: 12px;

      & .search-icon {
        padding: 12px;
      }
    }

    & svg {
      font-size: 3rem;
    }
  }
`;
