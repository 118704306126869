import { toastr } from 'react-redux-toastr';

import qs from 'qs';
import { put, select, call } from 'redux-saga/effects';

import config from '../../config';
import { Creators } from '../actionCreators';

export function* getKnowledges(action) {
  try {
    const pageRequest = yield select((state) => state.knowledges.pageRequest);
    const pageRequestQs = `?${qs.stringify(pageRequest)}`;
    const knowledges = yield config.api.getKnowledges(pageRequestQs);

    yield put(
      Creators.getKnowledgesSuccess(knowledges.data, action.isAssociate)
    );
  } catch (error) {
    yield put(
      Creators.knowledgeFailure(
        error,
        'Ocorreu um erro ao buscar Knowledge Base...'
      )
    );
  }
}

export function* getKnowledgeById(action) {
  try {
    const knowledge = yield config.api.getKnowledgeById(action.id);
    yield put(Creators.getKnowledgeByIdSuccess(knowledge.data));
  } catch (error) {
    yield put(
      Creators.knowledgeFailure(error, 'Ocorreu um erro ao buscar o KB!')
    );
  }
}

export function* saveKb(action) {
  try {
    let payload;
    if (!action.knowledge.id)
      payload = yield config.api.postKnowledgeBase(action.knowledge);
    else {
      const dtoKb = action.knowledge;

      Object.keys(dtoKb).forEach((el) => {
        if (el === 'responsible')
          dtoKb[el] = dtoKb[el].id ? dtoKb[el].id : parseInt(dtoKb[el], 10);
      });

      payload = yield config.api.putKnowledgeBase(dtoKb);
    }
    console.log(payload); // PEDIR PRA RAFAEL RETORNRA ID OU ALGO PRA MONTAR O FORM
    const msg = 'Knowledge Base salvo com sucesso!';
    toastr.success('Ok 😄', msg);
    yield put(Creators.saveKnowledgeSuccess(payload.data));
    yield call(getKnowledges);
  } catch (error) {
    const msg = 'Não foi possivel salvar o Knowledge Base!';
    toastr.error('Algo acontenceu!', msg);
    yield put(Creators.knowledgeFailure(error, msg));
  }
}
