import { HierarchyNode } from 'd3';
import { createActions, createReducer } from 'reduxsauce';

import { NodeData } from '../../models/TreeMapNode';
import {
  TreeHealthTypes,
  TreeHealthActions,
  TreeHealthState,
  GetTreeHealthRequestAction,
  GetSelectedCriticalityAction,
  GetTreeHealthRequestWithTimePeriodAction,
  OnHoverHealthAction,
  OnSelectHealthAction,
  GetTreeHealthSuccessAction,
  GetTreeHealthFailureAction,
  SetSelectedIndicatorAction,
  SetSelectedCriticalityAction,
  HandleLayerBlockFailureAction,
  GetSelectableIndicatorsRequestAction,
  GetSelectableIndicatorsFailureAction,
  GetSelectableIndicatorsSuccessAction,
  GetSelectableCriticalityRequestAction,
  GetSelectableCriticalityFailureAction,
  GetSelectableCriticalitySuccessAction,
  setTreeHealthTimePeriodAction,
  GetTreeHealthInitialRequestAction,
  SetSelectedNodeUuidAction,
  setTreeHealthPrevTimePeriodAction,
} from './types';

const { Types, Creators } = createActions<TreeHealthTypes, TreeHealthActions>({
  getTreeHealthRequest: [],
  getTreeHealthInitialRequest: [],
  getTreeHealthRequestWithTimePeriod: ['timePeriod'],
  getTreeHealthSuccess: [
    'data',
    'hierarchy',
    'treeMapData',
    'initialSequence',
    'selectedNode',
    'nodes',
  ],
  getTreeHealthFailure: ['error'],
  getSelectableIndicatorsRequest: null,
  getSelectableIndicatorsFailure: ['error'],
  getSelectableIndicatorsSuccess: ['selectableIndicatorsOptions'],
  getSelectableCriticalityRequest: null,
  getSelectableCriticalityFailure: ['error'],
  getSelectableCriticalitySucess: ['selectableCriticalityOptions'],
  setSelectedIndicator: ['selectedIndicator'],
  setSelectedCriticality:['selectedCriticality'],
  setSelectedNodeUuid:['selectedNodeUuid'],
  getTreeFilterRequest: ['field', 'search'],
  setTreeFilterField: ['field'],
  getTreeFilterSuccess: ['field', 'data'],
  getTreeFilterFailure: ['error'],
  handleLayerBlockRequest: ['uuid', 'blocked'],
  handleLayerBlockSuccess: null,
  handleLayerBlockFailure: ['error'],
  setLastAssetTrail: ['lastTrail'],
  onHoverHealth: ['sequence'],
  onSelectHealth: ['treeMapData', 'sequence', 'selectedNode', 'nodes'],
  setTreeHealthTimePeriod: ['timePeriod'],
  setTreeHealthPrevTimePeriod: ['prevTimePeriod'],
});

const INITIAL_STATE: TreeHealthState = {
  isLoading: true,
  data: {} as NodeData,
  error: '',
  fixedSequence: undefined,
  treeMapData: {} as NodeData,
  nodes: [],
  selectedNode: {} as HierarchyNode<NodeData>,
  sequence: undefined,
  isError: false,
  initialSequence: undefined,
  isFirstLoadingComplete: true,
  hierarchy: {} as HierarchyNode<NodeData>,
  timePeriod: {
    startTime: null,
    endTime: null,
  },
  prevTimePeriod: {
    startTime: null,
    endTime: null,
  },
  selectableCriticalityOptions:[0,1,2,3,4,5],
  selectableIndicatorsOptions: [],
  selectedIndicator: {
    uuid: '0afe2922-6acd-471e-8773-391988e2193b',
    name: 'Saúde',
    tagName: 'health',
  },
  selectedCriticality: [0,1,2,3,4,5],
  selectedNodeUuid:''
};

const getTreeHealthInitialRequest = (
  state = INITIAL_STATE,
  action: GetTreeHealthInitialRequestAction
) => {
  return {
    ...state,
    isFirstLoadingComplete: false,
  };
};

const getTreeHealthRequest = (
  state = INITIAL_STATE,
  action: GetTreeHealthRequestAction
) => {
  return {
    ...state,
    isLoading: true,
    isFirstLoadingComplete: !state.isFirstLoadingComplete,
    isError: false,
    error: '',
  };
};

const getTreeHealthRequestWithTimePeriod = (
  state = INITIAL_STATE,
  action: GetTreeHealthRequestWithTimePeriodAction
) => {
  return {
    ...state,
    isLoading: true,
    isError: false,
    error: '',
  };
};

const getTreeHealthSuccess = (
  state = INITIAL_STATE,
  action: GetTreeHealthSuccessAction
) => {
  return {
    ...state,
    isLoading: false,
    isFirstLoadingComplete: true,
    hierarchy: action.hierarchy,
    data: action.data,
    treeMapData: action.treeMapData,
    sequence: action.initialSequence,
    selectedNode: action.selectedNode,
    nodes: action.nodes,
    fixedSequence: action.initialSequence,
  };
};

const getTreeHealthFailure = (
  state = INITIAL_STATE,
  action: GetTreeHealthFailureAction
) => {
  return {
    ...state,
    isLoading: false,
    data: {} as NodeData,
    isError: true,
    error: action.error,
  };
};

const getSelectableIndicatorsRequest = (
  state = INITIAL_STATE,
  action: GetSelectableIndicatorsRequestAction
) => {
  return {
    ...state,
    isError: false,
    error: '',
  };
};

const getSelectableIndicatorsFailure = (
  state = INITIAL_STATE,
  action: GetSelectableIndicatorsFailureAction
): TreeHealthState => {
  return {
    ...state,
    isError: true,
    error: action.error,
    selectableIndicatorsOptions: [],
  };
};

const getSelectableIndicatorsSuccess = (
  state = INITIAL_STATE,
  action: GetSelectableIndicatorsSuccessAction
): TreeHealthState => {
  return {
    ...state,
    selectableIndicatorsOptions: action.selectableIndicatorsOptions,
  };
};

const getSelectableCriticalityRequest = (
  state = INITIAL_STATE,
  action: GetSelectableCriticalityRequestAction
) => {
  return {
    ...state,
    isError: false,
    error: '',
  };
};

const getSelectableCriticalityFailure = (
  state = INITIAL_STATE,
  action: GetSelectableCriticalityFailureAction
): TreeHealthState => {
  return {
    ...state,
    isError: true,
    error: action.error,
    selectableCriticalityOptions: [],
  };
};

const getSelectableCriticalitySuccess = (
  state = INITIAL_STATE,
  action: GetSelectableCriticalitySuccessAction
): TreeHealthState => {
  return {
    ...state,
    selectableCriticalityOptions: action.selectableCriticalityOptions,
  };
};

const setSelectedCriticality = (
  state = INITIAL_STATE,
  action: SetSelectedCriticalityAction
): TreeHealthState => {
  return {
    ...state,
    selectedCriticality: action.selectedCriticality,
  };
};

const setSelectedIndicator = (
  state = INITIAL_STATE,
  action: SetSelectedIndicatorAction
): TreeHealthState => {
  return {
    ...state,
    selectedIndicator: action.selectedIndicator,
  };
};

const setSelectedNodeUuid = (
  state = INITIAL_STATE,
  action: SetSelectedNodeUuidAction
): TreeHealthState => {
  return {
    ...state,
    selectedNodeUuid: action.selectedNodeUuid,
  };
};


const onHoverHealth = (state = INITIAL_STATE, action: OnHoverHealthAction) => {
  return {
    ...state,
    sequence: action.sequence,
  };
};

const onSelectHealth = (
  state = INITIAL_STATE,
  action: OnSelectHealthAction
) => {
  return {
    ...state,
    treeMapData: action.treeMapData,
    selectedNode: action.selectedNode,
    sequence: action.sequence,
    fixedSequence: action.sequence,
  };
};

const handleLayerBlockRequest = (state = INITIAL_STATE) => {
  return {
    ...state,
    isLoading: true,
    error: '',
    isError: false,
  };
};

const handleLayerBlockSuccess = (state = INITIAL_STATE) => {
  return {
    ...state,
    isLoading: true,
    error: '',
    isError: false,
  };
};

const handleLayerBlockFailure = (
  state = INITIAL_STATE,
  action: HandleLayerBlockFailureAction
) => {
  return {
    ...state,
    isLoading: false,
    error: action.error,
    isError: true,
  };
};

const setTreeHealthTimePeriod = (
  state = INITIAL_STATE,
  action: setTreeHealthTimePeriodAction
) => {
  return {
    ...state,
    timePeriod: action.timePeriod,
  };
};

const setTreeHealthPrevTimePeriod = (
  state = INITIAL_STATE,
  action: setTreeHealthPrevTimePeriodAction
) => {
  return {
    ...state,
    prevTimePeriod: action.prevTimePeriod,
  };
};

const HANDLERS = {
  [Types.GET_TREE_HEALTH_REQUEST]: getTreeHealthRequest,
  [Types.GET_TREE_HEALTH_INITIAL_REQUEST]: getTreeHealthInitialRequest,
  [Types.GET_TREE_HEALTH_REQUEST_WITH_TIME_PERIOD]: getTreeHealthRequestWithTimePeriod,
  [Types.GET_TREE_HEALTH_SUCCESS]: getTreeHealthSuccess,
  [Types.GET_TREE_HEALTH_FAILURE]: getTreeHealthFailure,
  [Types.ON_HOVER_HEALTH]: onHoverHealth,
  [Types.ON_SELECT_HEALTH]: onSelectHealth,
  [Types.HANDLE_LAYER_BLOCK_REQUEST]: handleLayerBlockRequest,
  [Types.HANDLE_LAYER_BLOCK_SUCCESS]: handleLayerBlockSuccess,
  [Types.HANDLE_LAYER_BLOCK_FAILURE]: handleLayerBlockFailure,
  [Types.GET_SELECTABLE_INDICATORS_REQUEST]: getSelectableIndicatorsRequest,
  [Types.GET_SELECTABLE_INDICATORS_FAILURE]: getSelectableIndicatorsFailure,
  [Types.GET_SELECTABLE_INDICATORS_SUCCESS]: getSelectableIndicatorsSuccess,
  [Types.SET_SELECTED_INDICATOR]: setSelectedIndicator,
  [Types.SET_SELECTED_CRITICALITY]: setSelectedCriticality,
  [Types.SET_TREE_HEALTH_TIME_PERIOD]: setTreeHealthTimePeriod,
  [Types.SET_TREE_HEALTH_PREV_TIME_PERIOD]: setTreeHealthPrevTimePeriod,
  [Types.SET_SELECTED_NODE_UUID]: setSelectedNodeUuid,
};

const Reducer = createReducer(INITIAL_STATE, HANDLERS);

export {
  Reducer as TreeHealthReducer,
  Types as TreeHealthTypes,
  Creators as TreeHealthCreators,
};
