import styled from 'styled-components';

import { supportedDevices } from '../../../../../../../styles/supportedDevices';

interface ContainerProps {
  bottomSheetOpen: boolean;
}

export const Container = styled.div<ContainerProps>`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  color: black;

  .info-navigation,
  .filters {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  .info-navigation {
    margin-bottom: 20px;

    h1 {
      font-size: 20px;
    }

    h4 {
      font-size: 16px;
    }

    h5 {
      font-size: 14px;
    }

    .navigation {
      display: flex;
      align-items: center;

      .csv-container {
        width: 160px;
        height: 48px;
        margin-right: 15px;
      }
    }

    .settings {
      .more-options-button {
        position: relative;
        background-color: ${({ theme, bottomSheetOpen }) =>
          bottomSheetOpen
            ? theme.palette.background.paper
            : 'rgba(230, 232, 235, 0.5)'};

        border-radius: 4px;
        border: 1px solid rgba(0, 0, 0, 0.1);
        padding: 4px;

        .add-icon,
        remove-icon {
          position: absolute;
          transition: all 0.2s ease;
        }

        .add-icon {
          transform: ${({ bottomSheetOpen }) =>
            bottomSheetOpen ? 'rotate(90deg)' : 'rotate(0)'};

          opacity: ${({ bottomSheetOpen }) => Number(!bottomSheetOpen)};
        }

        .remove-icon {
          transform: ${({ bottomSheetOpen }) =>
            bottomSheetOpen ? 'rotate(0)' : 'rotate(90deg)'};

          opacity: ${({ bottomSheetOpen }) => Number(bottomSheetOpen)};
        }
      }
    }
  }

  .filters {
    .search-select {
      width: 100%;

      .search {
        width: 100%;
      }
    }
  }

  @media ${supportedDevices.tablet} {
    min-height: 160px;

    .info-navigation {
      margin: 0;

      h1 {
        font-size: 32px;
      }
    }

    .filters {
      .search-select {
        width: fit-content;

        .search {
          max-height: 48px;
          width: 350px;
        }
      }
    }
  }
`;

interface BottomSheetContentProps {
  downloadFullWidth: boolean;
}

export const BottomSheetContent = styled.div<BottomSheetContentProps>`
  color: black;

  height: 100%;
  padding: 10px;

  h1 {
    margin-bottom: 20px;
  }

  .charts-container {
    display: flex;
    align-items: center;

    margin-bottom: 15px;
  }

  .filters {
    align-items: center;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;

    .csv-container {
      width: ${({ downloadFullWidth }) => (downloadFullWidth ? '100%' : '48%')};
      height: 42px;
      margin-bottom: ${({ downloadFullWidth }) =>
        downloadFullWidth ? '10px' : '0'};
    }
    .date-picker {
      height: 42px;
      width: ${({ downloadFullWidth }) => (downloadFullWidth ? '100%' : '52%')};
    }
  }

  @media ${supportedDevices.mobileM} {
    padding: 20px;
  }
`;
