import React from 'react';
import { RouteComponentProps } from 'react-router-dom';
import { Container, OptionContainer, OptionText, TooltipSpan } from './styles';
import { Slide, Tooltip } from '@material-ui/core';
import { Help } from '@material-ui/icons';
import { TreeViewWrapper } from './components/TreeWrapper/TreeWrapper';

export const Configurations: React.FC<RouteComponentProps> = (props) => {
  return (
    <Container>
      <Slide in timeout={500} direction="left">
        <OptionContainer>
          <OptionText>
            <h1>Nível Inicial do Sunburst</h1>
            <Tooltip
              title={
                <TooltipSpan>
                  {'Selecione o nível incial do gráfico no Dashboard para o seu usuário'}
                </TooltipSpan>
              }
            >
              <Help className="help-icon" />
            </Tooltip>
          </OptionText>
          <TreeViewWrapper />
        </OptionContainer>
      </Slide>
    </Container>
  );
};
