import RealApi from './api';
import { GrafanaUrls } from './api/grafana';

const dev = {
  api: RealApi,
  grafana: GrafanaUrls,
};

const prod = {
  api: RealApi,
  grafana: GrafanaUrls,
};

// eslint-disable-next-line import/no-mutable-exports
let config: typeof prod & typeof dev;

switch (process.env.REACT_APP_STAGE) {
  case 'production':
    config = prod;
    break;
  case 'development':
    config = dev;
    break;
  default:
    config = dev;
}

export default config;
