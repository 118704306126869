import React, { ReactText } from 'react';
import { Chart } from 'react-google-charts';

import { CircularProgress } from '@material-ui/core';

import { kChartMultiColors } from '../../../utils/constants';

import { Container, Loader } from './styles';

export interface BarsProps {
  /**
   * Bars to be displayed
   */
  bars: string[];

  /**
   * values to be displayed in chart
   */
  values: ReactText[][];

  /**
   * colors for the chart to be displayed with
   */
  colors?: string[];
}

/**
 * Bars chart component
 */
export const Bars: React.FC<BarsProps> = ({
  bars,
  values,
  colors = kChartMultiColors,
}) => {
  const ticks = Array.from(Array(6), (_, i) => {
    return { v: i * 20, f: `${i * 20}%` };
  });

  return (
    <Container data-testid="barsChart">
      <Chart
        width="100%"
        height="100%"
        chartType="ColumnChart"
        loader={
          <Loader>
            <CircularProgress size={50} />
          </Loader>
        }
        data={[['', ...bars], ...values]}
        options={{
          backgroundColor: 'transparent',
          tooltip: { isHtml: true },
          animation: {
            startup: true,
            easing: 'linear',
            duration: 1000,
          },
          vAxis: {
            ticks,
          } as any,
          fontName: 'Roboto',
          colors,
        }}
      />
    </Container>
  );
};
