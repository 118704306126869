import styled from 'styled-components';

import { supportedDevices } from '../../styles/supportedDevices';

export const Content = styled.main`
  overflow-y: scroll;
  overflow-x: hidden;

  width: 100%;
  height: 100%;
  padding: 8px 16px;

  @media ${supportedDevices.tablet} and (orientation: landscape) {
    padding: 20px 28px;
  }
`;
