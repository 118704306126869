import { createReducer } from "reduxsauce";

import { Types } from "../actionCreators";

export const INITIAL_STATE = {
  isLoading: false,
  data: [],
  error: "",
  isError: false,
  pageRequest: {
    assetIdentifier: "",
    from: "-5min",
    tagsList: "",
    until: "now"
  }
};

export const getTagsDatapointsRequest = (state = INITIAL_STATE, action) => {
  let filter = { ...state.pageRequest };
  action.pageRequest &&
    action.pageRequest.forEach(e => (filter = { ...filter, ...e }));
  return {
    ...state,
    isLoading: true,
    error: "",
    isError: false,
    pageRequest: filter
  };
};

export const getTagsDatapointsSuccess = (state = INITIAL_STATE, action) => {  
  return {
    ...state,
    isLoading: false,
    data: action.datapoints
  };
};

export const getTagsDatapointsFailure = (state = INITIAL_STATE, action) => {
  return {
    ...state,
    isLoading: false,
    error: action.error,
    isError: false
  };
};

export const HANDLERS = {
  [Types.GET_TAGS_DATAPOINTS_REQUEST]: getTagsDatapointsRequest,
  [Types.GET_TAGS_DATAPOINTS_SUCCESS]: getTagsDatapointsSuccess,
  [Types.GET_TAGS_DATAPOINTS_FAILURE]: getTagsDatapointsFailure
};

export default createReducer(INITIAL_STATE, HANDLERS);
