import styled from 'styled-components';

import { supportedDevices } from '../../../../../styles/supportedDevices';

export const Container = styled.div`
  height: 100%;
  width: 100%;

  .title {
    display: flex;
    align-items: center;
    justify-content: space-between;

    margin-bottom: 10px;

    h1 {
      color: black;
      font-size: 28px;
      font-weight: 500;
    }
  }

  @media ${supportedDevices.tablet} {
    padding: 20px;
  }
`;

export const TooltipSpan = styled.span`
  font-size: 12px;
  line-height: 1.5rem;
`;
