import { createReducer } from "reduxsauce";

import { Types } from "../actionCreators";

export const INITIAL_STATE = {
  isLoading: false,
  data: [],
  osResume: [],
  error: "",
  isError: false,
  errorMsg: "",
  activeTab: "Em andamento",
  searchText: "",
  pageRequest: {
    page: 0,
    lines: 10
  },
  pageResponse: {
    lastPage: true,
    firstPage: true,
    totalPages: 1,
    totalElements: 0,
    actualPage: 1,
    numberOfElements: 0,
    offset: 0
  },
  selected: {
    listNotification: []
  },
  newOs: false,
  formEnable: false
};

export const setEnableForm = (state = INITIAL_STATE, action) => {
  return {
    ...state,
    formEnable: !state.formEnable
  };
};

export const resetOSForm = (state = INITIAL_STATE, action) => {
  return {
    ...INITIAL_STATE
  };
};

export const setOSAsset = (state = INITIAL_STATE, action) => {
  return {
    ...state,
    selected: { ...state.selected, asset: action.asset },
    formEnable: true
  };
};

export const addNotificationInOS = (state = INITIAL_STATE, action) => {
  const {listNotification} = state.selected;
  listNotification.push(action.notification);
  return {
    ...state,
    selected: { ...state.selected, listNotification }
  };
};

export const changeTab = (state = INITIAL_STATE, action) => {
  return {
    ...state,
    pageRequest: { ...state.pageRequest, status: action.id },
    activeTab: action.id
  };
};

export const searchOs = (state = INITIAL_STATE, action) => {
  return {
    ...state,
    searchText: action.text
  };
};

export const getOsRequest = (state = INITIAL_STATE, action) => {
  let filter = { ...state.pageRequest };
  action.pageRequest &&
    action.pageRequest.forEach(e => (filter = { ...filter, ...e }));
  return {
    ...state,
    isLoading: true,
    error: "",
    isError: false,
    pageRequest: filter
  };
};

export const getOsSuccess = (state = INITIAL_STATE, action) => {
  return {
    ...state,
    isLoading: false,
    data: action.os.content,
    pageResponse: {
      totalPages: action.os.totalPages,
      totalElements: action.os.totalElements,
      actualPage: action.os.number,
      lastPage: action.os.last,
      firstPage: action.os.first,
      offset: action.os.pageable.offset
    },
    pageRequest: {
      ...state.pageRequest,
      page: action.os.number
    }
  };
};

export const getOsResumeRequest = (state = INITIAL_STATE, action) => {
  return {
    ...state,
    isLoading: true,
    error: "",
    isError: false
  };
};

export const getOsResumeSuccess = (state = INITIAL_STATE, action) => {
  return {
    ...state,
    isLoading: false,
    osResume: action.resume
  };
};

export const saveOsRequest = (state = INITIAL_STATE, action) => {
  return {
    ...state,
    isLoading: true,
    error: "",
    selected: action.os,
    newOs: false,
    formEnable: false,
    isError: false
  };
};

export const saveOsSuccess = (state = INITIAL_STATE, action) => {
  return {
    ...state,
    isLoading: false
  };
};

export const getOsByIdRequest = (state = INITIAL_STATE, action) => {
  return {
    ...state,
    isLoading: true,
    error: "",
    isError: false
  };
};

export const getOsByIdSuccess = (state = INITIAL_STATE, action) => {
  return {
    ...state,
    isLoading: false,
    selected: action.os
  };
};

export const osFailure = (state = INITIAL_STATE, action) => {
  return {
    ...state,
    isLoading: false,
    data: [],
    selected: {},
    error: action.error,
    isError: true
  };
};

export const HANDLERS = {
  [Types.GET_OS_REQUEST]: getOsRequest,
  [Types.GET_OS_SUCCESS]: getOsSuccess,

  [Types.GET_OS_RESUME_REQUEST]: getOsResumeRequest,
  [Types.GET_OS_RESUME_SUCCESS]: getOsResumeSuccess,

  [Types.GET_OS_BY_ID_REQUEST]: getOsByIdRequest,
  [Types.GET_OS_BY_ID_SUCCESS]: getOsByIdSuccess,

  [Types.SAVE_OS_REQUEST]: saveOsRequest,
  [Types.SAVE_OS_SUCCESS]: saveOsSuccess,
  [Types.OS_FAILURE]: osFailure,

  [Types.CHANGE_OS_TAB]: changeTab,
  [Types.SET_ENABLE_FORM]: setEnableForm,
  [Types.SET_OS_ASSET]: setOSAsset,
  [Types.ADD_NOTIFICATION_IN_OS]: addNotificationInOS,
  [Types.RESET_OS_FORM]: resetOSForm,

  [Types.SEARCH_OS]: searchOs
};

export default createReducer(INITIAL_STATE, HANDLERS);
