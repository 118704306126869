import { createReducer } from "reduxsauce";

import { Types } from "../actionCreators";

export const INITIAL_STATE = {
  isLoading: false,
  data: [],
  units: [],
  plants: [],
  areas: [],
  subareas: [],
  equipaments: [],
  classes: [],
  assets: [],
  error: "",
  isError: false
};

export const getTreeFilterRequest = (state = INITIAL_STATE, action) => {
  return {
    ...state,
    isLoading: true,
    isError: false,
    error: ""
  };
};

export const getTreeFilterSuccess = (state = INITIAL_STATE, action) => {
  return {
    ...state,
    isLoading: false,
    data: action.data,
    [action.field]: action.data
  };
};

export const setTreeFilterField = (state = INITIAL_STATE, action) => {
  return {
    ...state,
    isLoading: false,
    [action.field]: []
  };
};

export const getTreeFilterFailure = (state = INITIAL_STATE, action) => {
  return {
    ...state,
    isLoading: false,
    data: [],
    isError: true,
    error: action.error
  };
};

export const HANDLERS = {
  [Types.GET_TREE_FILTER_REQUEST]: getTreeFilterRequest,
  [Types.GET_TREE_FILTER_SUCCESS]: getTreeFilterSuccess,
  [Types.GET_TREE_FILTER_FAILURE]: getTreeFilterFailure,
  [Types.SET_TREE_FILTER_FIELD]: setTreeFilterField
};

export default createReducer(INITIAL_STATE, HANDLERS);
