import React from 'react';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';

import { IconButton, Fade, Tooltip, Typography } from '@material-ui/core';
import { ArrowBack, ArrowForward } from '@material-ui/icons';

import { HealthIndicator } from '../../../../../../../../components/charts/HealthIndicator';
import { AppState } from '../../../../../../../../store/reducers';

import { Container, TooltipSpan, TitleButton } from './styles';

import { ResponsiveHeaderParams } from '..';

export const DesktopHeader: React.FC<ResponsiveHeaderParams> = ({
  isLogical,
  trailStrings,
  handleBlockAsset,
  graphViewState,
  toggleAssetAlertsModal,
  handleEditAsset
}) => {
  const assetData = useSelector((state: AppState) => state.assetData);

  return (
    <Container data-testid="header">
      <div className="info-container">
        <div className="arrow-container">
          <Link
            to={{
              pathname: '/admin/dashboard',
              state: graphViewState,
            }}
          >
            <IconButton color="primary" className="back-button">
              <ArrowBack fontSize="large" />
            </IconButton>
          </Link>
          <h1>Informações do ativo</h1>
        </div>
        <div className="breadcrumbs-container">
          {trailStrings.map((item, index, array) => (
            <React.Fragment key={item}>
              <Fade in>
                <Tooltip
                  title={<TooltipSpan>{item}</TooltipSpan>}
                  enterDelay={500}
                >
                  <div
                    className={`${
                      index === array.length - 1 && 'gradient'
                    } breadcrumb`}
                  >
                    <Typography noWrap component="span" variant="button">
                      {item}
                    </Typography>
                    {index !== array.length - 1 && <ArrowForward />}
                  </div>
                </Tooltip>
              </Fade>
            </React.Fragment>
          ))}
        </div>
        <div className="title-container">
          <h1>{assetData.data.name}</h1>
          <TitleButton
            variant="contained"
            onClick={handleBlockAsset}
            data-testid="blockedButton"
          >
            {assetData.data.blocked ? 'Desbloquear' : 'Bloquear'}
          </TitleButton>
            <TitleButton
              variant="contained"
              onClick={() => toggleAssetAlertsModal(true)}
              data-testid="alertButton"
            >
              alertas
            </TitleButton>
          <TitleButton
            variant="contained"
            onClick={() => handleEditAsset(true)}
            data-testid="editAssetButton"
            disabled={assetData.data['isLoading']}
          >
            Editar
            </TitleButton>
        </div>
        <div className="description-container">
          {assetData.data.description && (
            <p data-testid="descriptionContainer">{`${assetData.data.description} |`}</p>
          )}
          <p>{`${assetData.data.modelDescription}`}</p>
        </div>
      </div>
      <div>
        <HealthIndicator
          size={30}
          value={assetData.data.health}
          fontSize={32}
          healthType={isLogical ? 'logical' : 'physical'}
          width={185}
        />
      </div>
    </Container>
  );
};
