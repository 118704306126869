import React from 'react';
import { Switch, BrowserRouter, Route, Redirect } from 'react-router-dom';

import { HomeScreen } from './screens';
import { Screen404 } from './screens/404';
import { AuthScreen } from './screens/Auth';
import { Splash } from './screens/Splash';

export const Router: React.FC = () => {
  return (
    <BrowserRouter>
      <Switch>
        <Route path="/auth" component={AuthScreen} />
        <Route path="/admin" component={HomeScreen} />
        <Route path="/404" component={Screen404} />
        <Route path="/" component={Splash} />
        <Redirect from="*" to="/404" />
      </Switch>
    </BrowserRouter>
  );
};
