import React from 'react';

import {
  Radio,
  Checkbox,
  makeStyles,
  Theme,
  createStyles,
  Tooltip,
} from '@material-ui/core';
import { TreeItem } from '@material-ui/lab';

import { SelectionModes } from '../..';

import { LabelContainer, TooltipSpan } from './styles';

export interface CustomTreeItemProps {
  checked: boolean;
  handleChange(): void;
  nodeId: string;
  labelText: string;
  selectionMode: SelectionModes;
  showSelect?: boolean;
}

const useTreeItemStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      color: 'black',
      '&:hover > $content': {
        backgroundColor: 'rgba(230,232,235,0.5)',
      },
      '&:focus > $content, &$selected > $content': {
        backgroundColor: theme.palette.background.paper,
      },
      '&:focus > $content $label, &:hover > $content $label, &$selected > $content $label': {
        backgroundColor: 'transparent',
      },
    },
    content: {
      color: 'black',
      borderTopRightRadius: theme.spacing(2),
      borderBottomRightRadius: theme.spacing(2),
      paddingRight: theme.spacing(1),
      fontWeight: theme.typography.fontWeightMedium,
      '$expanded > &': {
        fontWeight: theme.typography.fontWeightRegular,
      },
    },
    group: {},
    expanded: {},
    selected: {},
    label: {
      fontWeight: 'inherit',
      color: 'inherit',
    },
  })
);

export const CustomTreeItem: React.FC<CustomTreeItemProps> = ({
  checked,
  handleChange,
  labelText,
  selectionMode,
  showSelect = false,
  ...other
}) => {
  const classes = useTreeItemStyles();

  function buildSelect() {
    if (showSelect)
      return selectionMode === 'single' ? (
        <Radio
          checked={checked}
          onChange={handleChange}
          value={labelText}
          color="primary"
          data-testid="radio"
        />
      ) : (
        <Checkbox
          checked={checked}
          onChange={handleChange}
          color="primary"
          data-testid="checkbox"
        />
      );
    return undefined;
  }

  return (
    <TreeItem
      data-testid="treeItem"
      label={
        <Tooltip
          title={<TooltipSpan>{labelText}</TooltipSpan>}
          enterDelay={1000}
        >
          <LabelContainer>
            <div className="label-text">{labelText}</div>
            {buildSelect()}
          </LabelContainer>
        </Tooltip>
      }
      classes={{
        root: classes.root,
        content: classes.content,
        expanded: classes.expanded,
        selected: classes.selected,
        group: classes.group,
        label: classes.label,
      }}
      {...other}
    />
  );
};
