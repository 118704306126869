import React from "react";
import { BaseButton } from "./styles";

export interface ToggleButtonProps {
  onToggle: (event: React.MouseEvent<HTMLButtonElement>) => void;
  isSelected: boolean;
  offIcon: string;
  onIcon: string;
  alt: string;
}

export const ToggleButton: React.FC<ToggleButtonProps> = ({
  onToggle,
  isSelected,
  offIcon,
  onIcon,
  alt,
}) => {
  return (
    <BaseButton onClick={onToggle} isSelected={isSelected}>
      <img src={isSelected ? onIcon : offIcon} alt={alt} />
    </BaseButton>
  );
};
