import React from 'react';
import { RouteComponentProps } from 'react-router-dom';

import { NavigationPanel } from './components/panels/NavigationPanel';
import { Router } from './router';

import { Content } from './styles';

export const Reports: React.FC<RouteComponentProps> = (props) => {
  return (
    <>
      <NavigationPanel {...props} />
      <Content>
        <Router {...props} />
      </Content>
    </>
  );
};
