import { ButtonBase, Modal } from '@material-ui/core';
import styled from 'styled-components';

export const Container = styled.div`
  display: flex;

  height: 100%;

  .resolution-container,
  .select-container {
    width: 150px;
    margin-right: 10px;
  }

  .button-container > :first-child {
    margin-right: 10px;
  }

  .MuiInputAdornment-root {
    border-right: 1px solid rgba(0, 0, 0, 0.36);
    height: 20px;
  }
`;

export const TooltipSpan = styled.span`
  font-size: 12px;
`;

interface ButtonProps {
  isSelected: boolean;
}

export const Button = styled(ButtonBase).withConfig({
  shouldForwardProp: (prop) => !['isSelected'].includes(prop),
})<ButtonProps>`
  && {
    min-width: 48px;
    height: 48px;
    padding: 5px;
    border-radius: 4px;
    font-weight: 600;

    border: solid 1px rgba(0, 0, 0, 0.1);

    color: ${({ isSelected }) => (isSelected ? '#000' : 'rgba(0, 0, 0, 0.5)')};

    background: ${({ isSelected, theme }) =>
      isSelected ? theme.palette.background.paper : 'rgba(230,232,235, 0.5)'};

    && .MuiSvgIcon-colorPrimary {
      color: ${({ isSelected }) =>
        isSelected ? '#000' : 'rgba(0, 0, 0, 0.5)'};
    }
  }
`;

export const CustomModal = styled(Modal)`
  display: flex;
  height: 100%;
  justify-content: center;
  align-items: center;
  font-size: 1.5rem;

  .outline-0 {
    outline: 0;
  }
`;
