import { createReducer } from 'reduxsauce';

import { Types } from '../actionCreators';

export const INITIAL_STATE = {
  isLoading: false,
  data: [],
  error: '',
  isError: false,
};

export const getCostCentersRequest = (state = INITIAL_STATE) => {
  return {
    ...state,
    isLoading: true,
    error: '',
    isError: false,
  };
};

export const getCostCentersSuccess = (state = INITIAL_STATE, action) => {
  return {
    ...state,
    isLoading: false,
    data: action.data,
  };
};

export const getCostCentersFailure = (state = INITIAL_STATE, action) => {
  return {
    ...state,
    isLoading: false,
    data: [],
    error: action.error,
    isError: false,
  };
};

export const HANDLERS = {
  [Types.GET_COST_CENTERS_REQUEST]: getCostCentersRequest,
  [Types.GET_COST_CENTERS_SUCCESS]: getCostCentersSuccess,
  [Types.GET_COST_CENTERS_FAILURE]: getCostCentersFailure,
};

export default createReducer(INITIAL_STATE, HANDLERS);
