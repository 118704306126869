import qs from 'qs';
import { put, select } from 'redux-saga/effects';

import config from '../../config';
import { Creators } from '../actionCreators';

export function* getTagsDatapoints() {
  try {
    const pageRequest = yield select(
      (state) => state.tagsDatapoints.pageRequest
    );
    const pageRequestQs = `?${qs.stringify(pageRequest)}`;
    const result = yield config.api.getTagsDatapoints(pageRequestQs);
    yield put(Creators.getTagsDatapointsSuccess(result.data));
  } catch (error) {
    yield put(
      Creators.getTagsDatapointsFailure(
        'Ocorreu um erro ao buscar os dados destas tags.'
      )
    );
  }
}
