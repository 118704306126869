import { ListItem, IconButton } from '@material-ui/core';
import styled from 'styled-components';

import { supportedDevices } from '../../../../../styles/supportedDevices';

interface AsideProps {
  isOpen: boolean;
}

export const Aside = styled.aside<AsideProps>`
  display: grid;
  place-items: center;
  color: black;

  height: 100%;
  padding: 20px 0;
  width: ${({ isOpen }) => (isOpen ? '420px' : 0)};
  padding-left: ${({ isOpen }) => (isOpen ? '24px' : 0)};

  transition: width 0.4s, padding 0.4s;
`;

interface ContainerProps {
  isOpen: boolean;
}

export const Container = styled.div<ContainerProps>`
  position: relative;

  height: 100%;
  width: 100%;

  .card {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;

    overflow-y: auto;
    overflow-x: hidden;
    background: white;
    border-radius: 4px;
    box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.04);

    padding: 0;

    opacity: 0;
    z-index: -1;
    transform: translateX(-25px);
    transition: all 0.4s;

    ::-webkit-scrollbar {
      width: 3px;
    }

    *:not(input) {
      opacity: ${({ isOpen }) => Number(isOpen)};
    }

    .list {
      svg.help-icon {
        color: rgba(0, 0, 0, 0.6);
      }
    }

    h1 {
      font-size: 28px;
      font-weight: 500;
    }

    &.selected {
      transform: translateX(0);
      opacity: 1;
      z-index: 1;
    }

    .title {
      display: flex;
      align-items: center;
      justify-content: space-between;

      margin-bottom: 10px;

      .iconsPanel {
        display: flex;
        margin-left: 10px;
      }
    }

    .panelIcons {
      .MuiSvgIcon-fontSizeLarge {
        font-size: 2.4rem;
      }
    }

    @media ${supportedDevices.tablet} {
      padding: ${({ isOpen }) => (isOpen ? '20px' : '20px 0')};
    }
  }
`;

interface CustomListItemProps {
  isSelected: boolean;
}

export const CustomListItem = styled(ListItem).withConfig({
  shouldForwardProp: (prop) => !['isSelected'].includes(prop),
})<CustomListItemProps>`
  && {
    width: 100%;
    background-color: ${({ isSelected }) =>
      isSelected ? 'rgba(230,232,235, 0.5)' : 'white'};
    transition: background-color 0.2s;

    &:hover {
      background-color: ${({ theme }) => theme.palette.background.paper};
    }

    &.nested {
      padding-left: 50px;
    }
  }
`;

export const TooltipSpan = styled.span`
  font-size: 12px;
  line-height: 1.5rem;
`;
