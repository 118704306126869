import React, { useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import {
  CircularProgress,
  Collapse,
  AccordionSummary,
  Typography,
  AccordionDetails,
} from '@material-ui/core';
import { ExpandMore as ExpandMoreIcon } from '@material-ui/icons';
import { endOfWeek, startOfWeek, format } from 'date-fns';

import errorIcon from '../../../../../../../../assets/imgs/error-icon.png';
import linesOnIcon from '../../../../../../../../assets/new_icons/ic-switch-lines-select.svg';
import linesOffIcon from '../../../../../../../../assets/new_icons/ic-switch-lines-unselect.svg';
import tableOnIcon from '../../../../../../../../assets/new_icons/ic-switch-table-select.svg';
import tableOffIcon from '../../../../../../../../assets/new_icons/ic-switch-table-unselect.svg';
import { Lines } from '../../../../../../../../components/charts/Lines';
import { Table } from '../../../../../../../../components/charts/Table';
import { TimePeriod } from '../../../../../../../../components/DateFilter';
import { PickerType } from '../../../../../../../../components/PeriodDateFilter';
import { ToggleChartButton } from '../../../../../../../../components/ToggleChartButton';
import { HealthAndIndicatorsPerPeriod } from '../../../../../../../../models/Reports';
import { Creators } from '../../../../../../../../store/actionCreators';
import { AppState } from '../../../../../../../../store/reducers';
import { PeriodResolution } from '../../../../../../../../store/reports/types';
import {
  generateCsv,
  getElementFromTree,
} from '../../../../../../../../utils/methods';
import { Header } from '../../Header';

import { Container, TableCell, CustomAccordion } from './styles';
import { useAuthStore } from '../../../../../../../../zustand/AuthStore';

const timeList = [
  ['1d', 'diário', 'week', 'Semana'],
  ['1w', 'semanal', 'month', 'Mês'],
  ['1month', 'mensal', 'trimester', 'Trimestre'],
  ['1month', 'mensal', 'semester', 'Semestre'],
].map(([resolution, resLabel, picker, label]) => {
  return {
    resolution: [resolution, resLabel] as [PeriodResolution, string],
    picker: picker as PickerType,
    label,
  };
});

type RowData = (string | [number | null, number | null])[];

export const IndicatorsPerPeriod: React.FC = () => {
  const { reports } = useSelector((state: AppState) => state);
  const { isLoading, error, data, timePeriod, elementUuid } = reports;
  const { perPeriod } = data;
  const dispatch = useDispatch();

  const user = useAuthStore((state) => state.user);

  const [selectedChart, setSelectedChart] = useState<'table' | 'lines'>(
    'table'
  );

  useEffect(() => {
    dispatch(Creators.setNavigationOption('treeView'));
    dispatch(Creators.setTreeOptions(['LogicalAsset', 'Asset']));
  }, [dispatch]);

  useEffect(() => {
    if (user.tree && elementUuid) {
      const nowElement = getElementFromTree(
        user.tree,
        (element) => element.uuid === elementUuid
      );

      const firstAssetFromNowElement = getElementFromTree(
        nowElement,
        (el) => el.type === 'LogicalAsset'
      );

      if(firstAssetFromNowElement) dispatch(Creators.setElementUuid(firstAssetFromNowElement.uuid));
      else{
        const firstAsset = getElementFromTree(
          user.tree,
          (el) => el.type === 'LogicalAsset'
        );
        if (firstAsset) dispatch(Creators.setElementUuid(firstAsset.uuid));
      }
    }
  }, [user.tree, elementUuid, dispatch]);

  useEffect(() => {
    if (elementUuid) {
      let period = {} as TimePeriod;
      if (timePeriod.startTime === 'now-24h' || timePeriod.startTime === null)
        period = {
          startTime: startOfWeek(new Date()).getTime(),
          endTime: endOfWeek(new Date()).getTime(),
        };
      else period = { ...timePeriod };
      dispatch(
        Creators.getReportsRequest(period, 'healthAndIndicatorsLogicalLayerAnalysis')
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch, elementUuid]);

  function buildHealth(health: number | string | null, isPercentage = false) {
    let healthText: string;
    let color: string = 'black';
    if (health === null || typeof health !== 'number') healthText = '-';
    else {
      const value = Number(Math.fround(health * 100).toFixed(2));

      if (value > 0) color = 'green';
      else if (value < 0) color = 'red';

      healthText = `${value.toFixed(2)}%`;
    }
    return (
      <div className={`cellValue ${isPercentage ? color : ''}`}>
        {healthText}
      </div>
    );
  }

  function buildHeaders(elements: HealthAndIndicatorsPerPeriod) {
    return [
      '',
      ...elements.tagsValuesAndEvolution.map(
        (element) => element.representationTagName
      ),
    ];
  }

  /**
   * Get formated Rows from reports data to be used in Table.
   */
  function buildRows(elements: HealthAndIndicatorsPerPeriod) {
    const elementsHealthData = elements.tagsValuesAndEvolution[0];

    const formatedRowsData = elementsHealthData?.dataPointsValues.map(
      (dataPoint) => {
        const dataPointDate = dataPoint.time.slice(
          0,
          dataPoint.time.indexOf('T')
        );

        return [
          dataPoint.time,
          ...elements.tagsValuesAndEvolution.map(
            ({ dataPointsValues, dataPointsPercentageEvolution }) => [
              dataPointsValues.find((currentDataPoint) => {
                const currentDataPointDate = currentDataPoint.time.slice(
                  0,
                  currentDataPoint.time.indexOf('T')
                );
                return currentDataPointDate === dataPointDate;
              })?.value,
              dataPointsPercentageEvolution.find((currentDataPoint) => {
                const currentDataPointDate = currentDataPoint.time.slice(
                  0,
                  currentDataPoint.time.indexOf('T')
                );
                return currentDataPointDate === dataPointDate;
              })?.value,
            ]
          ),
        ];
      }
    );

    return formatedRowsData;
  }

  function createTableRow(item: RowData) {
    const [date, ...indicators] = item;

    return [
      format(new Date(date as string), 'dd/MM/yyyy'),
      ...(indicators as [number | null, number | null][]).map(
        ([value, percentage]) => (
          <TableCell>
            {buildHealth(value)}
            {buildHealth(percentage, true)}
          </TableCell>
        )
      ),
    ];
  }

  function buildCSVHealth(health: number | null) {
    if (health === null) return '-';
    if (typeof health !== 'number') return 0;
    return `${String(Math.fround(health * 100).toFixed(2))}%`;
  }

  function createCSVRow(row: (string | (number | null | undefined)[])[]) {
    const [date, ...indicators] = row;

    return [
      format(new Date(date as string), 'dd/MM/yyyy'),
      ...(indicators as [number | null, number | null][]).map(
        ([value, percentage]) =>
          `${buildCSVHealth(value)};${buildCSVHealth(percentage)}`
      ),
    ];
  }

  function handleDownloadCsv() {
    if (perPeriod) {
      const timeStamp =
        typeof timePeriod.startTime === 'string'
          ? timePeriod.startTime.split('-')[1]
          : `${format(new Date(timePeriod.startTime!), 'dd/MM/yyyy')}-${format(
              new Date(timePeriod.endTime!),
              'dd/MM/yyyy'
            )}`;

      generateCsv(
        buildHeaders(perPeriod).map(
          (name, index) => `${name}${index > 0 ? ';' : ''}`
        ),
        buildRows(perPeriod).map((row) => createCSVRow(row)),
        `Relatorio_${perPeriod.treeElementName}_Indicadores_${timeStamp}.csv`
      );
    }
  }

  const generateChartValues = useCallback(() => {
    const buildHealthNumber = (health: number | null) =>
      health === null ? 0 : Number(Math.fround(health * 100).toFixed(2));

    return perPeriod?.tagsValuesAndEvolution[0]?.dataPointsValues
      .filter((dp) => typeof dp.value === 'number')
      .map((dataPoint) => {
        return [
          format(new Date(dataPoint.time), 'dd/MM/yyyy'),
          ...perPeriod?.tagsValuesAndEvolution.flatMap((values) =>
            values.dataPointsValues
              .filter((dp) => dp.time === dataPoint.time)
              .map((dp) => buildHealthNumber(dp.value))
          ),
        ];
      });
  }, [perPeriod]);

  function buildToggleChartButtons(iconSize: number) {
    return (
      <>
        <ToggleChartButton
          onIcon={tableOnIcon}
          offIcon={tableOffIcon}
          alt="Mostrar ou esconder tabela"
          isSelected={selectedChart === 'table'}
          onToggle={() => setSelectedChart('table')}
          iconSize={iconSize}
          height={48}
          name="Tabela"
        />
        <ToggleChartButton
          onIcon={linesOnIcon}
          offIcon={linesOffIcon}
          alt="Mostrar ou esconder gráfico de linhas"
          isSelected={selectedChart === 'lines'}
          onToggle={() => setSelectedChart('lines')}
          iconSize={iconSize}
          height={48}
          name="Linhas"
          disabled={
            !generateChartValues() || generateChartValues()?.length === 0
          }
        />
      </>
    );
  }

  function buildErrorAndLoader() {
    if (error)
      return (
        <div className="error">
          <img src={errorIcon} alt="Ícone de erro" />
          <h3>Ocorreu um erro! Recarregue a página para tentar novamente.</h3>
        </div>
      );
    return (
      <div className="loader" data-testid="loader">
        <CircularProgress size={50} />
      </div>
    );
  }

  if (!elementUuid)
    return (
      <Container className="no-element">
        <h2>Selecione um elemento para iniciar</h2>
      </Container>
    );

  return (
    <Container data-testid="reportContainer">
      <Header
        title="Indicadores por período"
        subtitle={perPeriod?.treeElementName}
        description={perPeriod?.description}
        datePicker="period"
        hideSearchBar
        timeList={timeList}
        buildToggleChartsButtons={buildToggleChartButtons}
        handleDownloadCsv={handleDownloadCsv}
      />
      {error || isLoading || !perPeriod ? (
        buildErrorAndLoader()
      ) : (
        <div className="charts">
          <Collapse in={selectedChart === 'table'} timeout="auto" unmountOnExit>
            <div className="table">
              <Table
                headers={buildHeaders(perPeriod)}
                rows={buildRows(perPeriod)}
                createRow={createTableRow}
                firstColumnStyle={{
                  backgroundColor: '#E6E8EB',
                  border: '2px solid white',
                }}
              />
            </div>
          </Collapse>
          <Collapse in={selectedChart === 'lines'} timeout="auto" unmountOnExit>
            <div className="lines">
              <CustomAccordion defaultExpanded>
                <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                  <Typography>{perPeriod.treeElementName}</Typography>
                </AccordionSummary>
                <AccordionDetails>
                  <div className="lines-container" data-testid="chartContainer">
                    <Lines
                      lines={perPeriod.tagsValuesAndEvolution.map(
                        (indicator) => indicator.representationTagName
                      )}
                      values={generateChartValues()!}
                      yAxisTitle="Nota"
                    />
                  </div>
                </AccordionDetails>
              </CustomAccordion>
            </div>
          </Collapse>
        </div>
      )}
    </Container>
  );
};
